import { useEffect, useState } from "react";
import axios from "axios";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import InventoryOutIndex from "../../../../components/AppComponents/Autoloka/document/InventoryOut";
import InventoryOutAdd from "../../../../components/AppComponents/Autoloka/document/InventoryOut/add";
import InventoryOutEdit from "../../../../components/AppComponents/Autoloka/document/InventoryOut/edit";
import InventoryOutView from "../../../../components/AppComponents/Autoloka/document/InventoryOut/view";
import DeleteConfirmationModal from "../../../../components/modals/deleteConfirmation";

import MDSnackbar from "../../../../components/MDSnackbar";

import services from "../../../../service/services";

import { addCommas, cleanObject } from "../../../../utils";
import moment from "moment/moment";
import { useSearchParams } from "react-router-dom";

const InventoryOut = ({
	setIsLoading,
	setMode,
	mode,
 }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [rows, setRows] = useState([]);
	const [rowsDisplayed, setRowsDisplayed] = useState([]);
	const [idDetail, setIdDetail] = useState(null);
	const [paginationDetail, setPaginationDetail] = useState({});
	const [isFiltered, setIsFiltered] = useState(false);
	const [requester, setRequester] = useState([]);
	const [productOptions, setProductOptions] = useState([]);
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [dataFilter, setDataFilter] = useState({
		doc_no: searchParams.get("doc_no") || "",
		requester: searchParams.get("requester") || "",
		status: searchParams.get("status") || "",
		date_range: searchParams.get("date_range") || "",
	});

  const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Action", accessor: "action", align: "left" },
		{ Header: "Doc no", accessor: "doc_no", align: "left" },
		{ Header: "Requester", accessor: "requester", align: "left" },
		{ Header: "Total items", accessor: "total_items", align: "left" },
		{ Header: "Status", accessor: "status", align: "left" },
		{ Header: "Created date", accessor: "created_date", align: "left" },
	];

	useEffect(() => {

		let data = {...dataFilter, page: searchParams.get("page") || 1};
		if(data?.date_range?.length > 0){
			let daterangevalue = data.date_range.split(" - ");
			data.start_date = moment(daterangevalue[0], "DD.MM.YYYY").format("YYYY.MM.DD");
			data.end_date = moment(daterangevalue[1], "DD.MM.YYYY").format("YYYY.MM.DD");
		} else {
			data.start_date = "";
			data.end_date = "";
		};
		delete data.date_range;
		
		let fetcher;
		if(Object.keys(cleanObject(dataFilter)).length !== 0) {
			fetcher = services.filterInventoryOut(data);
		}else {
			fetcher = services.getAllInventoryOut(searchParams.get("page") || 1)
		}

		if(mode === "index"){
			setIsLoading(true);
			Promise
				.all([
					fetcher,
					services.getRequesterInventoryOut(),
					services.getProductInventoryOut(),
				]).then(axios.spread((...res) => {
					setIsLoading(false);
					setRows(settingRowsDataTable(res[0]?.data?.data?.list_inventory_out, 1));
					setPaginationDetail(res[0]?.data?.data);
					setRequester(res[1]?.data?.data);
					setProductOptions(res[2]?.data?.data);
				})).catch(errors => {
					setIsLoading(false);
					console.log("Error is found, apologies.")
					console.log(errors)
				})
		}
	}, [mode]);

	const settingRowsDataTable = (rowsArray, current_page) => {
		return rowsArray?.map((arr, i) => {
			return {
				no: ((current_page - 1) * 10) + i + 1,
				action: 
					arr?.status === 0 ?
					<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setMode("view"); setIdDetail(arr.id)}}>visibility</Icon>
					: <Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setMode("edit"); setIdDetail(arr.id)}}>edit</Icon>
				,
				doc_no: arr?.doc_no,
				requester: arr?.requester,
				total_items: arr?.total_items,
				status: arr?.status === 1 ? "Open" : "Close",
				created_date: moment(arr.created_date).format('DD-MM-yyyy'),
			}
		})
	};

	const getList = (page) => {
		setIsLoading(true);
		setIsFiltered(false);
		services.getAllInventoryOut(page)
			.then(res => {
				setIsLoading(false);
				setIsFiltered(false);
				setRows(settingRowsDataTable(res?.data?.data?.list_inventory_out, res?.data?.data?.current_page));
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	};

  return (
		<>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="Purchase Order Draft Deleted!"
				dateTime="Just Now"
				open={showNotifSuccess}
				close={() => setShowNotifSuccess(false)}
			/>
			<Grid container spacing={6} className="grid-pure-container">
				{
					mode === "index" ?
						<InventoryOutIndex 
						setDataFilter={setDataFilter}
						dataFilter={dataFilter}
							columns={columns} 
							rows={rows}
							rowsDisplayed={rowsDisplayed}
							setRowsDisplayed={setRowsDisplayed}
							setIsLoading={setIsLoading}
							paginationDetail={paginationDetail}
							setPaginationDetail={setPaginationDetail}
							isFiltered={isFiltered}
							setIsFiltered={setIsFiltered}
							getList={getList}
							settingRowsDataTable={settingRowsDataTable}
							setMode={setMode}
							requester={requester}
						/>
					: mode === "add" ?
						<InventoryOutAdd 
							setMode={setMode}
							setIsLoading={setIsLoading}
							requester={requester}
							productOptions={productOptions}
						/>
					: mode === "edit" ?
						<InventoryOutEdit
							setMode={setMode}
							setIsLoading={setIsLoading}
							requester={requester}
							id={idDetail}
							productOptions={productOptions}
						/> 
					: mode === "view" ?
						<InventoryOutView 
						setMode={setMode}
							setIsLoading={setIsLoading}
							requester={requester}
							id={idDetail}
							productOptions={productOptions}
						/>
					: null
				}
			</Grid>
		</>
  );
}

export default InventoryOut;
