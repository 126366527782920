import React from "react";
import { toast } from "react-toastify";
import axios from 'axios';
import qs from 'qs';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const ModalUnauthorized = ({ closeToast }) =>{
	const navigate = useNavigate();
	const closeToastModal = () => {
		Object.keys(Cookies.get()).forEach((cookieName) => {
			Cookies.remove(cookieName);
		});
		navigate("/");
		closeToast(); 
	}

	return (<div>
		<div className="text-24 text-bold mb-3">Session Expired</div>
		<div className="text-16 mb-4">We are sorry to say your token has expired. Please re-login to extend your token session</div>
		<div className="d-flex justify-content-end">
			<div className="text-bold" onClick={closeToastModal}>Go Back to Login Page</div>
		</div>
	</div>)
}

const getAuthToken = (options) => {
	let result = {}, dataToken;
	if(Cookies.get("token")){
		dataToken = Cookies.get("token");
	}
	if (options.token && dataToken) {
		result = {
			Authorization: `${dataToken}`
		};
	}
	if(options.method === "POST"){
		result["Content-Type"] = options.contentType;
	}
	return result;
};

const serviceApi = async (options) => {
	let baseUrl = process.env.NODE_ENV === 'development' ? `${process.env.REACT_APP_BE_DEV_URL}` : `${process.env.REACT_APP_BE_PROD_URL}`;
	// let baseUrl = `${process.env.REACT_APP_BE_DEV_URL}`;
	let axiosConfig = {
		baseURL: baseUrl,
		timeout: 20000,
	};

	axiosConfig.headers = getAuthToken(options);

	options.paramsSerializer = (params) => {
		return qs.stringify(params);
	};

	const instance = axios.create(axiosConfig);
	return await instance(options)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			if(err.response?.status === 401 && options.url !== "user/sign-in/dashboard"){
				toast.error(<ModalUnauthorized />, {toastId: "unAuthorized", closeOnClick: false, pauseOnHover: false, draggable: false, icon: false});
			} 
			return Promise.reject(err);
		});
};

export default serviceApi;