import { useEffect, useState } from "react";
import moment from 'moment';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Icon, MenuItem } from "@mui/material";
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import InputAdornment from '@mui/material/InputAdornment';

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDInput from "../../../../MDInput";
import MDEditor from "../../../../MDEditor";
import MDSnackbar from "../../../../MDSnackbar";
import MDTypography from "../../../../MDTypography";
import DataTable from "../../../../Table";

import services from "../../../../../service/services";

import { addCommas, getImageError } from "../../../../../utils";

import placeholder from "../../../../../assets/images/placeholder-image.jpeg";

const VoucherView = ({
	setMode,
	setIsLoading,
	settingPaginationDetail,
	settingUserRows,
	userGotoPage,
	settingRegionRows,
	regionGotoPage,
	productGotoPage,
	userRows,
	setUserRows,
	setUserRawRows,
	userColumns,
	userPaginationDetail,
	setUserPaginationDetail,
	regionRows,
	setRegionRows,
	setRegionRawRows,
	regionColumns,
	regionPaginationDetail,
	setRegionPaginationDetail,
	productRows,
	setProductRawRows,
	productColumns,
	productPaginationDetail,
	id,
}) => {

	const [detailData, setDetailData] = useState({});
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [displayDate, setDisplayDate] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		services.getVoucherDetail(id)
			.then(res => {
				setIsLoading(false);
				let data = res?.data?.data;
				setDetailData(data);
				if(data.customer_type === 1){
					setUserRawRows(data.array_user || []);
					let paginationDetailUser = settingPaginationDetail(data.array_user);
					setUserRows(settingUserRows(data.array_user, paginationDetailUser.total_page));
					setUserPaginationDetail(paginationDetailUser);
				} else if(data.customer_type === 2){
					setRegionRawRows(data.array_region || []);
					let paginationDetailRegion = settingPaginationDetail(data.array_region);
					setRegionRows(settingRegionRows(data.array_region, paginationDetailRegion.total_page));
					setRegionPaginationDetail(paginationDetailRegion);
				}
				setProductRawRows(data.array_product);
			}).catch(errors => {
				setIsLoading(false);
				setErrorMessage(errors?.response?.data?.message);
				setShowNotifError(true);
			})
	}, [id])

	const TabsList = styled(TabsListUnstyled)`
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		align-content: space-between;
	`;

	const Tab = styled(TabUnstyled)`
		cursor: pointer;
		font-size: 18px;
		background-color: #CFD8DC;
		width: 200px;
		border-radius: 8px 8px 0 0;
		border: 2px solid #000;
		border-bottom: none;
		display: flex;
		justify-content: center;
		
		&:hover, &:focus, &.${tabUnstyledClasses.selected} {
			font-weight: bold;
			background-color: #FFF;
		}
	`;

	const backClickHandler = () => {
		setMode("index");
	}
	
  return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" justifyContent="space-between" mt={-0.5} mb={2.5} width="100%">
			<MDBox display="flex" alignItems="center">
					<button onClick={backClickHandler} style={{border: 'none', background: 'white', borderRadius: '5px', marginRight: '1rem',width: '2rem', height: '2rem',display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
						<Icon fontSize="medium">
							arrow_back
						</Icon>
					</button>
					<MDTypography variant="h3" fontWeight="regular">
						Detail Voucher
					</MDTypography>
				</MDBox>
			</MDBox>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			<MDBox maxWidth="800px">
				<TabsUnstyled defaultValue={0} >
					<TabsList className="tablist-customized">
						<Tab>Rule Information</Tab>
						<Tab sx={{ marginLeft: "-2px" }}>Data Customer</Tab>
						<Tab sx={{ marginLeft: "-2px" }}>Condition</Tab>
						<Tab sx={{ marginLeft: "-2px" }}>Detail Condition</Tab>
						<Tab sx={{ marginLeft: "-2px" }}>Action</Tab>
					</TabsList>
					<TabPanelUnstyled style={{ backgroundColor: "#FFF" }} value={0}>
						<MDBox px={2} py={3} sx={{ border: "2px solid #000" }}>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={12} md={8}>
									<MDInput type="text" label="Voucher Code" fullWidth disabled InputLabelProps={{ shrink: true }} value={detailData?.voucher_code || ""} />
								</Grid>
								<Grid item xs={12} sm={12} md={4} sx={{ position: "relative" }}>
									<MDInput
										sx={{ backgroundColor: "#FFF", borderRadius: "8px" }}
										size='small'
										label="Effective Date"
										InputProps={{
											endAdornment: 
											<InputAdornment position="end">
												<Icon fontSize="small">calendar_today</Icon>
											</InputAdornment>,
										}}
										fullWidth
										disabled
										value={`${moment(detailData?.start_date, "YYYY-MM-DD").format("DD.MM.YYYYY")} - ${moment(detailData?.end_date, "YYYY-MM-DD").format("DD.MM.YYYYY")}` || ""}
										onClick={() => setDisplayDate(!displayDate)}
									/>
								</Grid>
								<Grid item xs={12} sm={12} md={8}>
									<MDInput type="text" label="Title" fullWidth disabled InputLabelProps={{ shrink: true }} value={detailData?.title || ""} />
									<MDBox mt={3}>
										<MDTypography style={{color: "#7b809a"}} fontSize={"12px"}>Description</MDTypography>
										<MDEditor
											className="readonly-quill"
											style={{ backgroundColor: "#f0f2f5", borderRadius: "8px", height: "100%" }}
											value={detailData.description || ""}
											readOnly={true}
										/>
									</MDBox>
								</Grid>
								<Grid item xs={12} sm={12} md={4}>
									<MDBox mb={3}>
										<MDInput
											id="select-category-name" 
											size="small" 
											fullWidth 
											label="Priority"
											type="select" 
											select
											value={`${detailData.priority}`}
											disabled
										>
											<MenuItem disabled value="">
												<em>Neither</em>
											</MenuItem>
											<MenuItem value="1">1</MenuItem>
											<MenuItem value="2">2</MenuItem>
											<MenuItem value="3">3</MenuItem>
											<MenuItem value="4">4</MenuItem>
											<MenuItem value="5">5</MenuItem>
											<MenuItem value="6">6</MenuItem>
											<MenuItem value="7">7</MenuItem>
											<MenuItem value="8">8</MenuItem>
											<MenuItem value="9">9</MenuItem>
											<MenuItem value="10">10</MenuItem>
										</MDInput>
									</MDBox>
									<Card
										sx={{
											marginTop: "2.5rem"
										}}
									>
										<MDBox
											position="relative"
											borderRadius="lg"
											mt={-3}
											mx={1}
											mb={-1}
											className="card-header"
											sx={{ zIndex: 1, backgroundColor: "transparent" }}
										>
											<MDBox
												sx={{ backgroundColor: detailData?.image_path?.includes(".png") ? "#FFF" : "", borderRadius: "8px" }}
											>
												<MDBox
													component="img"
													src={detailData?.image_path ? detailData.image_path : placeholder}
													alt="Voucher Image"
													borderRadius="lg"
													shadow="sm"
													width="100%"
													height="100%"
													position="relative"
													zIndex={10}
													onError={getImageError}
												/>
											</MDBox>
										</MDBox>
										<MDBox textAlign="center" mt={-3} pb={1} px={3}>
											<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 4 }}>
												Voucher Image
											</MDTypography>
										</MDBox>
									</Card>
								</Grid>
							</Grid>
						</MDBox>
					</TabPanelUnstyled>
					<TabPanelUnstyled style={{ backgroundColor: "#FFF" }} value={1}>
						<MDBox px={2} py={3} sx={{ border: "2px solid #000" }}>
							<Grid container spacing={3}>
								<Grid item md={8}>
									<MDBox ml={3} sx={{ marginRight: "5px"}}>
										<MDInput
											id="select-category-name" 
											size="small" 
											fullWidth 
											label="Customer Type"
											type="select" 
											select
											value={`${detailData.customer_type}`}
											disabled
										>
											<MenuItem disabled value="">
												<em>Neither</em>
											</MenuItem>
											<MenuItem value="3">ALL USER</MenuItem>
											<MenuItem value="1">SPECIFIC USER</MenuItem>
											<MenuItem value="2">BY REGION</MenuItem>
										</MDInput>
									</MDBox>
								</Grid>
								{
									detailData?.customer_type === 1 ?
										<MDBox width="100%" mt={3} ml={4} mr={1} sx={{ border: "2px dotted black", padding: "16px 14px"}}>
											<DataTable
												table={{ columns: userColumns.filter( pc => pc.accessor !== "delete"), rows : userRows }}
												isSorted={false}
												entriesPerPage={false}
												showTotalEntries={true}
												pagination={{variant: "contained", color: "primary"}}
												noEndBorder
												paginationDetail={userPaginationDetail}
												gotoPage={userGotoPage}
											/>
										</MDBox>
									: detailData?.customer_type === 2 ?
										<MDBox width="100%" mt={3} ml={4} mr={1} sx={{ border: "2px dotted black", padding: "16px 14px"}}>
											<DataTable
												table={{ columns: regionColumns.filter( pc => pc.accessor !== "delete"), rows : regionRows }}
												isSorted={false}
												entriesPerPage={false}
												showTotalEntries={true}
												pagination={{variant: "contained", color: "primary"}}
												noEndBorder
												paginationDetail={regionPaginationDetail}
												gotoPage={regionGotoPage}
											/>
										</MDBox>
									: null
								}
							</Grid>
						</MDBox>
					</TabPanelUnstyled>
					<TabPanelUnstyled style={{ backgroundColor: "#FFF" }} value={2}>
						<MDBox px={4} py={3} sx={{ border: "2px solid #000" }}>
							<Grid container spacing={3}>
								<Grid item md={8}>
									<MDInput
										id="select-category-name" 
										size="small" 
										fullWidth 
										label="Product Type"
										type="select" 
										select
										disabled
										value={`${detailData?.product_type}`}
									>
										<MenuItem disabled value="">
											<em>Neither</em>
										</MenuItem>
										<MenuItem value="0">ALL SKU</MenuItem>
										<MenuItem value="1">BRAND</MenuItem>
										<MenuItem value="2">MODEL</MenuItem>
										<MenuItem value="3">CATEGORY</MenuItem>
									</MDInput>
								</Grid>
								<Grid item md={8}>
									<MDInput type="text" label="Max Coupon" fullWidth disabled value={detailData?.max_coupon} />
								</Grid>
							</Grid>
						</MDBox>
					</TabPanelUnstyled>
					<TabPanelUnstyled style={{ backgroundColor: "#FFF" }} value={3}>
						<MDBox px={3} py={3} sx={{ border: "2px solid #000" }}>
							<MDTypography fontWeight="bold" sx={{ textDecoration: "underline" }}>
								Set Item
							</MDTypography>
							<MDBox my={3} px={1}>
								<Grid container spacing={3}>
									<Grid item md={8}>
										<MDInput type="text" disabled label="Product Type" fullWidth value={detailData?.product_type === 0 ? "ALL SKU" : detailData?.product_type === 1 ? "Brand" : detailData?.product_type === 2 ? "Model" : detailData?.product_type === 3 ? "Category" : ""} />
									</Grid>
									<Grid item md={4}>
										<MDInput type="text" disabled label="Max Coupon" fullWidth value={detailData?.max_coupon} />
									</Grid>
									<Grid item xs={12} sm={12}>
										<MDBox mx={-2} sx={{ border: "2px dotted black", padding: "16px 14px"}}>
											<MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
												<MDTypography fontWeight="bold">Logic {detailData?.logic === 1 ? "AND" : detailData?.logic === 2 ? "OR" : detailData?.logic === 3 ? "EXCEPT" : ""}</MDTypography>
											</MDBox>
											<DataTable
												table={{ columns: detailData.logic === 3 ? productColumns.filter( pc => pc.accessor !== "delete" && pc.accessor !== "min_qty_order") : productColumns.filter( pc => pc.accessor !== "delete"), rows : productRows }}
												isSorted={false}
												entriesPerPage={false}
												showTotalEntries={true}
												pagination={{variant: "contained", color: "primary"}}
												noEndBorder
												paginationDetail={productPaginationDetail}
												gotoPage={productGotoPage}
											/>
										</MDBox>
									</Grid>
								</Grid>
							</MDBox>
							<MDTypography fontWeight="bold" sx={{ textDecoration: "underline" }}>
								Set Periode
							</MDTypography>
							<MDBox mt={3} px={1}>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={12} md={8}>
										<MDInput type="number" label="Usage Coupon Per User" fullWidth disabled InputLabelProps={{ shrink: true }} value={detailData?.usage_coupon_per_user} />
									</Grid>
								</Grid>
							</MDBox>
						</MDBox>
					</TabPanelUnstyled>
					<TabPanelUnstyled style={{ backgroundColor: "#FFF" }} value={4}>
						<MDBox px={2} py={3} sx={{ border: "2px solid #000" }}>
							<Grid container spacing={3}>
								<Grid item md={8}>
									<MDBox ml={3} sx={{ marginRight: "5px"}}>
										<MDInput
											id="select-category-name" 
											size="small" 
											fullWidth 
											label="Impact Name"
											type="select" 
											select
											disabled
											value={`${detailData?.impact_name}`}
										>
											<MenuItem disabled value="">
												<em>Neither</em>
											</MenuItem>
											<MenuItem value="1">Cutting Price</MenuItem>
											<MenuItem value="2">Cashback Point</MenuItem>
										</MDInput>
									</MDBox>
								</Grid>
							</Grid>
							{
								detailData?.impact_name ?
									<MDBox width="100%" mt={3} ml={0} mr={1} sx={{ border: "2px dotted black", padding: "16px 14px"}}>
										<MDTypography fontWeight="bold" sx={{ textDecoration: "underline" }}>
											{detailData?.impact_name === 1 ? "Cutting Price" : "Cashback Point"}
										</MDTypography>
										<MDBox mt={2} mx={1}>
											<Grid container spacing={3}>
												<Grid item md={8}>
													<MDInput
														id="select-category-name" 
														size="small" 
														fullWidth 
														label="Impact Category"
														type="select" 
														select
														disabled
														value={`${detailData?.impact_category}`}
													>
														<MenuItem disabled value="">
															<em>Neither</em>
														</MenuItem>
														<MenuItem value="1">Fix Price</MenuItem>
														<MenuItem value="2">Percentage</MenuItem>
													</MDInput>
												</Grid>
												<Grid item md={4}>
													<MDInput label={detailData?.impact_category === 1 ? "Amount" : detailData?.impact_category === 2 ? "Percentage" : "Amount/Percentage"}
														fullWidth 
														value={detailData?.amount ? addCommas(detailData.amount) : detailData.percentage} 
														disabled
													/>
												</Grid>
												<Grid item md={8}>
													<MDInput label="Min Trx (Rp)" fullWidth disabled value={addCommas(detailData?.min_trx)} />
												</Grid>
												<Grid item md={4}>
													<MDInput
														id="select-category-name" 
														size="small" 
														fullWidth 
														label="Multiple"
														type="select" 
														select
														disabled
														value={`${detailData?.is_multiple}`}
													>
														<MenuItem disabled value="">
															<em>Neither</em>
														</MenuItem>
														<MenuItem value="1">YES</MenuItem>
														<MenuItem value="0">NO</MenuItem>
													</MDInput>
												</Grid>
												<Grid item md={8}>
												<MDInput label="Max Discount (Rp)" fullWidth disabled value={addCommas(detailData?.max_discount)} />
												</Grid>
											</Grid>
										</MDBox>
									</MDBox>
								: null
							}
						</MDBox>
					</TabPanelUnstyled>
				</TabsUnstyled>
			</MDBox>
		</Grid>
  );
}

export default VoucherView;
