import { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Autocomplete, Chip, Icon, MenuItem, SliderThumb, TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDButton from "../../../../MDButton";
import MDTypography from "../../../../MDTypography";

import placeholder from "../../../../../assets/images/placeholder-image.jpeg";
import MDInput from "../../../../MDInput";
import MDSnackbar from "../../../../MDSnackbar";

import services from "../../../../../service/services";

import { generateYear, getImageError, toBase64 } from "../../../../../utils";

const ModelEdit = ({
	brandNameOptions,
	brandCode,
	parentNameOptions,
	setIsLoading,
	rawRowsDisplayed,
	indexDetail,
	setMode
}) => {

	const modelImageRef = useRef();

	const [modelYear, setModelYear] = useState([]);
	const [modelYearError, setModelYearError] = useState(null);
	const [detailData, setDetailData] = useState({});
	const [modelImage, setModelImage] = useState();
	const [brandCodeInherited, setBrandCodeInherited] = useState("");
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isRemoved, setIsRemoved] = useState(false);
	const [isTransparent, setIsTransparent] = useState(false);

	const { register, handleSubmit, setValue, trigger, watch, control, formState: { errors } } = useForm({ mode: "all" });

	const watchAllFields = watch();
	const watchParentName = watch("parent_id");

	useEffect(() => {
		if(!watchParentName || watchParentName?.id === null) {
			setModelYear([]);
		}
	},[watchParentName]);

	const onImageChange = async (e, key) => {
		setIsRemoved(false);
		let format = e.target.files[0].name.split('.').pop();
		setIsTransparent(format.includes("png"));
		let val = await toBase64(e.target.files[0]);
		setValue(key, val);
		const result = await trigger(key);
		setModelImage(URL.createObjectURL(e.target.files[0]));
	}

	const removeImage = async (key) => {
		setIsRemoved(true);
		setIsTransparent(false);
		setValue(key, null);
		const result = await trigger(key);
		setModelImage(null);
	}

	const submitEdit = (e) => {
		setIsLoading(true);
		e.model_year = modelYear;
		e.id = detailData.id;
		if (e.parent_id?.parent_id || e.parent_id?.id) {
			e.parent_id = e.parent_id?.parent_id || e.parent_id?.id;
		} else {
			delete e.parent_id;
		}
		services.editModel(e)
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
			}).catch(errors => {
				setIsLoading(false);
				setErrorMessage(errors?.response?.data?.message);
				setShowNotifError(true);
				console.log(errors)
			})
	}

	useEffect(() => {
		if (rawRowsDisplayed?.length > 0) {
			setValue("model_image", "default");
			setValue("parent_id", parentNameOptions.filter(pn => pn.parent_id === parseInt(rawRowsDisplayed[indexDetail].parent_id))[0]);
			setValue("is_active", rawRowsDisplayed[indexDetail].is_active);
			setValue("brand_id", rawRowsDisplayed[indexDetail].brand_id);
			setValue("brand_name", rawRowsDisplayed[indexDetail]?.brand_name);
			setModelYear(rawRowsDisplayed[indexDetail]?.model_year);

			// let index = brandNameOptions.indexOf(rawRowsDisplayed[indexDetail].brand_id);
			// setBrandCodeInherited(brandCode[index]);
			setBrandCodeInherited(brandNameOptions.find(pn => pn.brand_id === parseInt(rawRowsDisplayed[indexDetail].brand_id)).brand_code);
			setDetailData({
				...rawRowsDisplayed[indexDetail],
				parent_id: parentNameOptions.find(pn => pn.parent_id === parseInt(rawRowsDisplayed[indexDetail].parent_id))
			});
			setDetailData({
				...rawRowsDisplayed[indexDetail],
				brand_id: rawRowsDisplayed[indexDetail].brand_id
			});
		}
	}, [rawRowsDisplayed])

	const backClickHandler = () => {
		setMode("index");
	}

	const onChangeYear = (value) => {
		setModelYear(value);
	}

	return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" justifyContent="space-between" mt={-0.5} mb={4} width="100%">
				<MDBox display="flex" alignItems="center">
					<button onClick={backClickHandler} style={{ border: 'none', background: 'white', borderRadius: '5px', marginRight: '1rem', width: '2rem', height: '2rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<Icon fontSize="medium">
							arrow_back
						</Icon>
					</button>
					<MDTypography variant="h3" fontWeight="regular">
						Edit Model
					</MDTypography>
				</MDBox>
				<MDButton color="info" type="submit" form="create-brand">
					Update
				</MDButton>
			</MDBox>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="Model Updated!"
				open={showNotifSuccess}
				dateTime="Just Now"
				close={() => setShowNotifSuccess(false)}
			/>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			{
				rawRowsDisplayed.length > 0 && Object.keys(detailData)?.length > 0 ?
					<MDBox component="form" role="form" id="create-brand" onSubmit={handleSubmit(submitEdit)}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6}>
								<MDBox width="100%" mb={3}>
									<Card
										sx={{
											"&:hover .card-header": {
												transform: "translate3d(0, -50px, 0)",
											},
											border: errors?.model_image ? "2px solid red" : "inherit",
											width: "100%",
											maxWidth: "500px",
											margin: "0 auto"
										}}
									>
										<MDBox
											position="relative"
											borderRadius="lg"
											mt={-3}
											mx={1}
											mb={-1}
											className="card-header"
											sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)", zIndex: 999, backgroundColor: "transparent" }}
										>
											<MDBox
												sx={{ backgroundColor: detailData.model_image?.includes(".png") || isTransparent ? "#FFF" : "", borderRadius: "8px" }}
											>
												<MDBox
													component="img"
													src={modelImage ? modelImage : isRemoved || !detailData.model_image ? placeholder : detailData.model_image}
													alt="Model Image"
													borderRadius="lg"
													shadow="sm"
													width="100%"
													height="100%"
													position="relative"
													zIndex={10}
													onError={getImageError}
												/>
											</MDBox>
										</MDBox>
										<MDBox textAlign="center" pt={4} pb={1} px={3}>
											<MDBox
												display="flex"
												justifyContent="center"
												alignItems="center"
												mt={-9}
												mb={-1}
												position="relative"
												zIndex={1}
											>
												<MDBox mr={1}>
													<input
														{...register("model_image", { required: "Required" })}
														type="file"
														accept="image/png;image/jpg;image/jpeg;"
														ref={modelImageRef}
														onChange={(e) => onImageChange(e, "model_image")}
														className="d-none"
													/>
													<MDButton variant="gradient" color="info" size="small" onClick={() => modelImageRef.current.click()}>
														edit
													</MDButton>
												</MDBox>
												<MDButton variant="outlined" color="dark" size="small" onClick={() => removeImage("model_image")}>
													remove
												</MDButton>
											</MDBox>
											<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 3 }}>
												Model Image
											</MDTypography>
											{
												errors?.model_image &&
												<MDTypography fontWeight="bold" sx={{ fontSize: "10px", color: "red" }}>
													{errors.model_image.message}
												</MDTypography>
											}
										</MDBox>
									</Card>
								</MDBox>

							</Grid>
							<Grid xs={12} md={6} item>
								<Card sx={{ padding: "24px 32px", width: "100%" }}>
									<MDTypography variant="h4" fontWeight="regular" sx={{ ml: -1, mb: 4 }}>
										Brand Information
									</MDTypography>
									<MDBox mb={2}>
										<Controller
											name="brand_id" control={control}
											render={(props) => {
												return <Autocomplete
													getOptionLabel={option => option.brand_name || option}
													options={brandNameOptions}
													size="small"
													renderInput={(params) => (
														// <MDInput {...params} label="Brand Name" />
														<MDInput label="Brand Name" {...register("brand_name", { required: "Required" })} error={errors?.brand_name} {...params} />
													)}
													onChange={async (e, newValue) => {
														setValue("brand_name", newValue.brand_name);
														setValue("brand_id", newValue.brand_id);
														let index = brandNameOptions.indexOf(newValue);
														setBrandCodeInherited(brandCode[index]);
														let temp = { target: { value: newValue } };
														props?.field?.onChange(temp);
													}}
													value={watchAllFields?.brand_name || ""}
												/>
											}
											}
										/>
										{errors?.brand_name &&
											<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
												<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.brand_name.message}</MDTypography>
											</MDBox>
										}
									</MDBox>
									{
										brandCodeInherited &&
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Brand Code"
												fullWidth
												value={brandCodeInherited}
												disabled
											/>
										</MDBox>
									}
								</Card>
								<Card sx={{ padding: "24px 32px", margin: "20px 0px 0px 0px" }}>
									<MDTypography variant="h4" fontWeight="regular" sx={{ ml: -1, mb: 4 }}>
										Model Information
									</MDTypography>
									<MDBox mb={2}>
										<Controller
											name="parent_id" control={control}
											render={(props) => {
												console.log('props', props)
												return <Autocomplete
													getOptionLabel={option => option.parent_name || option}
													options={parentNameOptions}
													size="small"
													renderInput={(params) => (
														<MDInput {...params} label="Parent Name" />
													)}
													onChange={async (e, data) => {
														let temp = { target: { value: data } };
														props?.field?.onChange(temp);
													}}
													value={props.field.value?.parent_name || ""}
												/>
											}
											}
										/>
										{errors?.parent_name &&
											<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
												<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.parent_name.message}</MDTypography>
											</MDBox>
										}
									</MDBox>
									<MDBox mb={2}>
										<MDInput error={errors?.model_name} defaultValue={detailData.model_name} {...register("model_name", { required: "Required" })} type="text" label="Model Name" fullWidth />
										{errors?.model_name &&
											<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
												<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.model_name.message}</MDTypography>
											</MDBox>
										}
									</MDBox>
									<MDBox mb={2}>
										<Autocomplete
											disabled={!watchAllFields?.parent_id || watchAllFields?.parent_id?.parent_name === "EMPTY"}
											value={modelYear || []}
											multiple
											id="model-year"
											options={generateYear(20)}
											onChange={(_, value) => onChangeYear(value)}
											freeSolo
											renderTags={(value, getTagProps) =>
												value.map((option, index) => (
													<Chip variant="outlined" label={option} {...getTagProps({ index })} />
												))
											}
											renderInput={(params) => (
												<MDInput {...params} label="Model Year" />
											)}
										/>
										{modelYearError &&
											<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
												<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{modelYearError}</MDTypography>
											</MDBox>
										}
									</MDBox>
									<MDBox mb={2}>
										<MDInput error={errors?.model_code} defaultValue={detailData.model_code} {...register("model_code", { required: "Required", maxLength: { value: 5, message: "Maximum character length is 5" } })} type="text" label="Model Code" fullWidth />
										{errors?.model_code &&
											<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
												<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.model_code.message}</MDTypography>
											</MDBox>
										}
									</MDBox>
									<MDBox mb={2}>
										<TextField
											{...register("is_active", { required: "Required" })}
											id="select-is-active"
											size="small"
											fullWidth
											label="Status"
											type="select"
											select
											error={errors?.is_active}
											value={watchAllFields?.is_active || ""}
										>
											<MenuItem disabled value="">
												<em>Neither</em>
											</MenuItem>
											<MenuItem value="1">Active</MenuItem>
											<MenuItem value="0">Not Active</MenuItem>
										</TextField>
										{errors?.is_active &&
											<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
												<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.is_active.message}</MDTypography>
											</MDBox>
										}
									</MDBox>
								</Card>
							</Grid>
						</Grid>
					</MDBox>
					: null
			}
		</Grid>
	);
}

export default ModelEdit;
