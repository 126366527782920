import { useEffect, useRef, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Autocomplete, Chip, MenuItem, TextField } from "@mui/material";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDInput from "../../../../MDInput";
import MDButton from "../../../../MDButton";
import MDSnackbar from "../../../../MDSnackbar";
import MDTypography from "../../../../MDTypography";

import services from "../../../../../service/services";

import { getImageError, toBase64 } from "../../../../../utils";

import placeholder from "../../../../../assets/images/placeholder-image.jpeg";

import useFetchState from "../../../../../hooks/useFetchState"

const ItemProductAdd = ({
	setIsLoading,
	brandOptions,
	modelOptions,
	modelTypeOptions,
	categoryOptions,
	subCategoryOptions,
	styleOptions,
	attributeOptions,
	subAttributeOptions,
}) => {

	const main_imageRef = useRef();
	const additional_image1Ref = useRef();
	const additional_image2Ref = useRef();
	const additional_image3Ref = useRef();
	const additional_image4Ref = useRef();

	const [images, setImages] = useState([]);
	const [isTransparent, setIsTransparent] = useState([]);
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [formData, setFormData] = useState({
		brand: {},
		model_id: [],
		model_type: {},
		model_code: "",
		category_name: "",
		category: {},
		subcategory: {},
		style: {},
		attribute: {},
		subattribute: {},
		is_active: "",
		uom: {},
		sku: "",
	});

	const getUom = useFetchState();

	useEffect(() => {
		getUom.fetchStart();
		services.getItemProductUom().then((res) => {
			getUom.fetchSuccess(res.data?.data || []);
		}).catch((err) => {
			getUom.fetchFailed(err?.message || 'Ooops something went wrong!');
		});
	}, []);

	const { register, handleSubmit, setValue, trigger, control, reset, watch, formState: { errors }, setError } = useForm({ mode: "all" });

	const { update: imagesUpdate } = useFieldArray({
		control,
		name: "images",
	});

	const { update: modelUpdate, remove: removeModel } = useFieldArray({
		control,
		name: "model_id",
	});

	const watchAllFields = watch();

	const onImageChange = async (e, key) => {
		let format = e.target.files[0].type.split('/').pop();
		let tempIsTransparent = [...isTransparent];
		tempIsTransparent[key] = format.includes("png");
		setIsTransparent(tempIsTransparent);
		let val = await toBase64(e.target.files[0]);
		imagesUpdate(key, val);
		const result = await trigger("images");
		let tempImages = [...images];
		tempImages[key] = URL.createObjectURL(e.target.files[0]);
		setImages(tempImages);
	}

	const removeImage = async (key) => {
		let tempIsTransparent = [...isTransparent];
		tempIsTransparent[key] = false;
		setIsTransparent(tempIsTransparent);
		imagesUpdate(key, null);
		const result = await trigger("images");
		let tempImages = [...images];
		tempImages[key] = "";
		setImages(tempImages);
	}

	const generateSku = () => {
		const brand = watchAllFields?.brand;
		const model = watchAllFields?.model_id;
		const modelType = watchAllFields?.model_type;
		const category = watchAllFields?.category_type;
		const subCategory = watchAllFields?.subcategory_id;
		const style = watchAllFields?.style_id;
		const attribute = watchAllFields?.attribute_id;
		const subAttribute = watchAllFields?.subattribute_id;

		if (!brand || !model || !category || !subCategory || !style || !attribute || !subAttribute) {
			setShowNotifError(true);
			setErrorMessage("There cannot be empty fields");
			return null;
		}
		setErrorMessage("")
		setShowNotifError(false);
		const brandCode = brand?.brand_code;
		const modelCode = model[0]?.model_code + (model[0]?.model_year ? model[0]?.model_year?.map(el => el.toString().slice(2)).join("") : "00")
		const modelTypeCode = modelType == null ? 0 : modelType?.model_type_code;
		const categoryCode = category?.category_code;
		const subcategoryCode = subCategory?.subcategory_code;
		const styleCode = style?.style_code;
		const attributeCode = attribute?.attribute_code;
		const subattributeCode = subAttribute?.subattribute_code;

		return brandCode + "-" + modelCode + "-" + modelTypeCode + "." + categoryCode + subcategoryCode + "." + styleCode + attributeCode + subattributeCode;
	}

	const generateSkuAndSave = () => {
		const sku = generateSku();
		setValue("sku", sku);
	}

	const submitCreate = (e) => {
		const sku = generateSku();

		if (!sku) {
			setError("sku", {
				type: "required",
				message: "Required"
			})
			return;
		}
		generateSkuAndSave();

		setIsLoading(true);

		let data = {
			model_id: e.model_id?.map((mi) => mi.id),
			// model_year: e.model_year,
			model_type_id: e.model_type?.id || null,
			subcategory_id: e.subcategory_id?.id,
			style_id: e.style_id?.id,
			attribute_id: e.attribute_id?.id,
			subattribute_id: e.subattribute_id?.id,
			code1: e.code1,
			code2: "",
			molding_code: e.molding_code,
			name: e.name,
			description: e.description,
			uom_id: e.uom,
			sku: sku,
			images: e.images.filter(img => img !== "" && img),
		};
		services.createItemProducts(data)
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
				setIsTransparent([]);
				setImages({});
				reset({
					attribute_id: null,
					brand: null,
					category_type: null,
					model_type: null,
					style_id: null,
					subattribute_id: null,
					subcategory_id: null,
				})
				for (let i = 0; i < watchAllFields?.model_id.length; i++) {
					removeModel(i);
				}
				for (let i = 0; i < watchAllFields?.images.length; i++) {
					removeImage(i);
				}
			}).catch(errors => {
				setIsLoading(false);
				setErrorMessage(errors?.response?.data?.message);
				setShowNotifError(true);
				console.log(errors)
			})
	}

	return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" justifyContent="space-between" mt={-0.5} mb={2.5} width="100%">
				<MDTypography variant="h3" fontWeight="regular">
					Add New Product
				</MDTypography>
				<MDButton color="info" type="submit" form="create-product">
					Save
				</MDButton>
			</MDBox>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="New Product Added!"
				open={showNotifSuccess}
				dateTime="Just Now"
				close={() => setShowNotifSuccess(false)}
			/>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			<MDBox component="form" role="form" id="create-product" onSubmit={handleSubmit(submitCreate)}>
				<Grid container spacing={3}>
					<Grid item xs={12} lg={4}>
						<Card
							sx={{
								"&:hover .card-header": {
									transform: "translate3d(0, -50px, 0)",
								},
								margin: "0 auto",
								maxWidth: "500px",
								border: errors?.images?.[0] ? "2px solid red" : "inherit"
							}}
						>
							<MDBox
								position="relative"
								borderRadius="lg"
								mt={-3}
								mx={1}
								mb={-1}
								className="card-header"
								sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)", zIndex: 999, backgroundColor: "transparent" }}
							>
								<MDBox
									sx={{ backgroundColor: isTransparent[0] ? "#FFF" : "", borderRadius: "8px" }}
								>
									<MDBox
										component="img"
										src={images?.[0] ? images?.[0] : placeholder}
										alt="Product Image"
										borderRadius="lg"
										shadow="sm"
										width="100%"
										height="100%"
										position="relative"
										zIndex={10}
										onError={getImageError}
									/>
								</MDBox>
							</MDBox>
							<MDBox textAlign="center" pt={4} pb={1} px={3}>
								<MDBox
									display="flex"
									justifyContent="center"
									alignItems="center"
									mt={-9}
									mb={-1}
									position="relative"
									zIndex={1}
								>
									<MDBox mr={1}>
										<input
											{...register("images.0", { required: "Required" })}
											type="file"
											accept="image/png;image/jpg;image/jpeg;"
											ref={main_imageRef}
											onChange={(e) => onImageChange(e, 0)}
											className="d-none"
										/>
										<MDButton variant="gradient" color="info" size="small"
											onClick={() => main_imageRef.current.click()}
										>
											edit
										</MDButton>
									</MDBox>
									<MDButton variant="outlined" color="dark" size="small" onClick={() => removeImage(0)}>
										remove
									</MDButton>
								</MDBox>
								<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 3 }}>
									Main Image *
								</MDTypography>
								{
									errors?.images?.[0] &&
									<MDTypography fontWeight="bold" sx={{ fontSize: "10px", color: "red" }}>
										{errors?.images?.[0].message}
									</MDTypography>
								}
							</MDBox>
						</Card>
						<Grid container alignItems="center" justifyContent="center" spacing={4} className="multiple-images-grid" sx={{ width: window.innerWidth <= 540 ? "calc(100% + 64px)" : "calc(100% + 32px)", mt: 0.1 }}>
							<Grid item xs={12} lg={6}>
								<Card
									sx={{
										"&:hover .card-header": {
											transform: "translate3d(0, -50px, 0)",
										},
										maxWidth: "500px",
									}}
								>
									<MDBox
										position="relative"
										borderRadius="lg"
										mt={-3}
										mx={1}
										mb={-1}
										className="card-header"
										sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)", zIndex: 999, backgroundColor: "transparent" }}
									>
										<MDBox
											sx={{ backgroundColor: isTransparent[1] ? "#FFF" : "", borderRadius: "8px" }}
										>
											<MDBox
												component="img"
												src={images?.[1] ? images?.[1] : placeholder}
												alt="Product Image"
												borderRadius="lg"
												shadow="sm"
												width="100%"
												height="100%"
												position="relative"
												zIndex={10}
												onError={getImageError}
											/>
										</MDBox>
									</MDBox>
									<MDBox textAlign="center" pt={4} pb={1} px={3}>
										<MDBox
											display="flex"
											justifyContent="center"
											alignItems="center"
											mt={-9}
											mb={-1}
											position="relative"
											zIndex={1}
										>
											<MDBox mr={1}>
												<input
													{...register("images.1")}
													type="file"
													accept="image/png;image/jpg;image/jpeg;"
													ref={additional_image1Ref}
													onChange={(e) => onImageChange(e, 1)}
													className="d-none"
												/>
												<MDButton variant="gradient" color="info" size="small"
													onClick={() => additional_image1Ref.current.click()}
												>
													edit
												</MDButton>
											</MDBox>
											<MDButton variant="outlined" color="dark" size="small" onClick={() => removeImage(1)}>
												remove
											</MDButton>
										</MDBox>
										<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 3 }}>
											Additional Image
										</MDTypography>
									</MDBox>
								</Card>
							</Grid>
							<Grid item xs={12} lg={6}>
								<Card
									sx={{
										"&:hover .card-header": {
											transform: "translate3d(0, -50px, 0)",
										},
										maxWidth: "500px",
									}}
								>
									<MDBox
										position="relative"
										borderRadius="lg"
										mt={-3}
										mx={1}
										mb={-1}
										className="card-header"
										sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)", zIndex: 999, backgroundColor: "transparent" }}
									>
										<MDBox
											sx={{ backgroundColor: isTransparent[2] ? "#FFF" : "", borderRadius: "8px" }}
										>
											<MDBox
												component="img"
												src={images?.[2] ? images?.[2] : placeholder}
												alt="Product Image"
												borderRadius="lg"
												shadow="sm"
												width="100%"
												height="100%"
												position="relative"
												zIndex={10}
												onError={getImageError}
											/>
										</MDBox>
									</MDBox>
									<MDBox textAlign="center" pt={4} pb={1} px={3}>
										<MDBox
											display="flex"
											justifyContent="center"
											alignItems="center"
											mt={-9}
											mb={-1}
											position="relative"
											zIndex={1}
										>
											<MDBox mr={1}>
												<input
													{...register("images.2")}
													type="file"
													accept="image/png;image/jpg;image/jpeg;"
													ref={additional_image2Ref}
													onChange={(e) => onImageChange(e, 2)}
													className="d-none"
												/>
												<MDButton variant="gradient" color="info" size="small"
													onClick={() => additional_image2Ref.current.click()}
												>
													edit
												</MDButton>
											</MDBox>
											<MDButton variant="outlined" color="dark" size="small" onClick={() => removeImage(2)}>
												remove
											</MDButton>
										</MDBox>
										<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 3 }}>
											Additional Image
										</MDTypography>
									</MDBox>
								</Card>
							</Grid>
							<Grid item xs={12} lg={6}>
								<Card
									sx={{
										"&:hover .card-header": {
											transform: "translate3d(0, -50px, 0)",
										},
										maxWidth: "500px",
									}}
								>
									<MDBox
										position="relative"
										borderRadius="lg"
										mt={-3}
										mx={1}
										mb={-1}
										className="card-header"
										sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)", zIndex: 999, backgroundColor: "transparent" }}
									>
										<MDBox
											sx={{ backgroundColor: isTransparent[3] ? "#FFF" : "", borderRadius: "8px" }}
										>
											<MDBox
												component="img"
												src={images?.[3] ? images?.[3] : placeholder}
												alt="Product Image"
												borderRadius="lg"
												shadow="sm"
												width="100%"
												height="100%"
												position="relative"
												zIndex={10}
												onError={getImageError}
											/>
										</MDBox>
									</MDBox>
									<MDBox textAlign="center" pt={4} pb={1} px={3}>
										<MDBox
											display="flex"
											justifyContent="center"
											alignItems="center"
											mt={-9}
											mb={-1}
											position="relative"
											zIndex={1}
										>
											<MDBox mr={1}>
												<input
													{...register("images.3")}
													type="file"
													accept="image/png;image/jpg;image/jpeg;"
													ref={additional_image3Ref}
													onChange={(e) => onImageChange(e, 3)}
													className="d-none"
												/>
												<MDButton variant="gradient" color="info" size="small"
													onClick={() => additional_image3Ref.current.click()}
												>
													edit
												</MDButton>
											</MDBox>
											<MDButton variant="outlined" color="dark" size="small" onClick={() => removeImage(3)}>
												remove
											</MDButton>
										</MDBox>
										<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 3 }}>
											Additional Image
										</MDTypography>
									</MDBox>
								</Card>
							</Grid>
							<Grid item xs={12} lg={6}>
								<Card
									sx={{
										"&:hover .card-header": {
											transform: "translate3d(0, -50px, 0)",
										},
										maxWidth: "500px",
									}}
								>
									<MDBox
										position="relative"
										borderRadius="lg"
										mt={-3}
										mx={1}
										mb={-1}
										className="card-header"
										sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)", zIndex: 999, backgroundColor: "transparent" }}
									>
										<MDBox
											sx={{ backgroundColor: isTransparent[4] ? "#FFF" : "", borderRadius: "8px" }}
										>
											<MDBox
												component="img"
												src={images?.[4] ? images?.[4] : placeholder}
												alt="Product Image"
												borderRadius="lg"
												shadow="sm"
												width="100%"
												height="100%"
												position="relative"
												zIndex={10}
												onError={getImageError}
											/>
										</MDBox>
									</MDBox>
									<MDBox textAlign="center" pt={4} pb={1} px={3}>
										<MDBox
											display="flex"
											justifyContent="center"
											alignItems="center"
											mt={-9}
											mb={-1}
											position="relative"
											zIndex={1}
										>
											<MDBox mr={1}>
												<input
													{...register("images.4")}
													type="file"
													accept="image/png;image/jpg;image/jpeg;"
													ref={additional_image4Ref}
													onChange={(e) => onImageChange(e, 4)}
													className="d-none"
												/>
												<MDButton variant="gradient" color="info" size="small"
													onClick={() => additional_image4Ref.current.click()}
												>
													edit
												</MDButton>
											</MDBox>
											<MDButton variant="outlined" color="dark" size="small" onClick={() => removeImage(4)}>
												remove
											</MDButton>
										</MDBox>
										<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 3 }}>
											Additional Image
										</MDTypography>
									</MDBox>
								</Card>
							</Grid>
						</Grid>
					</Grid>
					<Grid xs={12} lg={8} item>
						<Card sx={{ padding: "24px 32px" }}>
							<MDTypography variant="h4" fontWeight="regular" sx={{ ml: -1, mb: 4 }}>
								Product Literation
							</MDTypography>
							<Grid container spacing={2}>
								<Grid item xs={12} lg={8}>
									<Controller
										rules={{ required: "Required" }}
										name="brand" control={control}
										render={(props) => {
											return <Autocomplete
												options={brandOptions}
												getOptionLabel={(option) => option.brand_name}
												size="small"
												renderInput={(params) => (
													<MDInput label="Brand Name *" {...params} />
												)}
												onChange={async (e, data) => {
													let temp = { target: { value: data } };
													props?.field?.onChange(temp);
												}}
												error={props.fieldState.error}
												className={props.fieldState.error ? "autocomplete-error" : ""}
												value={props.field.value || null}
											/>
										}
										}
									/>
									{errors?.brand &&
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.brand.message}</MDTypography>
										</MDBox>
									}
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput disabled type="text" label="Brand Code" fullWidth value={watchAllFields?.brand?.brand_code || ""} />
								</Grid>
								<Grid item xs={12} lg={8}>
									<Controller
										rules={{ required: "Required" }}
										name="model_id" control={control}
										render={(props) => {
											return <Autocomplete
												options={watchAllFields.brand ? modelOptions.filter((mo) => mo.brand_name === watchAllFields.brand.brand_name) : []}
												getOptionLabel={option => option.model_name + " " + (option.model_year?.join(",") || "")}
												size="small" multiple
												error={props.fieldState.error}
												renderInput={(params) => (
													<MDInput label="Model Name *" {...params} />
												)}
												renderTags={(value, getTagProps) =>
													value.map((option, index) => (
														<Chip variant="outlined" label={option.model_name + " " + (option.model_year?.join(",") || "")} {...getTagProps({ index })} />
													))
												}
												onChange={async (e, data) => {
													let temp = { target: { value: data } };
													props?.field?.onChange(temp);
												}}
												value={props.field.value || []}
											/>
										}
										} />
									{errors?.model_id &&
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.model_id.message}</MDTypography>
										</MDBox>
									}
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput disabled type="text" label="Model Code" fullWidth value={watchAllFields.model_id ? watchAllFields.model_id.map((mn) => mn.model_code).join(", ") : ""} />
								</Grid>
								<Grid item xs={12} lg={8}>
									<Controller
										name="model_type" control={control}
										render={(props) => {
											return <Autocomplete
												options={modelTypeOptions}
												getOptionLabel={(option) => option.model_type_name}
												size="small"
												renderInput={(params) => (
													<MDInput label="Model Type" {...params} />
												)}
												onChange={async (e, data) => {
													let temp = { target: { value: data } };
													props?.field?.onChange(temp);
												}}
												value={props.field.value || null}
											/>
										}
										} />
								</Grid>
								<Grid item xs={12} lg={4}>
									{/* <MDInput label="Model Year" type="number" placeholder="XX (2 digit year)" fullWidth {...register("model_year", { maxLength: { value: 2, message: "Max Character Length is 2" } })} value={watchAllFields?.model_year || ""} error={errors?.model_year} />
									{errors?.model_year &&
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.model_year.message}</MDTypography>
										</MDBox>
									} */}
								</Grid>
								<Grid item xs={12} lg={8}>
									<MDInput
										{...register("category_name", { required: "Required" })}
										id="select-category-name"
										size="small"
										fullWidth
										label="Category Name *"
										type="select"
										select
										error={errors?.category_name}
										onChange={(e) => {
											setValue("category_name", e.target.value);
											setValue("category_type", "");
											setValue("subcategory_id", "");
										}}
										value={watchAllFields.category_name || ""}
									>
										<MenuItem disabled value="">
											<em>Neither</em>
										</MenuItem>
										<MenuItem value="INTERIOR">INTERIOR</MenuItem>
										<MenuItem value="EXTERIOR">EXTERIOR</MenuItem>
									</MDInput>
									{errors?.category_name &&
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.category_name.message}</MDTypography>
										</MDBox>
									}
								</Grid>
								<Grid item xs={12} lg={4}>
									<Controller
										rules={{ required: "Required" }}
										name="category_type" control={control}
										render={(props) => {
											return <Autocomplete
												options={watchAllFields.category_name ? categoryOptions.filter((co) => co.category_name === watchAllFields.category_name) : []}
												getOptionLabel={(option) => option.category_type}
												size="small"
												renderInput={(params) => (
													<MDInput label="Category Type *" {...params} />
												)}
												onChange={(e, data) => {
													let temp = { target: { value: data } };
													props?.field?.onChange(temp);
													setValue("subcategory_id", "");
												}}
												onInputChange={(event, newInputValue, reason) => {
													if (reason === "clear") {
														setValue("category_type", "");
														setValue("subcategory_id", "");
														return
													}
												}}
												value={props.field.value || null}
												className={props.fieldState.error ? "autocomplete-error" : ""}
											/>
										}
										} />
									{errors?.category_type &&
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.category_type.message}</MDTypography>
										</MDBox>
									}
								</Grid>

								<Grid item xs={12} lg={8}>
									<Controller
										rules={{ required: "Required" }}
										name="subcategory_id" control={control}
										render={(props) => {
											return <Autocomplete
												options={watchAllFields?.category_type ? subCategoryOptions.filter((so) => so.category_id === watchAllFields.category_type.id) : []}
												getOptionLabel={(option) => option.subcategory_name}
												size="small"
												renderInput={(params) => (
													<MDInput label="Subcategory Name *" error={errors?.subcategory_id} {...params} />
												)}
												onChange={(e, data) => {
													let temp = { target: { value: data } };
													props?.field?.onChange(temp);
												}}
												value={props.field.value || null}
												className={props.fieldState.error ? "autocomplete-error" : ""}
											/>
										}
										} />
									{errors?.subcategory_id &&
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.subcategory_id.message}</MDTypography>
										</MDBox>
									}
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput disabled type="text" label="Subcategory Code" fullWidth value={watchAllFields?.subcategory_id?.subcategory_code || ""} />
								</Grid>
								<Grid item xs={12} lg={8}>
									<Controller
										rules={{ required: "Required" }}
										name="style_id" control={control}
										render={(props) => {
											return <Autocomplete
												options={styleOptions}
												getOptionLabel={(option) => option.style_name}
												size="small"
												renderInput={(params) => (
													<MDInput label="Product Style *" {...params} />
												)}
												onChange={(e, data) => {
													let temp = { target: { value: data } };
													props?.field?.onChange(temp);
												}}
												value={props.field.value || null}
												className={props.fieldState.error ? "autocomplete-error" : ""}
											/>
										}
										} />
									{errors?.style_id &&
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.style_id.message}</MDTypography>
										</MDBox>
									}
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput disabled type="text" label="Product Style Code" fullWidth value={watchAllFields?.style_id?.style_code || ""} />
								</Grid>
								<Grid item xs={12} lg={8}>
									<Controller
										rules={{ required: "Required" }}
										name="attribute_id" control={control}
										render={(props) => {
											return <Autocomplete
												options={attributeOptions}
												getOptionLabel={(option) => option.attribute_name}
												size="small"
												renderInput={(params) => (
													<MDInput label="Attribute *" {...params} />
												)}
												onChange={(e, data) => {
													let temp = { target: { value: data } };
													props?.field?.onChange(temp);
												}}
												value={props.field.value || null}
												className={props.fieldState.error ? "autocomplete-error" : ""}
											/>
										}
										} />
									{errors?.attribute_id &&
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.attribute_id.message}</MDTypography>
										</MDBox>
									}
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput disabled type="text" label="Attribute Code" fullWidth value={watchAllFields?.attribute_id?.attribute_code || ""} />
								</Grid>
								<Grid item xs={12} lg={8}>
									<Controller
										rules={{ required: "Required" }}
										name="subattribute_id" control={control}
										render={(props) => {
											return <Autocomplete
												options={subAttributeOptions}
												getOptionLabel={(option) => option.subattribute_name}
												size="small"
												renderInput={(params) => (
													<MDInput label="Subattribute *" error={errors?.subattribute_id} {...params} />
												)}
												onChange={(e, data) => {
													let temp = { target: { value: data } };
													props?.field?.onChange(temp);
												}}
												value={props.field.value || null}
												className={props.fieldState.error ? "autocomplete-error" : ""}
											/>
										}
										} />
									{errors?.subattribute_id &&
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.subattribute_id.message}</MDTypography>
										</MDBox>
									}
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput disabled type="text" label="Subattribute Code" fullWidth value={watchAllFields?.subattribute_id?.subattribute_code || ""} />
								</Grid>

								<Grid item xs={12} sm={12}>
									<MDBox pt={2}>
										<MDTypography>Product Information</MDTypography>
									</MDBox>
								</Grid>
								<Grid item xs={12} lg={8}>
									<MDInput type="text" label="Product Name *" {...register("name", { required: "Required" })} error={errors?.name} value={watchAllFields?.name || ""} fullWidth />
									{errors?.name &&
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.name.message}</MDTypography>
										</MDBox>
									}
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput type="text" label="Product Code *" {...register("code1", { required: "Required" })} error={errors?.code1} value={watchAllFields?.code1 || ""} fullWidth />
									{errors?.code1 &&
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.code1.message}</MDTypography>
										</MDBox>
									}
								</Grid>
								<Grid item xs={12} lg={8}>
									<MDInput type="text" label="Product Description *" {...register("description", { required: "Required" })} error={errors?.description} fullWidth multiline rows={4} value={watchAllFields?.description || ""} />
									{errors?.description &&
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.description.message}</MDTypography>
										</MDBox>
									}
								</Grid>
								<Grid item xs={12} lg={4} >
									<MDInput type="text" label="Molding Code" {...register("molding_code")} value={watchAllFields?.molding_code || ""} fullWidth />
									<MDInput
										{...register("uom", { required: "Required" })}
										id="select-is-active"
										size="small"
										fullWidth
										label="UOM *"
										type="select"
										select
										error={errors?.uom}
										onChange={(e) => {
											setValue("uom", e.target.value);
										}}
										style={{ marginTop: "1rem" }}
									>
										<MenuItem disabled value="">
											<em>Neither</em>
										</MenuItem>
										{getUom.data?.map((uom) => (
											<MenuItem value={uom.uom_id}>
												{uom.uom_name}
											</MenuItem>
										))}
									</MDInput>
									{errors?.uom &&
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.uom.message}</MDTypography>
										</MDBox>
									}
								</Grid>
								<Grid item xs={12} sm={12} lg={8}>
									<MDInput
										{...register("is_active", { required: "Required" })}
										id="select-is-active"
										size="small"
										fullWidth
										label="Status *"
										type="select"
										select
										error={errors?.is_active}
										onChange={(e) => {
											setValue("is_active", e.target.value);
										}}
										value={watchAllFields.is_active || ""}
									>
										<MenuItem disabled value="">
											<em>Neither</em>
										</MenuItem>
										<MenuItem value="1">Active</MenuItem>
										<MenuItem value="0">Not Active</MenuItem>
									</MDInput>
									{errors?.is_active &&
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.is_active.message}</MDTypography>
										</MDBox>
									}
								</Grid>
								<Grid item xs={12} lg={4} style={{ position: "relative" }} >
									<MDInput type="text" label="SKU" {...register("sku")} value={watchAllFields?.sku || ""} fullWidth />
									<FontAwesomeIcon icon="fa-solid fa-arrows-rotate" style={{ position: "absolute", top: "50%", right: "10px", cursor: "pointer" }} fontSize={18} onClick={generateSkuAndSave} />
									{errors?.sku &&
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.sku.message}</MDTypography>
										</MDBox>
									}
								</Grid>
							</Grid>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		</Grid>
	)
};

export default ItemProductAdd;
