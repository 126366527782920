import React, { useEffect, useMemo, useState } from 'react';
import { useForm, useFieldArray } from "react-hook-form";

import Box from '@mui/material/Box';
import Icon from "@mui/material/Icon";
import Modal from '@mui/material/Modal';
import { Checkbox, FormControl } from '@mui/material';

import MDTypography from '../../../../MDTypography';
import MDButton from '../../../../MDButton';
import MDBox from '../../../../MDBox';
import DataTable from '../../../../Table';

import services from '../../../../../service/services';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  width: 'fit-content',
	maxWidth: '90vw',
  p: 3,
	borderRadius: "12px",
};

const AddPO = ({ 
	setIsLoading,
	open, 
	setOpen,
	id,
	setErrorMessage,
	setShowNotifError,
	replaceProductQty,
	productPaginationDetail,
	setProductPaginationDetail,
}) => {
	
	const [poRows, setPoRows] = useState(false);
	const [poPaginationDetail, setPoPaginationDetail] = useState({
		list: [],
		current_page: 1,
		total_page: 1,
		total_data: 0,
	});
	const [errorPo, setErrorPo] = useState("");

	const { register, trigger, control, setError, watch, formState: { errors } } = useForm({mode: "all"});

	const watchAllFields = watch();

	const { replace } = useFieldArray({
		control,
		shouldUnregister: true,
    name: "action",
	});

	const handleClose = () => {
		setOpen(false);
	};

	const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "PO No", accessor: "po_no", align: "left" },
		{ Header: "Total SKU", accessor: "total_sku", align: "left" },
		{ Header: "Total Item", accessor: "total_item", align: "left" },
		{ Header: "Valid Until", accessor: "date", align: "left" },
		{ Header: "Action", accessor: "action", align: "left" },
	];

	useEffect(() => {
		if(id){
			setProductPaginationDetail({
				list: [],
				total_data: 0,
				total_page: 1,
				current_page: 1,
			})
			setIsLoading(true);
			services.getPurchaseOrderOptions(id)
				.then(res => {
					setIsLoading(false);
					let list = res?.data?.data;
					setPoPaginationDetail({
						...poPaginationDetail,
						list: list,
						total_data: list?.length || 0,
					})
				}).catch(errors => {
					setIsLoading(false);
					setShowNotifError(true)
					setErrorMessage(errors?.response?.data?.message);
				});
		}
	}, [id]);

	useEffect(async() => {
		const result = await trigger("action");
		let listAll = settingRows();
		listAll = listAll?.filter((arr, i) => (((poPaginationDetail.current_page - 1) * 10) <= i && (poPaginationDetail.current_page * 10) > i));
		setPoRows(listAll);
	}, [watchAllFields.action])

	const settingRows = () => {
		let listAll = poPaginationDetail.list.map( (arr, j) => {
			return {
				no: j + 1,
				po_id: arr?.po_id,
				po_no: arr?.po_no,
				total_sku: arr?.total_sku,
				total_item: arr?.total_item,
				date: arr?.date,
				action: <>
					<Checkbox
						{...register(`action.${j}`)}
						checked={watchAllFields?.action?.[j]?.length > 0 ? true : false}
						value={arr?.po_id}
						inputProps={{
							'aria-label': 'Checkbox A'+j,
						}}					
						onChange={(e) => {
							let newVal = e.target.checked;
							let allValues = [...watchAllFields.action];
							if(newVal){
								allValues[j] = String(e.target.value);
							} else {
								allValues[j] = null;
							}
							replace(allValues);
						}}/>
				</>,
			}}
		);
		let total_page = parseInt(listAll.length/10);
		if(listAll.length%10 !== 0){
			total_page = total_page + 1;
		};
		setPoPaginationDetail({...poPaginationDetail, total_page: total_page});
		return listAll;
	}
	
	useMemo(() => {
		let listAll = settingRows();
		listAll = listAll?.filter((arr, i) => (((poPaginationDetail.current_page - 1) * 10) <= i && (poPaginationDetail.current_page * 10) > i));
		setPoRows(listAll);
	}, [poPaginationDetail.list, poPaginationDetail.current_page])

	const goToPagePo = (page) => {
		setPoPaginationDetail({...poPaginationDetail, current_page: page});
	};

	const handleAddPO = () => {
		if(watchAllFields?.action?.length < 1){
			setError('action', { type: 'custom', message: 'Check at least one Purchase Order to continue' });
		} else {
			let param = watchAllFields.action.filter( id => id ).join();
			setIsLoading(true);
			services.getDetailsPurchaseOrder(param)
				.then(res => {
					setIsLoading(false);
					let list = res?.data?.data;
					setProductPaginationDetail({
						...productPaginationDetail,
						list: list,
						total_data: list?.length || 0,
					})
					replaceProductQty(list.map( list => list.qty));
				}).catch(errors => {
					setIsLoading(false);
					setShowNotifError(true)
					setErrorMessage(errors?.response?.data?.message);
				});
			handleClose();
		}
	};

  return (
    <div>
      <Modal
        open={open}
				keepMounted
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="grid-modal" sx={style}>
					<MDBox position="relative">
						<Icon style={{ position: "absolute", right: "-12px", top: "-12px" }} className="c-pointer" onClick={handleClose} fontSize="small">close</Icon>
					</MDBox>
          <MDTypography variant="h4">
						List Purchase Order
					</MDTypography>
					<DataTable
						table={{ columns, rows : poRows }}
						isSorted={false}
						entriesPerPage={false}
						showTotalEntries={true}
						pagination={{variant: "contained", color: "primary"}}
						noEndBorder
						paginationDetail={poPaginationDetail}
						gotoPage={goToPagePo}
					/>
					{errorPo && 
						<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
							<MDTypography fontWeight="bold" sx={{ color: "red!important", fontSize: "10px"}}>{errorPo}</MDTypography>
						</MDBox>
					}
					<MDBox display="flex" alignItems="center" justifyContent="end">
						<MDButton onClick={handleAddPO} variant="gradient" color="info">Add</MDButton>
					</MDBox>
        </Box>
      </Modal>
    </div>
  );
}

export default AddPO;