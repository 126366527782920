import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from "react-hook-form";

import Box from '@mui/material/Box';
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Modal from '@mui/material/Modal';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import MDTypography from '../../../../MDTypography';
import MDInput from '../../../../MDInput';
import MDButton from '../../../../MDButton';
import MDBox from '../../../../MDBox';

import services from '../../../../../service/services';

import qrImage from "../../../../../assets/images/qr-code.png";
import { addCommas } from '../../../../../utils';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  width: '450px',
	maxWidth: '90vw',
  p: 3,
	borderRadius: "12px",
};

const AddProductModal = ({ 
	setIsLoading,
	open, 
	setOpen,
	productOptions,
	productPaginationDetail,
	setProductPaginationDetail,
	setProductRows,
}) => {
	const inputProductRef = useRef(null);

	const [skipCount, setSkipCount] = useState(true);

	const { register, control, reset, handleSubmit, watch, formState: { errors } } = useForm({mode: "all"});

	const handleAdd = (e) => {
		let newObj = {
			product: e.product,
			product_name: e.product.product_name,
			code: e.product.product_code2?.length > 0 ? e.product.product_code2 + "-" + e.product.product_code1 : e.product.product_code1,
			price: addCommas(e.product.product_price),
		}
		setProductPaginationDetail({...productPaginationDetail, total_data: productPaginationDetail.list.length+1, list: [...productPaginationDetail?.list, newObj]});
		reset({});
		setOpen(false);
	};

	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		limit: 100,
		stringify: (option) => option.product_name + option.product_code1 + option.product_code2,
	});

	const handleKeyDown = event => {
    if(event.key === "Enter"){
			event.preventDefault();
			event.stopPropagation();
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="grid-modal add-product-inventory" sx={style}>
					<MDBox position="relative">
						<Icon style={{ position: "absolute", right: "-12px", top: "-12px" }} className="c-pointer" onClick={() => setOpen(false)} fontSize="small">close</Icon>
					</MDBox>
          <MDTypography variant="h4" sx={{ mb: 2 }}>
						Add Product
					</MDTypography>
					<MDBox width="100%" mb={2} component="form" role="form" id="create-add-product" onSubmit={handleSubmit(handleAdd)}>
						<Controller 
							rules={{ required: "Required" }} 
							name="product" control={control}
							render={(props) => {
								return <Autocomplete
									className='autocomplete-product'
									options={productPaginationDetail?.list?.length > 0 ? productOptions.filter( po => !productPaginationDetail?.list?.some(item => item.product_name === po.product_name || item.product_code2 === po.product_code2 || item.product_code1 === po.product_code1)) : productOptions}
									getOptionLabel={option => option.product_name}
									filterOptions={filterOptions}
									size="small"
									renderInput={(params) => {
										params.inputProps.onKeyDown = handleKeyDown;
										return <MDInput fullWidth {...params} autoFocus={true} inputRef={inputProductRef} label="Product Name or Product Code" size="small" /> 
									}}
									renderOption={(props, option, { selected }) => (
										<li {...props} style={{ marginRight: "8px" }}>
											<MDBox width="100%" display="flex" flexDirection="column" justifyContent="space-evenly" mt={1}>
												<MDTypography fontSize="12px" sx={{ mb: "-0.4px" }}>{option.product_name}</MDTypography>
												<MDTypography fontSize="11px" sx={{ fontWeight: 900, mb: 1 }}>{option.product_code2?.length > 0 ? option.product_code2 + "-" : ""} {option.product_code1} </MDTypography>
												<MDBox width="100%" sx={{ borderBottom: "1px dotted #344767!important" }} mb={"-6px"}></MDBox>
											</MDBox>
										</li>
									)}
									onChange={async (e, data) => {
										let temp = { target: { value: data } };
										props?.field?.onChange(temp);
									}}
									onBlur={async () => {
										props?.field?.onBlur();
									}}
								/>
							}
						}/>
						{errors?.product && 
							<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
								<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.product.message}</MDTypography>
							</MDBox>
						}
					</MDBox>
					<MDBox width="100%" display="flex" justifyContent="flex-end">
						<MDButton color="info" type="submit" form="create-add-product">
							ADD PRODUCT
						</MDButton>
					</MDBox>
        </Box>
      </Modal>
    </div>
  );
}

export default AddProductModal;