import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Autocomplete, Icon, MenuItem, TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDButton from "../../../../MDButton";
import MDTypography from "../../../../MDTypography";
import MDInput from "../../../../MDInput";
import MDSnackbar from "../../../../MDSnackbar";

import placeholder from "../../../../../assets/images/placeholder-image.jpeg";

import services from "../../../../../service/services";

import { getImageError, toBase64 } from "../../../../../utils";

const SubCategoryEdit = ({
	categoryOptions,
	setIsLoading,
	rawRowsDisplayed,
	indexDetail,
	setMode
}) => {

	const subcategoryImageRef = useRef();

	const [detailData, setDetailData] = useState({});
	const [subcategoryImage, setModelImage] = useState();
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isReset, setIsReset] = useState(false);
	const [isTransparent, setIsTransparent] = useState(false);

	const { register, handleSubmit, setValue, watch, trigger, formState: { errors } } = useForm({mode: "all"});

	const watchAllFields = watch();

	const onImageChange = async (e, key) => {
		setIsReset(false);
		let format = e.target.files[0].name.split('.').pop();
		setIsTransparent(format.includes("png"));
		let val = await toBase64(e.target.files[0]);
		setValue(key, val);
		const result = await trigger(key);
		setModelImage(URL.createObjectURL(e.target.files[0])); 
	}

	const removeImage = async (key) => {
		setIsReset(true);
		setIsTransparent(false);
		setValue(key, null);
		const result = await trigger(key);
		setModelImage(null);
	}
	
	const submitEdit = (e) => {
		setIsReset(false);
		setIsLoading(true);
		services.editSubCategory({
			...e,
			id: detailData.id,
		})
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
			}).catch(errors => {
				setIsLoading(false);
				setErrorMessage(errors?.response?.data?.message);
				setShowNotifError(true);
				console.log(errors)
			})
	}

	useEffect(() => {
		if(rawRowsDisplayed?.length > 0){
			let categoryObj = categoryOptions.filter(co => co.id === rawRowsDisplayed[indexDetail].category_id)[0];
			setValue("category_name", categoryObj.category_name);
			setValue("subcategory_image", "default");
			setValue("category_id", String(categoryObj.id));
			setValue("is_popular", rawRowsDisplayed[indexDetail].is_popular == 1 ? "1" : "0");
			setValue("is_active", rawRowsDisplayed[indexDetail].status);
			setDetailData(rawRowsDisplayed[indexDetail])
		}
	}, [rawRowsDisplayed])

	const backClickHandler = () => {
		setMode("index");
	}

  return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" justifyContent="space-between" mt={-0.5} mb={4} width="100%">
				<MDBox display="flex" alignItems="center">
					<button onClick={backClickHandler} style={{border: 'none', background: 'white', borderRadius: '5px', marginRight: '1rem',width: '2rem', height: '2rem',display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
						<Icon fontSize="medium">
							arrow_back
						</Icon>
					</button>
					<MDTypography variant="h3" fontWeight="regular">
					Edit Subcategory
					</MDTypography>
				</MDBox>
				<MDButton color="info" type="submit" form="edit-subcategory">
					Save
				</MDButton>
			</MDBox>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="SubCategory Updated!"
				open={showNotifSuccess}
				dateTime="Just Now"
				close={() => setShowNotifSuccess(false)}
			/>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			{
				rawRowsDisplayed.length > 0 && Object.keys(detailData)?.length > 0 ?
					<MDBox component="form" role="form" id="edit-subcategory" onSubmit={handleSubmit(submitEdit)}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6}>
								<MDBox width="100%" mb={3}>
									<Card
										sx={{
											"&:hover .card-header": {
												transform: "translate3d(0, -50px, 0)",
											},
											border: errors?.subcategory_image ? "2px solid red" : "inherit",
											width: "100%",
											maxWidth: "500px",
											margin: "0 auto"
										}}
									>
										<MDBox
											position="relative"
											borderRadius="lg"
											mt={-3}
											mx={1}
											mb={-1}
											className="card-header"
											sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)", zIndex: 999, backgroundColor: "transparent" }}
										>
											<MDBox
												sx={{ backgroundColor: detailData.subcategory_image?.includes(".png") || isTransparent ? "#FFF" : "", borderRadius: "8px" }}
											>
												<MDBox
													component="img"
													src={subcategoryImage ? subcategoryImage : isReset || !detailData.subcategory_image ? placeholder : detailData.subcategory_image}
													alt="Subcategory Image"
													borderRadius="lg"
													shadow="sm"
													width="100%"
													height="100%"
													position="relative"
													zIndex={10}
													onError={getImageError}
												/>
											</MDBox>
										</MDBox>
										<MDBox textAlign="center" pt={4} pb={1} px={3}>
											<MDBox
												display="flex"
												justifyContent="center"
												alignItems="center"
												mt={-9}
												mb={-1}
												position="relative"
												zIndex={1}
											>
												<MDBox mr={1}>
													<input
														{...register("subcategory_image", { required: "Required" })}
														type="file"
														accept="image/png;image/jpg;image/jpeg;"
														ref={subcategoryImageRef}
														onChange={(e) => onImageChange(e, "subcategory_image")}
														className="d-none"
													/>
													<MDButton variant="gradient" color="info" size="small" onClick={() => subcategoryImageRef.current.click()}>
														edit
													</MDButton>
												</MDBox>
												<MDButton variant="outlined" color="dark" size="small" onClick={() => removeImage("subcategory_image")}>
													remove
												</MDButton>
											</MDBox>
											<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 3 }}>
												Subcategory Image
											</MDTypography>
											{
												errors?.subcategory_image &&
													<MDTypography fontWeight="bold" sx={{ fontSize: "10px", color: "red" }}>
														{errors.subcategory_image.message}
													</MDTypography>
											}
										</MDBox>
									</Card>
								</MDBox>
								<Card sx={{ padding: "24px 32px", width: "100%" }}>
									<MDTypography variant="h4" fontWeight="regular" sx={{ ml: -1, mb: 4}}>
										Category Information
									</MDTypography>
									<MDBox mb={2}>
										<TextField
											{...register("category_name", { required: "Required" })}
											id="select-category-name" 
											size="small" 
											fullWidth 
											label="Category Name"
											type="select" 
											select
											error={errors?.category_name}
											value={watchAllFields?.category_name}
										>
											<MenuItem disabled value="">
												<em>Neither</em>
											</MenuItem>
											<MenuItem value="INTERIOR">INTERIOR</MenuItem>
											<MenuItem value="EXTERIOR">EXTERIOR</MenuItem>
										</TextField>
										{errors?.category_name && 
											<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
												<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.category_name.message}</MDTypography>
											</MDBox>
										}
									</MDBox>
									{
										watchAllFields?.category_name ?
											<>
												<MDBox mb={2}>
													<MDInput 
														type="text" 
														label="Brand Code"
														fullWidth
														value={watchAllFields?.category_name?.substring(0,2)}
														disabled
													/>
												</MDBox>
												<MDBox mb={2}>
													<TextField
														{...register("category_id", { required: "Required" })}
														id="select-category-type" 
														size="small" 
														fullWidth 
														label="Category Type"
														type="select" 
														select
														error={errors?.category_id}
														value={watchAllFields?.category_id}
													>
														<MenuItem disabled value="">
															<em>Neither</em>
														</MenuItem>
														{
															categoryOptions.filter(co => co.category_name === watchAllFields?.category_name)
																.map((c, i) => {
																	return (
																		<MenuItem key={"category-type-list-"+i} value={c.id}>{c.category_type}</MenuItem>
																	)
															})
														}
													</TextField>
													{errors?.category_id && 
														<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
															<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.category_id.message}</MDTypography>
														</MDBox>
													}
												</MDBox>
											</>
										: null
									}
									{
										watchAllFields?.category_id ?
											<MDBox mb={2}>
												<MDInput 
													type="text" 
													label="Category Status"
													fullWidth
													value={categoryOptions.filter(co => co.id === watchAllFields?.category_id)[0]?.category_status == 1 ? "Active" : "Not Active"}
													disabled
												/>
											</MDBox>
										: null
									}
								</Card>
							</Grid>
							<Grid xs={12} md={6} item>
								<Card sx={{ padding: "24px 32px" }}>
									<MDTypography variant="h4" fontWeight="regular" sx={{ ml: -1, mb: 4}}>
										SubCategory Information
									</MDTypography>
										<MDBox mb={2}>
											<MDInput error={errors?.subcategory_name} defaultValue={detailData.subcategory_name} {...register("subcategory_name", { required: "Required" })} type="text" label="Subcategory Name" fullWidth />
											{errors?.subcategory_name && 
												<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
													<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.subcategory_name.message}</MDTypography>
												</MDBox>
											}
										</MDBox>
										<MDBox mb={2}>
											<MDInput error={errors?.subcategory_code} defaultValue={detailData.subcategory_code} {...register("subcategory_code", { required: "Required", maxLength: { value: 3, message: "Maximum character length is 3"} })} type="text" label="Subcategory Code" fullWidth />
											{errors?.subcategory_code && 
												<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
													<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.subcategory_code.message}</MDTypography>
												</MDBox>
											}
										</MDBox>
										<MDBox mb={2}>
											<TextField
												{...register("is_popular", { required: "Required" })}
												id="select-popular" 
												size="small" 
												fullWidth 
												label="Popular"
												type="select" 
												select
												error={errors?.is_popular}
												value={watchAllFields?.is_popular}
											>
												<MenuItem disabled value="">
													<em>Neither</em>
												</MenuItem>
												<MenuItem value="1">Yes</MenuItem>
												<MenuItem value="0">No</MenuItem>
											</TextField>
											{errors?.is_popular && 
												<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
													<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.is_popular.message}</MDTypography>
												</MDBox>
											}
										</MDBox>
										<MDBox mb={2}>
											<TextField
												{...register("is_active", { required: "Required" })}
												id="select-is-active" 
												size="small" 
												fullWidth 
												label="Status"
												type="select" 
												select
												error={errors?.is_active}
												value={watchAllFields?.is_active}
											>
												<MenuItem disabled value="">
													<em>Neither</em>
												</MenuItem>
												<MenuItem value="1">Active</MenuItem>
												<MenuItem value="0">Not Active</MenuItem>
											</TextField>
											{errors?.is_active && 
												<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
													<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.is_active.message}</MDTypography>
												</MDBox>
											}
										</MDBox>
								</Card>
							</Grid>
						</Grid>
					</MDBox>
				: null
			}
		</Grid>
  );
}

export default SubCategoryEdit;
