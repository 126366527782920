import { useEffect, useMemo, useState, useRef } from "react";
import { CSVLink } from "react-csv";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import InputAdornment from '@mui/material/InputAdornment';

import DataTable from "../../../../Table";
import MDInput from "../../../../MDInput";
import MDBox from "../../../../MDBox";
import MDButton from "../../../../MDButton";
import MDSnackbar from "../../../../MDSnackbar";

import FilterModal from "./filterModal";

import services from "../../../../../service/services";

import breakpoints from "../../../../../assets/theme/base/breakpoints";
import { useSearchParams } from "react-router-dom";
import { cleanObject } from "../../../../../utils";
import { MenuItem } from "@mui/material";

const ItemProductsIndex = ({
	dataFilter,
	setDataFilter,
	columns, 
	rows,
	rowsDisplayed,
	setRowsDisplayed,
	setRawRowsDisplayed,
	setIsLoading,
	setMode,
	paginationDetail,
	setPaginationDetail,
	getList,
	isFiltered,
	setIsFiltered,
	settingRowsDataTable,
	brandOptions,
	modelOptions,
	modelTypeOptions,
}) => {

	const [searchParams, setSearchParams] = useSearchParams();

  	const exportRef = useRef();

	const [query, setQuery] = useState(searchParams.get("query") || "");
	const [querySearched, setQuerySearched] = useState(searchParams.get("query") || "");
	const [openFilter, setOpenFilter] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [csvData, setCsvData] = useState([]);
	const headers = [
		{ label: "No", key: "no"},
		{ label: "Product Name", key: "product_name"},
		{ label: "Product Code", key: "product_code"},
		{ label: "Molding Code", key: "molding_code"},
		{ label: "Model Name", key: "model_name"},
		{ label: "Model Year", key: "model_year"},
		{ label: "Model Type", key: "model_type"},
		{ label: "Subcategory", key: "subcategory"},
		{ label: "Subattribute", key: "subattribute"},
		{ label: "Status", key: "status"},
	];

	const handleSearchChange = (e) => {
		setQuery(e.target.value);
	}

	const searchList = (e) => {
		if(e.keyCode === 13 && query.length >= 3){
			searchParams.set('page', 1);
			searchParams.set('query', query);
			setSearchParams(searchParams);
			setQuerySearched(query);
		}
	}
	
	useEffect(() => {
		if(querySearched.length === 0 && rows.length > 0){
			setRowsDisplayed(rows);
		}
	},[query, rows]);

	const searchInput = (page) => {
		setIsLoading(true);
		services.searchItemProductsList(querySearched, page)
			.then(res => {
				setIsLoading(false);
				setIsFiltered(false);
				setRowsDisplayed(settingRowsDataTable(res?.data?.data?.list, res?.data?.data?.current_page));
				setRawRowsDisplayed(res?.data?.data?.list);
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			});
	};

	useMemo(() => {
		if(querySearched.length > 0){
			searchInput(searchParams.get("page") || 1)
		}
	}, [querySearched]);

	const filterList = (page) => {
		setIsLoading(true);
		setSearchParams(cleanObject({ ...dataFilter, page }));
		services.filterItemProductsList({...dataFilter, page: page})
			.then(res => {
				setIsFiltered(true);
				setIsLoading(false);
				setRowsDisplayed(settingRowsDataTable(res?.data?.data?.list, res?.data?.data?.current_page));
				setPaginationDetail(res?.data?.data)
				setRawRowsDisplayed(res?.data?.data?.list);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	}

	const gotoPage = (page) => {
		searchParams.set('page', page);
		setSearchParams(searchParams);
		if(isFiltered){
			filterList(page);
		} else if(querySearched.length > 0){
			searchInput(page);
		} else {
			getList(page);
		}
	}

	const exportList = async () => {
		let tempData = [];
		setShowNotifError(false);
		setIsLoading(true)
		if(isFiltered){
			services.filterItemProductsList({...dataFilter, page: 0})
				.then(async res => {
					setIsLoading(false);
					tempData = await settingExportData(res?.data?.data?.list);
					setCsvData(tempData);
				}).catch(errors => {
					setIsLoading(false);
					setShowNotifError(true);
					setErrorMessage(errors?.response?.message);
					console.log(errors)
				})
		} else if(querySearched.length > 0){
			services.searchItemProductsList(querySearched, 0)
				.then(async res => {
					setIsLoading(false);
					tempData = await settingExportData(res?.data?.data?.list);
					setCsvData(tempData);
				}).catch(errors => {
					setIsLoading(false);
					setShowNotifError(true);
					setErrorMessage(errors?.response?.message);
					console.log(errors)
				})
		} else {
			services.getProductItemProducts(0)
				.then(async res => {
					setIsLoading(false);
					tempData = await settingExportData(res?.data?.data?.list);
					setCsvData(tempData);
				}).catch(errors => {
					setIsLoading(false);
					setShowNotifError(true);
					setErrorMessage(errors?.response?.message);
					console.log(errors)
				})
		}
  }

	const settingExportData = async (arrayList) => {
		let tempData = [];
		arrayList?.map((list, i) => {
			tempData.push({
				no: (i+1),
				product_name: list?.name,
				product_code: list?.code1,
				molding_code: list?.molding_code,
				model_name: list?.model_name,
				model_year: list?.model_year,
				model_type: list?.model_type_name,
				subcategory: list?.subcategory_name,
				subattribute: list?.subattribute_name,
				status: list?.is_active == "0" || list?.status == "0" ? "Not Active" : list?.is_active == "1" || list?.status == "1" ? "Active" : ""
			});
		});
		return tempData;
	}

	useEffect(() => {
    if(csvData?.length > 0){
      setTimeout(() => { 
        exportRef.current.link.click();
      }, 500);
    }
  }, [csvData])

  const removeQuery = () => {
	setQuery("");
	setQuerySearched("");
	setSearchParams({ page: 1, query: '' });
  }

  return (
		<Grid item xs={12}>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			<MDBox sx={ window.innerWidth > breakpoints.values.md ? {display: "flex", padding: "12px 48px", alignItems: "center"} : {display: "flex", flexWrap: "wrap", padding: "12px 24px", justifyContent: "center"} } bgColor="#E5ECF0" borderRadius="6px" marginBottom="12px">
				<MDInput 
					variant="outlined" 
					label="Product Name or Product Code or Molding Code or Brand or Model"
					className="search-input"
					InputProps={{
            endAdornment: <InputAdornment position="end"><Icon className="c-pointer" onClick={removeQuery} fontSize="small">close</Icon><Icon fontSize="small">search</Icon></InputAdornment>,
          }}
					backgroundColor="#FFF"
					style={{ height:"100%", borderRadius:"6px", lineHeight:"22px" }}
					fullWidth
					onChange={handleSearchChange}
					onKeyDown={searchList}
					value={query}
					autoFocus
				/>
				<MDBox sx={window.innerWidth > breakpoints.values.md ? {marginLeft: "24px", gap: "16px", justifyContent: "space-between"} : {marginTop: "8px", flexWrap: "wrap", gap: "8px", justifyContent: "center", alignItems: "center"}} display="flex">
					<MDButton variant="gradient" color="info" onClick={() => setOpenFilter(true)}>
						<Icon fontSize="small" style={{marginRight: "8px"}}>filter_list</Icon> Filter
					</MDButton>
					<MDButton variant="gradient" color="info" onClick={() => setMode("add")}>
						<Icon fontSize="small" fontWeight="medium" style={{marginRight: "8px"}}>add</Icon> Add
					</MDButton>
					<MDButton variant="gradient" color="info" onClick={exportList}>
						Export
					</MDButton>
					<CSVLink
						headers={headers}
						filename="ITEM_PRODUCTS.csv"
						data={csvData}
						ref={exportRef}
					>
					</CSVLink>
				</MDBox>
			</MDBox>
			<Card>
				<DataTable
					table={{ columns, rows : rowsDisplayed }}
					isSorted={false}
					entriesPerPage={false}
					showTotalEntries={true}
					pagination={{variant: "contained", color: "primary"}}
					noEndBorder
					paginationDetail={paginationDetail}
					gotoPage={gotoPage}
				/>
			</Card>
			<FilterModal
				open={openFilter} 
				setOpen={setOpenFilter}
				brandOptions={brandOptions}
				modelOptions={modelOptions}
				modelTypeOptions={modelTypeOptions}
				filterList={filterList}
				dataFilter={dataFilter}
				setDataFilter={setDataFilter}
			/>
		</Grid>
  );
}

export default ItemProductsIndex;
