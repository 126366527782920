import { useEffect, useState } from "react";
import parse from 'html-react-parser';
import axios from "axios";

// @mui material components
import {Grid, Icon} from "@mui/material";

import MDSnackbar from "../../../../components/MDSnackbar";

import VoucherIndex from "../../../../components/AppComponents/Autoloka/promo/voucher";
import VoucherAdd from "../../../../components/AppComponents/Autoloka/promo/voucher/add";
import VoucherView from "../../../../components/AppComponents/Autoloka/promo/voucher/view";

import services from "../../../../service/services";

import { addCommas } from "../../../../utils";
import { useSearchParams } from "react-router-dom";

const Voucher = ({
	setIsLoading,
	setMode,
	mode,
 }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [rows, setRows] = useState([]);
	const [rowsDisplayed, setRowsDisplayed] = useState([]);
	const [rawRowsDisplayed, setRawRowsDisplayed] = useState([]);
	const [paginationDetail, setPaginationDetail] = useState({});
	const [userOptions, setUserOptions] = useState([]);
	const [regionOptions, setRegionOptions] = useState([]);
	const [productOptions, setProductOptions] = useState([]);
	const [productTypeOptions, setProductTypeOptions] = useState([]);
	const [idDetail, setIdDetail] = useState(null);
	const [userRows, setUserRows] = useState([]);
	const [userRawRows, setUserRawRows] = useState([]);
	const [userColumns, setUserColumns] = useState([
		{ Header: "User", accessor: "full_name", align: "left" },
		{ Header: "Email", accessor: "email", align: "left" },
		{ Header: "Delete", accessor: "delete", align: "left" },
	]);
	const [userPaginationDetail, setUserPaginationDetail] = useState({});
	const [region, setRegion] = useState(null);
	const [regionRows, setRegionRows] = useState([]);
	const [regionRawRows, setRegionRawRows] = useState([]);
	const [regionColumns, setRegionColumns] = useState([
		{ Header: "Province", accessor: "province", align: "left" },
		{ Header: "City", accessor: "city", align: "left" },
		{ Header: "Delete", accessor: "delete", align: "left" },
	]);
	const [regionPaginationDetail, setRegionPaginationDetail] = useState({});
	const [productRows, setProductRows] = useState([]);
	const [productRawRows, setProductRawRows] = useState([]);
	const [productColumns, setProductColumns] = useState([
		{ Header: "Item", accessor: "item", align: "left" },
		{ Header: "Min Qty Order", accessor: "min_qty_order", align: "left" },
		{ Header: "Delete", accessor: "delete", align: "left" },
	]);
	const [productPaginationDetail, setProductPaginationDetail] = useState({});

  const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "View", accessor: "view", align: "left" },
		{ Header: "Voucher Code", accessor: "voucher_code", align: "left" },
		{ Header: "Title", accessor: "title", align: "left" },
		{ Header: "Description", accessor: "description", align: "left" },
		{ Header: "Effective Date", accessor: "effective_date", align: "left" },
	];

	useEffect(() => {
		if(mode === "index"){
			setIsLoading(true);
			Promise
				.all([
					services.getVoucherList(searchParams.get("page") || 1),
					services.getOptionsCustomerType(1),
					services.getOptionsCustomerType(2),
				]).then(axios.spread((...res) => {
					setIsLoading(false);
					setRows(settingRowsDataTable(res[0]?.data?.data?.list, res[0]?.data?.data?.current_page));
					setRawRowsDisplayed(res[0]?.data?.data?.list);
					setPaginationDetail(res[0]?.data?.data);
					setUserOptions(res[1]?.data?.data?.list);
					setRegionOptions(res[2]?.data?.data?.list);
				})).catch(errors => {
					setIsLoading(false);
					console.log("Error is found, apologies.")
					console.log(errors)
				})
		}
		setUserRawRows([]);
		setUserRows([]);
		setUserPaginationDetail({
			current_page: 1,
			total_data: 0,
			total_page: 1,
		});
		setRegionRawRows([]);
		setRegionRows([]);
		setRegionPaginationDetail({
			current_page: 1,
			total_data: 0,
			total_page: 1,
		});
		setProductRawRows([]);
	}, [mode])

	const getList = (page) => { 
		setIsLoading(true)
		services.getVoucherList(page)
			.then(res => {
				setIsLoading(false);
				setRows(settingRowsDataTable(res?.data?.data?.list, res?.data?.data?.current_page));
				setRawRowsDisplayed(res?.data?.data?.list);
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	};

	const settingRowsDataTable = (rowsArray, current_page) => {
		return rowsArray?.map((arr, i) => {
			return {
				no: ((current_page - 1) * 10) + i + 1,
				view: (
					<>
						<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setMode("view"); setIdDetail(arr.id)}}>visibility</Icon>
					</>
				),
				voucher_code: arr?.voucher_code,
				title: arr?.title || "",
				description: parse(arr?.description),
				effective_date: arr?.effective_date,
			};
		});
	};

	const settingPaginationDetail = (list, page = 0) => {
		let total_data = list.length;
		let total_page = parseInt(total_data/10);
		if(total_data%10 !== 0){
			total_page = total_page + 1;
		};
		return {
			current_page: page === 0 ? total_page : page,
			total_data: total_data,
			total_page: total_page,
		}
	};

	const settingUserRows = (list, page) => {
		return list?.filter((arr, i) => (((page - 1) * 10) <= i && (page * 10) > i))
			.map( arr2 => {
				return {
					full_name: arr2?.full_name,
					email: arr2?.email,
					delete:
						<>
							<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => deleteUser(arr2.id)}>delete</Icon>
						</>
				}}
			)
	};

	const deleteUser = (id) => {
		if(id === "all"){
			setUserRawRows([]);
			setUserRows([]);
			setUserPaginationDetail({
				current_page: 1,
				total_data: 0,
				total_page: 1,
			});
		} else {
			let tempRow = userRawRows.filter( urr => urr.id !== id );
			setUserRawRows(tempRow);
			let paginationDetail = settingPaginationDetail(tempRow);
			setUserRows(settingUserRows(tempRow, paginationDetail.total_page));
			setUserPaginationDetail(paginationDetail);
		}
	};

	const userGotoPage = (page) => {
		setUserRows(settingUserRows(userRawRows, page));
		setUserPaginationDetail(settingPaginationDetail(userRawRows, page));
	};

	const settingRegionRows = (list, page) => {
		return list?.filter((arr, i) => (((page - 1) * 10) <= i && (page * 10) > i))
			.map( arr2 => {
				return {
					province: arr2?.province_name,
					city: arr2?.city_name,
					delete:
						<>
							<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => deleteRegion(arr2.city_id)}>delete</Icon>
						</>
				}}
			)
	};

	const deleteRegion = (id) => {
		if(id === "all"){
			setRegionRawRows([]);
			setRegionRows([]);
			setRegionPaginationDetail({
				current_page: 1,
				total_data: 0,
				total_page: 1,
			});
		} else {
			let tempRow = regionRawRows.filter( urr => urr.city_id !== id );
			setRegionRawRows(tempRow);
			let paginationDetail = settingPaginationDetail(tempRow);
			setRegionRows(settingRegionRows(tempRow, paginationDetail.total_page));
			setRegionPaginationDetail(paginationDetail);
		}
	};

	const regionGotoPage = (page) => {
		setRegionRows(settingRegionRows(regionRawRows, page));
		setRegionPaginationDetail(settingPaginationDetail(regionRawRows, page));
	};

	const settingProductRows = (list, page) => {
		return list?.filter((arr, i) => (((page - 1) * 10) <= i && (page * 10) > i))
			.map( arr2 => {
				return {
					item: arr2?.item,
					min_qty_order: arr2?.min_qty_order || arr2?.min_qty_order,
					delete:
						<>
							<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => deleteProduct(arr2.id)}>delete</Icon>
						</>
				}}
			)
	};

	const deleteProduct = async (id) => {
		if(id === "all"){
			setProductRawRows([]);
		} else {
			let tempRow = [...productRawRows].filter( prr => prr.id !== id );
			setProductRawRows(tempRow);
		}
	};

	useEffect(() => {
		let paginationDetail = settingPaginationDetail(productRawRows);
		setProductRows(settingProductRows(productRawRows, paginationDetail.total_page));
		setProductPaginationDetail(paginationDetail);
	}, [productRawRows]);

	const productGotoPage = (page) => {
		setProductRows(settingProductRows(productRawRows, page));
		setProductPaginationDetail(settingPaginationDetail(productRawRows, page));
	}

  return (
    <>
			<Grid container spacing={6} className="grid-pure-container">
				{
					mode === "index" ?
						<VoucherIndex 
							columns={columns} 
							rows={rows}
							rowsDisplayed={rowsDisplayed}
							setRowsDisplayed={setRowsDisplayed} 
							setRawRowsDisplayed={setRawRowsDisplayed}
							setIsLoading={setIsLoading}
							setMode={setMode}
							paginationDetail={paginationDetail}
							setPaginationDetail={setPaginationDetail}
							getList={getList}
							settingRowsDataTable={settingRowsDataTable}
						/>
					: mode === "add" ?
						<VoucherAdd 
							setIsLoading={setIsLoading} 
							userOptions={userOptions}
							regionOptions={regionOptions}
							productOptions={productOptions}
							setProductOptions={setProductOptions}
							productTypeOptions={productTypeOptions}
							setProductTypeOptions={setProductTypeOptions}
							settingPaginationDetail={settingPaginationDetail}
							settingUserRows={settingUserRows}
							userGotoPage={userGotoPage}
							settingRegionRows={settingRegionRows}
							regionGotoPage={regionGotoPage}
							productGotoPage={productGotoPage}
							deleteUser={deleteUser}
							userRows={userRows}
							setUserRows={setUserRows}
							userRawRows={userRawRows}
							setUserRawRows={setUserRawRows}
							userColumns={userColumns}
							userPaginationDetail={userPaginationDetail}
							setUserPaginationDetail={setUserPaginationDetail}
							deleteRegion={deleteRegion}
							regionRows={regionRows}
							setRegionRows={setRegionRows}
							regionRawRows={regionRawRows}
							setRegionRawRows={setRegionRawRows}
							regionColumns={regionColumns}
							regionPaginationDetail={regionPaginationDetail}
							setRegionPaginationDetail={setRegionPaginationDetail}
							deleteProduct={deleteProduct}
							productRows={productRows}
							setProductRows={setProductRows}
							productRawRows={productRawRows}
							setProductRawRows={setProductRawRows}
							productColumns={productColumns}
							productPaginationDetail={productPaginationDetail}
						/>
					: mode === "view" ?
						<VoucherView 
							setMode={setMode}
							id={idDetail}
							setIsLoading={setIsLoading}
							settingPaginationDetail={settingPaginationDetail}
							settingUserRows={settingUserRows}
							userGotoPage={userGotoPage}
							settingRegionRows={settingRegionRows}
							regionGotoPage={regionGotoPage}
							productGotoPage={productGotoPage}
							userRows={userRows}
							setUserRows={setUserRows}
							setUserRawRows={setUserRawRows}
							userColumns={userColumns}
							userPaginationDetail={userPaginationDetail}
							setUserPaginationDetail={setUserPaginationDetail}
							regionRows={regionRows}
							setRegionRows={setRegionRows}
							setRegionRawRows={setRegionRawRows}
							regionColumns={regionColumns}
							regionPaginationDetail={regionPaginationDetail}
							setRegionPaginationDetail={setRegionPaginationDetail}
							deleteProduct={deleteProduct}
							productRows={productRows}
							setProductRows={setProductRows}
							setProductRawRows={setProductRawRows}
							productColumns={productColumns}
							productPaginationDetail={productPaginationDetail}
						/>
					: null
				}
			</Grid>
    </>
  );
}

export default Voucher;
