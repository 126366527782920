import { useEffect, useMemo, useRef, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import { Card, Icon, TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDInput from "../../../../MDInput";
import MDButton from "../../../../MDButton";
import MDSnackbar from "../../../../MDSnackbar";
import MDTypography from "../../../../MDTypography";

import services from "../../../../../service/services";

import AddProductModal from "./addProductModal";
import DataTable from "../../../../Table";
import { addCommas } from "../../../../../utils";
import { append } from "domutils";

import {marketPlace} from './data';

const PurchaseOrderView = ({
	setIsLoading,
	id,
	productOptions,
	setMode
}) => {

	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifSuccessDraft, setShowNotifSuccessDraft] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [errorProduct, setErrorProduct] = useState("");
	const [productRows, setProductRows] = useState([]);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [productPaginationDetail, setProductPaginationDetail] = useState({
		current_page: 1,
		total_data: 0,
		total_page: 0,
		list: [],
	});
	const [detailData, setDetailData] = useState({});
	const [displayAddProduct, setDisplayAddProduct] = useState(false);

	const { register, trigger, control, reset, watch, formState: { errors } } = useForm({mode: "all"});

	const watchAllFields = watch();

	const { fields, update, remove, replace } = useFieldArray({
		control,
		shouldUnregister: false,
    name: "qty",
	});

	const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Product Name", accessor: "product_name", align: "left" },
		{ Header: "Code", accessor: "product_code", align: "left" },
		{ Header: "Price", accessor: "price", align: "left" },
		{ Header: "Qty", accessor: "qty", align: "left" },
	];

	useEffect(() => {
		setIsLoading(true);
		services.getDetailSales(id)
			.then(res => {
				setIsLoading(false);
				setDetailData(res?.data?.data);
				let listAll = res?.data?.data.list_detail;
				let total_page = parseInt(listAll.length/10);
				if(listAll.length%10 !== 0){
					total_page = total_page + 1;
				};
				setProductPaginationDetail({
					current_page: 1,
					total_page: total_page,
					total_data: listAll.length,
					list: listAll,
				});
				replace(listAll.map( list => list.qty));
			}).catch(errors => {
				setIsLoading(false);
				setShowNotifError(true)
				setErrorMessage(errors?.response?.data?.message);
			});
	}, []);

	const settingRows = () => {
		let listAll = productPaginationDetail.list.map( (arr, j) => {
			return {
				no: j + 1,
				order_detail_id: arr?.order_detail_id,
				product_id: arr?.product_id,
				product_name: arr?.product_name,
				product_code: arr?.product_code || arr?.code,
				price: "Rp"+addCommas(arr?.price),
				qty: <>
					<TextField style={{ width: "48px" }} disabled className="without-label-input" type="number" inputProps={{ inputMode: 'numeric', pattern: '[1-9]*' }} defaultValue={arr?.qty} InputLabelProps={{ shrink: true }} />
				</>,
			}}
		);
		let total_page = parseInt(listAll.length/10);
		if(listAll.length%10 !== 0){
			total_page = total_page + 1;
		};
		setProductPaginationDetail({...productPaginationDetail, total_page: total_page});
		return listAll;
	}

	useMemo(() => {
		let listAll = settingRows();
		listAll = listAll?.filter((arr, i) => (((productPaginationDetail.current_page - 1) * 10) <= i && (productPaginationDetail.current_page * 10) > i));
		setProductRows(listAll);
	}, [productPaginationDetail.list, productPaginationDetail.current_page])

	const deleteProductRow = (id, index) => {
		let array = productPaginationDetail.list;
		let filtered = array.filter((a, i) => a.product.product_id !== id);
		remove(index);
		setProductPaginationDetail({...productPaginationDetail, list: [...filtered], total_data: filtered.length});
	};

	const goToPageProduct = (page) => {
		setProductPaginationDetail({...productPaginationDetail, current_page: page});
	};

	const backClickHandler = () => {
		setMode("index");
	}

  return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" flexDirection={window.innerWidth < 768 ? "column" : "row"} gap="8px" justifyContent="space-between" mt={-0.5} mb={2.5} width="100%">
				<MDBox display="flex" alignItems="center">
					<button onClick={backClickHandler} style={{border: 'none', background: 'white', borderRadius: '5px', marginRight: '1rem',width: '2rem', height: '2rem',display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
						<Icon fontSize="medium">
							arrow_back
						</Icon>
					</button>
					<MDTypography variant="h3" fontWeight="regular">
					Sales Order Detail
					</MDTypography>
				</MDBox>
			</MDBox>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			<MDBox maxWidth="800px" width="100%" mb={4}>
				<Card sx={{ px: 3, py: 2 }}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={12}>
							<MDTypography variant="h4" fontWeight="regular">Market place</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{textDecoration: "underline"}} fontStyle="italic">{marketPlace.find((el) => el.id === detailData.marketplace_id)?.value || ''}</MDTypography>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Customer Name</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{textDecoration: "underline"}} fontStyle="italic">{detailData?.customer_name}</MDTypography>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Customer Info</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{textDecoration: "underline"}} fontStyle="italic">{detailData?.customer_info}</MDTypography>
						</Grid>
					</Grid>
				</Card>
			</MDBox>
			<MDBox maxWidth="950px" width="100%">
				<DataTable
					table={{ columns, rows : productRows }}
					isSorted={false}
					entriesPerPage={false}
					showTotalEntries={true}
					pagination={{variant: "contained", color: "primary"}}
					noEndBorder
					paginationDetail={productPaginationDetail}
					gotoPage={goToPageProduct}
				/>
				{errorProduct && 
						<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
							<MDTypography fontWeight="bold" sx={{ color: "red!important", fontSize: "10px"}}>{errorProduct}</MDTypography>
						</MDBox>
					}
			</MDBox>
			<AddProductModal open={displayAddProduct} setOpen={setDisplayAddProduct} setIsLoading={setIsLoading} productOptions={productOptions} setProductRows={setProductRows} productPaginationDetail={productPaginationDetail} setProductPaginationDetail={setProductPaginationDetail} />
		</Grid>
  );
}

export default PurchaseOrderView;
