import React, { useEffect, useMemo, useState, useRef, version } from "react";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import {Helmet} from "react-helmet";

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";

import MyApps from "./pages/myApps";
import Login from "./pages/login";
import AllApps from "./pages/allApps";
import AllPages from "./pages/apps/allPages";

import ProtectedRoutes from "./components/PrivateRoutes";
import Spinner from "./components/spinner";
import DashboardLayout from "./components/DashboardLayout";
import DashboardNavbar from "./components/DashboardNavbar";
import MDBox from "./components/MDBox";
import Sidenav from "./components/Sidenav";

import { themed } from "./assets/theme";
import colors from "./assets/theme/base/colors";
import breakpoints from "./assets/theme/base/breakpoints";

import "react-daterange-picker/dist/css/react-calendar.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import logoTransparent from "./assets/images/logo-transparent.png";
import imgAutoloka from "./assets/images/autoloka.svg";
import imgAutotivo from "./assets/images/autotivo.svg";
import imgJsl from "./assets/images/jsl.svg";

import { useMaterialUIController, setMiniSidenav } from "./context";
import useFetchState from "./hooks/useFetchState";
import services from "./service/services";

const App = () => {

	const getMainMenu = useFetchState();
	
	library.add(fas);

	const navigate = useNavigate();

  	const location = useLocation();

  	const [controller, dispatch] = useMaterialUIController();

	const { miniSidenav } = controller;
	
	const [appsList, setAppsList] = useState([]);
	const [myAppsList, setMyAppsList] = useState([]);
	const [appActive, setAppActive] = useState("");
	const [menuActive, setMenuActive] = useState("");
	const [mode, setMode] = useState("index");
	const [isLoading, setIsLoading] = useState(false);
	const [allPath, setAllPath] = useState([]);

  	const [mobileView, setMobileView] = useState(false);

	const useOutsideAlerter = (ref) => {
		useEffect(() => {
			function handleClickOutside(event) {
				let className = event?.target?.className;
				if(!className?.includes("MuiPaper-elevation") && !className?.includes("sidenav-child") && !className?.includes("material-icons") && window.innerWidth < breakpoints.values.xl){
					setMobileView(false);
				}
			}

			// Bind the event listener
			document.addEventListener("click", handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("click", handleClickOutside);
			};
		}, [ref]);
	};

	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef);

	useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
			setMobileView(false);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

	const defaultColor = "#e91e63";
	const [primaryColor, setPrimaryColor] = useState(defaultColor);
	const dynamicTheme = useMemo(
		() =>
			createTheme({
				...themed,
				palette: { ...colors, primary: { main: primaryColor ? primaryColor : defaultColor, focus: primaryColor ? primaryColor : defaultColor } },
			})
		,
		[primaryColor],
	);

	const setApp = async () => {
		if(menuActive?.length === 0){
			if(Cookies.get("loggedIn") === "true"){
				const dashboard = await localStorage.getItem("dashboards");
				if(!dashboard) return;
				let dbs = JSON.parse(dashboard);
				setMyAppsList(dbs);
				setAppActive(Cookies.get("appActive"));
				setMenuActive(Cookies.get("menuActive"));
				let index = dbs.findIndex(x => x.apps_name.toLowerCase().replace(" ", "-") === Cookies.get("appActive"));
				setPrimaryColor(dbs[index]?.apps_theme_color);
				if(Cookies.get("menuActive")){
					navigate(`/${Cookies.get("appActive")+Cookies.get("menuActive")}`);
				}
			}
		} else {
			let index = myAppsList.findIndex(x => x.apps_name.toLowerCase().replace(" ", "-") === appActive);
			setAllPath(myAppsList[index]?.apps_menu ? myAppsList[index].apps_menu : []);
			if(menuActive?.length > 0){
				navigate(`/${appActive+menuActive}`);
			}
		}
	}

	const getMainMenuFetcher = async () => {
		setIsLoading(true);
		getMainMenu.fetchStart();
		setAppActive("");
		setMenuActive("");
		Cookies.remove("appActive");
		Cookies.remove("menuActive");
		services.getMainMenu().then((res) => {
			setIsLoading(false);
			getMainMenu.fetchSuccess(res.data?.data);
			localStorage.setItem("dashboards", JSON.stringify(res.data?.data));
			setMyAppsList(res.data?.data);
		}).catch((err) => {
			setIsLoading(false);
			getMainMenu.fetchFailed(err.response?.message || err?.message || 'Ooops something went wrong!');
		});
	}

	useEffect(() => {
		setApp();
	}, [menuActive]);

	const RouteComponent = useMemo(() => {
		return myAppsList?.map((app, appIndex) => {
			const appName = app?.apps_name.toLowerCase().replace(" ","-");
			return (
				<Route key={"dynmamic-parent-" + appIndex} path={appName}>
					{app?.apps_menu?.map((menu) => {
						const ComponentObj = AllPages.find((el) => el.name === appName).pages?.find((el) => el["menu-name"] === menu.key);
						if(menu.dropdown) {
							return (
								<Route path={menu?.key} key={menu?.key}>
									{menu?.collapse?.map((feature) => {
										const Component = ComponentObj?.components[feature.key]
										return (
											<Route 
												path={feature?.key} 
												key={feature?.key} 
												element={(
													<DashboardLayout>
														<DashboardNavbar menuActive={menuActive} setMenuActive={setMenuActive} appActive={appActive} setAppActive={setAppActive} setMode={setMode} setMobileView={setMobileView} mobileView={mobileView} />
														<MDBox sx={window.innerWidth > breakpoints.values.md ? {padding: "16px"} : {padding: "8px 12px 24px 12px"} }>
															<Component mode={mode} setMode={setMode} setIsLoading={setIsLoading} />
														</MDBox>
													</DashboardLayout>
												)} />
										)
									})}
								</Route>
							)
						}
						const Component = ComponentObj?.components
						return (
							<Route 
								path={menu?.key} 
								key={menu?.key} 
								element={(
									<DashboardLayout>
										<DashboardNavbar menuActive={menuActive} setMenuActive={setMenuActive} appActive={appActive} setAppActive={setAppActive} setMode={setMode} setMobileView={setMobileView} mobileView={mobileView} />
										<MDBox sx={window.innerWidth > breakpoints.values.md ? {padding: "16px"} : {padding: "8px 12px 24px 12px"} }>
											<Component mode={mode} setMode={setMode} setIsLoading={setIsLoading} />
										</MDBox>
									</DashboardLayout>
								)} />
						)
					})}
					<Route path="*" element="ROUTE NOT FOUND" />
				</Route>
			)
		})
	}, [myAppsList, mode, menuActive, appActive, mobileView]);

	const imgLogo = (() =>{
		if(process.env.REACT_APP_MODE === process.env.REACT_APP_AUTOLOKA) return imgAutoloka;
		if(process.env.REACT_APP_MODE === process.env.REACT_APP_AUTOTIVO) return imgAutotivo;
		if(process.env.REACT_APP_MODE === process.env.REACT_APP_JSL) return imgJsl;
		return imgAutoloka;
	})();

  return (
		<>
		<ThemeProvider theme={dynamicTheme}>
			<CssBaseline />
			<div className="wrapper" id="wrapper">
				<ToastContainer autoClose={false} />
				{ isLoading && <Spinner />}
				{
					Cookies.get("appActive") ?
						<Sidenav
							setAppActive={setAppActive}
							setMenuActive={setMenuActive}
							color={'info'}
							brand={imgLogo}
							routes={allPath}
							appActive={appActive}
							menuActive={menuActive}
							setMode={setMode}
							mobileView={mobileView}
						/>
					: null
				}
				<Routes>
					<Route index element={<Login isLoading={isLoading} setIsLoading={setIsLoading} menuActive={menuActive} setAppsList={setAppsList} myAppsList={myAppsList} setMyAppsList={setMyAppsList} setAllPath={setAllPath} />}/>
					<Route key={"container-home"} element={<ProtectedRoutes />} >
						<Route key={"all-apps"} path="all-apps" element={<AllApps getMainMenuFetcher={getMainMenuFetcher} isLoading={isLoading} setIsLoading={setIsLoading} appsList={appsList} setAppsList={setAppsList} menuActive={menuActive} setMenuActive={setMenuActive} appActive={appActive} setAppActive={setAppActive} setMode={setMode} />}/>
						<Route key={"my-apps"} path="my-apps" element={<MyApps isLoading={isLoading} setIsLoading={setIsLoading} menuActive={menuActive} setMenuActive={setMenuActive} appActive={appActive} setAppActive={setAppActive} setPrimaryColor={setPrimaryColor} myAppsList={myAppsList} setMode={setMode} />}/>
						{ RouteComponent }
					</Route>
					<Route path="*" element={<Navigate to="/my-apps" replace />} />
				</Routes>
			</div>
		</ThemeProvider>
		</>
  );
};

export default App;