import { Grid } from "@mui/material";
import MDBox from "../../../../components/MDBox";
import { FileUploader } from "react-drag-drop-files";
import { useState } from "react";
import MDButton from "../../../../components/MDButton";
import MDSnackbar from "../../../../components/MDSnackbar";
import axios from "axios";
import Cookies from "js-cookie";


const fileTypes = ['pdf'];

const PdfConverter = ({
    setIsLoading,
}) => {
    const [showNotifSuccess, setShowNotifSuccess] = useState(false);
    const [showNotifFailed, setShowNotifFailed] = useState(false);
    const [file, setFile] = useState(null);
    const handleChange = (file) => {
      setFile(file);
    };

    const submitFile = async () => {
        let baseUrl = process.env.NODE_ENV === 'development' ? `${process.env.REACT_APP_BE_DEV_URL}` : `${process.env.REACT_APP_BE_PROD_URL}`;
        const formData = new FormData();
        formData.append('file', file);
        setIsLoading(true);
        axios.post(baseUrl + '/version/pdf', formData, {
            responseType: 'blob',
            headers: {
                'Content-type': 'multipart/form-data',
                Authorization: Cookies.get("token"),
            },
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file?.name?.replace('.pdf', '.xlsx') || 'dowload.xlsx');
            document.body.appendChild(link);
            link.click();

            setIsLoading(false);
            setFile(null);
            setShowNotifSuccess(true);
        }).catch((err) => {
            setIsLoading(false);
            setShowNotifFailed(true);
        });
    }

    return (
        <>
        <MDSnackbar
            color="success"
            icon="done"
            title="Autoloka"
            content="File Uploaded"
            dateTime="Just Now"
            open={showNotifSuccess}
            close={() => setShowNotifSuccess(false)}
        />
        <MDSnackbar
            color="error"
            icon="error"
            title="Autoloka"
            content="Oops something went wrong"
            dateTime="Just Now"
            open={showNotifFailed}
            close={() => setShowNotifFailed(false)}
        />
        <MDBox width="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column" marginTop="3rem" >
            <FileUploader
                multiple={false}
                handleChange={handleChange}
                name="file"
                types={fileTypes}
            >
                <MDBox className="pointer" border="2px dashed grey" borderRadius=".5rem" padding="1rem 5rem" width="400px" textAlign="center">
                    {!file ? <p style={{margin: 0}}>Drag & Drop PDF FILE or CLICK HERE</p> 
                        : <p style={{margin: 0}}>{file?.name}</p>}
                </MDBox>
            </FileUploader>
            <MDButton onClick={submitFile} sx={{marginTop: '3rem'}} disabled={!file}>
                Upload
            </MDButton>
        </MDBox>
        </>
    )
}

export default PdfConverter;