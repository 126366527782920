import { Card, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";

const ConfigLayout = ({ setConfigPaper }) => {
    const sizing = {
        width: "4",
        height: "2.5",
        top: "0.175",
        left: "0.175",
        bottom: "0.175",
        right: "0.175",
        column: "2",
        row: "5",
        minSize: {
            width: "4",
            height: "2.5",
            top: "0.175",
            left: "0.175",
            bottom: "0.175",
            right: "0.175",
            column: "1",
            row: "1",
        }
    }

    const [ defaultSize, setDefaultSize] = useState({...sizing});
    const [sizes, setSizes] = useState({...sizing})

    const onSubmit = (e) => {
        e.preventDefault();
        const width = e.target.elements.width.value || defaultSize.width ;
        const height = e.target.elements.height.value || defaultSize.height;
        const top = e.target.elements.top.value || defaultSize.margins;
        const right = e.target.elements.right.value || defaultSize.margins;
        const bottom = e.target.elements.bottom.value || defaultSize.margins;
        const left = e.target.elements.left.value || defaultSize.margins;
        const column = e.target.elements.column.value || defaultSize.margins;
        const row = e.target.elements.row.value || defaultSize.margins;

        setSizes((prev) => ({
            ...prev,
            width,
            height,
            top,
            right,
            bottom,
            left,
            column,
            row,
        }))
    }

    useEffect(() => {
        setConfigPaper(sizes);
    }, [sizes, defaultSize])

    const onInput = (e, value) => {
        if(e.target.value === "0") {
            e.target.value = value;
        }
    }

    const onBlur = (e, value) => {
        if(!e.target.value || parseFloat(e.target.value, 10) < parseFloat(value)) {
            console.log(value);
            e.target.value = value;
        }
    }

    return (
        <Card style={{background: 'white', padding: '1rem', marginTop: '2rem'}}>
            <form  onSubmit={onSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <p>Card</p>
                        <Grid container>
                            <Grid item xs={6} >
                                <TextField 
                                    type="text" 
                                    name="width" 
                                    placeholder="cm" 
                                    InputLabelProps={{ shrink: true }}
                                    defaultValue={defaultSize.width}
                                    onInput={(e) => onInput(e, defaultSize.width)}
                                    onBlur={(e) => onBlur(e, defaultSize.width)}
                                    label="Width"/>
                            </Grid>
                            <Grid item xs={6} >
                                <TextField 
                                    type="text" 
                                    InputLabelProps={{ shrink: true }}
                                    defaultValue={defaultSize.height}
                                    onInput={(e) => onInput(e, defaultSize.height)}
                                    onBlur={(e) => onBlur(e, defaultSize.height)}
                                    name="height" 
                                    placeholder="cm" 
                                    label="Height" />
                            </Grid>
                        </Grid>  
                    </Grid>
                    <Grid item xs={12}>
                        <p>Layout</p>
                        <Grid container>
                            <Grid item xs={6} >
                                <TextField 
                                    defaultValue={defaultSize.column}
                                    InputLabelProps={{ shrink: true }}
                                    type="number" 
                                    onInput={(e) => onInput(e, defaultSize.minSize.column)}
                                    onBlur={(e) => onBlur(e, defaultSize.minSize.column)}  
                                    name="column" 
                                    label="Column"/>
                            </Grid>
                            <Grid item xs={6} >
                                <TextField 
                                    defaultValue={defaultSize.row}
                                    InputLabelProps={{ shrink: true }}
                                    type="number" 
                                    onInput={(e) => onInput(e, defaultSize.minSize.row)}
                                    onBlur={(e) => onBlur(e, defaultSize.minSize.row)}
                                    name="row" 
                                    label="Row"/>
                            </Grid>
                        </Grid>  
                    </Grid>
                    <Grid item xs={12}>
                        <p>Margin</p>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <TextField 
                                    InputLabelProps={{shrink: true}}
                                    type="text" 
                                    defaultValue={defaultSize.top}
                                    onInput={(e) => onInput(e, defaultSize.minSize.top)}
                                    onBlur={(e) => onBlur(e, defaultSize.minSize.top)}
                                    name="top" placeholder="cm" label="Top" />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField 
                                    InputLabelProps={{shrink: true}}
                                    type="text" 
                                    onInput={(e) => onInput(e, defaultSize.minSize.right)}
                                    onBlur={(e) => onBlur(e, defaultSize.minSize.right)}
                                    defaultValue={defaultSize.right}
                                    name="right" placeholder="cm" label="Right" />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField 
                                    InputLabelProps={{shrink: true}}
                                    type="text" 
                                    onInput={(e) => onInput(e, defaultSize.minSize.bottom)}
                                    onBlur={(e) => onBlur(e, defaultSize.minSize.bottom)}
                                    defaultValue={defaultSize.bottom}
                                    name="bottom" placeholder="cm" label="Bottom" />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField 
                                    InputLabelProps={{shrink: true}}
                                    type="text" 
                                    onInput={(e) => onInput(e, defaultSize.minSize.left)}
                                    onBlur={(e) => onBlur(e, defaultSize.minSize.left)}
                                    defaultValue={defaultSize.left}
                                    name="left" placeholder="cm" label="Left" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <button id="config-layout-submit" style={{display: 'none'}} type="submit">
                    submit
                </button>
            </form>
        </Card>
    )
}

export default ConfigLayout;