import { useEffect, useState } from "react";
import axios from "axios";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import ImageZoomModal from "../../../../components/modals/imageZoom";
import CategoryIndex from "../../../../components/AppComponents/Autoloka/masterData/category";
import CategoryAdd from "../../../../components/AppComponents/Autoloka/masterData/category/add";
import CategoryEdit from "../../../../components/AppComponents/Autoloka/masterData/category/edit";
import DeleteConfirmationModal from "../../../../components/modals/deleteConfirmation";

import services from "../../../../service/services";

import placeholder from "../../../../assets/images/placeholder-image.jpeg";
import MDSnackbar from "../../../../components/MDSnackbar";

import { cleanObject, getImageError } from "../../../../utils";
import { useSearchParams } from "react-router-dom";

const Category = ({
	setIsLoading,
	setMode,
	mode,
 }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [rows, setRows] = useState([]);
	const [rowsDisplayed, setRowsDisplayed] = useState([]);
	const [rawRowsDisplayed, setRawRowsDisplayed] = useState([]);
	const [paginationDetail, setPaginationDetail] = useState({});
	const [imgSource, setImgSource] = useState(null);
	const [open, setOpen] = useState(false);
	const [categoryNameOptions, setCategoryNameOptions] = useState([]);
	const [categoryCodeOptions, setCategoryCodeOptions] = useState([]);
	const [categoryTypeOptions, setCategoryTypeOptions] = useState([]);
	const [categoryInfo, setCategoryInfo] = useState([]);
	const [indexDetail, setIndexDetail] = useState(null);
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [isFiltered, setIsFiltered] = useState(false);
	const [id, setId] = useState(null);
	const [confirmationDisplay, setConfirmationDisplay] = useState(false);
	const [dataFilter, setDataFilter] = useState({
		category_name: searchParams.get("category_name") || "",
		category_code: searchParams.get("category_code") || "",
		category_type: searchParams.get("category_type") || "",
		is_active: searchParams.get("is_active") || "",
	});

	const handleOpen = (e, source) => {
		if(source){
			setOpen(true);
			setImgSource(source);
		} else {
			e.preventDefault();
		}
	};

  const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Edit", accessor: "edit", align: "left" },
		{ Header: "Category Image", accessor: "category_image", align: "left" },
		{ Header: "Category Name", accessor: "category_name", align: "left" },
		{ Header: "Category Code", accessor: "category_code", align: "left" },
		{ Header: "Category Type", accessor: "category_type", align: "left" },
		{ Header: "Status", accessor: "status", align: "left" },
	];

	useEffect(() => {

		let fetcher;
		if(Object.keys(cleanObject(dataFilter)).length !== 0) {
			fetcher = services.filterCategoryList({...dataFilter, page: searchParams.get("page") || 1})
		}else {
			fetcher = services.getMasterDataCategory(searchParams.get("page") || 1)
		}


		if(mode === "index"){
			setIsLoading(true);
			Promise
				.all([
					fetcher,
					services.getOptionsCategoryName(),
					services.getOptionsCategoryCode(),
					services.getOptionsCategoryType(),
					services.getCategoryInformation(),
				]).then(axios.spread((...res) => {
					setIsLoading(false);
					setPaginationDetail(res[0]?.data?.data);
					setRows(settingRowsDataTable(res[0]?.data?.data?.list, res[0]?.data?.data?.current_page));
					setRawRowsDisplayed(res[0]?.data?.data?.list);
					setCategoryNameOptions(res[1]?.data?.data);
					setCategoryCodeOptions(res[2]?.data?.data);
					setCategoryTypeOptions(res[3]?.data?.data);
					setCategoryInfo(res[4]?.data?.data);
				})).catch(errors => {
					setIsLoading(false);
					console.log("Error is found, apologies.")
					console.log(errors)
				})
		}
	}, [mode]);

	const deleteCategory = (id) => {
		setIsLoading(true);
		setShowNotifSuccess(false);
		services.deleteCategory(id)
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
				setConfirmationDisplay(false);
				getList(1);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors);
			})
	};

	const getList = (page) => {
		setIsLoading(true);
		services.getMasterDataCategory(page)
			.then(res => {
				setIsLoading(false);
				setIsFiltered(false);
				setRows(settingRowsDataTable(res?.data?.data?.list, res?.data?.data?.current_page));
				setRawRowsDisplayed(res?.data?.data?.list);
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	};

	const settingRowsDataTable = (rowsArray, current_page) => {
		return rowsArray?.map((arr, i) => {
			return {
				no: ((current_page - 1) * 10) + i + 1,
				edit: (
					<>
						<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setMode("edit"); setIndexDetail(i)}}>edit</Icon>
						<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setId(arr.id); setConfirmationDisplay(true);}}>delete</Icon>
					</>
				),
				category_image: <img onClick={(e) => handleOpen(e, arr?.category_image)} onError={getImageError} className={"img-thumbnail-pagination " + (arr.category_image ? "" : "forbidden")} src={arr?.category_image ? arr.category_image : placeholder} />,
				category_name: arr?.category_name,
				category_code: arr?.category_code,
				category_type: arr?.category_type,
				status: arr?.status == "0" ? "Not Active" : arr?.status == "1" ? "Active" : ""
			};
		});
	};

  return (
    <>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="Category Deleted!"
				dateTime="Just Now"
				open={showNotifSuccess}
				close={() => setShowNotifSuccess(false)}
			/>
			<Grid container spacing={6} className="grid-pure-container">
				{
					mode === "index" ?
						<CategoryIndex 
							setDataFilter={setDataFilter}
							dataFilter={dataFilter}
							columns={columns} 
							rows={rows}
							rowsDisplayed={rowsDisplayed}
							setRowsDisplayed={setRowsDisplayed} 
							setRawRowsDisplayed={setRawRowsDisplayed}
							setIndexDetail={setIndexDetail}
							categoryNameOptions={categoryNameOptions}
							categoryCodeOptions={categoryCodeOptions}
							categoryTypeOptions={categoryTypeOptions}
							setIsLoading={setIsLoading}
							handleOpen={handleOpen}
							placeholder={placeholder}
							setMode={setMode}
							deleteCategory={deleteCategory}
							paginationDetail={paginationDetail}
							setPaginationDetail={setPaginationDetail}
							getList={getList}
							isFiltered={isFiltered}
							setIsFiltered={setIsFiltered}
							settingRowsDataTable={settingRowsDataTable}
						/>
					: mode === "add" ?
						<CategoryAdd categoryInfo={categoryInfo} setIsLoading={setIsLoading} />
					: mode === "edit" ?
						<CategoryEdit setMode={setMode} categoryInfo={categoryInfo} indexDetail={indexDetail} rawRowsDisplayed={rawRowsDisplayed} setIsLoading={setIsLoading} /> 
					: null
				}
			</Grid>
			<ImageZoomModal open={open} setOpen={setOpen} imgSource={imgSource} setImgSource={setImgSource} />
			<DeleteConfirmationModal open={confirmationDisplay} setOpen={setConfirmationDisplay} itemName="category" deleteHandler={() => deleteCategory(id)} /> 
		</>
  );
}

export default Category;
