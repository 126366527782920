import { useEffect, useState } from "react";
import parse from 'html-react-parser';
import moment from 'moment';

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import BannerIndex from "../../../../components/AppComponents/Autoloka/feature/banner";
import BannerAdd from "../../../../components/AppComponents/Autoloka/feature/banner/add";
import BannerEdit from "../../../../components/AppComponents/Autoloka/feature/banner/edit";
import DeleteConfirmationModal from "../../../../components/modals/deleteConfirmation";

import services from "../../../../service/services";

import placeholder from "../../../../assets/images/placeholder-image.jpeg";
import MDSnackbar from "../../../../components/MDSnackbar";
import { useSearchParams } from "react-router-dom";
import { cleanObject } from "../../../../utils";

const Banner = ({
	setIsLoading,
	setMode,
	mode,
 }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [rows, setRows] = useState([]);
	const [rowsDisplayed, setRowsDisplayed] = useState([]);
	const [rawRowsDisplayed, setRawRowsDisplayed] = useState([]);
	const [paginationDetail, setPaginationDetail] = useState({});
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [isFiltered, setIsFiltered] = useState(false);
	const [id, setId] = useState(null);
	const [confirmationDisplay, setConfirmationDisplay] = useState(false);
	const [dataFilter, setDataFilter] = useState({
		title: searchParams.get("title") || "",
		date_range: searchParams.get("date_range") || "",
		status: searchParams.get("status") || "",
	});


  const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Edit", accessor: "edit", align: "left" },
		{ Header: "Title", accessor: "title", align: "left" },
		{ Header: "Description", accessor: "description", align: "left" },
		{ Header: "Position", accessor: "position", align: "left" },
		{ Header: "type", accessor: "type", align: "left" },
		{ Header: "Start Date - End Date", accessor: "date", align: "left" },
		{ Header: "Link", accessor: "link", align: "left" },
		{ Header: "Status", accessor: "status", align: "left" },
	];

	useEffect(() => {
		if(mode === "index"){
			getList(1);
		}
	}, [mode]);

	const deleteBanner = (id) => {
		setIsLoading(true);
		setShowNotifSuccess(false);
		services.deleteBanner(id)
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
				setConfirmationDisplay(false);
				getList(1);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors);
			})
	};

	const getList = (page) => {
		setIsLoading(true);

		let data = {...dataFilter, page: searchParams.get("page") || 1};
		if(data?.date_range?.length > 0){
			let daterangevalue = data.date_range.split(" - ");
			data.start_date = moment(daterangevalue[0], "DD.MM.YYYY").format("YYYY-MM-DD");
			data.end_date = moment(daterangevalue[1], "DD.MM.YYYY").format("YYYY-MM-DD");
		} else {
			data.start_date = "";
			data.end_date = "";
		};
		delete data.date_range;

		let fetcher;
		if(Object.keys(cleanObject(dataFilter)).length !== 0) {
			fetcher = services.filterBanner(data)
		}else {
			fetcher = services.getBannerList(searchParams.get("page") || 1)
		}
		fetcher.then(res => {
				setIsLoading(false);
				setIsFiltered(false);
				setRows(settingRowsDataTable(res?.data?.data?.list, res?.data?.data?.current_page));
				setRawRowsDisplayed(res?.data?.data?.list);
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	};

	const settingRowsDataTable = (rowsArray, current_page) => {
		return rowsArray?.map((arr, i) => {
			return {
				no: ((current_page - 1) * 10) + i + 1,
				edit: (
					<>
						<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setMode("edit"); setId(arr.id)}}>edit</Icon>
						<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setId(arr.id); setConfirmationDisplay(true);}}>delete</Icon>
					</>
				),
				title: arr?.title,
				description: parse(arr?.description || ""),
				position: arr?.position,
				type: arr?.type === 1 ? "Primary" : arr?.type === 2 ? "Secondary" : "",
				date: arr?.start_date + " - " + arr?.end_date,
				link: <a href={arr?.link} target="_blank">{arr?.link}</a>,
				status: arr?.status == "0" ? "Not Active" : arr?.status == "1" ? "Active" : ""
			};
		});
	};

  return (
    <>
      <MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="Banner Deleted!"
				dateTime="Just Now"
				open={showNotifSuccess}
				close={() => setShowNotifSuccess(false)}
			/>
			<Grid container spacing={6} className="grid-pure-container">
				{
					mode === "index" ?
						<BannerIndex 
							setDataFilter={setDataFilter}
							dataFilter={dataFilter}
							setIsLoading={setIsLoading}
							columns={columns} 
							rows={rows}
							rowsDisplayed={rowsDisplayed}
							setRowsDisplayed={setRowsDisplayed} 
							setRawRowsDisplayed={setRawRowsDisplayed}
							placeholder={placeholder}
							getList={getList}
							setMode={setMode}
							isFiltered={isFiltered}
							setIsFiltered={setIsFiltered}
							settingRowsDataTable={settingRowsDataTable}
							paginationDetail={paginationDetail}
							setPaginationDetail={setPaginationDetail}
						/>
					: mode === "add" ?
						<BannerAdd setIsLoading={setIsLoading} />
					: mode === "edit" ?
						<BannerEdit setMode={setMode} id={id} rawRowsDisplayed={rawRowsDisplayed} setIsLoading={setIsLoading} /> 
					: null
				}
			</Grid>
			<DeleteConfirmationModal open={confirmationDisplay} setOpen={setConfirmationDisplay} itemName="banner" deleteHandler={() => deleteBanner(id)} /> 
    </>
  );
}

export default Banner;
