import { useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import DateRangePicker from "react-daterange-picker";
import moment from 'moment';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { MenuItem, InputAdornment, Icon } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDInput from "../../../../MDInput";
import MDButton from "../../../../MDButton";
import MDTypography from "../../../../MDTypography";
import MDSnackbar from "../../../../MDSnackbar";

import services from "../../../../../service/services";

import { addCommas } from "../../../../../utils";

const LoyaltyAdd = ({
	setIsLoading,
}) => {

	const [displayDate, setDisplayDate] = useState(false);
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [skipCount, setSkipCount] = useState(true);

	const { register, control, handleSubmit, setValue, watch, trigger, clearErrors, reset, formState: { errors } } = useForm({mode: "all"});
	
	const watchAllFields = watch();

	const submitCreate = (e) => {
		setIsLoading(true);
		let date = e.date.split(" - ");
		let data = e;
		delete data.effective_date;
		delete data.date;
		data.start_date = date[0];
		data.end_date = date[1];
		data.starting_price = data.starting_price.replaceAll(".", "");
		data.end_price = data.end_price.replaceAll(".", "");
		data.point = data.point.replaceAll(".", "");
		data.max_point = data.max_point.replaceAll(".", "");
		services.createLoyalty(data)
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
				reset({
					name: "",
					type: "",
					starting_price: "",
					effective_date: "",
					end_price: "",
					is_multiple: "",
					max_user_per_day: "",
					point: "",
					max_point: "",
				});
			}).catch(errors => {
				setIsLoading(false);
				setErrorMessage(errors?.response?.data?.message);
				setShowNotifError(true);
				console.log(errors)
			})
	};

	useEffect(async() => {
		if(skipCount){
			setSkipCount(false);
		} else if(!skipCount){ 
			const result = await trigger(["starting_price", "end_price"]);
		}
	}, [watchAllFields.starting_price, watchAllFields.end_price]);

	useEffect(async() => {
		if(skipCount){
			setSkipCount(false);
		} else if(!skipCount){ 
			const result = await trigger(["point", "max_point"]);
		}
	}, [watchAllFields.point, watchAllFields.max_point]);

  return (
		<Grid item xs={12}>
			<MDBox mb={5} width="100%">
				<MDTypography variant="h3" fontWeight="regular">
					Add New Loyalty
				</MDTypography>
			</MDBox>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="New Loyalty Added!"
				open={showNotifSuccess}
				dateTime="Just Now"
				close={() => setShowNotifSuccess(false)}
			/>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			<MDBox maxWidth="800px" component="form" role="form" id="create-loyalty" onSubmit={handleSubmit(submitCreate)}>
				<Card sx={{ padding: "24px 32px" }}>
					<Grid container spacing={3} justifyContent="flex-end" sx={{ mb: 5 }}>
						<Grid item xs={12} md={8}>
							<MDInput label="Loyalty Name" error={errors?.name} {...register("name", { required: "Required" })} fullWidth />
							{errors?.name && 
								<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
									<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.name.message}</MDTypography>
								</MDBox>
							}
						</Grid>
						<Grid item xs={12} md={4}>
							<MDInput
								{...register("type", { required: "Required" })}
								id="select-is-active" 
								size="small" 
								fullWidth 
								label="Loyalty Type"
								type="select" 
								select
								error={errors?.type}
								value={watchAllFields?.type || ""}
							>
								<MenuItem disabled value="">
									<em>Neither</em>
								</MenuItem>
								<MenuItem value="0">First Customer</MenuItem>
								<MenuItem value="1">Daily Trx</MenuItem>
								<MenuItem value="2">Top Spender</MenuItem>
							</MDInput>
							{errors?.type && 
								<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
									<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.type.message}</MDTypography>
								</MDBox>
							}
						</Grid>
						<Grid item xs={12} md={8}>
							<Controller 
								rules={{ required: "Required", validate:  v => parseInt(v?.replaceAll(".", "") || 0) < parseInt(watchAllFields?.end_price?.replaceAll(".", "") || 0) || 'should be lower than End Price' }} 
								name="starting_price" control={control}
								render={(props) => {
									return <MDInput label="Starting Price" fullWidth value={addCommas(props.field.value)} error={props.formState?.errors?.starting_price}
										onChange={async (e) => {
											props?.field?.onChange(e)?.then(async () => {
												clearErrors(["starting_price", "end_price"]);
												const result = await trigger(["starting_price", "end_price"]);
											})
										}}
										onBlur={async () => {
											props?.field?.onBlur()?.then(async () => {
												clearErrors(["starting_price", "end_price"]);
												const result = await trigger(["starting_price", "end_price"]);
											})
										}}
									/>
								}
							}/>
							{errors?.starting_price && 
								<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
									<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.starting_price.message}</MDTypography>
								</MDBox>
							}
						</Grid>
						<Grid item xs={12} md={4}>
							<MDInput
								sx={{ backgroundColor: "#FFF", borderRadius: "8px" }}
								size='small'
								label="Effective Date"
								InputProps={{
									endAdornment: 
									<InputAdornment position="end">
										{watchAllFields?.effective_date?.length > 0 ? 
											<Icon className="c-pointer" onClick={() => setValue("effective_date", "")} fontSize="small">close</Icon>
											: null
										}
										<Icon fontSize="small">calendar_today</Icon>
									</InputAdornment>,
								}}
								{...register("effective_date", { required: "Required" })} 
								fullWidth
								value={watchAllFields.effective_date}
								onClick={() => setDisplayDate(!displayDate)}
								InputLabelProps={{ shrink: watchAllFields?.effective_date?.length > 0 }}
							/>
							{
								displayDate ?
									<DateRangePicker
										className="customized-daterange-picker voucher-date-picker"
										firstOfWeek={1}
										numberOfCalendars={1}
										selectionType="range"
										onSelect={async (dateValue) => {
											let val = `${moment(dateValue.start).format('DD.MM.YYYY')} - ${moment(dateValue.end).format('DD.MM.YYYY')}`;
											setValue("effective_date", val);
											setValue("date", `${moment(dateValue.start).format('YYYY-MM-DD')} - ${moment(dateValue.end).format('YYYY-MM-DD')}`)
											const result = await trigger("effective_date");
											setDisplayDate(false);
										}}
									/>
								: null
							}
							{errors?.effective_date && 
								<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
									<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.effective_date.message}</MDTypography>
								</MDBox>
							}
						</Grid>
						<Grid item xs={12} md={8}>
							<Controller 
								rules={{ required: "Required", validate:  v => parseInt(v?.replaceAll(".", "") || 0) > parseInt(watchAllFields?.starting_price?.replaceAll(".", "") || 0) || 'should be higher than Starting Price' }} 
								name="end_price" control={control}
								render={(props) => {
									return <MDInput label="End Price" fullWidth value={addCommas(props.field.value)} error={props.formState?.errors?.end_price}
										onChange={async (e) => {
											props?.field?.onChange(e)?.then(async () => {
												const result = await trigger(["starting_price", "end_price"]);
											})
										}}
										onBlur={async () => {
											props?.field?.onBlur()?.then(async () => {
												const result = await trigger(["starting_price", "end_price"]);
											})
										}}
									/>
								}
							}/>
							{errors?.end_price && 
								<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
									<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.end_price.message}</MDTypography>
								</MDBox>
							}
						</Grid>
						<Grid item xs={12} md={4}>
							<MDInput
								{...register("is_multiple", { required: "Required" })}
								id="select-category-name" 
								size="small" 
								fullWidth 
								label="Multiple"
								type="select" 
								select
								error={errors?.is_multiple} 
								onChange={(e) => 
									setValue("is_multiple", e.target.value)
								}
								value={watchAllFields?.is_multiple || ""}
							>
								<MenuItem disabled value="">
									<em>Neither</em>
								</MenuItem>
								<MenuItem value="1">YES</MenuItem>
								<MenuItem value="0">NO</MenuItem>
							</MDInput>
							{errors?.is_multiple && 
								<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
									<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.is_multiple.message}</MDTypography>
								</MDBox>
							}
						</Grid>
						<Grid item xs={12} md={8}>
							<MDInput type="text" label="Max User Per Day" fullWidth {...register("max_user_per_day", { required: "Required", pattern: { value: /^[1-9][0-9]*$/, message: "Number only" } })} error={errors?.max_user_per_day} onChange={(e) => setValue("max_user_per_day", e.target.value)} value={watchAllFields.max_user_per_day} />
							{errors?.max_user_per_day && 
								<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
									<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.max_user_per_day.message}</MDTypography>
								</MDBox>
							}
						</Grid>
						<Grid item xs={12} md={4}>
							<Controller 
								rules={{ required: "Required", validate:  v => parseInt(v?.replaceAll(".", "") || 0) < parseInt(watchAllFields?.max_point?.replaceAll(".", "") || 0) || 'should be lower than Max Point' }} 
								name="point" control={control}
								render={(props) => {
									return <MDInput label="Point" fullWidth value={addCommas(props.field.value)} error={props.formState?.errors?.point}
										onChange={async (e) => {
											props?.field?.onChange(e)?.then(async () => {
												clearErrors(["point", "max_point"]);
												const result = await trigger(["point", "max_point"]);
											})
										}}
										onBlur={async () => {
											props?.field?.onBlur()?.then(async () => {
												clearErrors(["point", "max_point"]);
												const result = await trigger(["point", "max_point"]);
											})
										}}
									/>
								}
							}/>
							{errors?.point && 
								<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
									<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.point.message}</MDTypography>
								</MDBox>
							}
						</Grid>
						<Grid item xs={12} md={4}>
							<Controller 
								rules={{ required: "Required", validate:  v => parseInt(v?.replaceAll(".", "") || 0) > parseInt(watchAllFields?.point?.replaceAll(".", "") || 0) || 'should be higher than Point' }} 
								name="max_point" control={control}
								render={(props) => {
									return <MDInput label="Max Point" fullWidth value={addCommas(props.field.value)} error={props.formState?.errors?.max_point}
										onChange={async (e) => {
											props?.field?.onChange(e)?.then(async () => {
												clearErrors(["point", "max_point"]);
												const result = await trigger(["point", "max_point"]);
											})
										}}
										onBlur={async () => {
											props?.field?.onBlur()?.then(async () => {
												clearErrors(["point", "max_point"]);
												const result = await trigger(["point", "max_point"]);
											})
										}}
									/>
								}
							}/>
							{errors?.max_point && 
								<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
									<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.max_point.message}</MDTypography>
								</MDBox>
							}
						</Grid>
					</Grid>
					<MDBox display="flex" alignItems="center" justifyContent="flex-end">
						<MDButton color="info" type="submit" form="create-loyalty">
							Save
						</MDButton>
					</MDBox>
				</Card>
			</MDBox>
		</Grid>
  );
}

export default LoyaltyAdd;
