import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import { firebase_app } from "../firebaseConfig";

// @mui material components
import { Card, Checkbox } from "@mui/material";

import BasicLayout from "../components/BasicLayout";
import MDBox from "../components/MDBox";
import MDButton from "../components/MDButton";
import MDInput from "../components/MDInput";
import MDTypography from "../components/MDTypography";

// Images
import bgImage from "../assets/images/bg-login.jpeg";
import imgAutoloka from "../assets/images/autoloka.png";
import imgAutotivo from "../assets/images/autotivo.png";
import imgJsl from "../assets/images/jsl.png";
import services from "../service/services";

const Login = (props) => {

  const { setAppsList, setMyAppsList, myAppsList, menuActive, setIsLoading } = props;

  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const login = () => {
    setIsLoading(true);
    setError("")
    firebase_app.auth().signInWithEmailAndPassword(email, password)
      .then(async (user) => {
        let id = await firebase_app.auth().currentUser.getIdToken(false);
        services.doLogin(JSON.stringify({
          email: email,
          idToken: id
        }))
          .then(res => {
            setIsLoading(false);
            Cookies.set("loggedIn", 'true');
            Cookies.set("email", res?.data?.data?.email);
            localStorage.setItem("dashboards", JSON.stringify(res?.data?.data?.apps));
            Cookies.set('token', res?.data?.data?.token);
            setMyAppsList(res?.data?.data?.apps);
            navigate('/my-apps');
          })
          .catch(errors => {
            setIsLoading(false);
            setError(errors?.response?.data?.message);
          })
      })
      .catch((error) => {
        setIsLoading(false);
        const { message } = error;
        let pos1 = message.indexOf("Firebase : ") + "Firebase : ".length;
        let pos2 = message.indexOf(" (");
        let str = message.substring(pos1, pos2);
        if(str === "An internal AuthError has occurred."){
          str = "Credentials for login are not complete.";
        }
        setError(str);
      });
  }

  useEffect(() => {
    if(Cookies.get("loggedIn") === "true"){
      navigate('/my-apps');
    }
  }, []);

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        login();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [email, password]);

  const imgLogo = (() =>{
    if(process.env.REACT_APP_MODE === process.env.REACT_APP_AUTOLOKA) return imgAutoloka;
    if(process.env.REACT_APP_MODE === process.env.REACT_APP_AUTOTIVO) return imgAutotivo;
    if(process.env.REACT_APP_MODE === process.env.REACT_APP_JSL) return imgJsl;
    return imgAutoloka;
  })();

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <img style={{margin: "16px 0", maxWidth: "100%"}} src={imgLogo} />
        </MDBox>
        <MDBox pt={3} pb={3} px={3}>
          <MDTypography
            fontWeight="medium"
            color="info"
            pb={2}
          >
            Sign In
          </MDTypography>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput error={error?.length > 0} type="email" label="Email" onChange={(e) => setEmail(e.target.value)} fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput error={error?.length > 0} type="password" label="Password" onChange={(e) => setPassword(e.target.value)} fullWidth />
            </MDBox>
            <MDBox>
              <MDTypography
                fontWeight="bold"
                color="error"
                fontSize="12px"
              >
                {error}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" onClick={login} fullWidth>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Login;
