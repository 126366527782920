import { useEffect, useMemo, useRef, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import { Card, Icon, TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDButton from "../../../../MDButton";
import MDSnackbar from "../../../../MDSnackbar";
import MDTypography from "../../../../MDTypography";
import DataTable from "../../../../Table";

import { MenuItem } from '@mui/material';
import MDInput from '../../../../MDInput';

import services from "../../../../../service/services";

import AddProductModal from "./addProductModal";

import {marketPlace} from './data';

const PurchaseOrderAdd = ({
	setIsLoading,
	productOptions,
}) => {

	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifSuccessDraft, setShowNotifSuccessDraft] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [errorProduct, setErrorProduct] = useState("");
	const [productRows, setProductRows] = useState([]);
	const [productPaginationDetail, setProductPaginationDetail] = useState({
		current_page: 1,
		total_data: 0,
		total_page: 0,
		list: [],
	});
	const [headers, setHeaders] = useState({});
	const [displayAddProduct, setDisplayAddProduct] = useState(false);
	const [salesDataCreate, setSalesDataCreate] = useState({
		marketplace_id: "",
		customer_name: "",
		customer_info: "",
	});
	const [errorSelectMarketPlace, setErrorSelectMarketPlace] = useState(false);

	const { register, trigger, control, reset, watch, formState: { errors } } = useForm({mode: "all"});

	const watchAllFields = watch();

	const { replace } = useFieldArray({
		control,
		shouldUnregister: false,
    name: "qty",
	});

	const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Product Name", accessor: "product_name", align: "left" },
		{ Header: "Code", accessor: "code", align: "left" },
		{ Header: "Price", accessor: "price", align: "left" },
		{ Header: "Qty", accessor: "qty", align: "left" },
		{ Header: "Delete", accessor: "delete", align: "left" },
	];

	useEffect(() => {
		setIsLoading(true);
		services.getHeaderSalesOrder()
			.then(res => {
				setIsLoading(false);
				setHeaders(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				setShowNotifError(true)
				setErrorMessage(errors?.response?.data?.message);
			});
	}, []);

	useEffect(() => {
		(async ()=> {
			const result = await trigger("qty");
			let listAll = settingRows();
			listAll = listAll?.filter((arr, i) => (((productPaginationDetail.current_page - 1) * 10) <= i && (productPaginationDetail.current_page * 10) > i));
			setProductRows(listAll);
		})();
	}, [watchAllFields.qty])

	const settingRows = () => {
		let listAll = productPaginationDetail.list.map( (arr, j) => {
			return {
				no: j + 1,
				product: arr?.product,
				product_name: arr?.product_name,
				code: arr?.code,
				price: arr?.price,
				qty: <>
					<TextField className="without-label-input" style={{ width: "48px" }} type="number" inputProps={{ inputMode: 'numeric', pattern: '[1-9]*' }} {...register(`qty.${j}`, { required: 'Required' })} value={watchAllFields.qty[j] || ""} 
						onChange={(e) => {
							let newVal = e.target.value;
							let allValues = [...watchAllFields.qty];
							allValues[j] = newVal;
							replace(allValues);
						}}
						InputLabelProps={{ shrink: false }} />
					{errors?.qty?.[j] && 
						<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
							<MDTypography fontWeight="bold" sx={{ color: "red!important", fontSize: "10px"}}>{errors.qty[j].message}</MDTypography>
						</MDBox>
					}
				</>,
				delete: 
					<>
						<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => deleteProductRow(arr?.product?.product_id, j)}>delete</Icon>
					</>,
			}}
		);
		let total_page = parseInt(listAll.length/10);
		if(listAll.length%10 !== 0){
			total_page = total_page + 1;
		};
		setProductPaginationDetail({...productPaginationDetail, total_page: total_page});
		return listAll;
	}
	
	useMemo(() => {
		let listAll = settingRows();
		listAll = listAll?.filter((arr, i) => (((productPaginationDetail.current_page - 1) * 10) <= i && (productPaginationDetail.current_page * 10) > i));
		setProductRows(listAll);
	}, [productPaginationDetail.list, productPaginationDetail.current_page])

	const deleteProductRow = (id, index) => {
		let allValues = [...watchAllFields.qty];
		allValues.splice(index, 1);
		replace(allValues);
		let array = [...productPaginationDetail.list];
		array.splice(index, 1);
		let current_page = productPaginationDetail.current_page;
		if(array.length%10 === 0){
			current_page = current_page - 1;
		}
		setProductPaginationDetail({...productPaginationDetail, list: [...array], current_page: current_page, total_data: array.length});
	};

	const goToPageProduct = (page) => {
		setProductPaginationDetail({...productPaginationDetail, current_page: page});
	};

	const submitPO = async (type) => {
		setErrorSelectMarketPlace(false);
		if(salesDataCreate.marketplace_id === '') {
			setErrorSelectMarketPlace(true);
			return;
		}
		if(productPaginationDetail.list.length > 0 ){
			setErrorProduct("");
			const result = await trigger("qty");
			let current_page = productPaginationDetail.current_page;
			setProductPaginationDetail({...productPaginationDetail, current_page: 0});
			setTimeout(() => setProductPaginationDetail({...productPaginationDetail, current_page: current_page}), 50)
			if(Object.keys(salesDataCreate).filter((key) => salesDataCreate[key] === '' ).length !== 0) return;
			if(result){
				let data = {
					marketplace_id: salesDataCreate.marketplace_id,
					customer_name: salesDataCreate.customer_name,
					customer_info: salesDataCreate.customer_info,
					detail_product: productPaginationDetail.list.map((list, i) => {
						return {
							product_id: list.product.product_id,
							qty: parseInt(watchAllFields.qty[i]),
							price: list.product.product_price,
						}
					})
				}
				if(type === "draft"){
					setIsLoading(true);
					services.createDraftSales(data)
						.then(res => {
							setIsLoading(false);
							setShowNotifSuccessDraft(true);
							setProductPaginationDetail({
								current_page: 1,
								total_data: 0,
								total_page: 0,
								list: [],
							})
							replace([]);
						}).catch(errors => {
							setIsLoading(false);
							setShowNotifError(true);
							setErrorMessage(errors?.response?.data?.message);
						});
				} else {
					setIsLoading(true);
					services.createSales(data)
						.then(res => {
							setIsLoading(false);
							setShowNotifSuccess(true);
							setProductPaginationDetail({
								current_page: 1,
								total_data: 0,
								total_page: 0,
								list: [],
							});
							replace([]);
						}).catch(errors => {
							setIsLoading(false);
							setShowNotifError(true)
							setErrorMessage(errors?.response?.data?.message);
						});
				}
			}
		} else {
			setErrorProduct("Please add atleast 1 Product");
		}
	}

  return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" flexDirection={window.innerWidth < 768 ? "column" : "row"} gap="8px" justifyContent="space-between" mt={-0.5} mb={2.5} width="100%">
				<MDTypography variant="h3" fontWeight="regular">
					Add Sales Order
				</MDTypography>
				<MDBox display="flex" maxWidth="90vw" gap="16px">
					<MDButton color="secondary" onClick={() => submitPO("draft")}>
						Draft
					</MDButton>
					<MDButton color="primary" onClick={submitPO}>
						Save
					</MDButton>
				</MDBox>
			</MDBox>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="New Purchase Order Draft Added!"
				open={showNotifSuccessDraft}
				dateTime="Just Now"
				close={() => setShowNotifSuccessDraft(false)}
			/>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="New Purchase Order Added!"
				open={showNotifSuccess}
				dateTime="Just Now"
				close={() => setShowNotifSuccess(false)}
			/>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			<MDBox maxWidth="800px" width="100%" mb={4}>
				<Card sx={{ px: 3, py: 2 }}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={12}>
							<MDTypography variant="h4" fontWeight="regular">Sales Order No</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{textDecoration: "underline"}} fontStyle="italic">{headers.so_number}</MDTypography>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Grid item xs={12} sm={6}>
								<MDInput 
									onChange={e => setSalesDataCreate((prev) => ({...prev, marketplace_id: e.target.value}))}
									value={salesDataCreate.marketplace_id} 
									id="select-brand-type" 
									size="small" 
									fullWidth 
									label="Market Place" 
									type="select" 
									select >
									<MenuItem value={""}>Any</MenuItem>
									{marketPlace.map((el) => (
										<MenuItem value={el.id}>{el.value}</MenuItem>
									))}
								</MDInput>
								{errorSelectMarketPlace &&
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red!important", fontSize: "10px"}}>
											Select market place
										</MDTypography>
									</MDBox> }
							</Grid>
						</Grid>
						<Grid item xs={12} sm={6}>
							<MDInput 
								type="text" 
								{...register('customer_name', { required: 'Required' })}
								label="Customer Name" 
								onChange={(e) => setSalesDataCreate((prev) => ({...prev, customer_name: e.target.value}))}
								fullWidth />
								{errors?.customer_name && 
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red!important", fontSize: "10px"}}>{errors.customer_name.message}</MDTypography>
									</MDBox>
								}
						</Grid>
						<Grid item xs={12} sm={6}>
							<MDInput 
								type="text" 
								{...register('customer_info', { required: 'Required' })}
								label="Customer Info" 
								onChange={(e) => setSalesDataCreate((prev) => ({...prev, customer_info: e.target.value}))}
								fullWidth />
								{errors?.customer_info && 
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red!important", fontSize: "10px"}}>{errors.customer_info.message}</MDTypography>
									</MDBox>
								}
						</Grid>
					</Grid>
				</Card>
			</MDBox>
			<MDBox maxWidth="950px" width="100%">
				<MDBox width="100%" display="flex" justifyContent="flex-end" my={2}>
					<MDButton color="info" onClick={() => setDisplayAddProduct(true)}>
						ADD PRODUCT
					</MDButton>
				</MDBox>
				<DataTable
					table={{ columns, rows : productRows }}
					isSorted={false}
					entriesPerPage={false}
					showTotalEntries={true}
					pagination={{variant: "contained", color: "primary"}}
					noEndBorder
					paginationDetail={productPaginationDetail}
					gotoPage={goToPageProduct}
				/>
				{errorProduct && 
						<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
							<MDTypography fontWeight="bold" sx={{ color: "red!important", fontSize: "10px"}}>{errorProduct}</MDTypography>
						</MDBox>
					}
			</MDBox>
			<AddProductModal open={displayAddProduct} setOpen={setDisplayAddProduct} setIsLoading={setIsLoading} productOptions={productOptions} setProductRows={setProductRows} productPaginationDetail={productPaginationDetail} setProductPaginationDetail={setProductPaginationDetail} />
		</Grid>
  );
}

export default PurchaseOrderAdd;
