/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

function configs(labels, datasets) {
  return {
    data: {
      labels,
      datasets: [...datasets],
    },
    options: {
      tension: 100,
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: true,
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      // scales: {
      //   y: {
      //     grid: {
      //       drawBorder: false,
      //       display: true,
      //       drawOnChartArea: true,
      //       drawTicks: false,
      //       borderDash: [5, 5],
      //       color: "#c1c4ce5c",
      //     },
      //     ticks: {
      //       display: true,
      //       padding: 10,
      //       color: "#9ca2b7",
      //       font: {
      //         size: 14,
      //         weight: 300,
      //         family: "Roboto",
      //         style: "normal",
      //         lineHeight: 2,
      //       },
      //     },
      //   },
      //   x: {
      //     grid: {
      //       drawBorder: false,
      //       display: true,
      //       drawOnChartArea: true,
      //       drawTicks: true,
      //       borderDash: [5, 5],
      //       color: "#c1c4ce5c",
      //     },
      //     ticks: {
      //       display: true,
      //       color: "#9ca2b7",
      //       padding: 10,
      //       font: {
      //         size: 14,
      //         weight: 300,
      //         family: "Roboto",
      //         style: "normal",
      //         lineHeight: 2,
      //       },
      //     },
      //   },
      // },
    },
  };
}

export default configs;
