import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import InputAdornment from '@mui/material/InputAdornment';

import DataTable from "../../../../Table";
import MDInput from "../../../../MDInput";
import MDBox from "../../../../MDBox";
import MDButton from "../../../../MDButton";

import FilterModal from "./filterModal";

import services from "../../../../../service/services";

import breakpoints from "../../../../../assets/theme/base/breakpoints";
import { useSearchParams } from "react-router-dom";
import { cleanObject } from "../../../../../utils";
import { MenuItem } from "@mui/material";

const QuantityProductsIndex = ({
	dataFilter,
	setDataFilter,
	columns, 
	rows,
	rowsDisplayed,
	setRowsDisplayed,
	setRawRowsDisplayed,
	setIsLoading,
	paginationDetail,
	setPaginationDetail,
	getList,
	isFiltered,
	setIsFiltered,
	settingRowsDataTable,
	brandOptions,
	modelOptions,
	modelTypeOptions,
	areaOptions,
	setWarehouse,
	warehouse,
	listWarehouse
}) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [query, setQuery] = useState(searchParams.get("query") || "");
	const [querySearched, setQuerySearched] = useState(searchParams.get("query") || "");
	const [openFilter, setOpenFilter] = useState(false);

	const handleSearchChange = (e) => {
		setQuery(e.target.value);
	}

	const searchList = (e) => {
		if(e.keyCode === 13 && query.length >= 3){
			searchParams.set('page', 1);
			searchParams.set('query', query);
			setSearchParams(searchParams);
			setQuerySearched(query);
		}
	}
	
	useEffect(() => {
		if(querySearched.length === 0 && rows.length > 0){
			setRowsDisplayed(rows);
		}
	},[query, rows]);

	useEffect(() => {
		if(querySearched.length > 0){
			searchInput(searchParams.get("page") || 1)
		}
	}, [querySearched]);

	const searchInput = (page) => {
		setIsLoading(true);
		services.searchQuantityProducts(querySearched, page, warehouse)
			.then(res => {
				setIsLoading(false);
				setIsFiltered(false);
				setRowsDisplayed(settingRowsDataTable(res?.data?.data?.list, res?.data?.data?.current_page));
				setRawRowsDisplayed(res?.data?.data?.list);
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			});
	};

	const filterList = (page) => {
		setIsLoading(true);
		setSearchParams(cleanObject({ ...{...dataFilter, warehouse}, page }));
		services.filterQuantityProducts({...{...dataFilter, warehouse}, page: page})
			.then(res => {
				setIsFiltered(true);
				setIsLoading(false);
				setRowsDisplayed(settingRowsDataTable(res?.data?.data?.list, res?.data?.data?.current_page));
				setPaginationDetail(res?.data?.data)
				setRawRowsDisplayed(res?.data?.data?.list);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	};

	const gotoPage = (page) => {
		searchParams.set('page', page);
		setSearchParams(page);
		if(isFiltered){
			filterList(page);
		} else if(querySearched.length > 0){
			searchInput(page);
		} else {
			getList(page);
		}
	};

	const removeQuery = () => {
		setQuery("");
		setQuerySearched("");
		setSearchParams({ page: 1, warehouse});
	  }

  return (
		<Grid item xs={12}>
			<MDBox sx={ window.innerWidth > breakpoints.values.md ? {display: "flex", padding: "12px 48px", alignItems: "center" } : {display: "flex", flexWrap: "wrap", padding: "12px 24px", justifyContent: "center", alignItems: "center"}} bgColor="#E5ECF0" borderRadius="6px" marginBottom="12px">
				<MDInput
					id="select-is-active" 
					size="small" 
					fullWidth 
					label="Warehouse"
					type="select" 
					select
					value={warehouse}
					onChange={(e) => {
						setWarehouse(e.target.value);
					}}
					style={{marginRight: "1rem"}}
				>
					{listWarehouse?.map((wh)=>(
						<MenuItem value={wh.warehouse_id}>
							{wh.warehouse_name}
						</MenuItem>
					))}
				</MDInput>
				<MDInput 
					variant="outlined" 
					label="Product Name or Product Code or Molding Code or Brand or Model"
					className="search-input"
					InputProps={{
            endAdornment: <InputAdornment position="end"><Icon className="c-pointer" onClick={removeQuery} fontSize="small">close</Icon><Icon fontSize="small">search</Icon></InputAdornment>,
          }}
					backgroundColor="#FFF"
					style={{ height:"100%", borderRadius:"6px", lineHeight:"22px" }}
					fullWidth
					onChange={handleSearchChange}
					onKeyDown={searchList}
					value={query}
					autoFocus
				/>
				<MDBox sx={window.innerWidth > breakpoints.values.md ? {marginLeft: "24px"} : {marginTop: "8px", flexWrap: "wrap", alignItems: "center", gap: "8px", justifyContent: "center"}} display="flex">
					<MDButton variant="gradient" color="info" style={window.innerWidth > breakpoints.values.md ? {marginRight: "12px"} : {}} onClick={() => setOpenFilter(true)}>
						<Icon fontSize="small" style={{marginRight: "8px"}}>filter_list</Icon> Filter
					</MDButton>
					<MDButton variant="gradient" color="info" sx={{ visibility: "hidden", display: window.innerWidth > breakpoints.values.md ? "inherit" : "none"}}>
						<Icon fontSize="small" fontWeight="medium" style={{marginRight: "8px"}}>add</Icon> Add
					</MDButton>
				</MDBox>
			</MDBox>
			<Card>
				<DataTable
					table={{ columns, rows : rowsDisplayed }}
					isSorted={false}
					entriesPerPage={false}
					showTotalEntries={true}
					pagination={{variant: "contained", color: "primary"}}
					noEndBorder
					paginationDetail={paginationDetail}
					gotoPage={gotoPage}
				/>
			</Card>
			<FilterModal
				open={openFilter} 
				setOpen={setOpenFilter}
				brandOptions={brandOptions}
				modelOptions={modelOptions}
				modelTypeOptions={modelTypeOptions}
				areaOptions={areaOptions}
				filterList={filterList}
				dataFilter={dataFilter}
				setDataFilter={setDataFilter}
			/>
		</Grid>
  );
}

export default QuantityProductsIndex;
