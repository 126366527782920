import { useEffect, useState } from "react";
import axios from "axios";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import DocInventoryIndex from "../../../../components/AppComponents/Autoloka/warehouse/docInventory";
import DocInventoryView from "../../../../components/AppComponents/Autoloka/warehouse/docInventory/view";

import services from "../../../../service/services";
import DocInventoryAdd from "../../../../components/AppComponents/Autoloka/warehouse/docInventory/add";
import { useSearchParams } from "react-router-dom";

const DocInventory = ({
	setIsLoading,
	setMode,
	mode,
 }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [rows, setRows] = useState([]);
	const [rowsDisplayed, setRowsDisplayed] = useState([]);
	const [paginationDetail, setPaginationDetail] = useState({});
	const [productOptions, setProductOptions] = useState([]);
	const [locatorOptions, setLocatorOptions] = useState([]);
	const [idDetail, setIdDetail] = useState(null);
	const [locatorRows, setLocatorRows] = useState([]);
	const [locatorPaginationDetail, setLocatorPaginationDetail] = useState({
		list: [],
	});

  const columns = [
		{ Header: "View", accessor: "view", align: "left" },
		{ Header: "Doc ID", accessor: "doc_id", align: "left" },
		{ Header: "Doc Type", accessor: "doc_type", align: "left" },
		{ Header: "Note", accessor: "note", align: "left" },
		{ Header: "Created By", accessor: "created_by", align: "left" },
		{ Header: "Created At", accessor: "created_at", align: "left" },
	];

	useEffect(() => {
		if(mode === "index"){
			setIsLoading(true);
			Promise
				.all([
					services.getDocInvList(searchParams.get("page") || 1),
					services.getOptionsAllProduct(),
				]).then(axios.spread((...res) => {
					setIsLoading(false);
					setPaginationDetail(res[0]?.data?.data);
					setRowsDisplayed(settingRowsDataTable(res[0]?.data?.data?.list, res[0]?.data?.data?.current_page));
					setProductOptions(res[1]?.data?.data);
				})).catch(errors => {
					setIsLoading(false);
					console.log("Error is found, apologies.");
					console.log(errors);
				})
		}
	}, [mode]);

	const getList = (page) => {
		setIsLoading(true)
		services.getDocInvList(page)
			.then(res => {
				setIsLoading(false);
				setRows(settingRowsDataTable(res?.data?.data?.list));
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	};

	const settingRowsDataTable = (rowsArray) => {
		return rowsArray?.map((arr, i) => {
			return {
				view: (
					<>
						<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setMode("view"); setIdDetail(arr.id)}}>visibility</Icon>
					</>
				),
				doc_id: arr?.id,
				doc_type: arr?.doc_type,
				note: arr?.note,
				created_at: arr?.created_at,
				created_by: arr?.created_by,
			};
		});
	};

	const locatorColumns = [
		{ Header: "Product Name", 
			accessor: "display_none_1",
			enableRowSpan: true,
			rowspan: 2,
			align: "left", 
			borderRight: true,
			columns: [
				{
					Header: 'Not visible header 1',
					displayNone: true,
					accessor: "product_name"
				}
			]
		},
		{
			Header: "Locator From",
			colspan: 4,
			align: "left",
			columns: [
				{
					Header: "Locator Name",
					accessor: "locator_from_name",
					align: "left",
				},
				{
					Header: "Qty Before",
					accessor: "locator_from_qty_before",
					align: "left",
				},
				{
					Header: "Quantity Transfer",
					accessor: "locator_from_qty_transfer",
					align: "left",
				},
				{
					Header: "Quantity After",
					accessor: "locator_from_qty_after",
					align: "left",
				}
			]
		},
		{
			Header: "Locator To",
			colspan: 4,
			align: "left",
			borderLeft: true,
			columns: [
				{
					Header: "Locator Name",
					accessor: "locator_to_name",
					borderLeft: true,
					align: "left",
				},
				{
					Header: "Qty Before",
					accessor: "locator_to_qty_before",
					align: "left",
				},
				{
						Header: "Quantity Transfer",
						accessor: "locator_to_qty_transfer",
						align: "left",
				},
				{
						Header: "Quantity After",
						accessor: "locator_to_qty_after",
						align: "left",
				}
			]
		},
		{ Header: "Delete", 
			accessor: "display_none_2",
			enableRowSpan: true,
			rowspan: 2,
			align: "left", 
			borderLeft: true,
			columns: [
				{
					Header: 'Not visible header 2',
					displayNone: true,
					accessor: "delete"
				}
			]
		},
	];

	const settingLocatorRows = (list, page = null) => {
		let total_page = parseInt(list.length/10);
		if(list.length%10 !== 0){
			total_page = total_page + 1;
		};
		if(!page){
			page = total_page;
		};
		let listAll = list.map( (arr, j) => {
			return {
				product: arr?.product,
				locator_from_id: arr?.locator_from?.area_id || arr?.locator_from_id,
				locator_to_id: arr?.locator_to?.area_id || arr?.locator_to_id,
				product_name: arr?.product?.product_name || arr?.product_name,
				locator_from_name: arr?.locator_from_name || arr?.locator_from?.area_name,
				locator_from_qty_before: arr?.locator_from_qty_before,
				locator_from_qty_transfer: -arr?.qty_transfer || arr?.locator_from_qty_transfer,
				locator_from_qty_after: arr?.locator_from_qty_after,
				locator_to_name: arr?.locator_to_name || arr?.locator_to?.area_name,
				locator_to_qty_before: arr?.locator_to_qty_before,
				locator_to_qty_transfer: arr?.locator_to_qty_transfer || arr?.qty_transfer,
				locator_to_qty_after: arr?.locator_to_qty_after,
				delete: 
					<>
						<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => deleteProductLocatorRow(j)}>delete</Icon>
					</>,
			}}
		);
		setLocatorPaginationDetail({
			current_page: page,
			total_page: total_page,
			total_data: list.length,
			list: listAll,
		});
		return listAll?.filter((arr, i) => (((page - 1) * 10) <= i && (page * 10) > i));
	};

	const deleteProductLocatorRow = (index) => {
		let list = [...locatorPaginationDetail.list].splice(index-1, 1);
		setLocatorRows(settingLocatorRows(list, locatorPaginationDetail.current_page));
	}

	const goToPageLocator = (page) => {
		setLocatorRows(settingLocatorRows(locatorPaginationDetail.list, page));
	}

  return (
    <>
			<Grid container spacing={6} className="grid-pure-container">
				{
					mode === "index" ?
						<DocInventoryIndex 
							columns={columns} 
							rows={rows}
							rowsDisplayed={rowsDisplayed}
							setRowsDisplayed={setRowsDisplayed} 
							setIsLoading={setIsLoading}
							setMode={setMode}
							paginationDetail={paginationDetail}
							setPaginationDetail={setPaginationDetail}
							getList={getList}
							settingRowsDataTable={settingRowsDataTable}
						/>
					: mode === "add" ?
					<DocInventoryAdd 
						setIsLoading={setIsLoading}
						productOptions={productOptions}
						locatorOptions={locatorOptions}
						setLocatorOptions={setLocatorOptions}
						locatorRows={locatorRows}
						locatorColumns={locatorColumns}
						locatorPaginationDetail={locatorPaginationDetail}
						goToPageLocator={goToPageLocator}
						settingLocatorRows={settingLocatorRows}
						setLocatorRows={setLocatorRows}
						setLocatorPaginationDetail={setLocatorPaginationDetail}
					/>
					:	mode === "view" ?
						<DocInventoryView 
							setMode={setMode}
							setIsLoading={setIsLoading}
							locatorRows={locatorRows}
							locatorColumns={locatorColumns}
							locatorPaginationDetail={locatorPaginationDetail}
							goToPageLocator={goToPageLocator}
							settingLocatorRows={settingLocatorRows}
							setLocatorRows={setLocatorRows}
							setLocatorPaginationDetail={setLocatorPaginationDetail}
							id={idDetail}
						/>
					: null
				}
			</Grid>
    </>
  );
}

export default DocInventory;
