import { useCallback, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import CardBarcode from "./card-barcode";
import { setSizePrintPaper } from '../../../../../utils';

const BarcodesLayout = ({printItems, configPaper})=> {
    const qrRef = useRef();
	const handlePrintQr = useReactToPrint({
		content: () => qrRef.current,
	});

    const brutoHeightCard = (parseFloat(configPaper?.height, 10) + parseFloat(configPaper?.top, 10) + parseFloat(configPaper?.bottom, 10) ).toFixed(2);
    const brutoWidthCard = (parseFloat(configPaper?.width, 10) + parseFloat(configPaper?.left, 10) + parseFloat(configPaper?.right, 10) ).toFixed(2);

    const limitCard = configPaper?.row;

    const flatenPrintItems = useCallback(() => {
        if(!limitCard) return [];
        const flaten = [];
        printItems?.forEach((el, i) => {
            [...new Array(parseInt(el.qty))].forEach((_, index) => {
                flaten.push({
                    molding_code: el.molding_code,
                    product_code: el.product_code,
                    product_name: el.product_name,
                })
            });
        });
        return flaten;
    },[printItems, limitCard]);

    const limitatePrintItems = useCallback(() => {
        if(!limitCard) return [];
        const dimen = (parseInt(configPaper?.column) * parseInt(limitCard, 10));
        const flaten = flatenPrintItems();
        const perPaper = [];
        const loop = Math.ceil(flaten.length / dimen);
        console.log('loop', loop, flaten.length);
        [...new Array(loop)].forEach((el, i) => {
            perPaper.push(flaten.splice(0, dimen))
        })
        return perPaper;
    },[limitCard, printItems]);

    setSizePrintPaper(
        (brutoWidthCard * parseFloat(configPaper?.column || 1)).toFixed(2) + 'cm' , 
        (brutoHeightCard * parseFloat(configPaper?.row || 1)).toFixed(2) + 'cm');

    return (
        <>
            <button onClick={handlePrintQr} className="" id="print-barcode-btn" style={{display: 'none'}}>
                print
            </button>
            <div ref={qrRef} className="layout-print-paper">
                {limitatePrintItems()?.map((item, index) => {
                    return (
                        <div className="page-break-before">
                            <div className={`layout-print-barcodes ${index !== 0 ? 'print-item' : ''}`} 
                                style={{
                                    display: 'grid',
                                    margin: '0 !important',
                                    gridTemplateColumns: `repeat(${configPaper?.column}, 1fr)`,
                                    gridTemplateRows: `repeat(${configPaper?.row}, 1fr)`,
                                }}>
                                {item?.map((el, i) => (
                                    <CardBarcode configPaper={configPaper} item={el} key={el.product_code + i} />
                                ))}
                            </div>
                        </div>
                    )
                })}
                {limitatePrintItems().length > 1 
                    && <p className="hide-when-print" style={{marginTop: '1rem', textAlign: 'end'}}>{`${limitatePrintItems().length - 1} page more`}</p>}
            </div>
        </>
    )
}

export default BarcodesLayout;