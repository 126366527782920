import React from 'react'
import { useNavigate } from 'react-router-dom';
import Icon from "@mui/material/Icon";

const AppBox = ({app, count, onClick, onMouseEnter, onMouseLeave}) => {

	const navigate = useNavigate();

	return (
		<>
			<div className='app-container'>
				<div className={'app-container-inactive'} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
					<div className={`mb-1 app`}>
						<Icon fontSize='default'>{app?.apps_icon || "dashboard"}</Icon>
					</div>
					<div>{app.apps_name}</div>
				</div>
			</div>
		</>
	)
}
	
export default AppBox;