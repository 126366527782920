import { useEffect, useState } from "react";
import axios from "axios";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import QuantityProductsIndex from "../../../../components/AppComponents/Autoloka/warehouse/quantityProducts";
import QuantityProductsView from "../../../../components/AppComponents/Autoloka/warehouse/quantityProducts/view";

import services from "../../../../service/services";
import { useSearchParams } from "react-router-dom";
import { cleanObject } from "../../../../utils";
import useFetchState from "../../../../hooks/useFetchState";

const QuantityProducts = ({
	setIsLoading,
	setMode,
	mode,
 }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [rows, setRows] = useState([]);
	const [rowsDisplayed, setRowsDisplayed] = useState([]);
	const [rawRowsDisplayed, setRawRowsDisplayed] = useState([]);
	const [paginationDetail, setPaginationDetail] = useState({});
	const [brandOptions, setBrandOptions] = useState([]);
	const [modelOptions, setModelOptions] = useState([]);
	const [modelTypeOptions, setModelTypeOptions] = useState([]);
	const [areaOptions, setAreaOptions] = useState([]);
	const [idDetail, setIdDetail] = useState(null);
	const [isFiltered, setIsFiltered] = useState(false);
	const [dataFilter, setDataFilter] = useState({
		product_name: searchParams.get("product_name") || "",
		product_code: searchParams.get("product_code") || "",
		molding_code: searchParams.get("molding_code") || "",
		brand_name: searchParams.get("brand_name") || "",
		model_name: searchParams.get("model_name") || "",
		model_type_name: searchParams.get("model_type_name") || "",
		area_code: searchParams.get("area_code") || "",
	});
	const [warehouse, setWarehouse] = useState(searchParams.get("model_type_name") || 0);

  const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "View", accessor: "view", align: "left" },
		{ Header: "Qty", accessor: "qty", align: "left" },
		{ Header: "Locator", accessor: "area", align: "left" },
		{ Header: "Product Name", accessor: "product_name", align: "left" },
		{ Header: "Product Code", accessor: "product_code", align: "left" },
		{ Header: "Brand", accessor: "brand", align: "left" },
		{ Header: "Model Name", accessor: "model_name", align: "left" },
		{ Header: "Model Year", accessor: "model_year", align: "left" },
		{ Header: "Molding Code", accessor: "molding_code", align: "left" },
		{ Header: "Model Type", accessor: "model_type", align: "left" },
	];

	useEffect(() => {

		let productsFetcher;
		if(Object.keys(cleanObject(dataFilter)).length !== 0) {
			productsFetcher = services.filterQuantityProducts({...{...dataFilter, warehouse: (searchParams.get("page") || 1, warehouse)}, page: searchParams.get("page") || 1})
		}else {
			productsFetcher = services.getQuantityProducts(searchParams.get("page") || 1, warehouse)
		}

		setPaginationDetail({});
		setRows([]);
		setRawRowsDisplayed({});
		setRowsDisplayed([]);

		if(mode === "index"){
			setIsLoading(true);
			Promise
				.all([
					productsFetcher,
					services.getOptionsBrands(),
					services.getOptionsModels(),
					services.getOptionsModelTypes(),
					services.getAreaOptions(),
				]).then(axios.spread((...res) => {
					setIsLoading(false);
					setPaginationDetail(res[0]?.data?.data);
					setRows(settingRowsDataTable(res[0]?.data?.data?.list, res[0]?.data?.data?.current_page));
					setRawRowsDisplayed(res[0]?.data?.data?.list);
					setBrandOptions(res[1]?.data?.data);
					setModelOptions(res[2]?.data?.data);
					setModelTypeOptions(res[3]?.data?.data);
					setAreaOptions(res[4]?.data?.data);
				})).catch(errors => {
					setIsLoading(false);
					console.log("Error is found, apologies.")
					console.log(errors)
				})
		}
	}, [mode, warehouse]);

	const getList = (page) => {
		setIsLoading(true)
		services.getQuantityProducts(page, warehouse)
			.then(res => {
				setIsLoading(false);
				setIsFiltered(false);
				setRows(settingRowsDataTable(res?.data?.data?.list, res?.data?.data?.current_page));
				setRawRowsDisplayed(res?.data?.data?.list);
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	};

	const getWarehouse = useFetchState();

    // getwarehouse fetcher
    useEffect(()=> {
        getWarehouse.fetchStart();
        services.getWarehouse().then((res) => {
			getWarehouse.fetchSuccess(res.data?.data || null);
			setWarehouse(res.data.data[0].warehouse_id);
        }).catch((error) => {
			getWarehouse.fetchFailed(error?.message || "Ooops something went wrong");
        });
    },[]);

	useEffect(() => {
		if(warehouse) {
			searchParams.set("warehouse", warehouse);
			setSearchParams(searchParams);
			getList(1);
		}
	},[warehouse]);


	const settingRowsDataTable = (rowsArray, current_page) => {
		return rowsArray?.map((arr, i) => {
			return {
				no: ((current_page - 1) * 10) + i + 1,
				view: (
					<>
						<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setMode("view"); setIdDetail(arr.product_id)}}>visibility</Icon>
					</>
				),
				qty: arr?.quantity,
				area: arr?.list_area.join(", "),
				product_name: arr?.product_name,
				product_code: arr?.product_code,
				brand: arr?.brand,
				model_name: arr?.model_name,
				model_year: arr?.model_year,
				molding_code: arr?.molding_code,
				model_type: arr?.model_type_name,
			};
		});
	};

  return (
    <>
			<Grid container spacing={6} className="grid-pure-container">
				{
					mode === "index" ?
						<QuantityProductsIndex 
							setWarehouse={setWarehouse}
							warehouse={warehouse}
							listWarehouse={getWarehouse.data || []}
							dataFilter={dataFilter}
							setDataFilter={setDataFilter}
							columns={columns} 
							rows={rows}
							rowsDisplayed={rowsDisplayed}
							setRowsDisplayed={setRowsDisplayed} 
							setRawRowsDisplayed={setRawRowsDisplayed}
							setIsLoading={setIsLoading}
							brandOptions={brandOptions}
							modelOptions={modelOptions}
							modelTypeOptions={modelTypeOptions}
							areaOptions={areaOptions}
							setMode={setMode}
							paginationDetail={paginationDetail}
							setPaginationDetail={setPaginationDetail}
							getList={getList}
							isFiltered={isFiltered}
							setIsFiltered={setIsFiltered}
							settingRowsDataTable={settingRowsDataTable}
						/>
					: mode === "view" ?
						<QuantityProductsView 
							setIsLoading={setIsLoading}
							id={idDetail}
						/>
					: null
				}
			</Grid>
    </>
  );
}

export default QuantityProducts;
