import Analytics from "./Autoloka/dashboard/analytics";
import Sales from "./Autoloka/dashboard/salesReport";

import Attribute from "./Autoloka/masterData/attribute";
import Brand from "./Autoloka/masterData/brand";
import Category from "./Autoloka/masterData/category";
import Model from "./Autoloka/masterData/model";
import ModelType from "./Autoloka/masterData/modelType";
import Style from "./Autoloka/masterData/style";
import Subattribute from "./Autoloka/masterData/subattribute";
import SubCategory from "./Autoloka/masterData/subCategory";

import ItemPrice from "./Autoloka/product/itemPrice";
import ItemProducts from "./Autoloka/product/itemProducts";
import ListOrders from "./Autoloka/product/listOrders";

import ProductsQuantity from "./Autoloka/warehouse/quantityProducts";
import DocInventory from "./Autoloka/warehouse/docInventory";

import Voucher from "./Autoloka/promo/voucher";
import Loyalty from "./Autoloka/promo/loyalty";

import Banner from "./Autoloka/feature/banner";

import PurchaseOrder from "./Autoloka/document/purchase-order";
import SalesOrder from "./Autoloka/document/sales-order";
import InventoryOut from "./Autoloka/document/inventory-out";
import DeliveryOrder from "./Autoloka/document/delivery-order";

// pos
import PosDashboard from "./Autoloka/pointOfSales/dashboard";
import PosSales from "./Autoloka/pointOfSales/sales";

// user management
import UserManagementBuyer from "./Autoloka/userManagement/buyer";
import UserManagementUser from "./Autoloka/userManagement/user";

// print
import PrintBarcode from "./Autoloka/print/barcode";

// other
import PdfConverter from "./Other/PdfConverter";

// company asset
import CompanyInventoryAsset from "./CompanyAsset/Inventory";

// report
import ReportSalesProduct from "./Autoloka/report/sales-product";
import ReportTransaction from "./Autoloka/report/transaction";

const AllPages = [
	{
		"name": "autoloka",
		"pages": [
			{
				"menu-name": "dashboard",
				"components": {
					"analytics": Analytics,
					"sales": Sales
				}
			},
			{
				"menu-name": "master-data",
				"components": {
					"brand": Brand,
					"model": Model,
					"model-type": ModelType,
					"category": Category,
					"subcategory": SubCategory,
					"style": Style,
					"attribute": Attribute,
					"subattribute": Subattribute,
				},
			},
			{
				"menu-name": "product",
				"components": {
					"list-order": ListOrders,
					"item-products": ItemProducts,
				},
			},
			{
				"menu-name": "warehouse",
				"components": {
					"product-price": ItemPrice,
					"product-quantity": ProductsQuantity,
					"doc-inventory": DocInventory,
				},
			},
			{
				"menu-name": "document",
				"components": {
					"purchase-order": PurchaseOrder,
					"sales-order": SalesOrder,
					"delivery-order": DeliveryOrder,
					"inventory-out": InventoryOut,
				}
			},
			{
				"menu-name": "promo",
				"components": {
					"voucher": Voucher,
					"loyalty": Loyalty,
				},
			},
			{
				"menu-name": "feature",
				"components": {
					"banner": Banner,
				},
			},
			{
				"menu-name": "user-management",
				"components": {
					"user": UserManagementUser,
					"buyer": UserManagementBuyer,
				},
			},
			{
				"menu-name": "print",
				"components": {
					"barcode": PrintBarcode,
				},
			},
			{
				"menu-name": "report",
				"components": {
					"transactions": ReportTransaction,
					"sales": ReportSalesProduct,
				}
			}
		],
	},
	{
		"name": "pos",
		"pages": [
			{
				"menu-name": "dashboard",
				"components": PosDashboard,
			},
			{
				"menu-name": "sales",
				"components": PosSales,
			},
			{
				"menu-name": "promo",
				"components": {
					"voucher": Voucher,
					"loyalty": Loyalty,
				},
			},
			{
				"menu-name": "user-management",
				"components": {
					"user": UserManagementUser,
					"buyer": UserManagementBuyer,
				},
			},
		]
	},
	{
		"name": "document",
		"pages": [
			{
				"menu-name": "purchase-order",
				"components": PurchaseOrder,
			},
			{
				"menu-name": "sales-order",
				"components": SalesOrder,
			},
			{
				"menu-name": "delivery-order",
				"components": DeliveryOrder,
			},
			{
				"menu-name": "document",
				"components": {
					"purchase-order": PurchaseOrder,
					"sales-order": SalesOrder,
					"delivery-order": DeliveryOrder,
					"inventory-out": InventoryOut,
				}
			}
		]
	},
	{
		"name": "inventory",
		"pages": [{
			"menu-name": "master-data",
			"components": {
				"brand": Brand,
				"model": Model,
				"model-type": ModelType,
				"category": Category,
				"subcategory": SubCategory,
				"style": Style,
				"attribute": Attribute,
				"subattribute": Subattribute,
			},
		},
		{
			"menu-name": "product",
			"components": {
				"list-order": ListOrders,
				"item-products": ItemProducts,
			},
		},
		{
			"menu-name": "warehouse",
			"components": {
				"product-price": ItemPrice,
				"product-quantity": ProductsQuantity,
				"doc-inventory": DocInventory,
			},
		},
		{
			"menu-name": "print",
			"components": {
				"barcode": PrintBarcode,
			},
		}]
	},
	{
		name: "other",
		pages: [
			{
				"menu-name": 'pdf-converter',
				components: PdfConverter,
			},
		]
	},
	{
		name: "company-asset",
		pages: [
			{
				"menu-name": 'company-asset',
				"components": {
					"inventory": CompanyInventoryAsset,
				},
			},
		]
	},
];

export default AllPages;