import { useEffect, useRef, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import DateRangePicker from "react-daterange-picker";
import moment from 'moment';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Autocomplete, Icon, TextField, MenuItem, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, createFilterOptions } from "@mui/material";
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import InputAdornment from '@mui/material/InputAdornment';

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDInput from "../../../../MDInput";
import MDEditor from "../../../../MDEditor";
import MDButton from "../../../../MDButton";
import MDSnackbar from "../../../../MDSnackbar";
import MDTypography from "../../../../MDTypography";
import DataTable from "../../../../Table";

import services from "../../../../../service/services";

import { addCommas, getImageError, toBase64 } from "../../../../../utils";

import placeholder from "../../../../../assets/images/placeholder-image.jpeg";

const VoucherAdd = ({
	setIsLoading,
	userOptions,
	regionOptions,
	productOptions,
	setProductOptions,
	productTypeOptions,
	setProductTypeOptions,
	settingPaginationDetail,
	settingUserRows,
	userGotoPage,
	settingRegionRows,
	regionGotoPage,
	productGotoPage,
	deleteUser,
	userRows,
	setUserRows,
	userRawRows,
	setUserRawRows,
	userColumns,
	userPaginationDetail,
	setUserPaginationDetail,
	deleteRegion,
	regionRows,
	setRegionRows,
	regionRawRows,
	setRegionRawRows,
	regionColumns,
	regionPaginationDetail,
	setRegionPaginationDetail,
	deleteProduct,
	productRows,
	productRawRows,
	setProductRawRows,
	productColumns,
	productPaginationDetail,
}) => {

	const voucher_imageRef = useRef();

	const [image, setImage] = useState(null);
	const [isTransparent, setIsTransparent] = useState(false);
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [formData, setFormData] = useState({
		voucher_code: "",
		effective_date: "",
		description: "",
		priority: "",
		voucher_image: null,
		product_type_id: null,
	});
	const [displayDate, setDisplayDate] = useState(false);
	const [tabIndexActive, setTabIndexActive] = useState(0);
	const [errorMessageTabIndex1, setErrorMessageTabIndex1] = useState("");
	const [errorMessageTabIndex3Item, setErrorMessageTabIndex3Item] = useState("");
	const [user, setUser] = useState(null);
	const [userError, setUserError] = useState("");
	const [region, setRegion] = useState(null);
	const [city, setCity] = useState({});
	const [cityOptions, setCityOptions] = useState([]);

	const { register, handleSubmit, setValue, watch, trigger, control, setError, reset, clearErrors, formState: { errors, isSubmitSuccessful } } = useForm({mode: "all", shouldUnregister: false});
	const { register: registerRegion, setValue: setValueRegion, handleSubmit: handleSubmitRegion, trigger: triggerRegion, setError: setErrorsRegion, clearErrors: clearErrorsRegion, formState: { errors: errorsRegion } } = useForm({mode: "all"});
	const { register: registerLogic, setValue: setValueLogic, watch: watchLogic, trigger: triggerLogic, setError: setErrorsLogic, clearErrors: clearErrorsLogic, formState: { errors: errorsLogic } } = useForm({mode: "all"});

	const onImageChange = async (e) => {
		let format = e.target.files[0].type.split('/').pop();
		setIsTransparent(format.includes("png"));
		let val = await toBase64(e.target.files[0]);
		setFormData({...formData, voucher_image: val});
		setValue("voucher_image", val);
		setImage(URL.createObjectURL(e.target.files[0]));
		const result = await trigger("voucher_image");
	}

	const removeImage = async () => {
		setIsTransparent(false);
		setFormData({...formData, voucher_image: null});
		setValue("voucher_image", null);
		setImage(null);
		const result = await trigger("voucher_image");
	}

	const submitCreate = (e) => {
		setIsLoading(true);
		let date = e.date.split(" - ");
		let data = {
			voucher_code: e.voucher_code,
			priority: e.priority,
			title: e.title,
			desc: e.description,
			start_date: date[0],
			end_date: date[1],
			customer_type: e.customer_type,
			product_type: e.product_type,
			max_coupon: e.max_coupon,
			logic: watchAllFieldsLogic.logic,
			usage_coupon_per_user: e.usage_coupon,
			array_product: [...productRawRows],
			impact_name: e.impact_name,
			impact_category: e.impact_category,
			min_trx: e.min_trx.replaceAll(".",""),
			max_discount: e.max_discount.replaceAll(".",""),
			amount: e.amount_percentage.replaceAll(".",""),
			is_multiple: e.multiple,
			image_path: e.voucher_image,
		};
		if(e.customer_type === "1"){
			delete data.array_region;
			data.array_user = [...userRawRows];
		} else if(e.customer_type === "2"){
			delete data.array_user;
			data.array_region = [...regionRawRows];
		}
		services.createVoucher(data)
			.then(async res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
				setTabIndexActive(0);
				setUserRawRows([]);
				setUserRows([]);
				setUserPaginationDetail({
					current_page: 1,
					total_data: 0,
					total_page: 1,
				});
				setRegionRawRows([]);
				setRegionRows([]);
				setRegionPaginationDetail({
					current_page: 1,
					total_data: 0,
					total_page: 1,
				});
				setProductRawRows([]);
				removeImage();
				setValue("voucher_code", "");
				setValue("effective_date", "");
				setValue("title", "");
				setValue("priority", "");
				setValue("description", "");
				setValue("voucher_code", "");
				setValue("voucher_image", "");
				setValue("customer_type", "");
				setValueRegion("province", "");
				setValueRegion("city", "");
				setValue("product_type", "");
				setValue("max_coupon", "");
				setValue("product_type_id", "");
				setValueLogic("min_qty_order", "");
				setValueLogic("product", "");
				setValueLogic("logic", "");
				setValue("usage_coupon", "");
				setValue("impact_name", "");
				setValue("impact_category", "");
				setValue("amount_percentage", "");
				setValue("min_trx", "");
				setValue("multiple", "");
				setValue("max_discount", "");
			}).catch(errors => {
				setIsLoading(false);
				setErrorMessage(errors?.response?.data?.message);
				setShowNotifError(true);
				console.log(errors)
			})
	};

	const watchAllFields = watch();
	const watchAllFieldsLogic = watchLogic();

	const addUser = () => {
		if(Object.keys(user).length > 0){
			if(userRawRows.filter( urr => urr.id === user.id).length > 0){
				setUserError("User already added"); 
			} else{
				setUserError(false);
				let list = [...userRawRows, user];
				setUserRawRows(list);
				setUser(null);
				let paginationDetail = settingPaginationDetail(list);
				setUserRows(settingUserRows(list, paginationDetail.total_page));
				setUserPaginationDetail(paginationDetail);
			}
		} else {
			setUserError("Required");
		}
	};

	const filterOptions2 = createFilterOptions({
		matchFrom: 'any',
		limit: 100,
		stringify: (option) => option.product_mame + option.product_code,
	});

	useMemo(() => {
		if(region?.province_id){
			setIsLoading(true);
			services.getOptionCities(region.province_id)
				.then(res => {
					setIsLoading(false);
					setCityOptions(res?.data?.data)
				}).catch(errors => {
					setIsLoading(false);
					setErrorMessage(errors?.response?.data?.message);
					setShowNotifError(true);
				})
		}
	}, [region?.province_id]);

	const addAllCity = async (type) => {
		let id;
		if(type === "province"){
			const result = await triggerRegion("province");
			if(result){
				id = region.province_id;
			}
		} else if(type === "indonesia"){
			id = 0;
		}
		setIsLoading(true);
		services.getOptionCities(id)
			.then(res => {
				setIsLoading(false);
				let cities = res?.data?.data;
				let ids = new Set(regionRawRows.map(r => r.city_id));
				let list =  [...regionRawRows, ...cities.filter(c => !ids.has(c.city_id))];
				setRegionRawRows(list);
				let paginationDetail = settingPaginationDetail(list);
				setRegionRows(settingRegionRows(list, paginationDetail.total_page));
				setRegionPaginationDetail(paginationDetail);
			}).catch(errors => {
				setIsLoading(false);
				setErrorMessage(errors?.response?.data?.message);
				setShowNotifError(true);
			})
	};

	const addRegion = async () => {
		clearErrorsRegion("city");
		const result = await triggerRegion(["province", "city"]);
		if(result){
			if(regionRawRows.filter( rrr => rrr.city_id === city.city_id).length > 0){
				setErrorsRegion('city', { type: 'custom', message: 'City already added' });
			} else{
				let list = [...regionRawRows, city];
				setRegionRawRows(list);
				let paginationDetail = settingPaginationDetail(list);
				setRegionRows(settingRegionRows(list, paginationDetail.total_page));
				setRegionPaginationDetail(paginationDetail);
			}
		}
	};

	useMemo(() => {
		let id;
		if(watchAllFields.product_type === "0"){
			id = {name:"", id: "0"};
		} else {
			id = null;
		}
		setValue("product_type_id", id);
		setValue("product", null);
		setValue("logic", "");
	}, [watchAllFields.product_type])

	useEffect(() => {
		if(watchAllFields.product_type){
			setIsLoading(true);
			services.getOptionProductType(watchAllFields.product_type)
				.then(res => {
					setIsLoading(false);
					setProductTypeOptions(res?.data?.data);
				}).catch(errors => {
					setIsLoading(false);
					setErrorMessage(errors?.response?.data?.message);
					setShowNotifError(true);
				})
		}
		if(watchAllFields.product_type === "0"){
			clearErrorsLogic("product_type_id");
			setIsLoading(true);
			services.getOptionProductFromProductType(watchAllFields.product_type, 0)
				.then(res => {
					setIsLoading(false);
					setProductOptions(res?.data?.data?.list_product);
				}).catch(errors => {
					setIsLoading(false);
					setErrorMessage(errors?.response?.data?.message);
					setShowNotifError(true);
				})
		}
	}, [watchAllFields?.product_type]);

	useEffect(() => {
		if(watchAllFieldsLogic.product_type_id){
			setIsLoading(true);
			services.getOptionProductFromProductType(watchAllFields.product_type, watchAllFieldsLogic.product_type_id?.id || 0)
				.then(res => {
					setIsLoading(false);
					setProductOptions(res?.data?.data?.list_product);
				}).catch(errors => {
					setIsLoading(false);
					setErrorMessage(errors?.response?.data?.message);
					setShowNotifError(true);
				})
		}
	}, [watchAllFieldsLogic.product_type_id]);

	const addItemLogic = async (type) => {
		clearErrorsLogic();
		if(type === "all"){
			let result = triggerLogic(["min_qty_order"]);
			if(!watchAllFieldsLogic.logic){
				setErrorsLogic("logic", { type: "required", message: "Required"});
			}
			if(watchAllFieldsLogic.min_qty_order && watchAllFieldsLogic.logic) {
				let ids = new Set(productRawRows.map(p => p.id));
				let newProducts = productOptions.map( po => {
					return {
						id: po.product_id,
						item: po.product_name,
						min_qty_order: watchAllFieldsLogic?.min_qty_order,
					}
				})
				let list =  [...productRawRows, ...newProducts.filter(p => !ids.has(p.id))];
				setProductRawRows(list);
			}
		} else{
			let result;
			if(watchAllFieldsLogic.logic !== "3"){
				result = triggerLogic(["min_qty_order"]);
			} else {
				clearErrorsLogic("min_qty_order");
			}
			if(watchAllFieldsLogic?.product_type_id === "0"){
				result = triggerLogic(["product"]);
			} else {
				result = triggerLogic(["product_type_id", "product"]);
			}
			if(watchAllFieldsLogic.logic === "3"){
				if(productRawRows.filter( prr => prr.id === formData.product.product_id).length > 0){
					setErrorsLogic('logic', { type: 'custom', message: 'Product already added' });
				} else {
					let list = [...productRawRows, {
						id: formData.product.product_id,
						item: formData.product.product_name,
					}];
					setProductRawRows(list);
				}
			} else if(watchAllFieldsLogic.min_qty_order && watchAllFieldsLogic.logic) {
				if(productRawRows.filter( prr => prr.id === formData.product.product_id).length > 0){
					setErrorsLogic('logic', { type: 'custom', message: 'Product already added' });
				} else {
					let list = [...productRawRows, {
						id: formData.product.product_id,
						item: formData.product.product_name,
						min_qty_order: watchAllFieldsLogic.min_qty_order
					}];
					setProductRawRows(list);
				}
			}
			if(!watchAllFieldsLogic?.logic){
				setErrorsLogic("logic", { type: "required", message: "Required"});
			}
		}
	};
	
	useEffect(() => {
		register("date");
		register("is_above_max_coupon");
		register("amount_percentage");
	}, []);

	useEffect(() => {
		validateAmountPercentage();
	}, [watchAllFields?.impact_category]);

	const validateMaxDiscount = () => {
		if(watchAllFields.impact_category === "1" && parseInt(watchAllFields.max_discount?.replaceAll(".", "")) < parseInt(watchAllFields.amount_percentage?.replaceAll(".", ""))){
			setError("max_discount", { type: 'custom', message: "Max Discount must be bigger than amount"});
		} else {
			clearErrors("max_discount");
		}
	}

	const validateAmountPercentage = () => {
		let val = watchAllFields?.amount_percentage;
		if(!val){
			setError("amount_percentage", { type: 'required', message: "Required" });
		} else if(watchAllFields?.impact_category === "1" && !(/^([1-9]{1}[0-9]{0,2})(\.{1}\d{3})*$/.test(val))) {
			setError("amount_percentage", { type: 'custom', message: "Format amount invalid" });
		} else if(watchAllFields?.impact_category === "2" && parseInt(val.replaceAll(".","")) > 100){
			setError("amount_percentage", { type: 'custom', message: "Percentage should be below or equal to 100"});
		} else {
			clearErrors("amount_percentage");
		}
	}

	const nextStep = async (step) => {
		let result;
		if(step === 1){
			result = await trigger(["voucher_code", "effective_date", "description", "priority", "voucher_image"]);
			if(result){
				setTabIndexActive(step);
			}
		} else if(step === 2){
			result = await trigger(["customer_type"]);
			if(result){
				if(watchAllFields?.customer_type === "1") {
					if(userRawRows.length > 0){
						setErrorMessageTabIndex1("");
						setTabIndexActive(step);
					} else {
						setErrorMessageTabIndex1("Please Add At least 1 User");
					}
				} else if (watchAllFields?.customer_type === "2"){
					if(regionRawRows?.length > 0){
						setErrorMessageTabIndex1("");
						setTabIndexActive(step);
					} else {
						setErrorMessageTabIndex1("Please Add At least 1 Region");
					}
				} else if (watchAllFields?.customer_type === "3"){
					setErrorMessageTabIndex1("");
					setTabIndexActive(step);
				}
			}
		} else if(step === 3){
			result = await trigger(["product_type", "max_coupon"]);
			if(result){
				setTabIndexActive(step);
			}
		} else if(step === 4){
			result = await trigger(["usage_coupon"]);
			if(productRawRows?.length > 0){
				setErrorMessageTabIndex3Item("");
			} else {
				setErrorMessageTabIndex3Item("Please Add At least 1 Product Item");
			}
			if(result && productRawRows?.length > 0){
				setTabIndexActive(step);
			}
		}
	};

	const TabsList = styled(TabsListUnstyled)`
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		align-content: space-between;
	`;

	const Tab = styled(TabUnstyled)`
		cursor: pointer;
		font-size: 18px;
		background-color: #CFD8DC;
		width: 200px;
		border-radius: 8px 8px 0 0;
		border: 2px solid #000;
		border-bottom: none;
		display: flex;
		justify-content: center;
		
		&:hover, &:focus, &.${tabUnstyledClasses.selected} {
			font-weight: bold;
			background-color: #FFF;
		}
	`;

	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		limit: 100,
	});
	
  return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" justifyContent="space-between" mb={5} width="100%">
				<MDTypography variant="h3" fontWeight="regular">
					Add New Voucher
				</MDTypography>
			</MDBox>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="New Voucher Added!"
				open={showNotifSuccess}
				dateTime="Just Now"
				close={() => setShowNotifSuccess(false)}
			/>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			<MDBox maxWidth="800px" component="form" role="form" id="create-voucher" onSubmit={handleSubmit(submitCreate)}>
				<TabsUnstyled value={tabIndexActive}>
					<MDBox sx={{ overflow: "auto" }}>
						<TabsList className="tablist-customized">
							<Tab disabled>Rule Information</Tab>
							<Tab disabled sx={{ marginLeft: "-2px" }}>Data Customer</Tab>
							<Tab disabled sx={{ marginLeft: "-2px" }}>Condition</Tab>
							<Tab disabled sx={{ marginLeft: "-2px" }}>Detail Condition</Tab>
							<Tab disabled sx={{ marginLeft: "-2px" }}>Action</Tab>
						</TabsList>
					</MDBox>
					<TabPanelUnstyled style={{ backgroundColor: "#FFF" }} value={0}>
						<MDBox px={2} py={3} sx={{ border: "2px solid #000" }}>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={12} md={8}>
									<MDInput type="text" label="Voucher Code" fullWidth {...register("voucher_code", { required: "Required" })} error={errors?.voucher_code} value={watchAllFields.voucher_code} />
									{errors?.voucher_code && 
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.voucher_code.message}</MDTypography>
										</MDBox>
									}
								</Grid>
								<Grid item xs={12} sm={12} md={4} sx={{ position: "relative" }}>
									<MDInput
										sx={{ backgroundColor: "#FFF", borderRadius: "8px" }}
										size='small'
										label="Effective Date"
										InputProps={{
											endAdornment: 
											<InputAdornment position="end">
												{formData?.effective_date?.length > 0 ? 
													<Icon className="c-pointer" onClick={() => setValue("effective_date", "")} fontSize="small">close</Icon>
													: null
												}
												<Icon fontSize="small">calendar_today</Icon>
											</InputAdornment>,
										}}
										{...register("effective_date", { required: "Required" })} 
										fullWidth
										value={watchAllFields.effective_date}
										onClick={() => setDisplayDate(!displayDate)}
										InputLabelProps={{ shrink: watchAllFields?.effective_date?.length > 0 }}
									/>
									{
										displayDate ?
											<DateRangePicker
												className="customized-daterange-picker voucher-date-picker"
												firstOfWeek={1}
												numberOfCalendars={1}
												selectionType="range"
												onSelect={async (dateValue) => {
													let val = `${moment(dateValue.start).format('DD.MM.YYYY')} - ${moment(dateValue.end).format('DD.MM.YYYY')}`;
													setValue("effective_date", val);
													setValue("date", `${moment(dateValue.start).format('YYYY-MM-DD')} - ${moment(dateValue.end).format('YYYY-MM-DD')}`)
													const result = await trigger("effective_date");
												 	setDisplayDate(false);
												}}
											/>
										: null
									}
									{errors?.effective_date && 
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.effective_date.message}</MDTypography>
										</MDBox>
									}
								</Grid>
								<Grid item xs={12} sm={12} md={8}>
									<MDInput type="text" label="Title" fullWidth {...register("title", { required: "Required" })} error={errors?.title} value={watchAllFields.title} />
									{errors?.title && 
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.title.message}</MDTypography>
										</MDBox>
									}
									<MDBox mt={3}>
										<MDTypography style={{color: "#7b809a"}} fontSize={"12px"}>Description</MDTypography>
										<MDEditor
											style={{ backgroundColor: "#FFF", borderRadius: "8px", height: "100%" }}
											{...register("description", { required: "Required" })} 
											error={errors?.description} 
											onChange={(e) => {
												setValue('description', e); 
											}}
											value={watchAllFields.description || ""}
										/>
										{errors?.description && 
											<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
												<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.description.message}</MDTypography>
											</MDBox>
										}
									</MDBox>
								</Grid>
								<Grid item xs={12} sm={12} md={4}>
									<MDBox mb={3}>
										<MDInput
											{...register("priority", { required: "Required" })}
											id="select-category-name" 
											size="small" 
											fullWidth 
											label="Priority"
											type="select" 
											select
											value={watchAllFields.priority}
											error={errors?.priority}
										>
											<MenuItem disabled value="">
												<em>Neither</em>
											</MenuItem>
											<MenuItem value="1">1</MenuItem>
											<MenuItem value="2">2</MenuItem>
											<MenuItem value="3">3</MenuItem>
											<MenuItem value="4">4</MenuItem>
											<MenuItem value="5">5</MenuItem>
											<MenuItem value="6">6</MenuItem>
											<MenuItem value="7">7</MenuItem>
											<MenuItem value="8">8</MenuItem>
											<MenuItem value="9">9</MenuItem>
											<MenuItem value="10">10</MenuItem>
										</MDInput>
										{errors?.priority && 
											<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
												<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.priority.message}</MDTypography>
											</MDBox>
										}
									</MDBox>
									<Card
										sx={{
											"&:hover .card-header": {
												transform: "translate3d(0, -50px, 0)",
											},
											marginTop: "2.5rem"
										}}
									>
										<MDBox
											position="relative"
											borderRadius="lg"
											mt={-3}
											mx={1}
											mb={-1}
											className="card-header"
											sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)", zIndex: 1, backgroundColor: "transparent" }}
										>
											<MDBox
												sx={{ backgroundColor: isTransparent ? "#FFF" : "", borderRadius: "8px" }}
											>
												<MDBox
													component="img"
													src={image ? image : placeholder}
													alt="Voucher Image"
													borderRadius="lg"
													shadow="sm"
													width="100%"
													height="100%"
													position="relative"
													zIndex={10}
													onError={getImageError}
												/>
											</MDBox>
										</MDBox>
										<MDBox textAlign="center" pt={4} pb={1} px={3}>
											<MDBox
												display="flex"
												justifyContent="center"
												alignItems="center"
												mt={-9}
												mb={-1}
												position="relative"
												zIndex={0}
											>
												<MDBox mr={1}>
													<input
														{...register("voucher_image", { required: "Required" })}
														type="file"
														accept="image/png;image/jpg;image/jpeg;"
														ref={voucher_imageRef}
														onChange={(e) => onImageChange(e)}
														className="d-none"
													/>
													<MDButton variant="gradient" color="info" size="small" 
														onClick={() => voucher_imageRef.current.click()}
													>
														edit
													</MDButton>
												</MDBox>
												<MDButton variant="outlined" color="dark" size="small" onClick={removeImage}>
													remove
												</MDButton>
											</MDBox>
											<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 3 }}>
												Voucher Image
											</MDTypography>
											{errors?.voucher_image && 
												<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.voucher_image.message}</MDTypography>
											}
										</MDBox>
									</Card>
								</Grid>
								<Grid item sx={{ display: "flex", justifyContent: "flex-end", width: "100%"}}>
									<MDButton color="info" onClick={() => nextStep(1)}>
										Next
									</MDButton>
								</Grid>
							</Grid>
						</MDBox>
					</TabPanelUnstyled>
					<TabPanelUnstyled style={{ backgroundColor: "#FFF" }} value={1}>
						<MDBox px={2} py={3} sx={{ border: "2px solid #000" }}>
							<Grid container spacing={3}>
								<Grid item md={8}>
									<MDBox ml={3} sx={{ marginRight: "5px"}}>
										<TextField
											{...register("customer_type", { required: "Required" })}
											id="select-category-name" 
											size="small" 
											fullWidth 
											label="Customer Type"
											type="select" 
											select
											error={errors?.customer_type}
											value={watchAllFields.customer_type}
											onChange={(e) => {
												let val = e.target.value;
												setValue("customer_type", val);
												if(val === "2"){
													setUser(null);
													setUserRows([]);
													setUserRawRows([]);
												} else if(val === "1"){
													setRegion(null);
													setRegionRows([]);
													setRegionRawRows([]);
												}
											}}
										>
											<MenuItem disabled value="">
												<em>Neither</em>
											</MenuItem>
											<MenuItem value="3">ALL USER</MenuItem>
											<MenuItem value="1">SPECIFIC USER</MenuItem>
											<MenuItem value="2">BY REGION</MenuItem>
										</TextField>
										{errors?.customer_type && 
											<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
												<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.customer_type.message}</MDTypography>
											</MDBox>
										}
									</MDBox>
								</Grid>
								{
									watchAllFields?.customer_type === "1" ?
										<MDBox width="100%" mt={3} ml={4} mr={1} sx={{ border: "2px dotted black", padding: "16px 14px"}}>
											<MDBox width="100%" pb={2} mb={1} sx={{ borderBottom: "2px solid #ededed"}}>
												<Grid item md={8}>
													<Autocomplete
														options={userOptions ? userOptions: []}
														getOptionLabel={(option) => option.full_name || option.email }
														filterOptions={filterOptions}
														size="small"
														renderInput={(params) => (
															<MDInput label="User" error={userError?.length > 0} fullWidth {...params} />
														)}
														renderOption={(props, option, { selected }) => (
															<li {...props} style={{ marginRight: "8px" }}>
																<MDBox width="100%" display="flex" flexDirection="column" justifyContent="space-evenly" mt={1}>
																	<MDTypography fontSize="12px" sx={{ fontWeight: 900, mb: "-0.4px" }}>{option.full_name}</MDTypography>
																	<MDTypography fontSize="11px" sx={{ mb: 1 }}>{option.email}</MDTypography>
																	<MDBox width="100%" sx={{ borderBottom: "1px solid #344767!important" }} mb={"-6px"}></MDBox>
																</MDBox>
															</li>
														)}
														className={userError ? "autocomplete-error" : ""}
														sx={{marginRight: "5px"}}
														onChange={(e, newValue) => {
															setUser(newValue);
														}}
														value={user ? user : null}
														onInputChange={(event, newInputValue, reason) => {
															if (reason === "clear") {
																setUser(null);
																return;
															}
														}}
													/>
													{userError?.length > 0 && 
														<MDBox mt={1} mb={-1} mr={1} display="flex" justifyContent="flex-end">
															<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{userError}</MDTypography>
														</MDBox>
													}
													<MDBox display="flex" justifyContent="flex-end" mt={2}>
														<MDButton color="info" onClick={addUser}>
															Add
														</MDButton>
													</MDBox>
												</Grid>
											</MDBox>
											<MDBox display="flex" justifyContent="flex-end" mb={1}>
												<MDButton color="info" onClick={() => deleteUser("all")}>
													Delete All
												</MDButton>
											</MDBox>
											<DataTable
												table={{ columns: userColumns, rows : userRows }}
												isSorted={false}
												entriesPerPage={false}
												showTotalEntries={true}
												pagination={{variant: "contained", color: "primary"}}
												noEndBorder
												paginationDetail={userPaginationDetail}
												gotoPage={userGotoPage}
											/>
										</MDBox>
									: watchAllFields?.customer_type === "2" ?
										<MDBox width="100%" mt={3} ml={4} mr={1} sx={{ border: "2px dotted black", padding: "16px 14px"}}>
											<MDBox width="100%" pb={2} mb={1} sx={{ borderBottom: "2px solid #ededed"}}>
												<Grid container spacing={2}>
													<Grid item md={8}>
														<Autocomplete
															options={regionOptions ? regionOptions: []}
															getOptionLabel={(option) => option.province_name}
															size="small"
															renderInput={(params) => (
																<MDInput {...registerRegion("province", { required: "Required" })} label="Province" error={errorsRegion.province} fullWidth {...params} />
															)}
															className={errorsRegion?.province ? "autocomplete-error" : ""}
															onChange={(e, newValue) => {
																setRegion(newValue);
															}}
															value={ region ? region : null}
															onInputChange={(event, newInputValue, reason) => {
																if (reason === "clear") {
																	setRegion({});
																	return
																}
															}}
														/>
														{errorsRegion?.province && 
															<MDBox mt={1} mb={0} display="flex" justifyContent="flex-end">
																<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errorsRegion.province.message}</MDTypography>
															</MDBox>
														}
													</Grid>
													<Grid item md={4}>
														<Autocomplete
															options={cityOptions ? cityOptions: []}
															getOptionLabel={(option) => option.city_name}
															size="small"
															renderInput={(params) => (
																<MDInput label="City" {...registerRegion("city", { required: "Required" })} error={errorsRegion?.city} fullWidth {...params} />
															)}
															className={errorsRegion?.city ? "autocomplete-error" : ""}
															onChange={(e, newValue) => {
																setCity(newValue);
															}}
														/>
														{errorsRegion?.city && 
															<MDBox mt={1} mb={0} display="flex" justifyContent="flex-end">
																<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errorsRegion?.city.message}</MDTypography>
															</MDBox>
														}
													</Grid>
												</Grid>
												<MDBox display="flex" justifyContent="flex-end" mt={2} mb={2} sx={{gap: "16px"}}>
													<MDButton color="info" onClick={addRegion}>
														Add
													</MDButton>
													<MDButton color="info" onClick={() => addAllCity("province")}>
														Add All city of Province
													</MDButton>
													<MDButton color="info" onClick={() => addAllCity("indonesia")}>
														Add All city in Indonesia
													</MDButton>
												</MDBox>
											</MDBox>
											<MDBox display="flex" justifyContent="flex-end" mb={1}>
												<MDButton color="info" onClick={() => deleteRegion("all")} >
													Delete All
												</MDButton>
											</MDBox>
											<DataTable
												table={{ columns: regionColumns, rows : regionRows }}
												isSorted={false}
												entriesPerPage={false}
												showTotalEntries={true}
												pagination={{variant: "contained", color: "primary"}}
												noEndBorder
												paginationDetail={regionPaginationDetail}
												gotoPage={regionGotoPage}
											/>
										</MDBox>
									: null
								}
							</Grid>
							<Grid item>
								{errorMessageTabIndex1?.length > 0 && 
									<MDBox mt={1} mb={-1} mr={1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errorMessageTabIndex1}</MDTypography>
									</MDBox>
								}
							</Grid>
							<Grid item sx={{ display: "flex", justifyContent: "space-between", margin: "16px 8px", marginBottom: "0"}}>
								<MDButton color="secondary" onClick={() => setTabIndexActive(0)}>
									Back
								</MDButton>
								<MDButton color="info" onClick={() => nextStep(2)}>
									Next
								</MDButton>
							</Grid>
						</MDBox>
					</TabPanelUnstyled>
					<TabPanelUnstyled style={{ backgroundColor: "#FFF" }} value={2}>
						<MDBox px={4} py={3} sx={{ border: "2px solid #000" }}>
							<Grid container spacing={3}>
								<Grid item md={8}>
									<TextField
										{...register("product_type", { required: "Required" })}
										id="select-category-name" 
										size="small" 
										fullWidth 
										label="Product Type"
										type="select" 
										select
										error={errors?.product_type} 
										onChange={(e) => {
											setValue("product_type", e.target.value);
											setProductRawRows([]);
										}}
										value={watchAllFields.product_type ? watchAllFields.product_type : ""}
									>
										<MenuItem disabled value="">
											<em>Neither</em>
										</MenuItem>
										<MenuItem value="0">ALL SKU</MenuItem>
										<MenuItem value="1">BRAND</MenuItem>
										<MenuItem value="2">MODEL</MenuItem>
										<MenuItem value="3">CATEGORY</MenuItem>
									</TextField>
									{errors?.product_type && 
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.product_type.message}</MDTypography>
										</MDBox>
									}
								</Grid>
								<Grid item md={8}>
									<MDInput type="text" label="Max Coupon" fullWidth {...register("max_coupon", { required: "Required", pattern: { value: /^[1-9][0-9]*$/, message: "Number only" } })} error={errors?.max_coupon} onChange={(e) => setValue("max_coupon", e.target.value)} value={watchAllFields.max_coupon} />
									{errors?.max_coupon && 
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.max_coupon.message}</MDTypography>
										</MDBox>
									}
								</Grid>
								<Grid item xs={12} sm={12} sx={{ display: "flex", justifyContent: "space-between", margin: "16px 8px", marginBottom: "0"}}>
									<MDButton color="secondary" onClick={() => setTabIndexActive(1)}>
										Back
									</MDButton>
									<MDButton color="info" onClick={() => nextStep(3)}>
										Next
									</MDButton>
								</Grid>
							</Grid>
						</MDBox>
					</TabPanelUnstyled>
					<TabPanelUnstyled style={{ backgroundColor: "#FFF" }} value={3}>
						<MDBox px={3} py={3} sx={{ border: "2px solid #000" }}>
							<MDTypography fontWeight="bold" sx={{ textDecoration: "underline" }}>
								Set Item
							</MDTypography>
							<MDBox my={3} px={1}>
								<Grid container spacing={3}>
									<Grid item md={8}>
										<MDInput type="text" disabled label="Product Type" fullWidth value={watchAllFields?.product_type === "0" ? "ALL SKU" : watchAllFields?.product_type === "1" ? "Brand" : watchAllFields?.product_type === "2" ? "Model" : watchAllFields?.product_type === "3" ? "Category" : ""} />
									</Grid>
									<Grid item md={4}>
										<MDInput type="text" disabled label="Max Coupon" fullWidth value={watchAllFields?.max_coupon} />
									</Grid>
									<Grid item md={8}>
										<Autocomplete
											disabled={watchAllFields?.product_type === "0"}
											options={ productTypeOptions ? productTypeOptions : [{name: "", id: "0"}]}
											getOptionLabel={(option) => option.name}
											size="small"
											renderInput={(params) => (
												<MDInput {...registerLogic('product_type_id', { required: "Required" })} label={watchAllFields?.product_type === "0" ? "ALL SKU" : watchAllFields?.product_type === "1" ? "Brand" : watchAllFields?.product_type === "2" ? "Model" : watchAllFields?.product_type === "3" ? "Category" : ""} error={errors?.product_type_id} fullWidth {...params} />
											)}
											onChange={async (e, newVal) => {
												setValueLogic("product_type_id", newVal);
											}}
											className={errorsLogic?.product_type_id ? "autocomplete-error" : ""}
											sx={{marginRight: "5px"}}
											defaultValue={watchAllFields?.product_type === "0" ?  {name: "", id: "0"} : productTypeOptions.filter( pto => pto.id == watchAllFieldsLogic?.product_type_id?.id)[0] ||  {name: "", id: "0"}}
										/>											
										{errorsLogic?.product_type_id && 
											<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
												<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errorsLogic.product_type_id.message}</MDTypography>
											</MDBox>}
									</Grid>
									<Grid item md={4}>
										<MDInput type="text" label="Min Order Qty" fullWidth {...registerLogic("min_qty_order", { required: "Required", pattern: { value: /^[1-9][0-9]*$/, message: "Number only" } })} value={watchAllFieldsLogic?.min_qty_order} onChange={(e) => setValueLogic("min_qty_order", e.target.value)} error={errors?.min_qty_order} />
										{errorsLogic?.min_qty_order && 
											<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
												<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errorsLogic.min_qty_order.message}</MDTypography>
											</MDBox>
										}
									</Grid>
									<Grid item md={8}>
										<Autocomplete
											options={productOptions}
											getOptionLabel={(option) => option?.product_name || ""}
											size="small"
											filterOptions={filterOptions2}
											renderInput={(params) => (
												<MDInput {...registerLogic('product', { required: "Required" })} label="Product Name" fullWidth {...params} />
											)}
											className={errorsLogic?.product ? "autocomplete-error" : ""}
											sx={{marginRight: "5px"}}
											onChange={async (e, newVal) => {
												setFormData({...formData, product: newVal});
												setValueLogic("product", newVal.product_id);
												const result = await triggerLogic("product");
											}}
											renderOption={(props, option, { selected }) => (
												<li {...props} style={{ marginRight: "8px" }}>
													<MDBox width="100%" display="flex" flexDirection="column" justifyContent="space-evenly" mt={1}>
														<MDTypography fontSize="12px" sx={{ mb: "-0.4px" }}>{option.product_name}</MDTypography>
														<MDTypography fontSize="11px" sx={{ fontWeight: 900, mb: 1 }}>{option.product_code} </MDTypography>
														<MDBox width="100%" sx={{ borderBottom: "1px solid #344767!important" }} mb={"-6px"}></MDBox>
													</MDBox>
												</li>
											)}
										/>											
										{errorsLogic?.product && 
											<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
												<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errorsLogic.product.message}</MDTypography>
											</MDBox>
										}
									</Grid>
									<Grid item md={4}>
										<MDInput InputLabelProps={{ shrink: formData?.product?.product_code }} type="text" disabled label="Product Code" fullWidth value={formData?.product?.product_code || ""} />
									</Grid>
									<Grid item xs={12} sm={12}>
										<FormControl component="fieldset">
											<FormLabel component="label" sx={{ color: "#344767!important", fontSize: "12px"}}>Logic</FormLabel>
											<RadioGroup row 
												onChange={async (e) => {
													setValueLogic("logic", e.target.value);
													const result = await triggerLogic("logic");
													setProductRawRows([]);
												}}
												value={watchAllFieldsLogic?.logic ? watchAllFieldsLogic.logic : ""}>
												<FormControlLabel
													value="1"
													control={<Radio />}
													label="AND"
												/>
												<FormControlLabel
													value="2"
													control={<Radio />}
													label="OR"
												/>
												<FormControlLabel
													value="3"
													control={<Radio />}
													label="EXCEPT"
												/>
											</RadioGroup>
											{errorsLogic?.logic && 
												<MDBox mt={-1} mb={1} mr={2} display="flex" justifyContent="flex-end">
													<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errorsLogic.logic.message}</MDTypography>
												</MDBox>
											}
										</FormControl>
										<MDBox display="flex" gap="16px">
											<MDButton color="info" onClick={() => addItemLogic("all")}>
												Add All Item
											</MDButton>
											<MDButton color="info" onClick={addItemLogic}>
												Add 1 Item
											</MDButton>
										</MDBox>
									</Grid>
									{
										watchAllFieldsLogic?.logic ?
											<Grid item xs={12} sm={12}>
												<MDBox mx={-2} sx={{ border: "2px dotted black", padding: "16px 14px"}}>
													<MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
														<MDTypography fontWeight="bold">Logic {watchAllFieldsLogic?.logic === "1" ? "AND" : watchAllFieldsLogic?.logic === "2" ? "OR" : watchAllFieldsLogic?.logic === "3" ? "EXCEPT" : ""}</MDTypography>
														<MDButton color="info" onClick={() => deleteProduct("all")}>
															Delete All
														</MDButton>
													</MDBox>
													<DataTable
														table={{ columns: watchAllFieldsLogic.logic === "3" ? productColumns.filter( pc => pc.accessor !== "min_qty_order") : productColumns, rows : productRows }}
														isSorted={false}
														entriesPerPage={false}
														showTotalEntries={true}
														pagination={{variant: "contained", color: "primary"}}
														noEndBorder
														paginationDetail={productPaginationDetail}
														gotoPage={productGotoPage}
													/>
												</MDBox>
											</Grid>
										: null
									}
									<Grid item>
										{errorMessageTabIndex3Item?.length > 0 && 
											<MDBox mt={-2} mb={-1} mr={1} display="flex" justifyContent="flex-end">
												<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errorMessageTabIndex3Item}</MDTypography>
											</MDBox>
										}
									</Grid>
								</Grid>
							</MDBox>
							<MDTypography fontWeight="bold" sx={{ textDecoration: "underline" }}>
								Set Periode
							</MDTypography>
							<MDBox mt={3} px={1}>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={12} md={8}>
										<MDInput type="number" label="Usage Coupon Per User" fullWidth 
											{...register("usage_coupon", 
												{ required: "Required",
													min: {
														value: 1,
														message: 'Minimum usage coupon is 1'
													},
													valueAsNumber: true,
												}
											)} 
											error={errors?.usage_coupon || errors?.is_above_max_coupon} 
											onChange={(e) => {
												let newVal = parseInt(e.target.value);
												if(newVal > parseInt(watchAllFields.max_coupon)){
													setError('is_above_max_coupon', { type: 'custom', message: 'Invalid usage coupon / Insufficient Max Coupon' });
												} else {
													clearErrors('is_above_max_coupon');
												}
												setValue("usage_coupon", newVal);
											}}
											value={watchAllFields?.usage_coupon}
										/>
										{errors?.usage_coupon && 
											<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
												<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.usage_coupon.message}</MDTypography>
											</MDBox>
										}
										{errors?.is_above_max_coupon && 
											<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
												<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.is_above_max_coupon.message}</MDTypography>
											</MDBox>
										}
									</Grid>
									<Grid item xs={12} sm={12} sx={{ display: "flex", justifyContent: "space-between", margin: "16px 8px", marginBottom: "0"}}>
										<MDButton color="secondary" onClick={() => setTabIndexActive(2)}>
											Back
										</MDButton>
										<MDButton color="info" onClick={() => nextStep(4)}>
											Next
										</MDButton>
									</Grid>
								</Grid>
							</MDBox>
						</MDBox>
					</TabPanelUnstyled>
					<TabPanelUnstyled style={{ backgroundColor: "#FFF" }} value={4}>
						<MDBox px={2} py={3} sx={{ border: "2px solid #000" }}>
							<Grid container spacing={3}>
								<Grid item md={8}>
									<MDBox ml={3} sx={{ marginRight: "5px"}}>
										<TextField
											{...register("impact_name", { required: "Required" })}
											id="select-category-name" 
											size="small" 
											fullWidth 
											label="Impact Name"
											type="select" 
											select
											error={errors?.impact_name} 
											onChange={(e) => {
												setValue("impact_name", e.target.value)
											}}
											value={watchAllFields.impact_name ? watchAllFields.impact_name : ""}
										>
											<MenuItem disabled value="">
												<em>Neither</em>
											</MenuItem>
											<MenuItem value="1">Cutting Price</MenuItem>
											<MenuItem value="2">Cashback Point</MenuItem>
										</TextField>
										{errors?.impact_name && 
											<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
												<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.impact_name.message}</MDTypography>
											</MDBox>
										}
									</MDBox>
								</Grid>
							</Grid>
							{
								watchAllFields?.impact_name ?
									<MDBox width="100%" mt={3} ml={0} mr={1} sx={{ border: "2px dotted black", padding: "16px 14px"}}>
										<MDTypography fontWeight="bold" sx={{ textDecoration: "underline" }}>
											{watchAllFields?.impact_name === "1" ? "Cutting Price" : "Cashback Point"}
										</MDTypography>
										<MDBox mt={2} mx={1}>
											<Grid container spacing={3}>
												<Grid item md={8}>
													<TextField
														{...register("impact_category", { required: "Required" })}
														id="select-category-name" 
														size="small" 
														fullWidth 
														label="Impact Category"
														type="select" 
														select
														error={errors?.impact_category} 
														onChange={(e) => {
															setValue("impact_category", e.target.value);
														}}
														value={watchAllFields.impact_category ? watchAllFields.impact_category : ""}
													>
														<MenuItem disabled value="">
															<em>Neither</em>
														</MenuItem>
														<MenuItem value="1">Fix Price</MenuItem>
														<MenuItem value="2">Percentage</MenuItem>
													</TextField>
													{errors?.impact_category && 
														<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
															<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.impact_category.message}</MDTypography>
														</MDBox>
													}
												</Grid>
												<Grid item md={4}>
													<MDInput label={watchAllFields?.impact_category === "1" ? "Amount" : watchAllFields?.impact_category === "2" ? "Percentage" : "Amount/Percentage"}
														fullWidth 
														value={watchAllFields.amount_percentage} 
														error={errors?.amount_percentage}
														onChange={e => {
															let val = e.target.value;
															setValue("amount_percentage", addCommas(val));
															validateAmountPercentage();
															validateMaxDiscount();
														}}
														onBlur={() => {
															validateAmountPercentage();
															validateMaxDiscount();
														}}
													/>
													{errors?.amount_percentage && 
														<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
															<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.amount_percentage.message}</MDTypography>
														</MDBox>
													}
												</Grid>
												<Grid item md={8}>
													<MDInput label="Min Trx (Rp)" fullWidth error={errors?.min_trx}
														{...register("min_trx", 
															{ required: "Required",
																pattern:{
																	value: /^([1-9]{1}[0-9]{0,2})(\.{1}\d{3})*$|^(0{1})$/,
																	message: "Format error"
																},
															}
														)} 
														onChange={(e)=>{
															setValue("min_trx", addCommas(e.target.value));
														}
													}/>
													{errors?.min_trx && 
														<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
															<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.min_trx.message}</MDTypography>
														</MDBox>
													}
												</Grid>
												<Grid item md={4}>
													<TextField
														{...register("multiple", { required: "Required" })}
														id="select-category-name" 
														size="small" 
														fullWidth 
														label="Multiple"
														type="select" 
														select
														error={errors?.multiple} 
														onChange={(e) => 
															setValue("multiple", e.target.value)
														}
														value={watchAllFields?.multiple ? watchAllFields.multiple : ""}
													>
														<MenuItem disabled value="">
															<em>Neither</em>
														</MenuItem>
														<MenuItem value="1">YES</MenuItem>
														<MenuItem value="0">NO</MenuItem>
													</TextField>
													{errors?.multiple && 
														<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
															<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.multiple.message}</MDTypography>
														</MDBox>
													}
												</Grid>
												<Grid item md={8}>
													<MDInput label="Max Discount (Rp)" fullWidth error={errors?.max_discount}
														{...register("max_discount", 
															{ required: "Required",
																pattern:{
																	value: /^([1-9]{1}[0-9]{0,2})(\.{1}\d{3})*$/,
																	message: "Format error"
																},
															}
														)} 
														onChange={(e)=>{
															setValue("max_discount", addCommas(e.target.value));
															validateMaxDiscount();
														}}
														onBlur={validateMaxDiscount}
													/>
													{errors?.max_discount && 
														<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
															<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.max_discount.message}</MDTypography>
														</MDBox>
													}
												</Grid>
											</Grid>
										</MDBox>
									</MDBox>
								: null
							}
							<MDBox display="flex" justifyContent="space-between" my={2} mx={1}>
								<MDButton color="secondary" onClick={() => setTabIndexActive(3)}>
									Back
								</MDButton>
								<MDButton color="info" type="submit" form="create-voucher">
									Save
								</MDButton>
							</MDBox>
						</MDBox>
					</TabPanelUnstyled>
				</TabsUnstyled>
			</MDBox>
		</Grid>
  );
}

export default VoucherAdd;
