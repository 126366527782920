import { useState } from "react";
import { useForm } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { MenuItem, TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDButton from "../../../../MDButton";
import MDTypography from "../../../../MDTypography";

import placeholder from "../../../../../assets/images/placeholder-image.jpeg";
import MDInput from "../../../../MDInput";
import MDSnackbar from "../../../../MDSnackbar";

import services from "../../../../../service/services";

import { getImageError } from "../../../../../utils";

const CategoryAdd = ({
	categoryInfo,
	setIsLoading,
}) => {
	const [categoryCode, setCategoryCode] = useState("");
	const [categoryImage, setCategoryImage] = useState("");
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const { register, handleSubmit, trigger, reset, watch, formState: { errors } } = useForm({mode: "all"});

	const watchAllFields = watch();
	
	const submitCreate = (e) => {
		setIsLoading(true);
		e.category_image = categoryImage;
		services.createCategory(e)
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
				reset({
					category_name: "",
					category_code: "",
					category_type: "",
					category_image: "",
					is_active: "",
				});
				setCategoryImage("");
				setCategoryCode("");
			}).catch(errors => {
				setIsLoading(false);
				setErrorMessage(errors?.response?.data?.message);
				setShowNotifError(true);
				console.log(errors)
			})
	}

  return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" justifyContent="space-between" mt={-0.5} mb={2.5} width="100%">
				<MDTypography variant="h3" fontWeight="regular">
					Add New Category
				</MDTypography>
				<MDButton color="info" type="submit" form="create-category">
					Save
				</MDButton>
			</MDBox>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="New Category Added!"
				open={showNotifSuccess}
				dateTime="Just Now"
				close={() => setShowNotifSuccess(false)}
			/>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			<MDBox component="form" role="form" id="create-category" onSubmit={handleSubmit(submitCreate)}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>
						<MDBox width="100%" mb={2}>
							<Card
								sx={{
									border: "2px solid gray",
									width: "100%",
									maxWidth: "500px",
									margin: "0 auto",
									color: "gray"
								}}
							>
								<MDBox
									position="relative"
									borderRadius="lg"
									mt={-3}
									mx={1}
									className="card-header"
									sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
								>
									<MDBox
										component="img"
										src={categoryImage ? categoryImage : placeholder}
										alt="Product Image"
										borderRadius="lg"
										shadow="sm"
										width="100%"
										height="100%"
										position="relative"
										zIndex={10}
										onError={getImageError}
									/>
								</MDBox>
								<MDBox textAlign="center" pt={4} pb={1} px={3}>
									<MDTypography variant="h5" fontWeight="regular" sx={{ mt: -4 }}>
										Category Image
									</MDTypography>
								</MDBox>
							</Card>
						</MDBox>
					</Grid>
					<Grid xs={12} md={6} item>
						<Card sx={{ padding: "24px 32px" }}>
							<MDTypography variant="h4" fontWeight="regular" sx={{ ml: -1, mb: 4}}>
								Category Information
							</MDTypography>
							<MDBox mb={2}>
								<MDInput
									{...register("category_name", { required: "Required" })}
									id="select-category-name" 
									size="small" 
									fullWidth 
									label="Category Name"
									type="select" 
									select
									error={errors?.category_name}
									value={watchAllFields?.category_name || ""}
								>
									<MenuItem disabled value="">
										<em>Neither</em>
									</MenuItem>
									{
										categoryInfo?.map((cno, i) => {
											return (
												<MenuItem 
													key={"category-name-select-list-"+i} 
													value={cno.category_name} 
													onClick={() => {
														setCategoryCode(categoryInfo[i].category_code);
														setCategoryImage(categoryInfo[i].category_image);
													}}>
													{cno.category_name}
												</MenuItem>
											)
										})
									}
								</MDInput>
								{errors?.category_name && 
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.category_name.message}</MDTypography>
									</MDBox>
								}
							</MDBox>
							{
								categoryCode &&
									<MDBox mb={2}>
										<MDInput 
											type="text" 
											label="Category Code"
											fullWidth
											value={categoryCode}
											disabled
											{...register("category_code")}
										/>
									</MDBox>
							}
							<MDBox mb={2}>
								<MDInput type="text" label="Category Type" error={errors?.category_type} fullWidth {...register("category_type", { required: "Required" })} />
								{errors?.category_type && 
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.category_type.message}</MDTypography>
									</MDBox>
								}
							</MDBox>
							<MDBox mb={2}>
								<TextField
									{...register("is_active", { required: "Required" })}
									id="select-is-active" 
									size="small" 
									fullWidth 
									label="Status"
									type="select" 
									select
									error={errors?.is_active} 
									value={watchAllFields?.is_active || ""}
								>
									<MenuItem disabled value="">
										<em>Neither</em>
									</MenuItem>
									<MenuItem value="1">Active</MenuItem>
									<MenuItem value="0">Not Active</MenuItem>
								</TextField>
								{errors?.is_active && 
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.is_active.message}</MDTypography>
									</MDBox>
								}
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		</Grid>
  );
}

export default CategoryAdd;
