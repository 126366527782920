import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';

import MDTypography from '../../../../MDTypography';
import MDInput from '../../../../MDInput';
import MDButton from '../../../../MDButton';
import MDBox from '../../../../MDBox';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  width: 'fit-content',
	maxWidth: '90vw',
  p: 3,
	borderRadius: "12px",
};

const FilterModal = ({ 
	open, 
	setOpen,
	brandOptions,
	modelOptions,
	modelTypeOptions,
	filterList,
	dataFilter,
	setDataFilter,
}) => {

	const handleClose = () => {
		setOpen(false);
	};

	const handleFilter = () => {
		handleClose();
		filterList(1);
	}

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="grid-modal" sx={style}>
					<MDBox position="relative">
						<Icon style={{ position: "absolute", right: "-12px", top: "-12px" }} className="c-pointer" onClick={handleClose} fontSize="small">close</Icon>
					</MDBox>
          <MDTypography variant="h4">
						Filter
					</MDTypography>
						<Grid container spacing={3} sx={{ width: "800px", maxWidth: "90vw", marginTop: "0", marginBottom: "24px"}}>
							<Grid item xs={12} sm={6}>
								<MDInput 
									onChange={e => setDataFilter({...dataFilter, product_name: e.target.value})}
									value={dataFilter.product_name}
									fullWidth 
									label="Product Name" 
									type="text"
									size="small" 
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<MDInput 
									onChange={e => setDataFilter({...dataFilter, product_code: e.target.value})}
									value={dataFilter.product_code}
									size="small" 
									fullWidth 
									label="Product Code" 
									type="text"
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<MDInput 
									onChange={e => setDataFilter({...dataFilter, molding_code: e.target.value})}
									value={dataFilter.molding_code}
									size="small" 
									fullWidth 
									label="Molding Code" 
									type="text"
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Autocomplete
									options={brandOptions}
									getOptionLabel={(option) => option.brand_name}
									size="small"
									renderInput={(params) => (
										<MDInput {...params} label="Brand Name" />
									)}
									onChange={(e, newValue) => setDataFilter({...dataFilter, brand_name: newValue.brand_name})}
									value={dataFilter.brand_name ? brandOptions.filter((bo) => bo.brand_name === dataFilter.brand_name)[0] : null}
									onInputChange={(event, newInputValue, reason) => {
										if (reason === 'clear') {
											setDataFilter({...dataFilter, brand_name: ""});
										}
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Autocomplete
									options={modelOptions}
									getOptionLabel={(option) => option.model_name}
									size="small"
									renderInput={(params) => (
										<MDInput {...params} label="Model Name" />
									)}
									onChange={(e, newValue) => setDataFilter({...dataFilter, model_name: newValue?.model_name ? newValue?.model_name : ""})}
									value={dataFilter.model_name ? modelOptions.filter((mo) => mo.model_name === dataFilter.model_name)[0] : null}
									onInputChange={(event, newInputValue, reason) => {
										if (reason === 'clear') {
											setDataFilter({...dataFilter, model_name: ""});
										}
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Autocomplete
									options={modelTypeOptions}
									getOptionLabel={(option) => option.model_type_name}
									size="small"
									renderInput={(params) => (
										<MDInput {...params} label="Model Type" />
									)}
									onChange={(e, newValue) => setDataFilter({...dataFilter, model_type_name: newValue.model_type_name})}
									value={dataFilter.model_type_name ? modelTypeOptions.filter((mto) => mto.model_type_name === dataFilter.model_type_name)[0] : null}
									onInputChange={(event, newInputValue, reason) => {
										if (reason === 'clear') {
											setDataFilter({...dataFilter, model_type_name: ""});
										}
									}}
								/>
							</Grid>
						</Grid>
					<MDBox display="flex" alignItems="center" justifyContent="end">
						<MDButton onClick={handleFilter} variant="gradient" color="info">Filter</MDButton>
					</MDBox>
        </Box>
      </Modal>
    </div>
  );
}

export default FilterModal;