import { useEffect, useState } from "react";
import axios from "axios";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import MDSnackbar from "../../../../components/MDSnackbar";

import ItemProductsIndex from "../../../../components/AppComponents/Autoloka/product/itemProducts";
import ItemProductAdd from "../../../../components/AppComponents/Autoloka/product/itemProducts/add";
import ItemProductEdit from "../../../../components/AppComponents/Autoloka/product/itemProducts/edit";
import DeleteConfirmationModal from "../../../../components/modals/deleteConfirmation";

import services from "../../../../service/services";
import { useSearchParams } from "react-router-dom";
import { cleanObject } from "../../../../utils";
import useFetchState from "../../../../hooks/useFetchState";

const ItemProducts = ({
	setIsLoading,
	setMode,
	mode,
}) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [rows, setRows] = useState([]);
	const [rowsDisplayed, setRowsDisplayed] = useState([]);
	const [rawRowsDisplayed, setRawRowsDisplayed] = useState([]);
	const [paginationDetail, setPaginationDetail] = useState({});
	const [brandOptions, setBrandOptions] = useState([]);
	const [modelOptions, setModelOptions] = useState([]);
	const [modelTypeOptions, setModelTypeOptions] = useState([]);
	const [categoryOptions, setCategoryOptions] = useState([]);
	const [subCategoryOptions, setSubCategoryOptions] = useState([]);
	const [styleOptions, setStyleOptions] = useState([]);
	const [attributeOptions, setAttributeOptions] = useState([]);
	const [subAttributeOptions, setSubAttributeOptions] = useState([]);
	const [idDetail, setIdDetail] = useState(null);
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [isFiltered, setIsFiltered] = useState(false);
	const [id, setId] = useState(null);
	const [confirmationDisplay, setConfirmationDisplay] = useState(false);
	const [dataFilter, setDataFilter] = useState({
		product_name: searchParams.get("product_name") || "",
		product_code: searchParams.get("product_code") || "",
		molding_code: searchParams.get("molding_code") || "",
		brand_name: searchParams.get("brand_name") || "",
		model_name: searchParams.get("model_name") || "",
		model_type: searchParams.get("model_type") || "",
		is_active: searchParams.get("is_active") || "",
	});

	const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Edit", accessor: "edit", align: "left" },
		{ Header: "Product Name", accessor: "product_name", align: "left" },
		{ Header: "Product Code", accessor: "product_code", align: "left" },
		{ Header: "Molding Code", accessor: "molding_code", align: "left" },
		{ Header: "Model Name", accessor: "model_name", align: "left" },
		// { Header: "Model Year", accessor: "model_year", align: "left" },
		{ Header: "Model Type", accessor: "model_type", align: "left" },
		{ Header: "Subcategory", accessor: "subcategory", align: "left" },
		{ Header: "Subattribute", accessor: "subattribute", align: "left" },
		{ Header: "Status", accessor: "status", align: "left" },
	];

	useEffect(() => {
		

		let productsFetcher;
		if (Object.keys(cleanObject(dataFilter)).length !== 0) {
			productsFetcher = services.filterItemProductsList({ ...dataFilter, page: searchParams.get("page") || 1 })
		} else {
			productsFetcher = services.getProductItemProducts(searchParams.get("page") || 1)
		}

		if (mode === "index") {
			setIsLoading(true);
			Promise
				.all([
					productsFetcher,
					services.getOptionsBrands(),
					services.getOptionsModels(),
					services.getOptionsModelTypes(),
					services.getOptionsCategories(),
					services.getOptionsSubcategories(),
					services.getOptionsStyles(),
					services.getOptionsAttributes(),
					services.getOptionsSubattributes(),
				]).then(axios.spread((...res) => {
					setIsLoading(false);
					setPaginationDetail(res[0]?.data?.data);
					setRows(settingRowsDataTable(res[0]?.data?.data?.list, res[0]?.data?.data?.current_page));
					setRawRowsDisplayed(res[0]?.data?.data?.list);
					setBrandOptions(res[1]?.data?.data);
					setModelOptions(res[2]?.data?.data);
					setModelTypeOptions(res[3]?.data?.data);
					setCategoryOptions(res[4]?.data?.data);
					setSubCategoryOptions(res[5]?.data?.data);
					setStyleOptions(res[6]?.data?.data);
					setAttributeOptions(res[7]?.data?.data);
					setSubAttributeOptions(res[8]?.data?.data);
				})).catch(errors => {
					setIsLoading(false);
					console.log("Error is found, apologies.")
					console.log(errors)
				})
		}
	}, [mode]);

	const deleteProduct = (id) => {
		setIsLoading(true);
		setShowNotifSuccess(false);
		services.deleteItemProducts(id)
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
				setConfirmationDisplay(false);
				getList(1);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors);
			})
	}

	const getList = (page) => {
		setIsLoading(true)
		services.getProductItemProducts(page)
			.then(res => {
				setIsLoading(false);
				setIsFiltered(false);
				setRows(settingRowsDataTable(res?.data?.data?.list, res?.data?.data?.current_page));
				setRawRowsDisplayed(res?.data?.data?.list);
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	};

	const settingRowsDataTable = (rowsArray, current_page) => {
		return rowsArray?.map((arr, i) => {
			return {
				no: ((current_page - 1) * 10) + i + 1,
				edit: (
					<>
						<Icon style={{ cursor: "pointer" }} fontSize="small" onClick={() => { setMode("edit"); setIdDetail(arr.id) }}>edit</Icon>
						<Icon style={{ cursor: "pointer" }} fontSize="small" onClick={() => { setId(arr.id); setConfirmationDisplay(true); }}>delete</Icon>
					</>
				),
				product_name: arr?.name,
				product_code: arr?.code1,
				molding_code: arr?.molding_code,
				model_name: arr?.model_name,
				model_year: arr?.model_year,
				model_type: arr?.model_type_name,
				subcategory: arr?.subcategory_name,
				subattribute: arr?.subattribute_name,
				status: arr?.is_active == "0" || arr?.status == "0" ? "Not Active" : arr?.is_active == "1" || arr?.status == "1" ? "Active" : ""
			};
		});
	};

	const getUom = useFetchState();

	useEffect(() => {
		getUom.fetchStart();
		services.getItemProductUom().then((res) => {
			getUom.fetchSuccess(res.data?.data || []);
		}).catch((err) => {
			getUom.fetchFailed(err?.message || 'Ooops something went wrong!');
		});
	}, []);

	return (
		<>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="Product Deleted!"
				dateTime="Just Now"
				open={showNotifSuccess}
				close={() => setShowNotifSuccess(false)}
			/>
			<Grid container spacing={6} className="grid-pure-container">
				{
					mode === "index" ?
						<ItemProductsIndex
							setDataFilter={setDataFilter}
							dataFilter={dataFilter}
							columns={columns}
							rows={rows}
							rowsDisplayed={rowsDisplayed}
							setRowsDisplayed={setRowsDisplayed}
							setRawRowsDisplayed={setRawRowsDisplayed}
							setIsLoading={setIsLoading}
							brandOptions={brandOptions}
							modelOptions={modelOptions}
							modelTypeOptions={modelTypeOptions}
							setMode={setMode}
							paginationDetail={paginationDetail}
							setPaginationDetail={setPaginationDetail}
							getList={getList}
							isFiltered={isFiltered}
							setIsFiltered={setIsFiltered}
							settingRowsDataTable={settingRowsDataTable}
						/>
						: mode === "add" ?
							<ItemProductAdd
								setIsLoading={setIsLoading}
								brandOptions={brandOptions}
								modelOptions={modelOptions}
								modelTypeOptions={modelTypeOptions}
								categoryOptions={categoryOptions}
								subCategoryOptions={subCategoryOptions}
								styleOptions={styleOptions}
								attributeOptions={attributeOptions}
								subAttributeOptions={subAttributeOptions}
							/>
							: mode === "edit" ?
								<ItemProductEdit
									setMode={setMode}
									setIsLoading={setIsLoading}
									getUom={getUom}
									idDetail={idDetail}
									brandOptions={brandOptions}
									modelOptions={modelOptions}
									modelTypeOptions={modelTypeOptions}
									categoryOptions={categoryOptions}
									subCategoryOptions={subCategoryOptions}
									styleOptions={styleOptions}
									attributeOptions={attributeOptions}
									subAttributeOptions={subAttributeOptions}
								/>
								: null
				}
			</Grid>
			<DeleteConfirmationModal open={confirmationDisplay} setOpen={setConfirmationDisplay} itemName="product" deleteHandler={() => deleteProduct(id)} />
		</>
	);
}

export default ItemProducts;
