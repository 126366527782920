import React, { useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Icon from "@mui/material/Icon";
import Modal from '@mui/material/Modal';

import MDTypography from '../../../../MDTypography';
import MDBox from '../../../../MDBox';
import DataTable from '../../../../Table';
import MDSnackbar from '../../../../MDSnackbar';

import services from '../../../../../service/services';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  width: 'fit-content',
	maxWidth: '90vw',
  p: 3,
	borderRadius: "12px",
};

const LocatorModal = ({ 
	open, 
	setOpen,
	setIsLoading,
	product,
	setProduct,
	setErrorMessage,
	setShowNotifError,
	selectLocator,
}) => {

	const [locatorRows, setLocatorRows] = useState([]);
	const [locatorRawRows, setLocatorRawRows] = useState([]);
	const [locatorPaginationDetail, setLocatorPaginationDetail] = useState({
		current_page: 1,
		total_page: 1,
		total_data: 0,
	});

	const locatorColumns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Product Name", accessor: "product_name", align: "left" },
		{ Header: "Stock Qty", accessor: "stock_qty", align: "left" },
		{ Header: "Locator", accessor: "product", align: "left" },
		{ Header: "Action", accessor: "action", align: "left" },
	];

	const settingLocatorRows = (data) => {
		return data?.map((arr, i) => {
			return {
				no: i + 1,
				product_name: arr?.product_name,
				stock_qty: arr?.stock_qty,
				product: arr?.area_name,
				action:
					<MDTypography onClick={() => { setProduct({...product, area_id: arr?.area_id, area_name: arr?.area_name}); selectLocator(arr, arr?.stock_qty); handleClose();}} sx={{ textDecoration: "underline", cursor: "pointer" }}>Choose this one</MDTypography>,
			};
		});
	};

	useEffect(() => {
		if(product?.product_id){
			setIsLoading(true)
			services.getProductLocator(product.product_id)
				.then(res => {
					setIsLoading(false);
					let data = res?.data?.data;
					setLocatorRawRows(data);
					setLocatorRows(settingLocatorRows(data));
					setLocatorPaginationDetail({...locatorPaginationDetail, total_data: data.length});
				}).catch(errors => {
					setIsLoading(false);
					setErrorMessage(errors?.response?.data?.message);
					setShowNotifError(true);
				})
		}
	}, [product.product_id])

	const handleClose = () => {
		setOpen(false);
	};

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="grid-modal" sx={style}>
					<MDBox position="relative">
						<Icon style={{ position: "absolute", right: "-12px", top: "-12px" }} className="c-pointer" onClick={handleClose} fontSize="small">close</Icon>
					</MDBox>
          <MDTypography variant="h4">
						Locator
					</MDTypography>
					<DataTable
						table={{ columns: locatorColumns, rows : locatorRows }}
						isSorted={false}
						entriesPerPage={false}
						showTotalEntries={true}
						pagination={{variant: "contained", color: "primary"}}
						noEndBorder
						paginationDetail={locatorPaginationDetail}
						gotoPage={(e) => e.preventDefault()}
					/>
        </Box>
      </Modal>
    </div>
  );
}

export default LocatorModal;