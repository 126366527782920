import { useState } from "react";
import { useForm } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDInput from "../../../../MDInput";
import MDButton from "../../../../MDButton";
import MDTypography from "../../../../MDTypography";
import MDSnackbar from "../../../../MDSnackbar";

import services from "../../../../../service/services";
import { MenuItem } from "@mui/material";
import DataTable from "../../../../Table";
import AddTransferModal from "./addTransferModal";


const DocInventoryAdd = ({
	setIsLoading,
	locatorColumns,
	locatorRows,
	setLocatorRows,
	locatorPaginationDetail,
	goToPageLocator,
	productOptions,
	locatorOptions,
	setLocatorOptions,
	settingLocatorRows,
	setLocatorPaginationDetail,
}) => {

	const [errorLocatorProducts, setErrorLocatorProducts] = useState("");
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [displayAddTransfer, setDisplayAddTransfer] = useState(false);

	const { register, handleSubmit, watch, reset, formState: { errors } } = useForm({mode: "all"});

	const watchAllFields = watch();
	
	const submitCreate = (e) => {
		if(locatorPaginationDetail.list.length > 0){
			setErrorLocatorProducts("");
			const data ={
				type_stock_opname: e.doc_type ,
				note: e.note,
				product: locatorPaginationDetail.list.map((lpd) => {
					return {
						product_id: lpd?.product?.product_id,
						qty_transfer: lpd?.locator_to_qty_transfer,
						locator_from: [
							{
								area_from: lpd?.locator_from_id,
								qty_from_before: lpd?.locator_from_qty_before,
								qty_from_after: lpd?.locator_from_qty_after,
							}
						],
						locator_to: [
							{
								area_to: lpd?.locator_to_id,
								qty_to_before: lpd?.locator_to_qty_before,
								qty_to_after: lpd?.locator_to_qty_after,
							}
						]
					}
				})
			};

			services.createDocInv(data)
				.then(res => {
					setIsLoading(false);
					setShowNotifSuccess(true);
					setLocatorRows([]);
					setLocatorPaginationDetail({ list: []});
					reset({});
				}).catch(errors => {
					setIsLoading(false);
					setErrorMessage(errors?.response?.data?.message);
					setShowNotifError(true);
				});
		} else {
			setErrorLocatorProducts("Please include product locator move information in table");
		}
	}

  return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" justifyContent="space-between" mt={-0.5} mb={2.5} width="100%">
				<MDTypography variant="h3" fontWeight="regular">
					Create Inventory Transfer
				</MDTypography>
				<MDSnackbar
					color="success"
					icon="success"
					title="Autoloka"
					content={"Inventory Transfer Doc Created!"}
					open={showNotifSuccess}
					dateTime="Just Now"
					close={() => setShowNotifSuccess(false)}
				/>
				<MDSnackbar
					color="error"
					icon="error"
					title="Autoloka"
					content={errorMessage}
					open={showNotifError}
					dateTime="Just Now"
					close={() => setShowNotifError(false)}
				/>
			</MDBox>
			<MDBox width="100%" maxWidth="1200px" mb={2} component="form" role="form" id="create-transfer" onSubmit={handleSubmit(submitCreate)}>
				<Card
					sx={{
						width: "100%",
						margin: "0 auto",
						padding: "24px"
					}}
				>
					<Grid container spacing={3} maxWidth={"500px"}>
						<Grid item xs={12} md={12} xl={8}>
						<MDInput
								{...register("doc_type", { required: "Required" })}
								id="select-is-active" 
								size="small" 
								fullWidth 
								label="Doc Type"
								type="select" 
								select
								error={errors?.doc_type}
								value={watchAllFields?.doc_type || ""}
							>
								<MenuItem disabled value="">
									<em>Neither</em>
								</MenuItem>
								<MenuItem value="INTERNAL MOVE">INTERNAL MOVE</MenuItem>
							</MDInput>
							{errors?.doc_type && 
								<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
									<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.doc_type.message}</MDTypography>
								</MDBox>
							}
						</Grid>
						<Grid item xs={12} md={12} xl={8}>
							<MDInput type="text" label="Note" {...register("note", { required: "Required" })} error={errors?.note} fullWidth multiline rows={5} value={watchAllFields?.note || ""} />
							{errors?.note && 
								<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
									<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.note.message}</MDTypography>
								</MDBox>
							}
						</Grid>
					</Grid>
					<MDBox pt={3} mt={3} sx={{ borderTop: "2px dashed #000"}}>
						<MDBox width="100%" display="flex" justifyContent="flex-end" my={2}>
							<MDButton color="info" onClick={() => setDisplayAddTransfer(true)}>
								ADD
							</MDButton>
						</MDBox>
						<DataTable
							table={{ columns: locatorColumns, rows : locatorRows }}
							isSorted={false}
							entriesPerPage={false}
							showTotalEntries={true}
							pagination={{variant: "contained", color: "primary"}}
							noEndBorder
							paginationDetail={locatorPaginationDetail}
							gotoPage={goToPageLocator}
						/>
					</MDBox>
					{errorLocatorProducts?.length > 0 && 
						<MDBox my={2} mb={-1} display="flex" justifyContent="flex-end">
							<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errorLocatorProducts}</MDTypography>
						</MDBox>
					}
					<MDBox width="100%" display="flex" justifyContent="flex-end" mt={2}>
						<MDButton color="info" type="submit" form="create-transfer">
							Submit
						</MDButton>
					</MDBox>
				</Card>
			</MDBox>
			<AddTransferModal setIsLoading={setIsLoading} setShowNotifError={setShowNotifError} setErrorMessage={setErrorMessage} open={displayAddTransfer} setOpen={setDisplayAddTransfer} locatorOptions={locatorOptions} setLocatorOptions={setLocatorOptions} productOptions={productOptions} locatorPaginationDetail={locatorPaginationDetail} settingLocatorRows={settingLocatorRows} setLocatorRows={setLocatorRows} setLocatorPaginationDetail={setLocatorPaginationDetail} />
		</Grid>
  );
}

export default DocInventoryAdd;
