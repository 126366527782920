import { useEffect, useState, forwardRef } from "react";
import moment from "moment";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import InputAdornment from '@mui/material/InputAdornment';

import DataTable from "../../../../Table";
import MDInput from "../../../../MDInput";
import MDBox from "../../../../MDBox";
import MDButton from "../../../../MDButton";

import FilterModal from "./filterModal";

import breakpoints from "../../../../../assets/theme/base/breakpoints";

import services from "../../../../../service/services";
import { useSearchParams } from "react-router-dom";
import { cleanObject } from "../../../../../utils";

// Material Dashboard 2 React components

const UserIndex = forwardRef(({
	dataFilter,
	setDataFilter,
	columns, 
	rows,
	setIsLoading,
	setMode,
	rowsDisplayed,
	isFiltered,
	setIsFiltered,
	settingRowsDataTable,
	paginationDetail,
	setPaginationDetail,
	setRowsDisplayed,
	getList,
},ref) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [query, setQuery] = useState(searchParams.get("query") || "");
	const [querySearched, setQuerySearched] = useState(searchParams.get("query") || "");
	const [openFilter, setOpenFilter] = useState(false);

	const handleSearchChange = (e) => {
		setQuery(e.target.value);
	};

	const searchList = (e) => {
		if(e.keyCode === 13 && query.length >= 3){
			setSearchParams({
				page: 1,
				query,
			})
			setQuerySearched(query);
		}
	};
	
	useEffect(() => {
		if(query.length === 0 && rows?.length > 0){
			setRowsDisplayed(rows);
		}
	},[query, rows]);

	useEffect(() => {
		if(querySearched.length > 0){
			searchInput(searchParams.get("page") || 1)
		}
	}, [querySearched]);

	const searchInput = (page) => {
		setIsLoading(true);
		setIsFiltered(false);
		services.getSearchUser(querySearched, page)
			.then(res => {
				setIsLoading(false);
				setIsFiltered(false);
				setRowsDisplayed(settingRowsDataTable(res?.data?.data?.list, res?.data?.data?.current_page));
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			});
	};

	const filterList = (page) => {
		setIsLoading(true);
		setIsFiltered(true);
		let data = {...dataFilter, page: page};
		setSearchParams(cleanObject({ ...dataFilter, page }));
		services.getFilterUser(data)
			.then(res => {
				setIsLoading(false);
				setRowsDisplayed(settingRowsDataTable(res?.data?.data?.list, res?.data?.data?.current_page));
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	};

	const gotoPage = (page) => {
		setSearchParams({
			...(searchParams.get("query") ? { query: searchParams.get("query") || ""} : {}),
			page,
		})
		if(isFiltered){
			filterList(page);
		} else if(querySearched.length > 0){
			searchInput(page);
		} else {
			getList(page);
		}
	};

	const removeQuery = () => {
		setQuery("");
		setQuerySearched("");
		setSearchParams({ page: 1, query: '' });
	  }

  return (
		<Grid item xs={12}>
			<MDBox sx={ window.innerWidth > breakpoints.values.md ? {display: "flex", padding: "12px 48px", alignItems: "center" } : {display: "flex", flexWrap: "wrap", padding: "12px 24px", justifyContent: "center", alignItems: "center"}} bgColor="#E5ECF0" borderRadius="6px" marginBottom="12px">
				<MDInput 
					variant="outlined" 
					label="Name Or Email Or Phone Number"
					className="search-input" 
					InputProps={{
            endAdornment: <InputAdornment position="end"><Icon className="c-pointer" onClick={removeQuery} fontSize="small">close</Icon><Icon fontSize="small">search</Icon></InputAdornment>,
          }}
					backgroundColor="#FFF"
					style={{ height:"100%", borderRadius:"6px", lineHeight:"22px" }}
					fullWidth
					onChange={handleSearchChange}
					onKeyDown={searchList}
					value={query}
				/>
				<MDBox sx={window.innerWidth > breakpoints.values.md ? {marginLeft: "24px"} : {marginTop: "8px", flexWrap: "wrap", alignItems: "center", gap: "8px", justifyContent: "center"}} display="flex">
					<MDButton variant="gradient" color="info" style={window.innerWidth > breakpoints.values.md ? {marginRight: "12px"} : {}} onClick={() => setOpenFilter(true)}>
						<Icon fontSize="small" style={{marginRight: "8px"}}>filter_list</Icon> Filter
					</MDButton>
				</MDBox>
			</MDBox>
			<Card ref={ref}>
				<DataTable
					table={{ columns, rows : rowsDisplayed }}
					isSorted={false}
					entriesPerPage={false}
					showTotalEntries={true}
					pagination={{variant: "contained", color: "primary"}}
					noEndBorder
					paginationDetail={paginationDetail}
					gotoPage={gotoPage}
				/>
			</Card>
			<FilterModal
				open={openFilter} 
				dataFilter={dataFilter}
				setDataFilter={setDataFilter}
				setOpen={setOpenFilter}
				filterList={filterList}
			/>
		</Grid>
  );
})

export default UserIndex;
