import { useEffect, useState } from "react";
import axios from "axios";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import PrintBarcodeIndex from "../../../../components/AppComponents/Autoloka/print/barcode";

import services from "../../../../service/services";
import CardBarcode from "../../../../components/AppComponents/Autoloka/print/barcode/card-barcode";
import { useSearchParams } from "react-router-dom";
import { cleanObject } from "../../../../utils";

const PrintBarcode = ({
	setIsLoading,
	setMode,
	mode,
 }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [rows, setRows] = useState([]);
	const [rowsDisplayed, setRowsDisplayed] = useState([]);
	const [rawRowsDisplayed, setRawRowsDisplayed] = useState([]);
	const [paginationDetail, setPaginationDetail] = useState({});
	const [brandOptions, setBrandOptions] = useState([]);
	const [modelOptions, setModelOptions] = useState([]);
	const [modelTypeOptions, setModelTypeOptions] = useState([]);
	const [categoryOptions, setCategoryOptions] = useState([]);
	const [subCategoryOptions, setSubCategoryOptions] = useState([]);
	const [styleOptions, setStyleOptions] = useState([]);
	const [attributeOptions, setAttributeOptions] = useState([]);
	const [subAttributeOptions, setSubAttributeOptions] = useState([]);
	const [idDetail, setIdDetail] = useState(null);
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [isFiltered, setIsFiltered] = useState(false);
	const [id, setId] = useState(null);
    const [printItems, setPrintItems] = useState([]);
	const [dataFilter, setDataFilter] = useState({
		product_name: searchParams.get("product_name") || "",
		product_code: searchParams.get("product_code") || "",
		molding_code: searchParams.get("molding_code") || "",
		brand_name: searchParams.get("brand_name") || "",
		model_name: searchParams.get("model_name") || "",
		model_type: searchParams.get("model_type") || "",
		is_active: searchParams.get("is_active") || "",
	});

  const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Product Name", accessor: "product_name", align: "left" },
		{ Header: "Product Code", accessor: "product_code", align: "left" },
		{ Header: "Molding Code", accessor: "molding_code", align: "left" },
		{ Header: "Model Name", accessor: "model_name", align: "left" },
		{ Header: "Action", accessor: "action", align: "left" },
	];

	useEffect(() => {

		let fetcher;
		if(Object.keys(cleanObject(dataFilter)).length !== 0) {
			fetcher = services.filterItemProductsList({...dataFilter, page: searchParams.get("page") || 1})
		}else {
			fetcher = services.getProductItemProducts(searchParams.get("page") || 1)
		}

		if(mode === "index"){
			setIsLoading(true);
			Promise
				.all([
					fetcher,
					services.getOptionsBrands(),
					services.getOptionsModels(),
					services.getOptionsModelTypes(),
					services.getOptionsCategories(),
					services.getOptionsSubcategories(),
					services.getOptionsStyles(),
					services.getOptionsAttributes(),
					services.getOptionsSubattributes(),
				]).then(axios.spread((...res) => {
					setIsLoading(false);
					setPaginationDetail(res[0]?.data?.data);
					setRows(settingRowsDataTable(res[0]?.data?.data?.list, res[0]?.data?.data?.current_page));
					setRawRowsDisplayed(res[0]?.data?.data?.list);
					setBrandOptions(res[1]?.data?.data);
					setModelOptions(res[2]?.data?.data);
					setModelTypeOptions(res[3]?.data?.data);
					setCategoryOptions(res[4]?.data?.data);
					setSubCategoryOptions(res[5]?.data?.data);
					setStyleOptions(res[6]?.data?.data);
					setAttributeOptions(res[7]?.data?.data);
					setSubAttributeOptions(res[8]?.data?.data);
				})).catch(errors => {
					setIsLoading(false);
					console.log("Error is found, apologies.")
					console.log(errors)
				})
		}
	}, [mode]);

	const getList = (page) => {
		setIsLoading(true)
		services.getProductItemProducts(page)
			.then(res => {
				setIsLoading(false);
				setIsFiltered(false);
				setRows(settingRowsDataTable(res?.data?.data?.list, res?.data?.data?.current_page));
				setRawRowsDisplayed(res?.data?.data?.list);
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	};

    const addPrintItem = (item) => {
        setPrintItems((prev) => {
            if(prev.find((el) => el.id === item.id)) return prev;
            return [...prev, {qty: 0, ...item}];
        });
    }

    const removePrintItem = (item) => {
        setPrintItems((prev) => [...prev].filter((el) => el.id !== item.id));
    }

	const settingRowsDataTable = (rowsArray, current_page) => {
		return rowsArray?.map((arr, i) => {
			return {
				no: ((current_page - 1) * 10) + i + 1,
				product_name: arr?.name,
				product_code: arr?.code1,
				molding_code: arr?.molding_code,
				model_name: arr?.model_name,
                action: <button className="add-to-print" onClick={() => addPrintItem(arr)}>
                    Add to print
                </button>
			};
		});
	};

  return (
    <>
        <Grid container spacing={6} className="grid-pure-container">
            <PrintBarcodeIndex 
			setDataFilter={setDataFilter}
			dataFilter={dataFilter}
                setPrintItems={setPrintItems}
                removePrintItem={removePrintItem}
                printItems={printItems}
                columns={columns} 
                rows={rows}
                rowsDisplayed={rowsDisplayed}
                setRowsDisplayed={setRowsDisplayed} 
                setRawRowsDisplayed={setRawRowsDisplayed}
                setIsLoading={setIsLoading}
                brandOptions={brandOptions}
                modelOptions={modelOptions}
                modelTypeOptions={modelTypeOptions}
                setMode={setMode}
                paginationDetail={paginationDetail}
                setPaginationDetail={setPaginationDetail}
                getList={getList}
                isFiltered={isFiltered}
                setIsFiltered={setIsFiltered}
                settingRowsDataTable={settingRowsDataTable}
            />
        </Grid>
    </>
  );
}

export default PrintBarcode;
