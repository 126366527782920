
import { Button } from '@mui/material';
import { useEffect } from 'react';
import PlaceholderImage from '../../../assets/images/placeholder-image.jpeg';
import useFetchState from '../../../hooks/useFetchState';
import services from '../../../service/services';
import { addCommas } from '../../../utils';
import MDBox from '../../MDBox';

const Products = ({
    subCategory,
    setStep,
    type,
    setProductPaginationDetail,
    modalClose
}) => {

    const getProducts = useFetchState();

    useEffect(() => {
        if (subCategory) {
            getProducts.fetchStart();
            services.getProductsSubcategory(type?.model_id, 0, 0, subCategory.subcategory_id).then((res) => {
                getProducts.fetchSuccess(res?.data?.data?.product_list || []);
            }).catch((err) => {
                getProducts.fetchFailed(err?.message || 'Ooops something went wrong!');
            })
        }
    }, [subCategory]);

    const productClick = (product) => {
        const newData = {
            product,
            product_name: product.product_name,
            code: product.product_code,
            price: (product.product_price)
        }
        setProductPaginationDetail((prev) => {
            console.log(prev);
            return ({
                ...prev,
                total_data: prev.list.length + 1,
                list: [...prev.list, newData],
            })
        });
        modalClose();
    }

    return (
        <>
            <MDBox style={{ width: '90vw' }} >
                <Button onClick={() => setStep((prev) => prev - 1)} variant="text">Back</Button>
            </MDBox>
            <div style={{ maxHeight: '80vh', overflowY: 'auto' }} className="">
                {getProducts.loading && <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1rem' }}>Get Products...</p>}
                {getProducts.data?.length === 0 && <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1rem' }}>Empty product</p>}
                {getProducts.data?.map((products, i) => (
                    <div className="" key={i}>
                        <p style={{ fontSize: '1rem' }}>{products?.name}</p>
                        <div className="" style={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gap: '1rem' }}>
                            {products?.list?.map((product) => (
                                <button key={product.product_id} onClick={() => productClick(product)} className="card-product">
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <div className="image">
                                            <img
                                                alt={product?.product_name}
                                                src={
                                                    (product?.product_images ? product?.product_images[0] : undefined) ??
                                                    PlaceholderImage
                                                }
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; // prevents looping
                                                    currentTarget.src = PlaceholderImage;
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="body">
                                        <p className="text-primary text-start m-0">
                                            {product?.product_code || product?.product_category}
                                        </p>
                                        <p
                                            style={{
                                                textAlign: 'start',
                                                textOverflow: 'ellipsis',
                                                WebkitLineClamp: 3,
                                                lineClamp: 3,
                                                textTransform: 'capitalize',
                                                fontSize: '0.8rem',
                                                fontWeight: 'bold'
                                            }} >
                                            {product?.product_name?.toLocaleLowerCase()}
                                        </p>

                                        <div className="flex-1 flex flex-col justify-end">
                                            <p style={{ textTransform: 'capitalize', fontSize: '0.6rem', margin: 0 }}>
                                                harga eceran tertinggi
                                            </p>
                                            <p style={{ fontSize: '0.8rem', fontWeight: 'bold', margin: 0, textAlign: 'start' }}>Rp. {product?.product_price}</p>
                                        </div>
                                    </div>
                                </button>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default Products;