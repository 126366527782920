import { useEffect, useMemo, useRef, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import { Autocomplete, Card, Icon, TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDButton from "../../../../MDButton";
import MDSnackbar from "../../../../MDSnackbar";
import MDTypography from "../../../../MDTypography";

import services from "../../../../../service/services";

import DataTable from "../../../../Table";
import AddPO from "./addSO";
import { addCommas } from "../../../../../utils";
import MDInput from "../../../../MDInput";

const DeliveryOrderAdd = ({
	setIsLoading,
	setMode,
	requester,
}) => {

	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifSuccessDraft, setShowNotifSuccessDraft] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [errorProduct, setErrorProduct] = useState("");
	const [productRows, setProductRows] = useState([]);
	const [productPaginationDetail, setProductPaginationDetail] = useState({
		current_page: 1,
		total_data: 0,
		total_page: 0,
		list: [],
	});
	const [headers, setHeaders] = useState({});
	const [requestFromID, setRequestFromID] = useState(null);
	const [displayAddPO, setDisplayAddPO] = useState(false);

	const { register, trigger, control, reset, watch, formState: { errors }, getValues } = useForm({ mode: "all" });

	const watchAllFields = watch();

	const { fields, update, remove, replace } = useFieldArray({
		control,
		shouldUnregister: false,
		name: "qty",
	});

	const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Sales Order No", accessor: "so_no", align: "left" },
		{ Header: "Product Name", accessor: "product_name", align: "left" },
		{ Header: "Code", accessor: "code", align: "left" },
		{ Header: "Price", accessor: "price", align: "left" },
		{ Header: "Qty", accessor: "qty", align: "left" },
		{ Header: "Delete", accessor: "delete", align: "left" },
	];

	// useEffect(() => {
	// 	setIsLoading(true);
	// 	services.detailProductSODO()
	// 		.then(res => {
	// 			setIsLoading(false);
	// 			setHeaders(res?.data?.data);
	// 		}).catch(errors => {
	// 			setIsLoading(false);
	// 			setShowNotifError(true)
	// 			setErrorMessage(errors?.response?.data?.message);
	// 		});
	// }, []);

	useEffect(() => {
		(async () => {
			const result = await trigger("qty");
			let listAll = settingRows();
			listAll = listAll?.filter((arr, i) => (((productPaginationDetail.current_page - 1) * 10) <= i && (productPaginationDetail.current_page * 10) > i));
			setProductRows(listAll);
		})();
	}, [watchAllFields.qty])

	const settingRows = () => {
		let listAll = productPaginationDetail.list.map((arr, j) => {
			return {
				no: j + 1,
				so_no: arr?.so_no,
				product_name: arr?.product_name,
				code: arr?.code,
				price: "Rp" + addCommas(arr?.price),
				qty: <>
					<TextField className="without-label-input" style={{ width: "48px" }} type="number" disabled inputProps={{ inputMode: 'numeric', pattern: '[1-9]*' }} {...register(`qty.${j}`, { required: 'Required' })} value={watchAllFields.qty[j] || ""}
						onChange={(e) => {
							let newVal = e.target.value;
							let allValues = [...watchAllFields.qty];
							allValues[j] = newVal;
							replace(allValues);
						}}
						InputLabelProps={{ shrink: false }} />
					{errors?.qty?.[j] &&
						<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
							<MDTypography fontWeight="bold" sx={{ color: "red!important", fontSize: "10px" }}>{errors.qty[j].message}</MDTypography>
						</MDBox>
					}
				</>,
				delete:
					<>
						<Icon style={{ cursor: "pointer" }} fontSize="small" onClick={() => deleteProductRow(arr?.product?.product_id, j)}>delete</Icon>
					</>,
			}
		}
		);
		let total_page = parseInt(listAll.length / 10);
		if (listAll.length % 10 !== 0) {
			total_page = total_page + 1;
		};
		setProductPaginationDetail({ ...productPaginationDetail, total_page: total_page });
		return listAll;
	}

	useMemo(() => {
		let listAll = settingRows();
		listAll = listAll?.filter((arr, i) => (((productPaginationDetail.current_page - 1) * 10) <= i && (productPaginationDetail.current_page * 10) > i));
		setProductRows(listAll);
	}, [productPaginationDetail.list, productPaginationDetail.current_page])

	const deleteProductRow = (id, index) => {
		let allValues = [...watchAllFields.qty];
		allValues.splice(index, 1);
		replace(allValues);
		let array = [...productPaginationDetail.list];
		array.splice(index, 1);
		let current_page = productPaginationDetail.current_page;
		if (array.length % 10 === 0) {
			current_page = current_page - 1;
		}
		setProductPaginationDetail({ ...productPaginationDetail, list: [...array], current_page: current_page, total_data: array.length });
	};
	const goToPageProduct = (page) => {
		setProductPaginationDetail({ ...productPaginationDetail, current_page: page });
	};

	const submitPO = async (type) => {
		if (productPaginationDetail.list.length > 0) {
			const note = document.querySelector('#note-do')?.value
			setErrorProduct("");
			const result = await trigger("qty");
			let current_page = productPaginationDetail.current_page;
			setProductPaginationDetail({ ...productPaginationDetail, current_page: 0 });
			setTimeout(() => setProductPaginationDetail({ ...productPaginationDetail, current_page: current_page }), 50)
			if (result) {
				let data = {
					note: note || '',
					plate_number: getValues('plate_number'),
					driver_name: getValues('driver_name'),
					account_id: requestFromID,
					child_document: [...new Set([...productPaginationDetail.list.map(product => product.so_id)])],
					detail_product: productPaginationDetail.list.map((list, i) => {
						return {
							so_id: list.so_id,
							product_id: list.product_id,
							qty: parseInt(watchAllFields.qty[i]),
							price: list?.price,

						}
					})
				}
				setIsLoading(true);
				services.createDO(data).then((res) => {
					setIsLoading(false);
					setShowNotifSuccessDraft(true);
					setRequestFromID(null);
					setProductPaginationDetail({
						current_page: 1,
						total_data: 0,
						total_page: 0,
						list: [],
					})
					replace([]);
					setTimeout(() => {
						setMode("index");
					}, 500);
				}).catch((err) => {
					setIsLoading(false);
					setShowNotifError(true);
					setErrorMessage(errors?.response?.data?.message);
				});
			}
		}
	}

	return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" flexDirection={window.innerWidth < 768 ? "column" : "row"} gap="8px" justifyContent="space-between" mt={-0.5} mb={2.5} width="100%">
				<MDTypography variant="h3" fontWeight="regular">
					Add New Delivery Order
				</MDTypography>
				<MDBox display="flex" maxWidth="90vw" gap="16px">
					<MDButton color="primary" onClick={submitPO}>
						Save And Send
					</MDButton>
				</MDBox>
			</MDBox>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="New Sales Order Draft Added!"
				open={showNotifSuccessDraft}
				dateTime="Just Now"
				close={() => setShowNotifSuccessDraft(false)}
			/>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="New Sales Order Added!"
				open={showNotifSuccess}
				dateTime="Just Now"
				close={() => setShowNotifSuccess(false)}
			/>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			<MDBox maxWidth="800px" width="100%" mb={4}>
				<Card sx={{ px: 3, py: 2 }}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Delivery Order No</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{ textDecoration: "underline" }} fontStyle="italic">
								Autogenerated
							</MDTypography>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Request To</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{ textDecoration: "underline" }} fontStyle="italic">
								Sehati Prima Makmur PT
							</MDTypography>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Request From</MDTypography>
							<Autocomplete
								options={requester}
								size="small"
								getOptionLabel={(option) => option.name}
								renderInput={(params) => (
									<TextField className="without-label-input" {...params} label="" />
								)}
								onChange={(e, newValue) => setRequestFromID(newValue.account_id)}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Driver Name</MDTypography>
							<MDInput
								type="text"
								{...register('driver_name', { required: 'Required' })}
								label="Driver Name"
								fullWidth />
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Plate Number</MDTypography>
							<MDInput
								type="text"
								{...register('plate_number', { required: 'Required' })}
								label="Plate Number"
								fullWidth />
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Note</MDTypography>
							<textarea name="notes" id="note-do" rows="1" style={{ width: '100%', padding: '.5rem', outline: 'none', border: '1px solid #d1d1d1', borderRadius: '.5rem' }}></textarea>
						</Grid>
					</Grid>
				</Card>
			</MDBox>
			<MDBox maxWidth="950px" width="100%">
				<MDBox width="100%" display="flex" justifyContent="flex-end" my={2}>
					<MDButton disabled={!requestFromID} color="info" onClick={() => setDisplayAddPO(true)}>
						DATA SO
					</MDButton>
				</MDBox>
				<DataTable
					table={{ columns, rows: productRows }}
					isSorted={false}
					entriesPerPage={false}
					showTotalEntries={true}
					pagination={{ variant: "contained", color: "primary" }}
					noEndBorder
					paginationDetail={productPaginationDetail}
					gotoPage={goToPageProduct}
				/>
				{errorProduct &&
					<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
						<MDTypography fontWeight="bold" sx={{ color: "red!important", fontSize: "10px" }}>{errorProduct}</MDTypography>
					</MDBox>
				}
			</MDBox>
			<AddPO open={displayAddPO} setOpen={setDisplayAddPO} setIsLoading={setIsLoading} id={requestFromID} setProductRows={setProductRows} productPaginationDetail={productPaginationDetail} setProductPaginationDetail={setProductPaginationDetail} setErrorMessage={setErrorMessage} setShowNotifError={setShowNotifError} replaceProductQty={replace} />
		</Grid>
	);
}

export default DeliveryOrderAdd;
