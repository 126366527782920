import placeholder from "../assets/images/broken-image.png";

export const toBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()

		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = (error) => reject(error)
})

export const addCommas = (nStr) => {
	nStr = nStr+"";
	nStr = nStr?.replace(/\D/g, "");
	nStr = nStr?.split(".").join("");
	var x = nStr.split('.');
	var x1 = x[0];
	var x2 = x.length > 1 ? '.' + x[1] : '';
	var rgx = /(\d+)(\d{3})/;
	while (rgx.test(x1)) {
		x1 = x1.replace(rgx, '$1' + '.' + '$2');
	}
	return x1 + x2;
}

export const setSizePrintPaper = (width, height) => {
	const page = `@page { 
		size: ${width} ${height}; 
		margin: 0;
		padding: 0;
	}`;
	console.log(page);
	const head = document.head || document.getElementsByTagName('head')[0];
	const style = document.createElement('style');
	style.type = 'text/css';
	style.media = 'print';

	if(style.styleSheet) {
		style.styleSheet.cssText = page
	}else {
		style.appendChild(document.createTextNode(page))
	}
	head.appendChild(style);
}

export const getImageError = e => { 
	e.currentTarget.src = placeholder;
}

export const cleanObject = (obj) => {
	for (var propName in obj) {
	  if (obj[propName] === null || obj[propName] === undefined || !obj[propName]) {
		delete obj[propName];
	  }
	}
	return obj
}

export const generateYear = (total, currentYear = new Date().getFullYear()) => {
	return [...new Array(total)].map((_,i) => currentYear - i).sort((a, b) => b - a);
}