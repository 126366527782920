import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { MenuItem, TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDInput from "../../../../MDInput";
import MDButton from "../../../../MDButton";
import MDSnackbar from "../../../../MDSnackbar";
import MDTypography from "../../../../MDTypography";

import services from "../../../../../service/services";
import { getImageError, toBase64 } from "../../../../../utils";

import placeholder from "../../../../../assets/images/placeholder-image.jpeg";

const SubCategoryAdd = ({
	categoryOptions,
	setIsLoading,
}) => {

	const subcategoryImageRef = useRef();

	const [subcategoryImage, setSubcategoryImage] = useState();
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isTransparent, setIsTransparent] = useState(false);

	const { register, handleSubmit, setValue, watch, trigger, reset, formState: { errors } } = useForm({mode: "all"});

	const watchAllFields = watch();

	const onImageChange = async (e, key) => {
		let format = e.target.files[0].name.split('.').pop();
		setIsTransparent(format.includes("png"));
		let val = await toBase64(e.target.files[0]);
		setValue(key, val);
		const result = await trigger(key);
		setSubcategoryImage(URL.createObjectURL(e.target.files[0]));
	}

	const removeImage = async (key) => {
		setIsTransparent(false);
		setValue(key, null);
		const result = await trigger(key);
		setSubcategoryImage(null);
	}
	
	const submitCreate = (e) => {
		setIsLoading(true);
		services.createSubCategory(e)
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
				reset({
					subcategory_name: "",
					subcategory_code: "",
					subcategory_image: "",
					brand_name: "",
					parent_name: "",
					is_active: "",
				});
				setSubcategoryImage("");
			}).catch(errors => {
				setIsLoading(false);
				setErrorMessage(errors?.response?.data?.message);
				setShowNotifError(true);
				console.log(errors)
			})
	};

  return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" justifyContent="space-between" mt={-0.5} mb={2.5} width="100%">
				<MDTypography variant="h3" fontWeight="regular">
					Add Subcategory
				</MDTypography>
				<MDButton color="info" type="submit" form="create-subcategory">
					Save
				</MDButton>
			</MDBox>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="New SubCategory Added!"
				open={showNotifSuccess}
				dateTime="Just Now"
				close={() => setShowNotifSuccess(false)}
			/>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			<MDBox component="form" role="form" id="create-subcategory" onSubmit={handleSubmit(submitCreate)}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>
						<MDBox width="100%" mb={3}>
							<Card
								sx={{
									"&:hover .card-header": {
										transform: "translate3d(0, -50px, 0)",
									},
									border: errors?.subcategory_image ? "2px solid red" : "inherit",
									width: "100%",
									maxWidth: "500px",
									margin: "0 auto"
								}}
							>
								<MDBox
									position="relative"
									borderRadius="lg"
									mt={-3}
									mx={1}
									mb={-1}
									className="card-header"
									sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)", zIndex: 999, backgroundColor: "transparent" }}
								>
									<MDBox
										sx={{ backgroundColor: isTransparent ? "#FFF" : "", borderRadius: "8px" }}
									>
										<MDBox
											component="img"
											src={subcategoryImage ? subcategoryImage : placeholder}
											alt="Subcategory Image"
											borderRadius="lg"
											shadow="sm"
											width="100%"
											height="100%"
											position="relative"
											zIndex={10}
											onError={getImageError}
										/>
									</MDBox>
								</MDBox>
								<MDBox textAlign="center" pt={4} pb={1} px={3}>
									<MDBox
										display="flex"
										justifyContent="center"
										alignItems="center"
										mt={-9}
										mb={-1}
										position="relative"
										zIndex={1}
									>
										<MDBox mr={1}>
											<input
												{...register("subcategory_image", { required: "Required" })}
												type="file"
												accept="image/png;image/jpg;image/jpeg;"
												ref={subcategoryImageRef}
												onChange={(e) => onImageChange(e, "subcategory_image")}
												className="d-none"
											/>
											<MDButton variant="gradient" color="info" size="small" onClick={() => subcategoryImageRef.current.click()}>
												edit
											</MDButton>
										</MDBox>
										<MDButton variant="outlined" color="dark" size="small" onClick={() => removeImage("subcategory_image")}>
											remove
										</MDButton>
									</MDBox>
									<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 3 }}>
										Subcategory Image
									</MDTypography>
									{
										errors?.subcategory_image &&
											<MDTypography fontWeight="bold" sx={{ fontSize: "10px", color: "red" }}>
												{errors.subcategory_image.message}
											</MDTypography>
									}
								</MDBox>
							</Card>
						</MDBox>
						<Card sx={{ padding: "24px 32px", width: "100%" }}>
							<MDTypography variant="h4" fontWeight="regular" sx={{ ml: -1, mb: 4}}>
								Category Information
							</MDTypography>
							<MDBox mb={2}>
								<TextField
									{...register("category_name", { required: "Required" })}
									id="select-category-name" 
									size="small" 
									fullWidth 
									label="Category Name"
									type="select" 
									select
									error={errors?.category_name}
									value={watchAllFields?.category_name || ""}
								>
									<MenuItem disabled value="">
										<em>Neither</em>
									</MenuItem>
									<MenuItem value="INTERIOR">INTERIOR</MenuItem>
									<MenuItem value="EXTERIOR">EXTERIOR</MenuItem>
								</TextField>
								{errors?.category_name && 
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.category_name.message}</MDTypography>
									</MDBox>
								}
							</MDBox>
							{
								watchAllFields?.category_name ?
									<MDBox mb={2}>
										<MDInput 
											type="text" 
											label="Brand Code"
											fullWidth
											value={watchAllFields?.category_name?.substring(0,2) || ""}
											disabled
										/>
									</MDBox>
								: null
							}
							<MDBox mb={2}>
								<TextField
									{...register("category_id", { required: "Required" })}
									id="select-category-type" 
									size="small" 
									fullWidth 
									label="Category Type"
									type="select" 
									select
									error={errors?.category_id}
									value={watchAllFields?.category_id || ""}
								>
									<MenuItem disabled value="">
										<em>Neither</em>
									</MenuItem>
									{
										categoryOptions.filter(co => co.category_name === watchAllFields?.category_name)
											.map((c, i) => {
												return (
													<MenuItem key={"category-type-list-"+i} value={c.id}>{c.category_type}</MenuItem>
												)
										})
									}
								</TextField>
								{errors?.category_id && 
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.category_id.message}</MDTypography>
									</MDBox>
								}
							</MDBox>
							{
								watchAllFields?.category_id ?
									<MDBox mb={2}>
										<MDInput 
											type="text" 
											label="Category Status"
											fullWidth
											value={categoryOptions.filter(co => co.id === watchAllFields?.category_id)[0]?.category_status == 1 ? "Active" : categoryOptions.filter(co => co.id === watchAllFields?.category_id)[0]?.category_status == 0 ? "Not Active" : ""}
											disabled	
										/>
									</MDBox>
								: null
							}
						</Card>
					</Grid>
					<Grid xs={12} md={6} item>
						<Card sx={{ padding: "24px 32px" }}>
							<MDTypography variant="h4" fontWeight="regular" sx={{ ml: -1, mb: 4}}>
								SubCategory Information
							</MDTypography>
								<MDBox mb={2}>
									<MDInput error={errors?.subcategory_name} {...register("subcategory_name", { required: "Required" })} type="text" label="Subcategory Name" fullWidth />
									{errors?.subcategory_name && 
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.subcategory_name.message}</MDTypography>
										</MDBox>
									}
								</MDBox>
								<MDBox mb={2}>
									<MDInput error={errors?.subcategory_code} {...register("subcategory_code", { required: "Required", maxLength: { value: 3, message: "Maximum character length is 3"} })} type="text" label="Subcategory Code" fullWidth />
									{errors?.subcategory_code && 
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.subcategory_code.message}</MDTypography>
										</MDBox>
									}
								</MDBox>
								<MDBox mb={2}>
									<TextField
										{...register("is_popular", { required: "Required" })}
										id="select-popular" 
										size="small" 
										fullWidth 
										label="Popular"
										type="select" 
										select
										error={errors?.is_popular}
										value={watchAllFields?.is_popular || ""}
									>
										<MenuItem disabled value="">
											<em>Neither</em>
										</MenuItem>
										<MenuItem value="1">Yes</MenuItem>
										<MenuItem value="0">No</MenuItem>
									</TextField>
									{errors?.is_active && 
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.is_active.message}</MDTypography>
										</MDBox>
									}
								</MDBox>
								<MDBox mb={2}>
									<TextField
										{...register("is_active", { required: "Required" })}
										id="select-is-active" 
										size="small" 
										fullWidth 
										label="Status"
										type="select" 
										select
										error={errors?.is_active} 
										value={watchAllFields?.is_active || ""}
									>
										<MenuItem disabled value="">
											<em>Neither</em>
										</MenuItem>
										<MenuItem value="1">Active</MenuItem>
										<MenuItem value="0">Not Active</MenuItem>
									</TextField>
									{errors?.is_active && 
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.is_active.message}</MDTypography>
										</MDBox>
									}
								</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		</Grid>
  );
}

export default SubCategoryAdd;
