import { useEffect, useRef, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Autocomplete, Button, Chip, Icon, MenuItem, TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDInput from "../../../../MDInput";
import MDButton from "../../../../MDButton";
import MDSnackbar from "../../../../MDSnackbar";
import MDTypography from "../../../../MDTypography";

import services from "../../../../../service/services";

import { getImageError, toBase64 } from "../../../../../utils";

import placeholder from "../../../../../assets/images/placeholder-image.jpeg";
import useFetchState from "../../../../../hooks/useFetchState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ItemProductEdit = ({
	setMode,
	setIsLoading,
	brandOptions,
	modelOptions,
	modelTypeOptions,
	categoryOptions,
	subCategoryOptions,
	styleOptions,
	attributeOptions,
	subAttributeOptions,
	idDetail,
	getUom,
}) => {

	const main_imageRef = useRef();
	const additional_image1Ref = useRef();
	const additional_image2Ref = useRef();
	const additional_image3Ref = useRef();
	const additional_image4Ref = useRef();

	const [images, setImages] = useState([]);
	const [isTransparent, setIsTransparent] = useState([]);
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [formData, setFormData] = useState({});

	const { register, watch, handleSubmit, setValue, trigger, control, reset, formState: { errors } } = useForm({ mode: "all" });

	const watchAllFields = watch();

	const { update: imagesUpdate, replace: imagesReplace, fields } = useFieldArray({
		control,
		name: "images",
	});

	const onImageChange = async (e, key) => {
		let format = e.target.files[0].type.split('/').pop();
		let tempIsTransparent = [...isTransparent];
		tempIsTransparent[key] = format.includes("png");
		setIsTransparent(tempIsTransparent);
		let val = await toBase64(e.target.files[0]);
		imagesUpdate(key, val);
		const result = await trigger("images");
		let tempImages = [...images];
		tempImages[key] = URL.createObjectURL(e.target.files[0]);
		setImages(tempImages);
	}

	const removeImage = async (key) => {
		let tempIsTransparent = [...isTransparent];
		tempIsTransparent[key] = false;
		setIsTransparent(tempIsTransparent);
		imagesUpdate(key, null);
		const result = await trigger("images");
		let tempImages = [...images];
		tempImages[key] = "";
		setImages(tempImages);
	}

	const generateSku = () => {
		const brand = formData?.brand;
		const model = formData?.model_name;
		const modelType = formData?.model_type;
		const category = formData?.category;
		const subCategory = formData?.subcategory;
		const style = formData?.style;
		const attribute = formData?.attribute;
		const subAttribute = formData?.subattribute;
		if (!brand || !model || !category || !subCategory || !style || !attribute || !subAttribute) {
			setErrorMessage("There cannot be empty fields");
			setShowNotifError(true);
			return null;
		};
		setErrorMessage("")
		setShowNotifError(false);
		const brandCode = brand?.brand_code;
		const modelCode = model[0]?.model_code + (model ? model[0]?.model_year?.map(el => el.toString().slice(2)).join("") : "00")
		const modelTypeCode = modelType == null ? 0 : modelType?.model_type_code;
		const categoryCode = category?.category_code;
		const subcategoryCode = subCategory?.subcategory_code;
		const styleCode = style?.style_code;
		const attributeCode = attribute?.attribute_code;
		const subattributeCode = subAttribute?.subattribute_code;

		return brandCode + "-" + modelCode + "-" + modelTypeCode + "." + categoryCode + subcategoryCode + "." + styleCode + attributeCode + subattributeCode;
	}

	const generateSkuAndSave = () => {
		const sku = generateSku();
		setValue("sku", sku);
	}

	const submitEdit = (e) => {
		const sku = generateSku();
		if (!sku) return;
		if (formData.model_name.length > 0) {
			setIsLoading(true);
			let tempImages = [0, 1, 2, 3, 4].map(index => {
				if (e.images[index]?.value || e.images[index]?.value === "") {
					return e.images[index].value;
				} else if (e.images[index]) {
					return e.images[index]
				} else {
					return ""
				}
			});

			generateSkuAndSave();

			let data = {
				id: formData.id,
				model_id: formData.model_name.map((mn) => mn.id),
				model_year: e.model_year,
				model_type_id: formData.model_type?.id ? formData.model_type?.id : null,
				subcategory_id: formData.subcategory.id,
				style_id: formData.style.id,
				attribute_id: formData.attribute.id,
				subattribute_id: formData.subattribute.id,
				code1: e.code1,
				code2: "",
				molding_code: e.molding_code,
				name: e.name,
				uom_id: e.uom,
				sku,
				description: e.description,
				images: tempImages.filter(img => img && img !== ""),
			};
			services.editItemProducts(data)
				.then(res => {
					setIsLoading(false);
					setShowNotifSuccess(true);
				}).catch(errors => {
					setIsLoading(false);
					setErrorMessage(errors?.response?.data?.message);
					setShowNotifError(true);
					console.log(errors)
				})
		}
	}

	useEffect(() => {
		if (brandOptions.length > 0) {
			setIsLoading(true);
			services.getItemProductDetail(idDetail)
				.then(res => {
					setIsLoading(false);
					let data = res.data.data;
					let tempSubCategory = subCategoryOptions.filter(sco => sco.id === data?.subcategory_id)[0];
					let tempModelName = [];
					data?.model_id.forEach(mi => {
						tempModelName.push(modelOptions.filter((mo) => {
							return mo.id === mi;
						})[0]);
					})

					const parseData = {
						id: data?.id,
						brand: brandOptions.filter((bo) => bo.brand_name === modelOptions.filter((mo) => mo.id === data?.model_id[0])[0]?.brand_name)[0],
						model_name: tempModelName,
						model_code: modelOptions.filter((mo) => mo.id === data?.model_id).map((mo) => mo?.model_code).join(",") || "",
						model_type: data?.model_type_id !== 0 ? modelTypeOptions.filter((mto) => mto.id === data?.model_type_id)[0] : null,
						category_name: tempSubCategory.category_name,
						category: categoryOptions.filter(co => co.id === tempSubCategory.category_id)[0],
						subcategory: tempSubCategory,
						style: styleOptions.filter(so => so.id === data?.style_id)[0],
						attribute: attributeOptions.filter(ao => ao.id === data?.attribute_id)[0],
						subattribute: subAttributeOptions.filter(sao => sao.id === data?.subattribute_id)[0],
						uom: data?.uom_id,
						sku: data?.sku,
						is_active: `${data?.is_active}`,
					}
					setFormData(parseData);

					setImages(data?.images);
					data?.images.map((img, index) => {
						imagesUpdate(index, {
							index: index,
							value: img
						})
					})

					setValue("brand_name", brandOptions.filter((bo) => bo.brand_name === modelOptions.filter((mo) => mo.id === data?.model_id[0])[0]?.brand_name)[0]);
					setValue("subcategory_name", tempSubCategory.category_name);
					setValue("style_name", styleOptions.filter(so => so.id === data?.style_id)[0]);
					setValue("attribute_name", attributeOptions.filter(ao => ao.id === data?.attribute_id)[0]);
					setValue("subattribute_name", subAttributeOptions.filter(sao => sao.id === data?.subattribute_id)[0]);
					setValue("category_type", categoryOptions.filter(co => co.id === tempSubCategory.category_id)[0])
					setValue("name", data?.name);
					setValue("code1", data?.code1);
					setValue("description", data?.description);
					setValue("molding_code", data?.molding_code);
					setValue("uom", data?.uom_id);
					setValue("sku", data?.sku);
				}).catch(errors => {
					setIsLoading(false);
					console.log("Error is found, apologies.")
					console.log(errors)
				})
		}
	}, [brandOptions, getUom.data]);

	const backClickHandler = () => {
		setMode("index");
	}

	return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" justifyContent="space-between" mt={-0.5} mb={4} width="100%">
				<MDBox display="flex" alignItems="center">
					<button onClick={backClickHandler} style={{ border: 'none', background: 'white', borderRadius: '5px', marginRight: '1rem', width: '2rem', height: '2rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<Icon fontSize="medium">
							arrow_back
						</Icon>
					</button>
					<MDTypography variant="h3" fontWeight="regular">
						Edit product
					</MDTypography>
				</MDBox>
				<MDButton color="info" type="submit" form="edit-product">
					Save
				</MDButton>
			</MDBox>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="Product Updated!"
				open={showNotifSuccess}
				dateTime="Just Now"
				close={() => setShowNotifSuccess(false)}
			/>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			{
				Object.keys(formData).length > 0 ?
					<MDBox component="form" role="form" id="edit-product" onSubmit={handleSubmit(submitEdit)}>
						<Grid container spacing={3}>
							<Grid item xs={12} lg={4}>
								<Card
									sx={{
										"&:hover .card-header": {
											transform: "translate3d(0, -50px, 0)",
										},
										margin: "0 auto",
										maxWidth: "500px",
										border: errors?.images?.[0] ? "2px solid red" : "inherit"
									}}
								>
									<MDBox
										position="relative"
										borderRadius="lg"
										mt={-3}
										mx={1}
										mb={-1}
										className="card-header"
										sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)", zIndex: 999, backgroundColor: "transparent" }}
									>
										<MDBox
											sx={{ backgroundColor: isTransparent[0] || images?.[0]?.includes(".png") ? "#FFF" : "", borderRadius: "8px" }}
										>
											<MDBox
												component="img"
												src={images?.[0] ? images?.[0] : placeholder}
												alt="Product Image"
												borderRadius="lg"
												shadow="sm"
												width="100%"
												height="100%"
												position="relative"
												zIndex={10}
												onError={getImageError}
											/>
										</MDBox>
									</MDBox>
									<MDBox textAlign="center" pt={4} pb={1} px={3}>
										<MDBox
											display="flex"
											justifyContent="center"
											alignItems="center"
											mt={-9}
											mb={-1}
											position="relative"
											zIndex={1}
										>
											<MDBox mr={1}>
												<input
													{...register("images.0", { required: "Required" })}
													type="file"
													accept="image/png;image/jpg;image/jpeg;"
													ref={main_imageRef}
													onChange={(e) => onImageChange(e, 0)}
													className="d-none"
												/>
												<MDButton variant="gradient" color="info" size="small"
													onClick={() => main_imageRef.current.click()}
												>
													edit
												</MDButton>
											</MDBox>
											<MDButton variant="outlined" color="dark" size="small" onClick={() => removeImage(0)}>
												remove
											</MDButton>
										</MDBox>
										<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 3 }}>
											Main Image *
										</MDTypography>
										{
											errors?.images?.[0] &&
											<MDTypography fontWeight="bold" sx={{ fontSize: "10px", color: "red" }}>
												{errors?.images?.[0].message}
											</MDTypography>
										}
									</MDBox>
								</Card>
								<Grid container alignItems="center" justifyContent="center" spacing={4} className="multiple-images-grid" sx={{ width: window.innerWidth <= 540 ? "calc(100% + 64px)" : "calc(100% + 32px)", mt: 0.1 }}>
									<Grid item xs={12} lg={6}>
										<Card
											sx={{
												"&:hover .card-header": {
													transform: "translate3d(0, -50px, 0)",
												},
												maxWidth: "500px",
											}}
										>
											<MDBox
												position="relative"
												borderRadius="lg"
												mt={-3}
												mx={1}
												mb={-1}
												className="card-header"
												sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)", zIndex: 999, backgroundColor: "transparent" }}
											>
												<MDBox
													sx={{ backgroundColor: isTransparent[1] || images?.[1]?.includes(".png") ? "#FFF" : "", borderRadius: "8px" }}
												>
													<MDBox
														component="img"
														src={images?.[1] ? images?.[1] : placeholder}
														alt="Product Image"
														borderRadius="lg"
														shadow="sm"
														width="100%"
														height="100%"
														position="relative"
														zIndex={10}
														onError={getImageError}
													/>
												</MDBox>
											</MDBox>
											<MDBox textAlign="center" pt={4} pb={1} px={3}>
												<MDBox
													display="flex"
													justifyContent="center"
													alignItems="center"
													mt={-9}
													mb={-1}
													position="relative"
													zIndex={1}
												>
													<MDBox mr={1}>
														<input
															{...register("images.1")}
															type="file"
															accept="image/png;image/jpg;image/jpeg;"
															ref={additional_image1Ref}
															onChange={(e) => onImageChange(e, 1)}
															className="d-none"
														/>
														<MDButton variant="gradient" color="info" size="small"
															onClick={() => additional_image1Ref.current.click()}
														>
															edit
														</MDButton>
													</MDBox>
													<MDButton variant="outlined" color="dark" size="small" onClick={() => removeImage(1)}>
														remove
													</MDButton>
												</MDBox>
												<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 3 }}>
													Additional Image
												</MDTypography>
											</MDBox>
										</Card>
									</Grid>
									<Grid item xs={12} lg={6}>
										<Card
											sx={{
												"&:hover .card-header": {
													transform: "translate3d(0, -50px, 0)",
												},
												maxWidth: "500px",
											}}
										>
											<MDBox
												position="relative"
												borderRadius="lg"
												mt={-3}
												mx={1}
												mb={-1}
												className="card-header"
												sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)", zIndex: 999, backgroundColor: "transparent" }}
											>
												<MDBox
													sx={{ backgroundColor: isTransparent[2] || images?.[2]?.includes(".png") ? "#FFF" : "", borderRadius: "8px" }}
												>
													<MDBox
														component="img"
														src={images?.[2] ? images?.[2] : placeholder}
														alt="Product Image"
														borderRadius="lg"
														shadow="sm"
														width="100%"
														height="100%"
														position="relative"
														zIndex={10}
														onError={getImageError}
													/>
												</MDBox>
											</MDBox>
											<MDBox textAlign="center" pt={4} pb={1} px={3}>
												<MDBox
													display="flex"
													justifyContent="center"
													alignItems="center"
													mt={-9}
													mb={-1}
													position="relative"
													zIndex={1}
												>
													<MDBox mr={1}>
														<input
															{...register("images.2")}
															type="file"
															accept="image/png;image/jpg;image/jpeg;"
															ref={additional_image2Ref}
															onChange={(e) => onImageChange(e, 2)}
															className="d-none"
														/>
														<MDButton variant="gradient" color="info" size="small"
															onClick={() => additional_image2Ref.current.click()}
														>
															edit
														</MDButton>
													</MDBox>
													<MDButton variant="outlined" color="dark" size="small" onClick={() => removeImage(2)}>
														remove
													</MDButton>
												</MDBox>
												<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 3 }}>
													Additional Image
												</MDTypography>
											</MDBox>
										</Card>
									</Grid>
									<Grid item xs={12} lg={6}>
										<Card
											sx={{
												"&:hover .card-header": {
													transform: "translate3d(0, -50px, 0)",
												},
												maxWidth: "500px",
											}}
										>
											<MDBox
												position="relative"
												borderRadius="lg"
												mt={-3}
												mx={1}
												mb={-1}
												className="card-header"
												sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)", zIndex: 999, backgroundColor: "transparent" }}
											>
												<MDBox
													sx={{ backgroundColor: isTransparent[3] || images?.[3]?.includes(".png") ? "#FFF" : "", borderRadius: "8px" }}
												>
													<MDBox
														component="img"
														src={images?.[3] ? images?.[3] : placeholder}
														alt="Product Image"
														borderRadius="lg"
														shadow="sm"
														width="100%"
														height="100%"
														position="relative"
														zIndex={10}
														onError={getImageError}
													/>
												</MDBox>
											</MDBox>
											<MDBox textAlign="center" pt={4} pb={1} px={3}>
												<MDBox
													display="flex"
													justifyContent="center"
													alignItems="center"
													mt={-9}
													mb={-1}
													position="relative"
													zIndex={1}
												>
													<MDBox mr={1}>
														<input
															{...register("images.3")}
															type="file"
															accept="image/png;image/jpg;image/jpeg;"
															ref={additional_image3Ref}
															onChange={(e) => onImageChange(e, 3)}
															className="d-none"
														/>
														<MDButton variant="gradient" color="info" size="small"
															onClick={() => additional_image3Ref.current.click()}
														>
															edit
														</MDButton>
													</MDBox>
													<MDButton variant="outlined" color="dark" size="small" onClick={() => removeImage(3)}>
														remove
													</MDButton>
												</MDBox>
												<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 3 }}>
													Additional Image
												</MDTypography>
											</MDBox>
										</Card>
									</Grid>
									<Grid item xs={12} lg={6}>
										<Card
											sx={{
												"&:hover .card-header": {
													transform: "translate3d(0, -50px, 0)",
												},
												maxWidth: "500px",
											}}
										>
											<MDBox
												position="relative"
												borderRadius="lg"
												mt={-3}
												mx={1}
												mb={-1}
												className="card-header"
												sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)", zIndex: 999, backgroundColor: "transparent" }}
											>
												<MDBox
													sx={{ backgroundColor: isTransparent[4] || images?.[4]?.includes(".png") ? "#FFF" : "", borderRadius: "8px" }}
												>
													<MDBox
														component="img"
														src={images?.[4] ? images?.[4] : placeholder}
														alt="Product Image"
														borderRadius="lg"
														shadow="sm"
														width="100%"
														height="100%"
														position="relative"
														zIndex={10}
														onError={getImageError}
													/>
												</MDBox>
											</MDBox>
											<MDBox textAlign="center" pt={4} pb={1} px={3}>
												<MDBox
													display="flex"
													justifyContent="center"
													alignItems="center"
													mt={-9}
													mb={-1}
													position="relative"
													zIndex={1}
												>
													<MDBox mr={1}>
														<input
															{...register("images.4")}
															type="file"
															accept="image/png;image/jpg;image/jpeg;"
															ref={additional_image4Ref}
															onChange={(e) => onImageChange(e, 4)}
															className="d-none"
														/>
														<MDButton variant="gradient" color="info" size="small"
															onClick={() => additional_image4Ref.current.click()}
														>
															edit
														</MDButton>
													</MDBox>
													<MDButton variant="outlined" color="dark" size="small" onClick={() => removeImage(4)}>
														remove
													</MDButton>
												</MDBox>
												<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 3 }}>
													Additional Image
												</MDTypography>
											</MDBox>
										</Card>
									</Grid>
								</Grid>
							</Grid>
							<Grid xs={12} lg={8} item>
								<Card sx={{ padding: "24px 32px" }}>
									<MDTypography variant="h4" fontWeight="regular" sx={{ ml: -1, mb: 4 }}>
										Product Literation
									</MDTypography>
									<Grid container spacing={2}>
										<Grid item xs={12} lg={8}>
											<Autocomplete
												options={brandOptions}
												getOptionLabel={(option) => option?.brand_name}
												size="small"
												renderInput={(params) => (
													<MDInput label="Brand Name *" {...register("brand_name", { required: "Required" })} error={errors?.brand_name} {...params} />
												)}
												onChange={(e, newValue) => {
													setValue("brand_name", newValue.brand_name);
													setFormData({ ...formData, brand: newValue, model_name: [] });
												}}
												onInputChange={(event, newInputValue, reason) => {
													if (reason === "clear") {
														setValue("brand_name", "");
														setFormData({ ...formData, brand: {}, model_name: [] });
														return
													}
												}}
												value={formData.brand?.brand_name ? formData.brand : null}
												className={errors.brand_name ? "autocomplete-error" : ""}
											/>
											{errors?.brand_name &&
												<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
													<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.brand_name.message}</MDTypography>
												</MDBox>
											}
										</Grid>
										<Grid item xs={12} lg={4}>
											<MDInput disabled type="text" label="Brand Code" fullWidth value={formData.brand?.brand_code ? formData.brand?.brand_code : ""} />
										</Grid>
										<Grid item xs={12} lg={8}>
											<Autocomplete
												options={formData.brand ? modelOptions.filter((mo) => mo.brand_name === formData.brand.brand_name) : []}
												multiple
												getOptionLabel={(option) => (option?.model_year ? option?.model_name + " " + option?.model_year : option?.model_name)}
												size="small"
												renderInput={(params) => (
													<MDInput label="Model Name *" error={formData.model_name.length === 0 && formData.brand?.brand_name} {...params} />
												)}
												renderTags={(value, getTagProps) =>
													value.map((option, index) => (
														<Chip variant="outlined" label={(option?.model_year ? option?.model_name + " " + option?.model_year : option?.model_name)} {...getTagProps({ index })} />
													))
												}
												onChange={(e, newValue) => {
													setFormData({ ...formData, model_name: newValue });
												}}
												value={formData.model_name ? formData.model_name : []}
												className={formData.model_name.length === 0 && formData.brand?.brand_name ? "autocomplete-error" : ""}
											/>
											{formData.model_name.length === 0 && formData.brand?.brand_name &&
												<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
													<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>Required</MDTypography>
												</MDBox>
											}
										</Grid>
										<Grid item xs={12} lg={4}>
											<MDInput disabled type="text" label="Model Code" fullWidth value={formData.model_name ? formData.model_name.map((mn) => mn?.model_code)?.join(",") : ""} />
										</Grid>
										<Grid item xs={12} lg={8}>
											<Autocomplete
												options={modelTypeOptions}
												getOptionLabel={(option) => option?.model_type_name}
												size="small"
												renderInput={(params) => (
													<MDInput label="Model Type" {...register("model_type")} {...params} />
												)}
												onChange={(e, newValue) => {
													setValue("model_type", newValue?.model_type_name ? newValue.model_type_name : "");
													setFormData({ ...formData, model_type: newValue });
												}}
												value={formData.model_type?.id ? formData.model_type : null}
											/>
										</Grid>

										<Grid item xs={12} lg={8}>
											<TextField
												{...register("category_name", { required: "Required" })}
												id="select-category-name"
												size="small"
												fullWidth
												label="Category Name *"
												type="select"
												select
												error={errors?.category_name}
												onChange={(e) => {
													setValue("category_name", e.target.value);
													setValue("category_type", "");
													setValue("subcategory_name", "");
													setFormData({ ...formData, category_name: e.target.value, category: {}, subcategory: {} })
												}}
												value={formData.category_name ? formData.category_name : ""}
											>
												<MenuItem disabled value="">
													<em>Neither</em>
												</MenuItem>
												<MenuItem value="INTERIOR">INTERIOR</MenuItem>
												<MenuItem value="EXTERIOR">EXTERIOR</MenuItem>
											</TextField>
											{errors?.category_name &&
												<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
													<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.category_name.message}</MDTypography>
												</MDBox>
											}
										</Grid>
										<Grid item xs={12} lg={4}>
											<Autocomplete
												options={formData.category_name ? categoryOptions.filter((co) => co.category_name === formData.category_name) : []}
												getOptionLabel={(option) => option?.category_type}
												size="small"
												renderInput={(params) => (
													<MDInput label="Category Type" {...register("category_type", { required: "Required" })} error={errors?.category_type} {...params} />
												)}
												onChange={(e, newValue) => {
													setValue("category_type", newValue.category_type);
													setValue("subcategory_name", "");
													setFormData({ ...formData, category: newValue, subcategory: {} })
												}}
												onInputChange={(event, newInputValue, reason) => {
													if (reason === "clear") {
														setValue("category_type", "");
														setValue("subcategory_name", "");
														setFormData({ ...formData, category: {}, subcategory: {} });
														return
													}
												}}
												value={formData?.category?.category_type ? formData.category : null}
												className={errors.category_type ? "autocomplete-error" : ""}
											/>
											{errors?.category_type &&
												<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
													<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.category_type.message}</MDTypography>
												</MDBox>
											}
										</Grid>
										<Grid item xs={12} lg={8}>
											<Autocomplete
												options={formData?.category?.category_type ? subCategoryOptions.filter((so) => so.category_id === formData.category.id) : []}
												getOptionLabel={(option) => option?.subcategory_name}
												size="small"
												renderInput={(params) => (
													<MDInput label="Subcategory Name *" {...register("subcategory_name", { required: "Required" })} error={errors?.subcategory_name} {...params} />
												)}
												onChange={(e, newValue) => {
													setValue("subcategory_name", newValue.subcategory_name);
													setFormData({ ...formData, subcategory: newValue })
												}}
												value={formData.subcategory?.subcategory_name ? formData.subcategory : null}
												className={errors.subcategory_name ? "autocomplete-error" : ""}
											/>
											{errors?.subcategory_name &&
												<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
													<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.subcategory_name.message}</MDTypography>
												</MDBox>
											}
										</Grid>
										<Grid item xs={12} lg={4}>
											<MDInput disabled type="text" label="Subcategory Code" fullWidth value={formData.subcategory?.subcategory_code ? formData.subcategory?.subcategory_code : ""} />
										</Grid>
										<Grid item xs={12} lg={8}>
											<Autocomplete
												options={styleOptions}
												getOptionLabel={(option) => option?.style_name}
												size="small"
												renderInput={(params) => (
													<MDInput label="Product Style *" {...register("style_name", { required: "Required" })} error={errors?.style_name} {...params} />
												)}
												onChange={(e, newValue) => {
													setValue("style_name", newValue.style_name);
													setFormData({ ...formData, style: newValue })
												}}
												onInputChange={(event, newInputValue, reason) => {
													if (reason === "clear") {
														setValue("style_name", "");
														setFormData({ ...formData, style: {} });
														return
													}
												}}
												value={formData.style?.style_name ? formData.style : null}
												className={errors.style_name ? "autocomplete-error" : ""}
											/>
											{errors?.style_name &&
												<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
													<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.style_name.message}</MDTypography>
												</MDBox>
											}
										</Grid>
										<Grid item xs={12} lg={4}>
											<MDInput disabled type="text" label="Product Style Code" fullWidth value={formData.style?.style_code ? formData.style?.style_code : ""} />
										</Grid>
										<Grid item xs={12} lg={8}>
											<Autocomplete
												options={attributeOptions}
												getOptionLabel={(option) => option?.attribute_name}
												size="small"
												renderInput={(params) => (
													<MDInput label="Attribute *" {...register("attribute_name", { required: "Required" })} error={errors?.attribute_name} {...params} />
												)}
												onChange={(e, newValue) => {
													setValue("attribute_name", newValue.attribute_name);
													setFormData({ ...formData, attribute: newValue })
												}}
												onInputChange={(event, newInputValue, reason) => {
													if (reason === "clear") {
														setValue("attribute_name", "");
														setFormData({ ...formData, attribute: {} });
														return
													}
												}}
												value={formData.attribute?.attribute_name ? formData.attribute : null}
												className={errors.attribute_name ? "autocomplete-error" : ""}
											/>
											{errors?.attribute_name &&
												<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
													<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.attribute_name.message}</MDTypography>
												</MDBox>
											}
										</Grid>
										<Grid item xs={12} lg={4}>
											<MDInput disabled type="text" label="Attribute Code" fullWidth value={formData.attribute?.attribute_code ? formData.attribute?.attribute_code : ""} />
										</Grid>
										<Grid item xs={12} lg={8}>
											<Autocomplete
												options={subAttributeOptions}
												getOptionLabel={(option) => option?.subattribute_name}
												size="small"
												renderInput={(params) => (
													<MDInput label="Subattribute *" {...register("subattribute_name", { required: "Required" })} error={errors?.subattribute_name} {...params} />
												)}
												onChange={(e, newValue) => {
													setValue("subattribute_name", newValue.subattribute_name);
													setFormData({ ...formData, subattribute: newValue })
												}}
												onInputChange={(event, newInputValue, reason) => {
													if (reason === "clear") {
														setValue("subattribute_name", "");
														setFormData({ ...formData, subattribute: {} });
														return
													}
												}}
												value={formData?.subattribute?.subattribute_name ? formData.subattribute : null}
												className={errors.subattribute_name ? "autocomplete-error" : ""}
											/>
											{errors?.subattribute_name &&
												<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
													<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.subattribute_name.message}</MDTypography>
												</MDBox>
											}
										</Grid>
										<Grid item xs={12} lg={4}>
											<MDInput disabled type="text" label="Subattribute Code" fullWidth value={formData.subattribute?.subattribute_code ? formData.subattribute.subattribute_code : ""} />
										</Grid>

										<Grid item xs={12} sm={12}>
											<MDBox pt={2}>
												<MDTypography>Product Information</MDTypography>
											</MDBox>
										</Grid>
										<Grid item xs={12} lg={8}>
											<MDInput type="text" label="Product Name *" {...register("name", { required: "Required" })} error={errors?.name} fullWidth />
											{errors?.name &&
												<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
													<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.name.message}</MDTypography>
												</MDBox>
											}
										</Grid>
										<Grid item xs={12} lg={4}>
											<MDInput type="text" label="Product Code *" {...register("code1", { required: "Required" })} error={errors?.code1} fullWidth />
											{errors?.code1 &&
												<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
													<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.code1.message}</MDTypography>
												</MDBox>
											}
										</Grid>
										<Grid item xs={12} lg={8}>
											<MDInput type="text" label="Product Description *" {...register("description", { required: "Required" })} error={errors?.description} fullWidth multiline rows={5} />
											{errors?.description &&
												<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
													<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.description.message}</MDTypography>
												</MDBox>
											}
										</Grid>
										<Grid item xs={12} lg={4} >
											<MDInput type="text" label="Molding Code" {...register("molding_code")} value={watchAllFields?.molding_code || ""} fullWidth />
											<TextField
												{...register("uom", { required: "Required" })}
												id="select-uom"
												size="small"
												fullWidth
												label="UOM *"
												type="select"
												select
												error={errors?.uom}
												style={{ marginTop: "1rem" }}
												onChange={(e) => {
													setValue("uom", e.target.value);
													setFormData({ ...formData, uom: e.target.value });
												}}
												value={formData?.uom ? formData.uom : null}
											>
												<MenuItem disabled value="">
													<em>Neither</em>
												</MenuItem>
												{getUom.data?.map((uom) => (
													<MenuItem value={uom.uom_id}>
														{uom.uom_name}
													</MenuItem>
												))}
											</TextField>
											{/* <MDInput
												{...register("uom", { required: "Required" })}
												id="select-is-active"
												size="small"
												fullWidth
												label="UOM *"
												type="select"
												select
												error={errors?.uom}
												onChange={(e) => {
													setValue("uom", e.target.value);
												}}
												style={{ marginTop: "1rem" }}
												value={formData?.uom ? formData.uom : null}
											>
												
											</MDInput> */}
											{errors?.uom &&
												<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
													<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.uom.message}</MDTypography>
												</MDBox>
											}
										</Grid>
										<Grid item xs={12} sm={12} lg={8}>
											<TextField
												{...register("is_active", { required: "Required" })}
												id="select-is-active"
												size="small"
												fullWidth
												label="Status *"
												type="select"
												select
												error={errors?.is_active}
												onChange={(e) => {
													setValue("is_active", e.target.value);
													setFormData({ ...formData, is_active: e.target.value });
												}}
												value={formData.is_active}
											>
												<MenuItem disabled value="">
													<em>Neither</em>
												</MenuItem>
												<MenuItem value="1">Active</MenuItem>
												<MenuItem value="0">Not Active</MenuItem>
											</TextField>
											{errors?.is_active &&
												<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
													<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.is_active.message}</MDTypography>
												</MDBox>
											}
										</Grid>
										<Grid item xs={12} lg={4} style={{ position: "relative" }} >
											<MDInput type="text" label="SKU" {...register("sku")} defaultValue={formData?.sku || ""} fullWidth />
											<FontAwesomeIcon icon="fa-solid fa-arrows-rotate" style={{ position: "absolute", top: "50%", right: "10px", cursor: "pointer" }} fontSize={18} onClick={generateSkuAndSave} />
										</Grid>
									</Grid>
								</Card>
							</Grid>
						</Grid>
					</MDBox>
					: null
			}
		</Grid>
	);
}

export default ItemProductEdit;
