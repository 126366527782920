import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import ListOrdersIndex from "../../../../components/AppComponents/Autoloka/product/listOrders";
import ListOrdersView from "../../../../components/AppComponents/Autoloka/product/listOrders/view";

import { addCommas, cleanObject } from "../../../../utils";

import services from "../../../../service/services";
import { useSearchParams } from "react-router-dom";

const ListOrders = ({
	setIsLoading,
	setMode,
	mode,
 }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [rows, setRows] = useState([]);
	const [rowsDisplayed, setRowsDisplayed] = useState([]);
	const [rawRowsDisplayed, setRawRowsDisplayed] = useState([]);
	const [paginationDetail, setPaginationDetail] = useState({});
	const [isFiltered, setIsFiltered] = useState(false);
	const [id, setId] = useState(null);
	const [dataFilter, setDataFilter] = useState({
		order_number: searchParams.get("order_number") || "",
		invoice_no: searchParams.get("invoice_no") || "",
		client_name: searchParams.get("client_name") || "",
		order_status: searchParams.get("order_status") || "",
		payment_status: searchParams.get("payment_status") || "",
	});

	const orderStatus = {
		1: "Waiting",
		2: "Approve",
		3: "Packing",
		4: "Ready to Deliver",
		5: "On Deliver",
		6: "Delivered",
		7: "Confirmed By User",
		8: "On Hold",
		9: "Cancelled",
		10: "Ready to Pickup",
	}

	const paymentStatus = {
		1: "Checking Payment Status",
		2: "Approve by Admin",
		3: "Reject by Admin",
	}

  const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "View", accessor: "view", align: "left" },
		{ Header: "Order Number", accessor: "order_number", align: "left" },
		{ Header: "Invoice No", accessor: "invoice_no", align: "left" },
		{ Header: "Client Name", accessor: "client_name", align: "left" },
		{ Header: "Status Order", accessor: "status_order", align: "left" },
		{ Header: "Status Payment", accessor: "status_payment", align: "left" },
		{ Header: "Grand Total", accessor: "grand_total", align: "left" },
		{ Header: "Date", accessor: "date", align: "left" },
	];

	const getList = (page) => {
		let productsFetcher;
		if(Object.keys(cleanObject(dataFilter)).length !== 0) {
			productsFetcher = services.filterOrder({...dataFilter, page: page || searchParams.get("page") || 1})
		}else {
			productsFetcher = services.getOrders( page || searchParams.get("page") || 1)
		}

		setIsLoading(true)
		productsFetcher
			.then(res => {
				setIsLoading(false);
				setIsFiltered(false);
				setRows(settingRowsDataTable(res?.data?.data?.list, res?.data?.data?.current_page));
				setRawRowsDisplayed(res?.data?.data?.list);
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	};

	const settingRowsDataTable = (rowsArray, current_page) => {
		return rowsArray?.map((arr, i) => {
			return {
				no: ((current_page - 1) * 10) + i + 1,
				view: (
					<>
						<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setMode("view"); setId(arr.id)}}>visibility</Icon>
					</>
				),
				order_number: arr?.order_no,
				invoice_no: arr?.invoice_no,
				client_name: arr?.client_name,
				status_order: orderStatus[arr?.order_status],
				status_payment: arr?.payment_status === null ? "Waiting for Payment" : paymentStatus[arr?.payment_status],
				grand_total: "Rp "+ addCommas(arr?.grand_total),
				date: arr?.date,
			};
		});
	};

	useEffect(() => {
		if(mode === "index"){
			getList( );
		}
	}, [mode])

  return (
		<>
			<Grid container spacing={6} className="grid-pure-container">
				{
					mode === "index" ?
						<ListOrdersIndex 
							setDataFilter={setDataFilter}
							dataFilter={dataFilter}
							columns={columns} 
							rows={rows}
							rowsDisplayed={rowsDisplayed}
							setRowsDisplayed={setRowsDisplayed} 
							setRawRowsDisplayed={setRawRowsDisplayed}
							setIsLoading={setIsLoading}
							paginationDetail={paginationDetail}
							setPaginationDetail={setPaginationDetail}
							getList={getList}
							isFiltered={isFiltered}
							setIsFiltered={setIsFiltered}
							settingRowsDataTable={settingRowsDataTable}
						/>
					: mode === "view" ?
						<ListOrdersView setMode={setMode} id={id} setIsLoading={setIsLoading} orderStatus={orderStatus} settingRowsDataTable={settingRowsDataTable} paymentStatus={paymentStatus} />
					: null
				}
			</Grid>
		</>
  );
}

export default ListOrders;
