import { useEffect, useState } from "react";
import moment from "moment";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import InputAdornment from '@mui/material/InputAdornment';

import DataTable from "../../../../Table";
import MDInput from "../../../../MDInput";
import MDBox from "../../../../MDBox";
import MDButton from "../../../../MDButton";

import FilterModal from "./filterModal";

import breakpoints from "../../../../../assets/theme/base/breakpoints";

import services from "../../../../../service/services";
import { useSearchParams } from "react-router-dom";
import { cleanObject } from "../../../../../utils";
import useFetchState from "../../../../../hooks/useFetchState";
import { Typography } from "@mui/material";
import MDTypography from "../../../../MDTypography";

// Material Dashboard 2 React components

const SalesOrderIndex = ({
	columns,
	rows,
	setIsLoading,
	setMode,
	rowsDisplayed,
	isFiltered,
	setIsFiltered,
	settingRowsDataTable,
	paginationDetail,
	setPaginationDetail,
	setRowsDisplayed,
	getList,
	setDataFilter,
	dataFilter
}) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [query, setQuery] = useState(searchParams.get("query") || "");
	const [querySearched, setQuerySearched] = useState(searchParams.get("query") || "");
	const [openFilter, setOpenFilter] = useState(false);


	const handleSearchChange = (e) => {
		setQuery(e.target.value);
	};

	const searchList = (e) => {
		if (e.keyCode === 13 && query.length >= 3) {
			setSearchParams({
				page: 1,
				query,
			})
			setQuerySearched(query);
		}
	};

	useEffect(() => {
		if (query.length === 0 && rows?.length > 0) {
			setRowsDisplayed(rows);
		}
	}, [query, rows]);

	useEffect(() => {
		if (querySearched.length > 0) {
			searchInput(searchParams.get("page") || 1)
		}
	}, [querySearched]);

	const searchInput = (page) => {
		setIsLoading(true);
		setIsFiltered(false);
		services.searchSalesOrder(querySearched, page)
			.then(res => {
				setIsLoading(false);
				setIsFiltered(false);
				console.log(res.data.data?.list_so)
				setRowsDisplayed(settingRowsDataTable(res?.data?.data?.list_so, res?.data?.data?.current_page));
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			});
	};

	const filterList = (page) => {
		setIsLoading(true);
		setIsFiltered(true);
		let data = { ...dataFilter, page: page };
		if (data?.date_range?.length > 0) {
			let daterangevalue = data.date_range.split(" - ");
			data.start_date = moment(daterangevalue[0], "DD.MM.YYYY").format("YYYY.MM.DD");
			data.end_date = moment(daterangevalue[1], "DD.MM.YYYY").format("YYYY.MM.DD");
		} else {
			data.start_date = "";
			data.end_date = "";
		};
		delete data.date_range;
		setSearchParams(cleanObject(data));
		services.filterSalesOrder(data)
			.then(res => {
				setIsLoading(false);
				setRowsDisplayed(settingRowsDataTable(res?.data?.data?.list, res?.data?.data?.current_page));
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	};

	const gotoPage = (page) => {
		setSearchParams({
			...(searchParams.get("page_pending") ? { query: searchParams.get("page_pending") || "" } : {}),
			...(searchParams.get("query") ? { query: searchParams.get("query") || "" } : {}),
			page,
		})
		if (isFiltered) {
			filterList(page);
		} else if (querySearched.length > 0) {
			searchInput(page);
		} else {
			getList(page);
		}
	};

	const removeQuery = () => {
		setQuery("");
		setQuerySearched("");
		setSearchParams({ page: 1, query: '' });
	}

	const pendingSOColumns = [
		{ Header: "No", accessor: "no", align: "left" },
		// { Header: "PO ID", accessor: "po_id", align: "left" },
		{ Header: "PO NO", accessor: "po_no", align: "left" },
		{ Header: "Requester", accessor: "requester", align: "left" },
		{ Header: "Create Date", accessor: "created_date", align: "left" },
	];

	const getPendingSO = useFetchState();
	const pageSO = searchParams.get("page_pending") || 1;
	const [rowPending, setRowPending] = useState([]);

	const settingRowsDataTablePending = (rowsArray, current_page) => {
		return rowsArray?.map((arr, i) => {
			return {
				no: ((current_page - 1) * 10) + i + 1,
				po_id: arr?.po_id,
				po_no: arr?.po_no,
				requester: arr?.requester,
				created_date: arr?.created_date ? moment(arr?.created_date).format("DD MMM YYYY HH:mm") : "-",
			}
		})
	};

	// useEffect(() => {
	// 	getPendingSO.fetchStart();
	// 	services.getPendingSO(pageSO).then((res) => {
	// 		getPendingSO.fetchSuccess(res.data.data);
	// 		const setting = settingRowsDataTablePending(res.data.data?.list_pending || [], res.data.data?.current_page);
	// 		setRowPending(setting);
	// 	}).catch((error) => {
	// 		getPendingSO.fetchFailed(error?.message || 'Ooops something went wrong');
	// 	});
	// }, [pageSO]);

	const gotoPagePending = (page) => {
		searchParams.set("page_pending", page);
		setSearchParams(searchParams);
	};

	return (
		<Grid item xs={12}>
			<MDBox sx={window.innerWidth > breakpoints.values.md ? { display: "flex", padding: "12px 48px", alignItems: "center" } : { display: "flex", flexWrap: "wrap", padding: "12px 24px", justifyContent: "center", alignItems: "center" }} bgColor="#E5ECF0" borderRadius="6px" marginBottom="12px">
				<MDInput
					variant="outlined"
					label="PO No or SO No or Inv No or Request To"
					className="search-input"
					InputProps={{
						endAdornment: <InputAdornment position="end"><Icon className="c-pointer" onClick={removeQuery} fontSize="small">close</Icon><Icon fontSize="small">search</Icon></InputAdornment>,
					}}
					backgroundColor="#FFF"
					style={{ height: "100%", borderRadius: "6px", lineHeight: "22px" }}
					fullWidth
					onChange={handleSearchChange}
					onKeyDown={searchList}
					value={query}
				/>
				<MDBox sx={window.innerWidth > breakpoints.values.md ? { marginLeft: "24px" } : { marginTop: "8px", flexWrap: "wrap", alignItems: "center", gap: "8px", justifyContent: "center" }} display="flex">
					<MDButton variant="gradient" color="info" style={window.innerWidth > breakpoints.values.md ? { marginRight: "12px" } : {}} onClick={() => setOpenFilter(true)}>
						<Icon fontSize="small" style={{ marginRight: "8px" }}>filter_list</Icon> Filter
					</MDButton>
					<MDButton variant="gradient" color="info" onClick={() => setMode("add")}>
						<Icon fontSize="small" fontWeight="medium" style={{ marginRight: "8px" }}>add</Icon> Add
					</MDButton>
				</MDBox>
			</MDBox>
			<Grid container spacing={2}>
				{/* <Grid item xs={4}>
					<Card>
						<MDTypography variant="h3" fontWeight="regular" padding="1rem">
							Pending SO
						</MDTypography>
						{getPendingSO.loading
							? <MDTypography variant="h3" fontWeight="regular" padding="1rem">
								Get Pending SO
							</MDTypography>
							: (
								<DataTable
									table={{ columns: pendingSOColumns, rows: rowPending }}
									isSorted={false}
									entriesPerPage={false}
									showTotalEntries={true}
									pagination={{ variant: "contained", color: "primary" }}
									noEndBorder
									paginationDetail={getPendingSO.data || {}}
									gotoPage={gotoPagePending}
								/>
							)}
					</Card>
				</Grid> */}
				<Grid item xs={12}>
					<Card>
						{/* <MDTypography variant="h3" fontWeight="regular" padding="1rem">
							Sales Order
						</MDTypography> */}
						<DataTable
							table={{ columns, rows: rowsDisplayed }}
							isSorted={false}
							entriesPerPage={false}
							showTotalEntries={true}
							pagination={{ variant: "contained", color: "primary" }}
							noEndBorder
							paginationDetail={paginationDetail}
							gotoPage={gotoPage}
						/>
					</Card>
				</Grid>
			</Grid>
			<FilterModal
				open={openFilter}
				setOpen={setOpenFilter}
				dataFilter={dataFilter}
				setDataFilter={setDataFilter}
				filterList={filterList}
			/>
		</Grid>
	);
}

export default SalesOrderIndex;
