import serviceApi from './index';

const services = {
	//login page
	doLogin: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: `user/sign-in/dashboard`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	getMasterDataBrand: async (page) => {
		return await serviceApi({
			method: 'GET',
			url: `master-brand/all-brands?page=${page}`,
			token: true,
			params: null,
		});
	},
	getOptionsBrandName: async (query) => {
		return await serviceApi({
			method: 'GET',
			url: `master-brand/suggest-brandname?brand_name=${query}`,
			token: true,
			params: null,
		});
	},
	getOptionsBrandCode: async (query) => {
		return await serviceApi({
			method: 'GET',
			url: `master-brand/suggest-brandcode?brand_code=${query}`,
			token: true,
			params: null,
		});
	},
	getOptionsBrandType: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-brand/suggest-brandtype`,
			token: true,
			params: null,
		});
	},
	filterBrandList: async (param) => {
		return await serviceApi({
			method: 'GET',
			url: `master-brand/filter-brand`,
			token: true,
			params: param
		});
	},
	searchBrandList: async (param, page) => {
		return await serviceApi({
			method: 'GET',
			url: `master-brand/search-brand?param_search=${param}&page=${page}`,
			token: true,
		});
	},
	createBrand: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: `master-brand/create-brand`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	editBrand: async (data) => {
		return await serviceApi({
			method: 'PUT',
			url: `master-brand/edit-brand`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	deleteBrand: async (id) => {
		return await serviceApi({
			method: 'DELETE',
			url: `master-brand/delete-brand/${id}`,
			token: true,
		});
	},
	getMasterDataModel: async (page) => {
		return await serviceApi({
			method: 'GET',
			url: `master-model/all-models?brand_id=0&page=${page}`,
			token: true,
			params: null,
		});
	},
	getOptionsModelName: async (query) => {
		return await serviceApi({
			method: 'GET',
			url: `master-model/suggest-model-name?model_name=${query}`,
			token: true,
			params: null,
		});
	},
	getOptionsModelCode: async (query) => {
		return await serviceApi({
			method: 'GET',
			url: `master-model/suggest-model-code?model_code=${query}`,
			token: true,
			params: null,
		});
	},
	getBrandInformationModel: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-model/brand-information?brand_name=`,
			token: true,
		});
	},
	getParentNameModel: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-model/parent-name?parent_name=`,
			token: true,
		});
	},
	filterModelList: async (param) => {
		return await serviceApi({
			method: 'GET',
			url: `master-model/filter-model`,
			token: true,
			params: param
		});
	},
	searchModelList: async (param, page) => {
		return await serviceApi({
			method: 'GET',
			url: `master-model/search-model?param_search=${param}&page=${page}`,
			token: true,
		});
	},
	createModel: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: `master-model/create-model`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	editModel: async (data) => {
		return await serviceApi({
			method: 'PUT',
			url: `master-model/edit-model`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	deleteModel: async (id) => {
		return await serviceApi({
			method: 'DELETE',
			url: `master-model/delete-model/${id}`,
			token: true,
		});
	},
	getMasterDataModelType: async (page) => {
		return await serviceApi({
			method: 'GET',
			url: `master-type/all-modeltype?page=${page}`,
			token: true,
			params: null,
		});
	},
	getOptionsModelTypeName: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-type/suggest-modeltype-name?model_type_name=`,
			token: true,
			params: null,
		});
	},
	getOptionsModelTypeCode: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-type/suggest-modeltype-code?model_type_code=`,
			token: true,
			params: null,
		});
	},
	filterModelTypeList: async (param) => {
		return await serviceApi({
			method: 'GET',
			url: `master-type/filter-model-type`,
			token: true,
			params: param
		});
	},
	searchModelTypeList: async (param, page) => {
		return await serviceApi({
			method: 'GET',
			url: `master-type/search-model-type?param_search=${param}&page=${page}`,
			token: true,
		});
	},
	createModelType: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: `master-type/create-modeltype`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	editModelType: async (data) => {
		return await serviceApi({
			method: 'PUT',
			url: `master-type/edit-modeltype`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	deleteModelType: async (id) => {
		return await serviceApi({
			method: 'DELETE',
			url: `master-type/delete-modeltype/${id}`,
			token: true,
		});
	},
	getMasterDataCategory: async (page) => {
		return await serviceApi({
			method: 'GET',
			url: `master-category/all-category?page=${page}`,
			token: true,
			params: null,
		});
	},
	getOptionsCategoryName: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-category/suggest-category-name?category_name=`,
			token: true,
			params: null,
		});
	},
	getOptionsCategoryCode: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-category/suggest-category-code?category_code=`,
			token: true,
			params: null,
		});
	},
	getOptionsCategoryType: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-category/suggest-category-type?category_type=`,
			token: true,
			params: null,
		});
	},
	getCategoryInformation: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-category/category-information`,
			token: true,
			params: null,
		});
	},
	filterCategoryList: async (param) => {
		return await serviceApi({
			method: 'GET',
			url: `master-category/filter-category`,
			token: true,
			params: param
		});
	},
	searchCategoryList: async (param, page) => {
		return await serviceApi({
			method: 'GET',
			url: `master-category/search-category?param_search=${param}&page=${page}`,
			token: true,
		});
	},
	createCategory: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: `master-category/create-category`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	editCategory: async (data) => {
		return await serviceApi({
			method: 'PUT',
			url: `master-category/edit-category`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	deleteCategory: async (id) => {
		return await serviceApi({
			method: 'DELETE',
			url: `master-category/delete-category/${id}`,
			token: true,
		});
	},
	getMasterDataSubCategory: async (page) => {
		return await serviceApi({
			method: 'GET',
			url: `master-subcategory/all-subcategory?category_id=0&page=${page}`,
			token: true,
			params: null,
		});
	},
	getOptionsCategorySubCategoryName: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-subcategory/suggest-by-categoryname?category_name=`,
			token: true,
			params: null,
		});
	},
	getOptionsSubCategoryName: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-subcategory/suggest-subcategory-name?subcategory_name=`,
			token: true,
			params: null,
		});
	},
	getOptionsSubCategoryCode: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-subcategory/suggest-subcategory-code/?subcategory_code=`,
			token: true,
			params: null,
		});
	},
	filterSubCategoryList: async (param) => {
		return await serviceApi({
			method: 'GET',
			url: `master-subcategory/filter-subcategory`,
			token: true,
			params: param
		});
	},
	searchSubCategoryList: async (param, page) => {
		return await serviceApi({
			method: 'GET',
			url: `master-subcategory/search-subcategory?param_search=${param}&page=${page}`,
			token: true,
		});
	},
	createSubCategory: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: `master-subcategory/create-subcategory`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	editSubCategory: async (data) => {
		return await serviceApi({
			method: 'PUT',
			url: `master-subcategory/edit-subcategory`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	deleteSubCategory: async (id) => {
		return await serviceApi({
			method: 'DELETE',
			url: `master-subcategory/delete-subcategory?id=${id}`,
			token: true,
		});
	},
	getMasterDataStyle: async (page) => {
		return await serviceApi({
			method: 'GET',
			url: `master-style/all-style?page=${page}`,
			token: true,
			params: null,
		});
	},
	getOptionsStyleName: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-style/suggest-style-name?style_name=`,
			token: true,
			params: null,
		});
	},
	getOptionsStyleCode: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-style/suggest-style-code?style_code=`,
			token: true,
			params: null,
		});
	},
	filterStyleList: async (param) => {
		return await serviceApi({
			method: 'GET',
			url: `master-style/filter-style`,
			token: true,
			params: param
		});
	},
	searchStyleList: async (param, page) => {
		return await serviceApi({
			method: 'GET',
			url: `master-style/search-style?param_search=${param}&page=${page}`,
			token: true,
		});
	},
	createStyle: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: `master-style/create-style`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	editStyle: async (data) => {
		return await serviceApi({
			method: 'PUT',
			url: `master-style/edit-style`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	deleteStyle: async (id) => {
		return await serviceApi({
			method: 'DELETE',
			url: `master-style/delete-style/${id}`,
			token: true,
		});
	},
	getMasterDataAttribute: async (page) => {
    return await serviceApi({
			method: 'GET',
			url: `master-attribute/all-attribute?page=${page}`,
			token: true,
			params: null,
    });
	},
	getOptionsAttributeName: async () => {
    return await serviceApi({
			method: 'GET',
			url: `master-attribute/suggest-attribute-name?attribute_name=`,
			token: true,
			params: null,
    });
	},
	getOptionsAttributeCode: async () => {
    return await serviceApi({
			method: 'GET',
			url: `master-attribute/suggest-attribute-code?attribute_code=`,
			token: true,
			params: null,
    });
	},
	filterAttributeList: async (param) => {
    return await serviceApi({
			method: 'GET',
			url: `master-attribute/filter-attribute`,
			token: true,
			params: param
    });
	},
	searchAttributeList: async (param, page) => {
		return await serviceApi({
			method: 'GET',
			url: `master-attribute/search-attribute?param_search=${param}&page=${page}`,
			token: true,
		});
	},
	createAttribute: async (data) => {
    return await serviceApi({
			method: 'POST',
			url: `master-attribute/create-attribute`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
    });
	},
	editAttribute: async (data) => {
    return await serviceApi({
			method: 'PUT',
			url: `master-attribute/edit-attribute`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
    });
	},
	deleteAttribute: async (id) => {
    return await serviceApi({
			method: 'DELETE',
			url: `master-attribute/delete-attribute/${id}`,
			token: true,
    });
	},
	getMasterDataSubattribute: async (page) => {
		return await serviceApi({
			method: 'GET',
			url: `master-subattribute/all-subattribute?page=${page}`,
			token: true,
			params: null,
		});
	},
	getOptionsSubattributeName: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-subattribute/suggest-subattribute-name?subattribute_name=`,
			token: true,
			params: null,
		});
	},
	getOptionsSubattributeCode: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-subattribute/suggest-subattribute-code?subattribute_code=`,
			token: true,
			params: null,
		});
	},
	filterSubattributeList: async (param) => {
		return await serviceApi({
			method: 'GET',
			url: `master-subattribute/filter-subattribute`,
			token: true,
			params: param
		});
	},
	searchSubattributeList: async (param, page) => {
		return await serviceApi({
			method: 'GET',
			url: `master-subattribute/search-subattribute?param_search=${param}&page=${page}`,
			token: true,
		});
	},
	createSubattribute: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: `master-subattribute/create-subattribute`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	editSubattribute: async (data) => {
		return await serviceApi({
			method: 'PUT',
			url: `master-subattribute/edit-subattribute`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	deleteSubattribute: async (id) => {
		return await serviceApi({
			method: 'DELETE',
			url: `master-subattribute/delete-subattribute/${id}`,
			token: true,
		});
	},
	getProductItemProducts: async (page) => {
		return await serviceApi({
			method: 'GET',
			url: `master-product/all-products?model_type_id=0&subcategory_id=0&style_id=0&attribute_id=0&subattribute_id=0&page=${page}`,
			token: true,
			params: null,
		});
	},
	getOptionsBrands: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-brand/get-allbrand`,
			token: true,
			params: null,
		});
	},
	getOptionsModels: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-model/get-all-model?brand_id=0`,
			token: true,
			params: null,
		});
	},
	getOptionsModelTypes: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-type/get-all-modeltype`,
			token: true,
			params: null,
		});
	},
	getOptionsCategories: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-category/get-all-category`,
			token: true,
			params: null,
		});
	},
	getOptionsSubcategories: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-subcategory/get-all-subcategory?category_id=0`,
			token: true,
			params: null,
		});
	},
	getOptionsStyles: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-style/get-all-style`,
			token: true,
			params: null,
		});
	},
	getOptionsAttributes: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-attribute/get-all-attribute`,
			token: true,
			params: null,
		});
	},
	getOptionsSubattributes: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-subattribute/get-all-subattribute`,
			token: true,
			params: null,
		});
	},
	getOptionsModelYears: async () => {
		return await serviceApi({
			method: 'GET',
			url: `master-model-year/all-years`,
			token: true,
			params: null,
		});
	},
	filterItemProductsList: async (param) => {
		return await serviceApi({
			method: 'GET',
			url: `master-product/filter-product`,
			token: true,
			params: param
		});
	},
	searchItemProductsList: async (param, page, warehouse) => {
		return await serviceApi({
			method: 'GET',
			url: `master-product/search-master-product?param_search=${param}&page=${page}`,
			token: true,
		});
	},
	createItemProducts: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: `master-product/create-product`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	getItemProductDetail: async (id) => {
		return await serviceApi({
			method: 'GET',
			url: `master-product/get-product-by-id?id=${id}`,
			token: true,
			params: null,
		});
	},
	editItemProducts: async (data) => {
		return await serviceApi({
			method: 'PUT',
			url: `master-product/edit-product`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	deleteItemProducts: async (id) => {
		return await serviceApi({
			method: 'DELETE',
			url: `master-product/delete-product/${id}`,
			token: true,
		});
	},
	getOrders: async (page) => {
		return await serviceApi({
			method: 'GET',
			url: `master-order/list-order?page=${page}`,
			token: true,
			params: null,
		});
	},
	filterOrder: async (param) => {
		return await serviceApi({
			method: 'GET',
			url: `master-order/filter-order`,
			token: true,
			params: param
		});
	},
	searchOrder: async (param, page) => {
		return await serviceApi({
			method: 'GET',
			url: `master-order/search-order?param_search=${param}&page=${page}`,
			token: true,
		});
	},
	createOrder: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: `master-product/create-product`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	getOrderDetail: async (id) => {
		return await serviceApi({
			method: 'GET',
			url: `master-order/detail-order?id=${id}`,
			token: true,
			params: null,
		});
	},
	getOrderProduct: async (id, page) => {
		return await serviceApi({
			method: 'GET',
			url: `master-order/detail-order-product?id=${id}&page=${page}`,
			token: true,
			params: null,
		});
	},
	AddOrderRemark: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: `master-order/add-remark`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	getOrderProductRemark: async (id, page) => {
		return await serviceApi({
			method: 'GET',
			url: `master-order/get-remarks?id=${id}&page=${page}`,
			token: true,
			params: null,
		});
	},
	getOrderPaymentHistory: async (id) => {
		return await serviceApi({
			method: 'GET',
			url: `master-order/list-payment-history?order_id=${id}`,
			token: true,
			params: null,
		});
	},
	changeStatusPayment: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: `master-order/set-status-payment`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	changeOrderProductCheck: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: `master-order/set-is-check`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	changeOrderStatus: async (id, status) => {
		return await serviceApi({
			method: 'POST',
			url: `master-order/set-status-order/${id}/${status}`,
			token: true,
		});
	},
	getProductLocator: async (id) => {
		return await serviceApi({
			method: 'GET',
			url: `master-order/get-locator?product_id=${id}`,
			token: true,
			params: null,
		});
	},
	getProductPrices: async (page,warehouse) => {
		return await serviceApi({
			method: 'GET',
			url: `product-price/all-price?warehouse_id=${warehouse || 0}&page=${page}`,
			token: true,
			params: null,
		});
	},
	getOptionsProductNameCode: async () => {
		return await serviceApi({
			method: 'GET',
			url: `product-price/suggest-product?product_name_code=`,
			token: true,
			params: null,
		});
	},
	getDetailProductInfo: async (id) => {
		return await serviceApi({
			method: 'GET',
			url: `product-price/detail-product-info?product_id=${id}`,
			token: true,
			params: null,
		});
	},
	filterPrices: async (param) => {
		return await serviceApi({
			method: 'GET',
			url: `product-price/filter-price`,
			token: true,
			params: param
		});
	},
	searchPrices: async (param, page, warehouse) => {
		return await serviceApi({
			method: 'GET',
			url: `product-price/search-price?param_search=${param}&page=${page}&warehouse_id=${warehouse}`,
			token: true,
		});
	},
	createProductPrice: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: `product-price/add-price`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	getEditPriceDetail: async (id) => {
		return await serviceApi({
			method: 'GET',
			url: `product-price/detail-product-edit?id=${id}`,
			token: true,
			params: null,
		});
	},
	editProductPrice: async (data) => {
		return await serviceApi({
			method: 'PUT',
			url: `product-price/edit-price`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	deleteProductPrice: async (id) => {
		return await serviceApi({
			method: 'DELETE',
			url: `product-price/delete-price/${id}`,
			token: true,
		});
	},
	getAreaOptions: async () => {
		return await serviceApi({
			method: 'GET',
			url: `quantity-product/get-all-area`,
			token: true,
			params: null,
		});
	},
	getQuantityProducts: async (page, warehouse) => {
		return await serviceApi({
			method: 'GET',
			url: `quantity-product/all-quantity?page=${page}&warehouse_id=${warehouse}`,
			token: true,
			params: null,
		});
	},
	filterQuantityProducts: async (param) => {
		return await serviceApi({
			method: 'GET',
			url: `quantity-product/filter-quantity`,
			token: true,
			params: param
		});
	},
	searchQuantityProducts: async (param, page, warehouse) => {
		return await serviceApi({
			method: 'GET',
			url: `quantity-product/search-quantity?param_search=${param}&page=${page}&warehouse_id=${warehouse}`,
			token: true,
		});
	},
	getQuantityProductsDetail: async (id) => {
		return await serviceApi({
			method: 'GET',
			url: `quantity-product/get-product-info?product_id=${id}`,
			token: true,
			params: null,
		});
	},
	getProductHistory: async (id, areaID, page) => {
		return await serviceApi({
			method: 'GET',
			url: `quantity-product/get-product-history?product_id=${id}&area_id=${areaID}&page=${page}`,
			token: true,
			params: null,
		});
	},
	getOptionsAllProduct: async () => {
		return await serviceApi({
			method: 'GET',
			url: `doc-inv/get-product`,
			token: true,
			params: null,
		});
	},
	getOptionsLocatorByProduct: async (id) => {
		return await serviceApi({
			method: 'GET',
			url: `doc-inv/get-locator?product_id=${id}`,
			token: true,
			params: null,
		});
	},
	getDocInvList: async (page) => {
		return await serviceApi({
			method: 'GET',
			url: `doc-inv/all-doc-inventory?page=${page}`,
			token: true,
			params: null,
		});
	},
	getDocInvDetail: async (id) => {
		return await serviceApi({
			method: 'GET',
			url: `doc-inv/detail-doc-inventory/${id}`,
			token: true,
			params: null,
		});
	},
	searchDocInv: async (param, page) => {
		return await serviceApi({
			method: 'GET',
			url: `doc-inv/search-doc-inventory?param_search=${param}&page=${page}`,
			token: true,
		});
	},
	createDocInv: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: `doc-inv/add-doc-inventory`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	getOptionsCustomerType: async (id) => {
		return await serviceApi({
			method: 'GET',
			url: `voucher/data-customer?customer_type=${id}`,
			token: true,
			params: null,
		});
	},
	getOptionCities: async (id) => {
		return await serviceApi({
			method: 'GET',
			url: `voucher/get-city?province_id=${id}`,
			token: true,
			params: null,
		});
	},
	getOptionProductType: async (id) => {
		return await serviceApi({
			method: 'GET',
			url: `voucher/get-product-type?product_type=${id}`,
			token: true,
			params: null,
		});
	},
	getOptionProductFromProductType: async (product_type_id, id) => {
		return await serviceApi({
			method: 'GET',
			url: `voucher/get-product?product_type=${product_type_id}&id=${id}`,
			token: true,
			params: null,
		});
	},
	getVoucherList: async (page) => {
		return await serviceApi({
			method: 'GET',
			url: `voucher/all-voucher?page=${page}`,
			token: true,
			params: null,
		});
	},
	getVoucherDetail: async (id) => {
		return await serviceApi({
			method: 'GET',
			url: `voucher/detail-voucher/${id}`,
			token: true,
			params: null,
		});
	},
	searchVoucher: async (param, page) => {
		return await serviceApi({
			method: 'GET',
			url: `voucher/search-voucher?param_search=${param}&page=${page}`,
			token: true,
		});
	},
	createVoucher: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: `voucher/add-voucher`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	getLoyaltyList: async (page) => {
		return await serviceApi({
			method: 'GET',
			url: `loyalty/all-loyalty?page=${page}`,
			token: true,
			params: null,
		});
	},
	getLoyaltyDetail: async (id) => {
		return await serviceApi({
			method: 'GET',
			url: `loyalty/detail-loyalty/${id}`,
			token: true,
			params: null,
		});
	},
	searchLoyalty: async (param, page) => {
		return await serviceApi({
			method: 'GET',
			url: `loyalty/search-loyalty?param_search=${param}&page=${page}`,
			token: true,
		});
	},
	createLoyalty: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: `loyalty/add-loyalty`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	getBannerList: async (page) => {
		return await serviceApi({
			method: 'GET',
			url: `banner/get-banner?page=${page}`,
			token: true,
			params: null,
		});
	},
	getBannerDetail: async (id) => {
		return await serviceApi({
			method: 'GET',
			url: `banner/detail-banner/${id}`,
			token: true,
			params: null,
		});
	},
	searchBanner: async (param, page) => {
		return await serviceApi({
			method: 'GET',
			url: `banner/search-banner?param_search=${param}&page=${page}`,
			token: true,
		});
	},
	filterBanner: async (param) => {
		return await serviceApi({
			method: 'GET',
			url: `banner/filter-banner`,
			token: true,
			params: param
		});
	},
	createBanner: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: `banner/add-banner`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	editBanner: async (data) => {
		return await serviceApi({
			method: 'PUT',
			url: `banner/edit-banner`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	deleteBanner: async (id) => {
		return await serviceApi({
			method: 'DELETE',
			url: `banner/delete-banner/${id}`,
			token: true,
		});
	},
	// purchase order
	getOptionsAllProduct: async () => {
		return await serviceApi({
			method: 'GET',
			url: `po/get-all-product`,
			token: true,
			params: null,
		});
	},
	getPurchaseOrder: async (page) => {
		return await serviceApi({
			method: 'GET',
			url: `po/all-purchase-order?page=${page}`,
			token: true,
			params: null,
		});
	},
	filterPurchaseOrder: async (param) => {
		return await serviceApi({
			method: 'GET',
			url: `po/filter-purchase-order`,
			token: true,
			params: param
		});
	},
	searchPurchaseOrder: async (query, page) => {
		return await serviceApi({
			method: 'GET',
			url: `po/search-purchase-order?param_search=${query}&page=${page}`,
			token: true,
			params: null
		});
	},
	getHeaderPurchaseOrder: async () => {
		return await serviceApi({
			method: 'GET',
			url: `po/get-header-po`,
			token: true,
			params: null
		});
	},
	createPurchaseOrder: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: `po/create-purchase-order`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	draftPurchaseOrder: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: `po/draft-purchase-order`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	getPurchaseOrderDetail: async (id) => {
		return await serviceApi({
			method: 'GET',
			url: `po/detail-purchase-order/${id}`,
			token: true,
			params: null,
		});
	},
	editDraftPurchaseOrder: async (data) => {
		return await serviceApi({
			method: 'PUT',
			url: `po/edit-draft-po`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	editPurchaseOrder: async (data) => {
		return await serviceApi({
			method: 'PUT',
			url: `po/edit-purchase-order`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	deleteDraftPurchaseOrder: async (id) => {
		return await serviceApi({
			method: 'DELETE',
			url: `po/delete-draft-po/${id}`,
			token: true,
		});
	},
	getAllSales: async(page) => {
		return await serviceApi({
			method: 'GET',
			url: `/pos/get-all-sales?page=${page}`,
			token: true,
			params: null,
		})
	},
	getSearchSales: async(query, page) => {
		return await serviceApi({
			method: 'GET',
			url: `/pos/search-sales?query=${query}&page=${page}`,
			token: true,
			params: null,
		})
	},
	getFilterSales: async(params) => {
		return await serviceApi({
			method: 'GET',
			url: `/pos/filter-sales`,
			token: true,
			params,
		})
	},
	getSalesAllProduct: async() => {
		return await serviceApi({
			method: 'GET',
			url: '/pos/get-all-product',
			token: true,
		})
	},
	getDetailSales: async(id) => {
		return await serviceApi({
			method: 'GET',
			url: '/pos/detail-sales/' + id,
			token: true,
		})
	},
	createDraftSales: async(data) => {
		return await serviceApi({
			method: 'POST',
			url: '/pos/create-draft-sales',
			token: true,
			data: data,
			contentType: "application/json"
		})
	},
	createSales: async(data) => {
		return await serviceApi({
			method: 'POST',
			url: '/pos/create-sales',
			token: true,
			data: data,
			contentType: "application/json"
		})
	},
	editSales: async(data) => {
		return await serviceApi({
			method: 'PUT',
			url: '/pos/edit-sales',
			token: true,
			data: data,
			contentType: "application/json"
		})
	},
	editDraftSales: async(data) => {
		return await serviceApi({
			method: 'PUT',
			url: '/pos/edit-draft-sales',
			token: true,
			data: data,
			contentType: "application/json"
		})
	},
	deleteDraftSales: async(id) => {
		return await serviceApi({
			method: 'DELETE',
			url: `/pos/delete-draft-sales/${id}`,
			token: true,
		})
	},
	// user
	getAllUser: async (page) => {
		return await serviceApi({
			method: 'GET',
			url: '/manage/user/get-all-user?page=' + page,
			token: true,
		})
	},
	getSearchUser: async (query,page) => {
		return await serviceApi({
			method: 'GET',
			url: `/manage/user/search-user?query=${query}&page=${page}`,
			token: true,
		})
	},
	getFilterUser: async(params) => {
		return await serviceApi({
			method: 'GET',
			url: `/manage/user/filter-user`,
			token: true,
			params,
		})
	},
	getDetailUser: async(id) => {
		return await serviceApi({
			method: 'GET',
			url: `/manage/user/get-detail-user/` + id,
			token: true,
		})
	},
	// buyer
	getAllBuyer: async (page) => {
		return await serviceApi({
			method: 'GET',
			url: '/manage/buyer/get-all-buyer?page=' + page,
			token: true,
		})
	},
	getSearchBuyer: async (query,page) => {
		return await serviceApi({
			method: 'GET',
			url: `/manage/buyer/search-buyer?query=${query}&page=${page}`,
			token: true,
		})
	},
	getFilterBuyer: async(params) => {
		return await serviceApi({
			method: 'GET',
			url: `/manage/buyer/filter-buyer`,
			token: true,
			params,
		})
	},
	getDetailBuyer: async(id) => {
		return await serviceApi({
			method: 'GET',
			url: `/manage/buyer/get-detail-buyer/` + id,
			token: true,
		})
	},
	getAllApps: async() => {
		return await serviceApi({
			method: 'GET',
			url: '/apps-menu/get-apps',
			token: true,
		})
	},
	installApps: async(data) => {
		return await serviceApi({
			method: 'POST',
			url: '/apps-menu/install-apps',
			token: true,
			data: data,
			contentType: "application/json"
		})
	},
	getMainMenu: async(data) => {
		return await serviceApi({
			method: 'GET',
			url: '/apps-menu/get-main-menu',
			token: true,
		})
	},
	getSalesOrder: async (page) => {
		return await serviceApi({
			method: 'GET',
			url: `so/all-sales-order?page=${page}`,
			token: true,
			params: null,
		});
	},
	filterSalesOrder: async (param) => {
		return await serviceApi({
			method: 'GET',
			url: `so/filter-sales-order`,
			token: true,
			params: param
		});
	},
	searchSalesOrder: async (query, page) => {
		return await serviceApi({
			method: 'GET',
			url: `so/search-sales-order??param_search=${query}&page=${page}`,
			token: true,
			params: null
		});
	},
	getHeaderSalesOrder: async () => {
		return await serviceApi({
			method: 'GET',
			url: `so/get-header-so`,
			token: true,
			params: null
		});
	},
	getRequestToOptions: async() => {
		return await serviceApi({
			method: 'GET',
			url: `dashboard-tf/suggest-employee-name?employee_name=`,
			token: true,
			params: null,
		});
	},
	getRequestFromOptions: async() => {
		return await serviceApi({
			method: 'GET',
			url: `so/list-account`,
			token: true,
			params: null,
		});
	},
	getPurchaseOrderOptions: async(id) => {
		return await serviceApi({
			method: 'GET',
			url: `so/list-po?account_id=${id}`,
			token: true,
			params: null,
		});
	},
	getDetailsPurchaseOrder: async (id) => {
		return await serviceApi({
			method: 'GET',
			url: `so/detail-product-po?po_id=${id}`,
			token: true,
			params: null,
		});
	},
	createSalesOrder: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: `so/create-sales-order`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	draftSalesOrder: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: `so/draft-sales-order`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	getSalesOrderDetail: async (id) => {
		return await serviceApi({
			method: 'GET',
			url: `so/detail-sales-order/${id}`,
			token: true,
			params: null,
		});
	},
	editDraftSalesOrder: async (data) => {
		return await serviceApi({
			method: 'PUT',
			url: `so/edit-draft-so`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	editSalesOrder: async (data) => {
		return await serviceApi({
			method: 'PUT',
			url: `so/edit-sales-order`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	deleteDraftSalesOrder: async (id) => {
		return await serviceApi({
			method: 'DELETE',
			url: `so/delete-draft-so/${id}`,
			token: true,
		});
	},
	// inventory out
	getAllInventoryOut: async (page) => {
		return await serviceApi({
			method: 'GET',
			url: `/inv-out/all-inventory-out?page=${page}`,
			token: true,
		});
	},
	getProductInventoryOut: async (page) => {
		return await serviceApi({
			method: 'GET',
			url: '/inv-out/get-product',
			token: true,
		});
	},
	getRequesterInventoryOut: async () => {
		return await serviceApi({
			method: 'GET',
			url: '/inv-out/get-requester',
			token: true,
		});
	},
	searchInventoryOut: async (query, page) => {
		return await serviceApi({
			method: 'GET',
			url: `/inv-out/search-inventory-out?query=${query}&page=${page}`,
			token: true,
		});
	},
	filterInventoryOut: async (param) => {
		return await serviceApi({
			method: 'GET',
			url: `/inv-out/filter-inventory-out`,
			token: true,
			params: param
		});
	},
	detailInventoryOut: async (id) => {
		return await serviceApi({
			method: 'GET',
			url: `/inv-out/detail-inventory-out/${id}`,
			token: true,
		});
	},
	detailProductInventoryOut: async (id, page) => {
		return await serviceApi({
			method: 'GET',
			url: `/inv-out/detail-product-invout?id=${id}&page=${page}`,
			token: true,
		});
	},
	getRemarksInventoryOut: async (id, page) => {
		return await serviceApi({
			method: 'GET',
			url: `/inv-out/get-remarks?id=${id}&page=${page}`,
			token: true,
		});
	},
	addRemarkInventoryOut: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: '/inv-out/add-remarks',
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	createInventoryOut: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: '/inv-out/create-inventory-out',
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	setStatusInventoryOut: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: '/inv-out/set-status',
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	pdfConverter: async (data) => {
		return await serviceApi({
			method: 'GET',
			url: '/version/pdf',
			token: true,
			params: null,
			data: data,
			contentType: "multipart/form-data"
		});
	},
	// DELIVERYI ORDER
	getAllDO: async (page) => {
		return await serviceApi({
			method: 'GET',
			url: `/do/all-delivery-order?page=${page}`,
			token: true,
		});
	},
	searchDO: async (query, page) => {
		return await serviceApi({
			method: 'GET',
			url: `/do/search-delivery-order?query=${query}&page=${page}`,
			token: true,
		});
	},
	getRequesterDO: async () => {
		return await serviceApi({
			method: 'GET',
			url: `/do/get-requester`,
			token: true,
		});
	},
	listSalesOrderDO: async (id) => {
		return await serviceApi({
			method: 'GET',
			url: `/do/list-so?account_id=${id}`,
			token: true,
		});
	},
	detailProductSODO: async (id) => {
		return await serviceApi({
			method: 'GET',
			url: `/do/detail-product-so?so_id=${id}`,
			token: true,
		});
	},
	detailDO: async (id) => {
		return await serviceApi({
			method: 'GET',
			url: `/do/detail-delivery-order/${id}`,
			token: true,
		});
	},
	createDO: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: '/do/create-delivery-order',
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	// giias
	getReport: async () => {
		return await serviceApi({
			method: 'GET',
			url: '/giias/report/get-all',
			token: true,
		});
	},
	getTopTenProduct: async () => {
		return await serviceApi({
			method: 'GET',
			url: '/giias/report/top-ten-product',
			token: true,
		});
	},
	getSalesOverview: async () => {
		return await serviceApi({
			method: 'GET',
			url: '/giias/report/chart-sales-overview',
			token: true,
		})
	},
	getSalesPerformance: async () => {
		return await serviceApi({
			method: 'GET',
			url: '/giias/report/sales-performance',
			token: true,
		})
	},
	getHistoryPO: async (id, page) => {
		return await serviceApi({
			method: 'GET',
			url: `/po/history-purchase-order?id=${id}&page=${page}`,
			token: true,
		})
	},
	getHistorySO: async (id, page) => {
		return await serviceApi({
			method: 'GET',
			url: `/so/history-sales-order?id=${id}&page=${page}`,
			token: true,
		})
	},
	getHistoryDO: async (id, page) => {
		return await serviceApi({
			method: 'GET',
			url: `/do/history-delivery-order?id=${id}&page=${page}`,
			token: true,
		})
	},
	// Company Inventory Asset
	getCompanyAssetList: async (page) => {
		return await serviceApi({
			method: 'GET',
			url: `/inventory/get-all?page=${page}`,
			token: true,
			params: null,
		});
	},
	getBrand: async () => {
		return await serviceApi({
			method: 'GET',
			url: `/giias/cashier/get-brand`,
			token: true,
		});
	},
	getModelByBrand: async (brandid) => {
		return await serviceApi({
			method: 'GET',
			url: `/giias/cashier/get-model-by-brand?brand_id=${brandid}`,
			token: true,
		});
	},
	getModelType: async (modelid) => {
		return await serviceApi({
			method: 'GET',
			url: `/giias/cashier/get-model-type?model_id=${modelid}`,
			token: true,
		});
	},
	getSubcategoryProduct: async (model, year, type) => {
		return await serviceApi({
			method: 'GET',
			url: `/giias/cashier/get-subcategory-product?model_id=${model}&model_year_id=${year}&type_id=${type}`,
			token: true,
		});
	},
	getProductsSubcategory: async (model, year, type, subcategory) => {
		return await serviceApi({
			method: 'GET',
			url: `/giias/cashier/get-product-by-subcategory?model_id=${model}&model_year_id=${year}&type_id=${type}&subcategory_id=${subcategory}`,
			token: true,
		});
	},
	searchCompanyAsset: async (param, page) => {
		return await serviceApi({
			method: 'GET',
			url: `/inventory/search-inventory?query=${param}&page=${page}`,
			token: true,
		});
	},
	createCompanyAsset: async (data) => {
		return await serviceApi({
			method: 'POST',
			url: `/inventory/create-inventory`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	editCompanyAsset: async (data) => {
		return await serviceApi({
			method: 'PUT',
			url: `/inventory/edit-inventory`,
			token: true,
			params: null,
			data: data,
			contentType: "application/json"
		});
	},
	deleteCompanyAsset: async (id) => {
		return await serviceApi({
			method: 'DELETE',
			url: `/inventory/delete-inventory/${id}`,
			token: true,
		});
	},
	getCompanyAssetDetail: async (id) => {
		return await serviceApi({
			method: 'GET',
			url: `/inventory/get-detail/${id}`,
			token: true,
			params: null,
		});
	},
	getItemProductUom: async () => {
		return await serviceApi({
			method: 'GET',
			url: `/master-product/get-uom`,
			token: true,
			params: null,
		});
	},
	getPendingSO: async (page) => {
		return await serviceApi({
			method: 'GET',
			url: `/so/get-pending-so?page=${page}`,
			token: true,
			params: null,
		});
	},
	getPendingDO: async (page) => {
		return await serviceApi({
			method: 'GET',
			url: `/do/get-pending-do?page=${page}`,
			token: true,
			params: null,
		});
	},
	getWarehouse: async () => {
		return await serviceApi({
			method: 'GET',
			url: '/get-warehouse',
			token: true,
			params: null,
		});
	},
}

export default services;