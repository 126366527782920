import MDBox from "../../../../MDBox";
import QRCode from "react-qr-code";
import JslSmallImg from '../../../../../assets/images/jsl-small.png';

const CardBarcode = ({item, configPaper: { top, left, right, bottom, height, width}}) => {

    return (
        <MDBox 
            className="barcode-item" 
            sx={{
                marginTop: parseFloat(top).toFixed(2) + 'cm',
                marginRight: parseFloat(right).toFixed(2) + 'cm',
                marginBottom: parseFloat(bottom).toFixed(2) + 'cm',
                marginLeft: parseFloat(left).toFixed(2) + 'cm',
                height: parseFloat(height).toFixed(2) + 'cm',
                width: parseFloat(width).toFixed(2) + 'cm',
            }}>
            <MDBox sx={{width: 'full', display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                <QRCode
                    size={50}
                    value={item.product_code}
                />
                <MDBox sx={{display: 'flex', flexDirection: 'column'}}>
                    <p className="" style={{fontSize: '10px', margin: 0, textAlign: 'right'}}>{item.product_code}</p>
                    <p className="" style={{fontSize: '10px', margin: 0, textAlign: 'right'}}>{item.molding_code}</p>
                </MDBox>
            </MDBox>
            <div style={{height: '100%', width: '100%',display: 'flex',flexDirection: 'column', justifyContent: 'space-between'}}>
                <p className="max-line-4" style={{fontSize: '10px', marginLeft: '7px'}}>
                    {item.product_name}
                </p>
                <img src={JslSmallImg} alt="" style={{height: '20px', objectFit: 'contain',alignSelf: 'flex-end'}} />
            </div>
        </MDBox>
    )
}

export default CardBarcode;