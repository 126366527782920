import { useEffect, useMemo, useRef, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import { Card, Icon, MenuItem, TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDButton from "../../../../MDButton";
import MDSnackbar from "../../../../MDSnackbar";
import MDInput from '../../../../MDInput';
import MDTypography from "../../../../MDTypography";

import services from "../../../../../service/services";

import AddProductModal from "./addProductModal";
import DataTable from "../../../../Table";
import { addCommas } from "../../../../../utils";

import {marketPlace} from './data';

const PurchaseOrderEdit = ({
	setIsLoading,
	id,
	productOptions,
	setMode
}) => {

	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifSuccessDraft, setShowNotifSuccessDraft] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [errorProduct, setErrorProduct] = useState("");
	const [productRows, setProductRows] = useState([]);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [productPaginationDetail, setProductPaginationDetail] = useState({
		current_page: 1,
		total_data: 0,
		total_page: 0,
		list: [],
	});
	const [detailData, setDetailData] = useState({});
	const [displayAddProduct, setDisplayAddProduct] = useState(false);
	const [salesDataCreate, setSalesDataCreate] = useState({
		marketplace_id: "",
		customer_name: "",
		customer_info: "",
	});
	const [errorSelectMarketPlace, setErrorSelectMarketPlace] = useState(false);

	const { register, trigger, control, reset, watch, formState: { errors } } = useForm({mode: "all"});

	const watchAllFields = watch();

	const { fields, update, remove, replace } = useFieldArray({
		control,
		shouldUnregister: true,
    name: "qty",
	});

	const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Product Name", accessor: "product_name", align: "left" },
		{ Header: "Code", accessor: "product_code", align: "left" },
		{ Header: "Price", accessor: "price", align: "left" },
		{ Header: "Qty", accessor: "qty", align: "left" },
		{ Header: "Delete", accessor: "delete", align: "left" },
	];

	useEffect(() => {
		setIsLoading(true);
		services.getDetailSales(id)
			.then(res => {
				setIsLoading(false);
				setDetailData(res?.data?.data);
				setSalesDataCreate({
					customer_info: res?.data?.data?.customer_info,
					customer_name: res?.data?.data?.customer_name,
					marketplace_id: res?.data?.data?.marketplace_id,
				})
				let listAll = res?.data?.data.list_detail;
				let total_page = parseInt(listAll.length/10);
				if(listAll.length%10 !== 0){
					total_page = total_page + 1;
				};
				setProductPaginationDetail({
					current_page: 1,
					total_page: total_page,
					total_data: listAll.length,
					list: listAll,
				});
				replace(listAll.map( list => list.qty));
			}).catch(errors => {
				setIsLoading(false);
				setShowNotifError(true)
				setErrorMessage(errors?.response?.data?.message);
			});
	}, []);

	useEffect(async() => {
		const result = await trigger("qty");
		let listAll = settingRows();
		listAll = listAll?.filter((arr, i) => (((productPaginationDetail.current_page - 1) * 10) <= i && (productPaginationDetail.current_page * 10) > i));
		setProductRows(listAll);
	}, [watchAllFields.qty])

	const settingRows = () => {
		let listAll = productPaginationDetail.list.map( (arr, j) => {
			return {
				no: j + 1,
				product: arr?.product,
				order_detail_id: arr?.order_detail_id,
				product_id: arr?.product_id || arr?.product?.product_id,
				product_name: arr?.product_name,
				product_code: arr?.product_code || arr?.code,
				price: "Rp"+addCommas(arr?.price),
				qty: <>
					<TextField key={"without-label-qty-"+j} style={{ width: "48px" }} className="without-label-input" type="number" disabled={isSubmitted} inputProps={{ inputMode: 'numeric', pattern: '[1-9]*' }} {...register(`qty.${j}`, { required: 'Required' })} defaultValue="" value={watchAllFields.qty?.[j] || ""} 
						onChange={(e) => {
							let newVal = e.target.value;
							let allValues = [...watchAllFields.qty];
							allValues[j] = newVal;
							replace(allValues);
						}}
						InputLabelProps={{ shrink: false }} />
					{errors?.qty?.[j] && 
						<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
							<MDTypography fontWeight="bold" sx={{ color: "red!important", fontSize: "10px"}}>{errors.qty[j].message}</MDTypography>
						</MDBox>
					}
				</>,
				delete: 
					<>
						<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => deleteProductRow(arr?.product?.product_id, j)}>delete</Icon>
					</>,
			}}
		);
		let total_page = parseInt(listAll.length/10);
		if(listAll.length%10 !== 0){
			total_page = total_page + 1;
		};
		setProductPaginationDetail({...productPaginationDetail, total_page: total_page});
		return listAll;
	}

	useMemo(() => {
		if(isSubmitted){
			let listAll = settingRows();
			listAll = listAll?.filter((arr, i) => (((productPaginationDetail.current_page - 1) * 10) <= i && (productPaginationDetail.current_page * 10) > i));
			setProductRows(listAll);
		}
	}, [isSubmitted])
	
	useMemo(() => {
		let listAll = settingRows();
		listAll = listAll?.filter((arr, i) => (((productPaginationDetail.current_page - 1) * 10) <= i && (productPaginationDetail.current_page * 10) > i));
		setProductRows(listAll);
	}, [productPaginationDetail.list, productPaginationDetail.current_page])

	const deleteProductRow = (id, index) => {
		let allValues = [...watchAllFields.qty];
		allValues.splice(index, 1);
		replace(allValues);
		let array = [...productPaginationDetail.list];
		array.splice(index, 1);
		let current_page = productPaginationDetail.current_page;
		if(array.length%10 === 0){
			current_page = current_page - 1;
		}
		setProductPaginationDetail({...productPaginationDetail, list: [...array], current_page: current_page, total_data: array.length});
	};

	const goToPageProduct = (page) => {
		setProductPaginationDetail({...productPaginationDetail, current_page: page});
	};

	console.log(detailData);

	const submitSales = async (type) => {
		if(productPaginationDetail.list.length > 0 ){
			setErrorProduct("");
			const result = await trigger("qty");
			let listAll = settingRows();
			listAll = listAll?.filter((arr, i) => (((productPaginationDetail.current_page - 1) * 10) <= i && (productPaginationDetail.current_page * 10) > i));
			setProductRows(listAll);
			if(Object.keys(salesDataCreate).filter((key) => salesDataCreate[key] === '' ).length !== 0) return;
			if(result){
				let data = {
					id: id,
					marketplace_id: salesDataCreate.marketplace_id,
					customer_name: salesDataCreate.customer_name,
					customer_info: salesDataCreate.customer_info,
					detail_product: productPaginationDetail.list.map((list, i) => {
						return {
							order_detail_id: list.order_detail_id,
							product_id: list.product_id || list?.product?.product_id,
							qty: parseInt(watchAllFields.qty[i]),
							price: typeof list?.price === "string" ? parseInt(list?.price.replaceAll(",","")) : list?.price,
						}
					})
				}
				if(type === "draft"){
					setIsLoading(true);
					services.editDraftSales(data)
						.then(res => {
							setIsLoading(false);
							setShowNotifSuccess(true);
						}).catch(errors => {
							setIsLoading(false);
							setShowNotifError(true);
							setErrorMessage(errors?.response?.data?.message);
						});
					} else {
						setIsLoading(true);
						services.editSales(data)
						.then(res => {
							setIsLoading(false);
							setShowNotifSuccess(true);
							setIsSubmitted(true);
						}).catch(errors => {
							setIsLoading(false);
							setShowNotifError(true)
							setErrorMessage(errors?.response?.data?.message);
						});
				}
			}
		} else {
			setErrorProduct("Please add atleast 1 Product");
		}
	}

	const backClickHandler = () => {
		setMode("index");
	}

  return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" flexDirection={window.innerWidth < 768 ? "column" : "row"} gap="8px" justifyContent="space-between" mt={-0.5} mb={2.5} width="100%">
				<MDBox display="flex" alignItems="center">
					<button onClick={backClickHandler} style={{border: 'none', background: 'white', borderRadius: '5px', marginRight: '1rem',width: '2rem', height: '2rem',display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
						<Icon fontSize="medium">
							arrow_back
						</Icon>
					</button>
					<MDTypography variant="h3" fontWeight="regular">
					Edit Sales Order Draft
					</MDTypography>
				</MDBox>
				<MDBox maxWidth="90vw" gap="16px" display={isSubmitted ? "none" : "flex"}>
					<MDButton color="secondary" onClick={() => submitSales("draft")}>
						Draft
					</MDButton>
					<MDButton color="primary" onClick={submitSales}>
						Save
					</MDButton>
				</MDBox>
			</MDBox>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="New Purchase Order Draft Updated!"
				open={showNotifSuccessDraft}
				dateTime="Just Now"
				close={() => setShowNotifSuccessDraft(false)}
			/>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="New Purchase Order Updated!"
				open={showNotifSuccess}
				dateTime="Just Now"
				close={() => setShowNotifSuccess(false)}
			/>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			<MDBox maxWidth="800px" width="100%" mb={4}>
				<Card sx={{ px: 3, py: 2 }}>
					<Grid container spacing={3}>
					<Grid item xs={12} sm={12}>
							<MDTypography variant="h4" fontWeight="regular">Sales Order No</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{textDecoration: "underline"}} fontStyle="italic">{detailData.so_number}</MDTypography>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Grid item xs={12} sm={6}>
								<MDInput 
									onChange={e => setSalesDataCreate((prev) => ({...prev, marketplace_id: e.target.value}))}
									value={salesDataCreate.marketplace_id} 
									id="select-brand-type" 
									size="small"
									fullWidth 
									label="Market Place" 
									type="select" 
									select >
									<MenuItem value={""}>Any</MenuItem>
									{marketPlace.map((el) => (
										<MenuItem value={el.id}>{el.value}</MenuItem>
									))}
								</MDInput>
								{errorSelectMarketPlace &&
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red!important", fontSize: "10px"}}>
											Select market place
										</MDTypography>
									</MDBox> }
							</Grid>
						</Grid>
						<Grid item xs={12} sm={6}>
							<MDInput 
								type="text" 
								{...register('customer_name', { required: 'Required' })}
								value={salesDataCreate.customer_name}
								label="Customer Name" 
								onChange={(e) => setSalesDataCreate((prev) => ({...prev, customer_name: e.target.value}))}
								fullWidth />
								{errors?.customer_name && 
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red!important", fontSize: "10px"}}>{errors.customer_name.message}</MDTypography>
									</MDBox>
								}
						</Grid>
						<Grid item xs={12} sm={6}>
							<MDInput 
								type="text" 
								{...register('customer_info', { required: 'Required' })}
								label="Customer Info" 
								value={salesDataCreate.customer_info}
								onChange={(e) => setSalesDataCreate((prev) => ({...prev, customer_info: e.target.value}))}
								fullWidth />
								{errors?.customer_info && 
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red!important", fontSize: "10px"}}>{errors.customer_info.message}</MDTypography>
									</MDBox>
								}
						</Grid>
					</Grid>
				</Card>
			</MDBox>
			<MDBox maxWidth="950px" width="100%">
				{
					!isSubmitted ? 
						<MDBox width="100%" display="flex" justifyContent="flex-end" my={2}>
							<MDButton color="info" onClick={() => setDisplayAddProduct(true)}>
								ADD PRODUCT
							</MDButton>
						</MDBox>
					: null
				}
				<DataTable
					table={{ columns, rows : productRows }}
					isSorted={false}
					entriesPerPage={false}
					showTotalEntries={true}
					pagination={{variant: "contained", color: "primary"}}
					noEndBorder
					paginationDetail={productPaginationDetail}
					gotoPage={goToPageProduct}
				/>
				{errorProduct && 
						<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
							<MDTypography fontWeight="bold" sx={{ color: "red!important", fontSize: "10px"}}>{errorProduct}</MDTypography>
						</MDBox>
					}
			</MDBox>
			<AddProductModal open={displayAddProduct} setOpen={setDisplayAddProduct} setIsLoading={setIsLoading} productOptions={productOptions} setProductRows={setProductRows} productPaginationDetail={productPaginationDetail} setProductPaginationDetail={setProductPaginationDetail} />
		</Grid>
  );
}

export default PurchaseOrderEdit;
