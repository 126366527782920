import { useEffect, useRef, useState } from "react";
import DateRangePicker from "react-daterange-picker";
import { useForm } from "react-hook-form";
import moment from "moment";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Icon, MenuItem, InputAdornment, TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../MDBox";
import MDButton from "../../../MDButton";
import MDTypography from "../../../MDTypography";
import MDEditor from "../../../MDEditor";

import placeholder from "../../../../assets/images/placeholder-image.jpeg";
import MDInput from "../../../MDInput";
import MDSnackbar from "../../../MDSnackbar";

import services from "../../../../service/services";

import { getImageError, toBase64 } from "../../../../utils";

const CompanyAssetEdit = ({
	setIsLoading,
	id,
	rawRowsDisplayed,
}) => {

	const InventoryImageRef = useRef();

	const [displayDate, setDisplayDate] = useState(false);
	const [inventoryImage, setModelImage] = useState();
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isTransparent, setIsTransparent] = useState(false);
	const [isRemoved, setIsRemoved] = useState(false);
	const [date, setDate] = useState("");

	const { register, handleSubmit, setValue, watch, trigger, reset, formState: { errors } } = useForm({mode: "all"});

	const watchAllFields = watch();

	useEffect(() => {
		register("date");
	}, []);

	useEffect(() => {
		services.getCompanyAssetDetail(id)
			.then(res => {
				setIsLoading(false);
				let detail = res?.data?.data;
				let defaultValues = {
					image: detail.image,
					name: detail.name,
					quantity : detail.quantity,
					serial_code: detail.serial_code,
					expired_date: `${moment(detail.expired_date, "DD-MM-YYYY").format("DD.MM.YYY")}`,
					type: detail.type,
					uom: detail.uom,
					is_active: detail.is_active
				};
				reset({...defaultValues});
				setDate(detail.expired_date);
			}).catch(errors => {
				setIsLoading(false);
				setErrorMessage(errors?.response?.data?.message);
				setShowNotifError(true);
				console.log(errors)
			})
	}, [id])

	const onImageChange = async (e, key) => {
		setIsRemoved(false);
		let format = e.target.files[0].name.split('.').pop();
		setIsTransparent(format.includes("png"));
		let val = await toBase64(e.target.files[0]);
		setValue(key, val);
		const result = await trigger(key);
		setModelImage(URL.createObjectURL(e.target.files[0]));
	}

	const removeImage = async (key) => {
		setIsTransparent(false);
		setIsRemoved(true);
		setValue(key, null);
		const result = await trigger(key);
		setModelImage(null);
	}
	
	const submitEdit = (e) => {
		setIsLoading(true);
		let data = {
			id: id,
			name: e.name,
			quantity: e.quantity,
			serial_code: e.serial_code,
			expired_date: new Date(date).toISOString().split("T")[0],
			type: e.type,
			uom: e.uom,
			is_active: e.is_active,
			image: e.image,
		}
		services.editCompanyAsset(data)
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
			}).catch(errors => {
				setIsLoading(false);
				setErrorMessage(errors?.response?.data?.message);
				setShowNotifError(true);
				console.log(errors)
			})
	}
  const type = [
	{
		value: 1,
		label: "Mobil",	

	},
	{
		value: 2,
		label: "ATK",	

	}
  ]
  return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" justifyContent="space-between" mt={-0.5} mb={2.5} width="100%">
				<MDTypography variant="h3" fontWeight="regular">
					Edit Inventory Asset
				</MDTypography>
				<MDButton color="info" type="submit" form="create-brand">
					Save
				</MDButton>
			</MDBox>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="Inventory Asset Updated!"
				open={showNotifSuccess}
				dateTime="Just Now"
				close={() => setShowNotifSuccess(false)}
			/>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			<MDBox component="form" role="form" id="create-brand" onSubmit={handleSubmit(submitEdit)}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>
						<MDBox width="100%" mb={2}>
							<Card
								sx={{
									"&:hover .card-header": {
										transform: "translate3d(0, -50px, 0)",
									},
									border: errors?.image ? "2px solid red" : "inherit",
									maxWidth: "500px",
									margin: "0 auto"
								}}
							>
								<MDBox
									position="relative"
									borderRadius="lg"
									mt={-3}
									mx={1}
									mb={-1}
									className="card-header"
									sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)", zIndex: 999, backgroundColor: "transparent" }}
								>
									<MDBox
										sx={{ backgroundColor: isTransparent ? "#FFF" : "", borderRadius: "8px" }}
									>
										<MDBox
											component="img"
											src={inventoryImage ? inventoryImage : isRemoved || !watchAllFields.image ? placeholder : watchAllFields.image}
											alt="Model Image"
											borderRadius="lg"
											shadow="sm"
											width="100%"
											height="100%"
											position="relative"
											zIndex={10}
											onError={getImageError}
										/>
									</MDBox>
								</MDBox>
								<MDBox textAlign="center" pt={4} pb={1} px={3}>
									<MDBox
										display="flex"
										justifyContent="center"
										alignItems="center"
										mt={-9}
										mb={-1}
										position="relative"
										zIndex={1}
									>
										<MDBox mr={1}>
											<input
												{...register("image", { required: "Required" })}
												type="file"
												accept="image/png;image/jpg;image/jpeg;"
												ref={InventoryImageRef}
												onChange={(e) => onImageChange(e, "image")}
												className="d-none"
											/>
											<MDButton variant="gradient" color="info" size="small" onClick={() => InventoryImageRef.current.click()}>
												edit
											</MDButton>
										</MDBox>
										<MDButton variant="outlined" color="dark" size="small" onClick={() => removeImage("image")}>
											remove
										</MDButton>
									</MDBox>
									<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 3 }}>
										Model Image
									</MDTypography>
									{
										errors?.image &&
											<MDTypography fontWeight="bold" sx={{ fontSize: "10px", color: "red" }}>
												{errors.image.message}
											</MDTypography>
									}
								</MDBox>
							</Card>
						</MDBox>
					</Grid>
					<Grid xs={12} md={6} item>
						<Card sx={{ padding: "24px 32px" }}>
							<MDTypography variant="h4" fontWeight="regular" sx={{ ml: -1, mb: 4}}>
								Inventory Asset
							</MDTypography>
							<MDBox mb={2}>
								<MDInput error={errors?.name} InputLabelProps={{ shrink: watchAllFields?.name?.length > 0 }} {...register("name", { required: "Required" })} type="text" label="Name" fullWidth />
								{errors?.name && 
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.name.message}</MDTypography>
									</MDBox>
								}
							</MDBox>
							<MDBox mb={2}>
								<MDInput error={errors?.quantity} InputLabelProps={{ shrink: true }} {...register("quantity", { required: "Required" })} type="number" label="Quantity" fullWidth />
								{errors?.quantity && 
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.quantity.message}</MDTypography>
									</MDBox>
								}
							</MDBox>
							<MDBox mb={2}>
								<MDInput error={errors?.serial_code} InputLabelProps={{ shrink: watchAllFields?.serial_code?.length > 0 }} {...register("serial_code", { required: "Required" })} type="text" label="Serial Code" fullWidth />
								{errors?.serial_code && 
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.serial_code.message}</MDTypography>
									</MDBox>
								}
							</MDBox>
							<MDBox mb={2}>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DatePicker
										label="Expired Date"
										value={date}
										onChange={(newValue) => {
										setDate(newValue);
										}}
										renderInput={(params) => <TextField {...params} />}
									/>
								</LocalizationProvider>
							</MDBox>
							<MDBox mb={2}>
								<MDInput
									{...register("type", { required: "Required" })}
									id="select-is-active" 
									size="small" 
									fullWidth 
									label="Type"
									type="select" 
									select
									error={errors?.type}
									value={watchAllFields?.type || ""}
								>
									<MenuItem disabled value="">
										<em>Neither</em>
									</MenuItem>
									{
										type.map((el)=>{
											return (
												<MenuItem value={el.value}>
													{
														el.label
													}
												</MenuItem>
											)
										})
									}
								</MDInput>
								{errors?.type && 
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.type.message}</MDTypography>
									</MDBox>
								}
							</MDBox>
							<MDBox mb={2}>
								<MDInput error={errors?.uom} InputLabelProps={{ shrink: true }} {...register("uom", { required: "Required" })} type="text" label="UOM" fullWidth />
								{errors?.uom && 
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.uom.message}</MDTypography>
									</MDBox>
								}
							</MDBox>
							<MDBox mb={2}>
								<MDInput
									{...register("is_active", { required: "Required" })}
									id="select-is-active" 
									size="small" 
									fullWidth 
									label="Status"
									type="select" 
									select
									error={errors?.is_active}
									value={watchAllFields?.is_active}
								>
									<MenuItem disabled value="">
										<em>Neither</em>
									</MenuItem>
									<MenuItem value="1">Active</MenuItem>
									<MenuItem value="0">Not Active</MenuItem>
								</MDInput>
								{errors?.is_active && 
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.is_active.message}</MDTypography>
									</MDBox>
								}
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		</Grid>
  );
}

export default CompanyAssetEdit;
