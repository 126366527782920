
import { useEffect } from "react";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";

import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Cookie from "js-cookie";
function Breadcrumbs({ icon, title, route, light, menuActive, setMenuActive, appActive, setAppActive, setMode }) {
  const [_, setSearchParams] = useSearchParams();

  const routes = route.slice(0, -1);

  const navigate = useNavigate();
  
  useEffect(() => {
    if(menuActive?.length === 0 && appActive?.length === 0){
      navigate("/my-apps");
    }
  }, [menuActive, appActive]);

  const handleHome = () => {
    Cookie.set("appActive", "");
    Cookie.set("menuActive", "");
    setAppActive(""); 
    setMenuActive("");
  }

  const menuClickHandler = () => {
    setSearchParams({});
    setMode("index");
  }

  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      {/* <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
          },
        }}
      >
        <MDBox onClick={handleHome}>
          <MDTypography
            component="span"
            variant="body2"
            color={light ? "white" : "dark"}
            opacity={1}
            sx={{ lineHeight: 0, display: "flex", alignItems: "center" }}
          >
            <Icon sx={{ cursor: "pointer", fontSize: "14px!important" }} baseClassName="material-icons-outlined">{icon}</Icon>
          </MDTypography>
        </MDBox>
        {routes.map((el) => (
          <Link to={`/${route.join("/")}`} key={el}>
            <MDTypography
              component="span"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color={light ? "white" : "dark"}
              opacity={light ? 0.8 : 0.5}
              sx={{ lineHeight: 0 }}
            >
              {el.replace("-", " ")}
            </MDTypography>
          </Link>
        ))}
        <MDTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? "white" : "dark"}
          sx={{ lineHeight: 0, display: "flex", alignItems: "center" }}
        >
          {title.replace("-", " ")}
        </MDTypography>
      </MuiBreadcrumbs> */}
      <MDTypography
        fontWeight="bold"
        textTransform="capitalize"
        variant="h1"
        color={light ? "white" : "dark"}
        noWrap
        pt={1}
        sx={{ cursor: "pointer", display: "flex", alignItems: "center", fontSize: '1.5rem !important' }}
        onClick={() => {
          let menu = menuActive.split("/");
          menu = menu[menu.length-1];
          if(menu === title){
            setMode("render again");
            setTimeout(() => menuClickHandler(), 100);
          } else {
            menuClickHandler();
          }
        }}
      >
        {title.replace("-", " ")}
      </MDTypography>
    </MDBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
