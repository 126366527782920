import { useMemo, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import QRCode from "react-qr-code";
import moment from "moment";
import { useState } from "react";

const PrintLetterDO = ({ dataDO, children }) => {

    const [currentTime, setCurrentTime] = useState();

    const productPerPage = 10;

    const letterRef = useRef();
    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: dataDO?.do_no || '',
        pageStyle: '@page { size: auto; }',
    });

    const listDetailGroupBy10 = useMemo(() => {
        if (!dataDO?.list_detail) return [];
        const listProductCopy = [...dataDO?.list_detail];
        const temp = [];
        const totalPage = Math.ceil(dataDO?.list_detail.length / productPerPage);
        [...new Array(totalPage)].forEach((el, i) => {
            temp.push(listProductCopy.splice(0, i + productPerPage));
        });
        return temp;
    }, [dataDO]);

    const clickPrintHandler = () => {
        setCurrentTime(moment.now());
        setTimeout(() => {
            handlePrintLetter();
        }, 500);
    }

    return (
        <>
            <div ref={letterRef} className="print">
                {listDetailGroupBy10?.map((products, page) => (
                    <>
                        <div className="do-container page-break">
                            <div className="do-header">
                                <div className="do-header-info">
                                    <h1 className="do-title">
                                        {dataDO?.request_to}
                                    </h1>
                                    <p className="do-address">{dataDO?.address_to}</p>
                                </div>
                                <div className="do-header-letter">
                                    <h2>Surat Jalan</h2>
                                </div>
                            </div>
                            <div className="do-header-line" />
                            <div className="do-header-line-2" />
                            <div className="do-body">
                                <div className="do-body-header">
                                    <div style={{ flex: 1 }} className="do-body-header-left">
                                        <h2 className="do-yth">Kepada Yth.</h2>
                                        <p className="do-address">
                                            {dataDO?.request_from}
                                        </p>
                                        <p className="do-address">{dataDO?.address_from || '-'}</p>
                                    </div>
                                    <div style={{ flex: 1 }} className="do-body-header-center">
                                        <QRCode
                                            size={70}
                                            value={dataDO?.do_no}
                                        />
                                    </div>
                                    <div style={{ flex: 1 }} className="do-body-header-right">
                                        <table>
                                            <tr>
                                                <td className="title-tabel">No</td>
                                                <td>{`: ${dataDO?.do_no}`}</td>
                                            </tr>
                                            <tr>
                                                <td className="title-tabel">Tanggal</td>
                                                <td>{moment().format('dddd DD MM yyyy')}</td>
                                            </tr>
                                            <tr>
                                                <td className="title-tabel" style={{ maxWidth: '300px' }}>No So</td>
                                                <td>{`: ${dataDO?.list_so?.join(',')}`}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div className="do-body-tabel">
                                    <table>
                                        <thead>
                                            <th>No</th>
                                            <th>Kode</th>
                                            <th>Nama Produk</th>
                                            <th>Qty</th>
                                            <th>No Koli</th>
                                        </thead>
                                        <tbody>
                                            {products?.map((el, i) => (
                                                <tr key={i}>
                                                    <td className="do-number">{i + 1 + (productPerPage * page)}</td>
                                                    <td className="do-text">{el.product_code}</td>
                                                    <td className="do-text">{el.product_name}</td>
                                                    <td className="do-number">{el.qty}</td>
                                                    <td className="do-number"></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <p className="do-pages">
                                        {`Halaman ${page + 1} dari ${listDetailGroupBy10.length}`}
                                    </p>
                                </div>
                                <div className="do-body-sign">
                                    {['Penerima', 'Pengirim', 'Petugas Gudang'].map((el, i) => (
                                        <div className="sign-template" key={i}>
                                            <p className="sign-title">{el}</p>
                                            <p className="sign">(...........)</p>
                                        </div>
                                    ))}
                                    <div className="note">
                                        <table>
                                            <tr>
                                                <td className="title-tabel">Catatan</td>
                                                <td>{`: ${dataDO?.note}`}</td>
                                            </tr>
                                            <tr>
                                                <td className="title-tabel">Print</td>
                                                <td>: - <br />{moment(currentTime || moment.now()).format('yyyy-MM-DD HH:mm')}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ))}
            </div>
            {children(clickPrintHandler)}
        </>
    )
}

export default PrintLetterDO;