import { useEffect, useRef, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDInput from "../../../../MDInput";
import MDButton from "../../../../MDButton";
import MDTypography from "../../../../MDTypography";
import MDSnackbar from "../../../../MDSnackbar";

import services from "../../../../../service/services";
import { MenuItem, Icon } from "@mui/material";
import DataTable from "../../../../Table";
import AddTransferModal from "./addTransferModal";


const DocInventoryView = ({
	setMode,
	setIsLoading,
	locatorColumns,
	locatorRows,
	setLocatorRows,
	locatorPaginationDetail,
	goToPageLocator,
	settingLocatorRows,
	setLocatorPaginationDetail,
	id
}) => {

	const [detailData, setDetailData] = useState({});
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	useEffect(() => {
		setIsLoading(true);
		services.getDocInvDetail(id)
			.then(res => {
				setIsLoading(false);
				let tempData = res?.data?.data;
				setDetailData(tempData);
				let locRows = tempData?.list_product?.map((lp) => {
					return {
						product_name: lp?.product_name,
						locator_from_name: lp?.list_locator_from[0]?.locator_name,
						locator_from_qty_before: lp?.list_locator_from[0]?.qty_before,
						locator_from_qty_transfer: lp?.list_locator_from[0]?.qty_transfer,
						locator_from_qty_after: lp?.list_locator_from[0]?.qty_after,
						locator_to_name: lp?.list_locator_to[0]?.locator_name,
						locator_to_qty_before: lp?.list_locator_to[0]?.qty_before,
						locator_to_qty_transfer: lp?.list_locator_to[0]?.qty_transfer,
						locator_to_qty_after: lp?.list_locator_to[0]?.qty_after,
					}
				});
				setLocatorRows(settingLocatorRows(locRows, 1));
			}).catch(errors => {
				setIsLoading(false);
				setShowNotifError(true);
				setErrorMessage(errors?.response?.message);
			})
	}, [id])

	const backClickHandler = () => {
		setMode("index");
	}
	
  return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" justifyContent="space-between" mt={-0.5} mb={2.5} width="100%">
				<MDBox display="flex" alignItems="center">
					<button onClick={backClickHandler} style={{border: 'none', background: 'white', borderRadius: '5px', marginRight: '1rem',width: '2rem', height: '2rem',display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
						<Icon fontSize="medium">
							arrow_back
						</Icon>
					</button>
					<MDTypography variant="h3" fontWeight="regular">
					Detail Inventory Transfer
					</MDTypography>
				</MDBox>
				<MDSnackbar
					color="error"
					icon="error"
					title="Autoloka"
					content={errorMessage}
					open={showNotifError}
					dateTime="Just Now"
					close={() => setShowNotifError(false)}
				/>
			</MDBox>
			<MDBox width="100%" maxWidth="1200px" mb={2}>
				<Card
					sx={{
						width: "100%",
						margin: "0 auto",
						padding: "24px"
					}}
				>
					<Grid container spacing={3} maxWidth={"500px"}>
						<Grid item xs={12} md={12} xl={8}>
						<MDInput
							id="select-is-active" 
							size="small" 
							fullWidth 
							label="Doc Type"
							type="select" 
							select
							value={detailData?.doc_type || ""}
							disabled
						>
								<MenuItem disabled value="">
									<em>Neither</em>
								</MenuItem>
								<MenuItem value="INTERNAL MOVE">INTERNAL MOVE</MenuItem>
							</MDInput>
						</Grid>
						<Grid item xs={12} md={12} xl={8}>
							<MDInput type="text" label="Note" disabled fullWidth multiline rows={5} value={detailData?.note || ""} />
						</Grid>
					</Grid>
					<MDBox pt={3} mt={3} sx={{ borderTop: "2px dashed #000"}}>
						<DataTable
							table={{ columns: locatorColumns.filter(lc => lc.accessor !== "display_none_2"), rows : locatorRows }}
							isSorted={false}
							entriesPerPage={false}
							showTotalEntries={true}
							pagination={{variant: "contained", color: "primary"}}
							noEndBorder
							paginationDetail={locatorPaginationDetail}
							gotoPage={goToPageLocator}
						/>
					</MDBox>
				</Card>
			</MDBox>
		</Grid>
  );
}

export default DocInventoryView;
