import { Alert, Button, Dialog, DialogActions, DialogTitle, Icon } from '@mui/material';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import backIcon from "../assets/icons/back.png";

import AppBox from '../components/AppBox';
import Header from '../components/Header';
import State from '../components/State';
import useFetchState from '../hooks/useFetchState';

import services from '../service/services';

const AllApps = ({appsList, setAppsList, setMenuActive, setAppActive, setMode, getMainMenuFetcher}) =>{

	const getAllApps = useFetchState();
	const installApp = useFetchState();

	const [installPopup, setInstallPopup] = useState(false);
	const [selectedApp, setSelectedApp] = useState(null);

	const navigate = useNavigate();

	const [appOnHover, setAppOnHover] = useState('');

	const openPopup = () => {
		setInstallPopup(true);
	}

	const closePopup = () => {
		setInstallPopup(false);
	}

	const getAllAppsFetcher = () => {
		getAllApps.fetchStart();
			services.getAllApps().then((res) => {
				getAllApps.fetchSuccess(res.data?.data || []);
			}).catch((err) => {
				getAllApps.fetchFailed(err?.message || err?.response?.data?.message || 'Ooops something went wrong!');
			});
	}
	
	const installAppFetcher = () => {
		if(installApp.loading) return;
		installApp.fetchStart();
		services.installApps({
			user_role_menu_id: selectedApp?.user_role_menu_id,
			role_menu_id: selectedApp?.role_menu_id,
			is_active: selectedApp?.is_active === 1 ? 0 : 1
		}).then((res) => {
			closePopup();
			installApp.fetchSuccess(res.data?.data);
			getAllAppsFetcher();
		}).catch((err) => {
			installApp.fetchFailed(err?.message || err?.response?.data?.message || 'Ooops something went wrong!');
		})
	}

	useEffect(() => {
		getAllAppsFetcher();
	}, []);

	const onClickApp = (app) => {
		if(installApp.loading) return;
		setSelectedApp(app);
		openPopup();
	}

	const onBackClick = () => {
		navigate('/my-apps');
		getMainMenuFetcher();
	}

	return (
		<>
			<Dialog
				open={installPopup}
				onClose={closePopup}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title" style={{fontSize: '16px'}}>
					{`Are you sure to ${selectedApp?.is_active === 1 ? 'Uninstall' : 'Install'} ${selectedApp?.name} App ?`}
				</DialogTitle>
				<DialogActions>
					<Button disabled={installApp.loading} onClick={installAppFetcher} autoFocus>
						{installApp.loading ? (selectedApp?.is_active === 1 ? 'Uninstalling...' : 'Installing...') : (selectedApp?.is_active === 1 ? 'Uninstall' : 'Install' )}
					</Button>
				</DialogActions>
			</Dialog>
			<Header setMenuActive={setMenuActive} setAppActive={setAppActive} setMode={setMode} />
			<div className='header-flex'>
				<div onClick={onBackClick} className='back-button'><img src={backIcon} alt="go back" /></div>
			</div>
			<div className='apps'>
				<State data={getAllApps.data} isLoading={getAllApps.loading} isError={getAllApps.error}>
					{(state) => (
						<>
							<State.Data state={state}>
								{getAllApps.data?.map((app, i) => {
										return (
											<div className="" style={{position: 'relative'}}>
												{app?.is_active === 1 && <Icon style={{position: 'absolute', top: '-15px', right: '-10px',color: '#007fff'}} fontSize="large">check_circle</Icon>}
												<AppBox 
													key={`unadded-app-list-`+i} 
													app={{
															apps_icon: app?.icon,
															apps_name: app?.name
														}} 
													onClick={() => onClickApp(app)}
													className="add-app" 
													iconName={'fa-solid '+ app?.icon} 
													count={i+1} 
													appOnHover={appOnHover} 
													onMouseEnter={() => setAppOnHover(i+1)} 
													onMouseLeave={() => setAppOnHover('')} />
													{(selectedApp?.id === app.id) && installApp.loading && <p className="" style={{position: 'absolute', bottom: '-30px'}}>
														{(selectedApp?.is_active === 1 ? 'Uninstalling...' : 'Installing...')}
													</p> }
											</div>
										)
									})}
							</State.Data>
							<State.Loading state={state}>
								<p>Getting apps...</p>
							</State.Loading>
							<State.Error state={state}>
								<Alert severity="error">{getAllApps.error}</Alert>
							</State.Error>
						</>
					)}
				</State>
			</div>
		</>
	)
}
	

export default AllApps;