import React, { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

import Box from '@mui/material/Box';
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';

import MDTypography from '../../../../MDTypography';
import MDInput from '../../../../MDInput';
import MDButton from '../../../../MDButton';
import MDBox from '../../../../MDBox';
import services from '../../../../../service/services';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  width: 'fit-content',
	maxWidth: '90vw',
  p: 3,
	borderRadius: "12px",
};

const FilterModal = ({ 
	open, 
	setOpen,
	attributeNameOptions,
	attributeCodeOptions,
	filterList,
	dataFilter,
	setDataFilter,
}) => {

	const handleClose = () => {
		setOpen(false);
	};

	const handleFilter = () => {
		handleClose();
		filterList(1);
	}

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="grid-modal" sx={style}>
					<MDBox position="relative">
						<Icon style={{ position: "absolute", right: "-12px", top: "-12px" }} className="c-pointer" onClick={handleClose} fontSize="small">close</Icon>
					</MDBox>
          <MDTypography variant="h4">
						Filter
					</MDTypography>
					<Grid container spacing={3} sx={{ width: "800px", maxWidth: "90vw", marginTop: "0", marginBottom: "24px"}}>
						<Grid item xs={12} sm={6}>
							<Autocomplete
								options={attributeNameOptions}
								size="small"
								renderInput={(params) => (
									<MDInput {...params} label="Attribute Name" />
								)}
								onChange={(e, newValue) => setDataFilter({...dataFilter, attribute_name: newValue})}
								value={dataFilter.brand_name}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Autocomplete
								options={attributeCodeOptions}
								size="small"
								renderInput={(params) => (
									<MDInput {...params} label="Attribute Code" />
								)}
								onChange={(e, newValue) => setDataFilter({...dataFilter, attribute_code: newValue})}
								value={dataFilter.attribute_code}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<MDInput 
								onChange={e => setDataFilter({...dataFilter, is_active: e.target.value})}
								value={dataFilter.is_active} 
								id="select-status-type" 
								size="small" 
								fullWidth 
								label="Status" 
								type="select" 
								select
							>
								<MenuItem value={""}>Any</MenuItem>
								<MenuItem value="1">Active</MenuItem>
								<MenuItem value="0">Not Active</MenuItem>
							</MDInput>
						</Grid>
					</Grid>
					<MDBox display="flex" alignItems="center" justifyContent="end">
						<MDButton onClick={handleFilter} variant="gradient" color="info">Filter</MDButton>
					</MDBox>
        </Box>
      </Modal>
    </div>
  );
}

export default FilterModal;