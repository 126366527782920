import { Button } from "@mui/material";
import { useEffect } from "react";
import useFetchState from "../../../hooks/useFetchState";
import services from "../../../service/services";
import MDBox from "../../MDBox";

const SubcategoryDetail = ({
    type,
    setStep,
    setSubCategory
}) => {

    const getSubcategory = useFetchState();

    useEffect(() => {
        if(type) {
            getSubcategory.fetchStart();
            services.getSubcategoryProduct(type?.model_id, 0, 0).then((res) => {
                getSubcategory.fetchSuccess(res?.data?.data);
            }).catch((err) => { 
                getSubcategory.fetchFailed(err?.message || 'Ooops something went wrong!');
            })
        }
    }, [type]);

    const onClickAccesories = (accessory) => {
        setStep((prev) => prev + 1);
        setSubCategory(accessory);
    }

    return (
        <MDBox style={{width: '90vw'}} >
            <Button onClick={() => setStep((prev) => prev - 1)} variant="text">Back</Button>
            {getSubcategory.loading ? <p style={{textAlign: 'center', fontWeight: 'bold', fontSize: '1rem'}}>Get data...</p> : 
            <>
                {getSubcategory.data?.subcategory_list?.length === 0 ? <p style={{textAlign: 'center', fontWeight: 'bold', fontSize: '1rem'}}>Empty product</p> : 
                    <>
                        <div className="subcategory-detail-container">
                        <div style={{flex: 1}}>
                            <p style={{fontSize: '1rem', margin: '0'}}>Accesories for</p>
                            <p style={{fontSize: '2rem', margin: '0', color: '#4197ef', fontWeight: 'bold'}}>
                                {getSubcategory.data?.name}
                            </p>
                        </div>
                        <div style={{flex: 1}} className="subcategory-detail-grid">
                            <div >
                                <p style={{margin: 0}}>Merk</p>
                                <p style={{fontWeight: 'bold', fontSize: '1rem'}}>
                                    {getSubcategory.data?.brand_name}
                                </p>
                            </div>
                            <div >
                                <p style={{margin: 0}}>Year</p>
                                <p style={{fontWeight: 'bold', fontSize: '1rem'}}>
                                    {getSubcategory.data?.model_year}
                                </p>
                            </div>
                            <div >
                                <p style={{margin: 0}}>Model</p>
                                <p style={{fontWeight: 'bold', fontSize: '1rem'}}>
                                    {getSubcategory.data?.model}
                                </p>
                            </div>
                            <div >
                                <p style={{margin: 0}}>Type</p>
                                <p style={{fontWeight: 'bold', fontSize: '1rem'}}>
                                    {getSubcategory.data?.type}
                                </p>
                            </div>
                        </div>
                        <img src={getSubcategory.data?.model_image} alt=""  />
                        </div> 
                        <div className="subcategory-list">
                            <p style={{fontSize: '1.2rem', fontWeight: 'bold'}}>Accesories for {getSubcategory.data?.brand_name}</p>
                            <div style={{display: 'flex', flexWrap: 'wrap', gap: '1rem', maxHeight: '300px', overflowY: 'auto'}}>
                                {getSubcategory.data?.subcategory_list?.map((el) => (
                                    <div key={el.subcategory_id} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100px'}}>
                                        <button className="button-accessories" type="button" onClick={() => onClickAccesories(el)} key={el.subcategory_id}>
                                            <img src={el.subcategory_image} alt="" />
                                        </button>
                                        <p style={{textAlign: 'center'}} >{el.subcategory_name}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </> }
            </>
            }
        </MDBox>
    )
}

export default SubcategoryDetail;