import { useEffect, useRef, useState } from "react";
import axios from "axios";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import UserManagementIndex from "../../../../components/AppComponents/Autoloka/userManagement/user/index";
import UserManagementAdd from "../../../../components/AppComponents/Autoloka/userManagement/user/add";
import UserManagementEdit from "../../../../components/AppComponents/Autoloka/userManagement/user/edit";
import UserManagementView from "../../../../components/AppComponents/Autoloka/userManagement/user/view";
import DeleteConfirmationModal from "../../../../components/modals/deleteConfirmation";

import MDSnackbar from "../../../../components/MDSnackbar";
import MDButton from "../../../../components/MDButton";

import services from "../../../../service/services";

import { addCommas, cleanObject } from "../../../../utils";
import { useReactToPrint } from "react-to-print";

import QrImg from '../../../../assets/images/qr-code.png';
import { useSearchParams } from "react-router-dom";

const UserManagementUser = ({
	isLoading,
	setIsLoading,
	setMode,
	mode,
 }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [rows, setRows] = useState([]);
	const [rowsDisplayed, setRowsDisplayed] = useState([]);
	const [idDetail, setIdDetail] = useState(null);
	const [paginationDetail, setPaginationDetail] = useState({});
	const [isFiltered, setIsFiltered] = useState(false);
	const [productOptions, setProductOptions] = useState([]);
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [confirmationDisplay, setConfirmationDisplay] = useState(false);
	const [dataFilter, setDataFilter] = useState({
		name: searchParams.get("name") || "",
		email: searchParams.get("email") || "",
		status: searchParams.get("status") || "",
	});

  const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Action", accessor: "action", align: "left" },
		{ Header: "Name", accessor: "full_name", align: "left" },
		{ Header: "Email", accessor: "email", align: "left" },
		{ Header: "Phone Number", accessor: "phone_number", align: "left" },
		{ Header: "Join Date", accessor: "join_date", align: "left" },
		{ Header: "Status", accessor: "status", align: "left" },
	];

	useEffect(() => {

		let fetcher;
		if(Object.keys(cleanObject(dataFilter)).length !== 0) {
			fetcher = services.getFilterUser({...dataFilter, page: searchParams.get("page") || 1})
		}else {
			fetcher = services.getAllUser(searchParams.get("page") || 1)
		}

		if(mode === "index"){
			setIsLoading(true);
			Promise
				.all([
					fetcher,
				]).then(axios.spread((...res) => {
					setIsLoading(false);
					setRows(settingRowsDataTable(res[0]?.data?.data?.list, 1));
					setPaginationDetail(res[0]?.data?.data);
					// setProductOptions(res[1]?.data?.data);
				})).catch(errors => {
					setIsLoading(false);
					console.log("Error is found, apologies.")
					console.log(errors)
				})
		}
	}, [mode]);

	const settingRowsDataTable = (rowsArray, current_page) => {
		return rowsArray?.map((arr, i) => {
			return {
				no: ((current_page - 1) * 10) + i + 1,
				action: <Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setMode("view"); setIdDetail(arr.id)}}>visibility</Icon>,
				full_name: arr?.full_name,
				email: arr?.email,
				phone_number: arr?.phone_number,
				join_date: arr?.join_date,
				status: arr?.status === 0 ? 'Non active' : 'Active'
			}
		})
	};

	const getList = (page) => {
		setIsLoading(true);
		setIsFiltered(false);
		services.getAllUser(page)
			.then(res => {
				setIsLoading(false);
				setIsFiltered(false);
				setRows(settingRowsDataTable(res?.data?.data?.list, res?.data?.data?.current_page));
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	};

  return (
		<>
			<Grid container spacing={6} className="grid-pure-container">
				{
					mode === "index" ?
						<UserManagementIndex 
							setDataFilter={setDataFilter}
							dataFilter={dataFilter}
							columns={columns} 
							rows={rows}
							rowsDisplayed={rowsDisplayed}
							setRowsDisplayed={setRowsDisplayed}
							setIsLoading={setIsLoading}
							paginationDetail={paginationDetail}
							setPaginationDetail={setPaginationDetail}
							isFiltered={isFiltered}
							setIsFiltered={setIsFiltered}
							getList={getList}
							settingRowsDataTable={settingRowsDataTable}
							setMode={setMode}
						/>
					: mode === "view" ?
						<UserManagementView 
							setMode={setMode}
							setIsLoading={setIsLoading} 
							id={idDetail} 
						/>
					: null
				}
			</Grid>
			{/* <DeleteConfirmationModal open={confirmationDisplay} setOpen={setConfirmationDisplay} itemName="User" deleteHandler={() => deleteDraft(idDetail)} />  */}
		</>
  );
}

export default UserManagementUser;
