import { useRef, useState } from "react";
import { useForm } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { MenuItem, TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDButton from "../../../../MDButton";
import MDTypography from "../../../../MDTypography";

import placeholder from "../../../../../assets/images/placeholder-image.jpeg";
import MDInput from "../../../../MDInput";
import MDSnackbar from "../../../../MDSnackbar";

import services from "../../../../../service/services";

import { getImageError, toBase64 } from "../../../../../utils";

const BrandAdd = ({
	brandTypeOptions,
	setIsLoading,
}) => {

	const header_imageRef = useRef();
	const brand_imageRef = useRef();
	const car_imageRef = useRef();

	const [images, setImages] = useState({
		header_image: null,
		brand_image: null,
		car_image: null,
	});
	const [isTransparent, setIsTransparent] = useState({
		header_image: false,
		brand_image: false,
		car_image: false,
	});
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const { register, handleSubmit, setValue, trigger, reset, watch, formState: { errors } } = useForm({mode: "all"});

	const watchAllFields = watch();

	const onImageChange = async (e, key) => {
		let format = e.target.files[0].name.split('.').pop();
		setIsTransparent({...isTransparent, [key]: format.includes("png")})
		let val = await toBase64(e.target.files[0]);
		setValue(key, val);
		const result = await trigger(key);
		setImages({...images, [key]: URL.createObjectURL(e.target.files[0])});
	}

	const removeImage = async (key) => {
		setIsTransparent({...isTransparent, [key]: false});
		setValue(key, null);
		const result = await trigger(key);
		setImages({...images, [key]: null});
	}
	
	const submitCreate = (e) => {
		setIsLoading(true);
		services.createBrand(e)
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
				reset({
					brand_code: "",
					brand_image: "",
					brand_name: "",
					car_image: "",
					header_image: "",
					is_popular: "",
					is_active: "",
					brand_type: "",
				});
				setImages({
					header_image: null,
					brand_image: null,
					car_image: null,
				})
			}).catch(errors => {
				setIsLoading(false);
				setErrorMessage(errors?.response?.data?.message);
				setShowNotifError(true);
				console.log(errors)
			})
	}

  return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" justifyContent="space-between" mt={-0.5} mb={2.5} width="100%">
				<MDTypography variant="h3" fontWeight="regular">
					Add New Brand
				</MDTypography>
				<MDButton color="info" 
					type="submit" form="create-brand"
				>
					Save
				</MDButton>
			</MDBox>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="New Brand Added!"
				open={showNotifSuccess}
				dateTime="Just Now"
				close={() => setShowNotifSuccess(false)}
			/>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			<MDBox component="form" role="form" id="create-brand" onSubmit={handleSubmit(submitCreate)}>
				<Grid container spacing={3}>
					<Grid item xs={12} lg={3}>
						<Card
							sx={{
								"&:hover .card-header": {
									transform: "translate3d(0, -50px, 0)",
								},
								maxWidth: "500px",
								margin: "0 auto",
								border: errors?.header_image ? "2px solid red" : "inherit"
							}}
						>
							<MDBox
								position="relative"
								borderRadius="lg"
								mt={-3}
								mx={1}
								className="card-header"
								sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)", zIndex: 999, backgroundColor: "transparent" }}
							>
								<MDBox
									sx={{ backgroundColor: isTransparent.header_image ? "#FFF" : "", borderRadius: "8px" }}
								>
									<MDBox
										component="img"
										src={images?.header_image ? images.header_image : placeholder}
										alt="Product Image"
										borderRadius="lg"
										shadow="sm"
										width="100%"
										height="100%"
										position="relative"
										zIndex={10}
										onError={getImageError}
									/>
								</MDBox>
							</MDBox>
							<MDBox textAlign="center" pt={4} pb={1} px={3}>
								<MDBox
									display="flex"
									justifyContent="center"
									alignItems="center"
									mt={-10}
									mb={-1}
									position="relative"
									zIndex={1}
								>
									<MDBox mr={1}>
										<input
											{...register("header_image", { required: "Required" })}
											type="file"
											accept="image/png;image/jpg;image/jpeg;"
											ref={header_imageRef}
											onChange={(e) => onImageChange(e, "header_image")}
											className="d-none"
										/>
										<MDButton variant="gradient" color="info" size="small" onClick={() => header_imageRef.current.click()}>
											edit
										</MDButton>
									</MDBox>
									<MDButton variant="outlined" color="dark" size="small" onClick={() => removeImage("header_image")}>
										remove
									</MDButton>
								</MDBox>
								<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 3 }}>
									Header Image
								</MDTypography>
								{
									errors?.header_image &&
										<MDTypography fontWeight="bold" sx={{ fontSize: "10px", color: "red" }}>
											{errors.header_image.message}
										</MDTypography>
								}
							</MDBox>
						</Card>
						<Card
							sx={{
								"&:hover .card-header": {
									transform: "translate3d(0, -50px, 0)",
								},
								maxWidth: "500px",
								margin: "0 auto",
								marginTop: "48px",
								border: errors?.car_image ? "2px solid red" : "inherit"
							}}
						>
							<MDBox
								position="relative"
								borderRadius="lg"
								mt={-3}
								mx={1}
								mb={-1}
								className="card-header"
								sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)", zIndex: 999, backgroundColor: "transparent" }}
							>
								<MDBox
									sx={{ backgroundColor: isTransparent.car_image ? "#FFF" : "", borderRadius: "8px" }}
								>
									<MDBox
										component="img"
										src={images?.car_image ? images.car_image : placeholder}
										alt="Product Image"
										borderRadius="lg"
										shadow="sm"
										width="100%"
										height="100%"
										position="relative"
										zIndex={10}
										onError={getImageError}
									/>
								</MDBox>
							</MDBox>
							<MDBox textAlign="center" pt={4} pb={1} px={3}>
								<MDBox
									display="flex"
									justifyContent="center"
									alignItems="center"
									mt={-9}
									mb={-1}
									position="relative"
									zIndex={1}
								>
									<MDBox mr={1}>
										<input
											{...register("car_image", { required: "Required" })}
											type="file"
											accept="image/png;image/jpg;image/jpeg;"
											ref={car_imageRef}
											onChange={(e) => onImageChange(e, "car_image")}
											className="d-none"
										/>
										<MDButton variant="gradient" color="info" size="small" onClick={() => car_imageRef.current.click()}>
											edit
										</MDButton>
									</MDBox>
									<MDButton variant="outlined" color="dark" size="small" onClick={() => removeImage("car_image")}>
										remove
									</MDButton>
								</MDBox>
								<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 3 }}>
									Car Image
								</MDTypography>
								{
									errors?.car_image &&
										<MDTypography fontWeight="bold" sx={{ fontSize: "10px", color: "red" }}>
											{errors.car_image.message}
										</MDTypography>
								}
							</MDBox>
						</Card>
					</Grid>
					<Grid item xs={12} lg={3}>
						<Card
							sx={{
								"&:hover .card-header": {
									transform: "translate3d(0, -50px, 0)",
								},
								maxWidth: "500px",
								margin: "0 auto",
								border: errors?.brand_image ? "2px solid red" : "inherit"
							}}
						>
							<MDBox
								position="relative"
								borderRadius="lg"
								mt={-3}
								mx={1}
								mb={-1}
								className="card-header"
								sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)", zIndex: 999, backgroundColor: "transparent" }}
							>
								<MDBox
									sx={{ backgroundColor: isTransparent.brand_image ? "#FFF" : "", borderRadius: "8px" }}
								>
									<MDBox
										component="img"
										src={images?.brand_image ? images.brand_image : placeholder}
										alt="Product Image"
										borderRadius="lg"
										shadow="sm"
										width="100%"
										height="100%"
										position="relative"
										zIndex={10}
										onError={getImageError}
									/>
								</MDBox>
							</MDBox>
							<MDBox textAlign="center" pt={4} pb={1} px={3}>
								<MDBox
									display="flex"
									justifyContent="center"
									alignItems="center"
									mt={-9}
									mb={-1}
									position="relative"
									zIndex={1}
								>
									<MDBox mr={1}>
										<input
											{...register("brand_image", { required: "Required" })}
											type="file"
											accept="image/png;image/jpg;image/jpeg;"
											ref={brand_imageRef}
											onChange={(e) => onImageChange(e, "brand_image")}
											className="d-none"
										/>
										<MDButton variant="gradient" color="info" size="small" onClick={() => brand_imageRef.current.click()}>
											edit
										</MDButton>
									</MDBox>
									<MDButton variant="outlined" color="dark" size="small" onClick={() => removeImage("brand_image")}>
										remove
									</MDButton>
								</MDBox>
								<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 3 }}>
									Brand Image
								</MDTypography>
								{
									errors?.brand_image &&
										<MDTypography fontWeight="bold" sx={{ fontSize: "10px", color: "red" }}>
											{errors.brand_image.message}
										</MDTypography>
								}
							</MDBox>
						</Card>
					</Grid>
					<Grid xs={12} lg={6} item>
						<Card sx={{ padding: "24px 32px" }}>
							<MDTypography variant="h4" fontWeight="regular" sx={{ ml: -1, mb: 4}}>
								Brand Information
							</MDTypography>
								<MDBox mb={2}>
									<MDInput error={errors?.brand_name} {...register("brand_name", { required: "Required" })} type="text" label="Brand Name" fullWidth />
									{errors?.brand_name && 
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.brand_name.message}</MDTypography>
										</MDBox>
									}
								</MDBox>
								<MDBox mb={2}>
									<MDInput error={errors?.brand_code} {...register("brand_code", { required: "Required", maxLength: {value: 2, message: "Maximum character is 2"} })} type="text" label="Brand Code" fullWidth />
									{errors?.brand_code && 
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.brand_code.message}</MDTypography>
										</MDBox>
									}
								</MDBox>
								<MDBox mb={2}>
									<TextField
										{...register("brand_type", { required: "Required" })}
										id="select-brand-type" 
										size="small" 
										fullWidth 
										label="Brand Type"
										type="select" 
										select
										error={errors?.brand_type} 
										value={watchAllFields.brand_type || ""}
										displayEmpty
									>
										<MenuItem disabled value="">
											<em>Neither</em>
										</MenuItem>
										{
											brandTypeOptions?.map((bt, i) => {
												return <MenuItem key={"option-autoloka-brand-name-"+i} value={bt.brand_type}>{bt.type_name}</MenuItem>
											})
										}
									</TextField>
									{errors?.brand_type && 
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.brand_type.message}</MDTypography>
										</MDBox>
									}						
								</MDBox>
								<MDBox mb={2}>
									<TextField
										{...register("is_popular", { required: "Required" })}
										id="select-popular" 
										size="small" 
										fullWidth 
										label="Popular"
										type="select" 
										select
										error={errors?.is_popular} 
										value={watchAllFields.is_popular || ""}
									>
										<MenuItem disabled value="">Neither</MenuItem>
										<MenuItem value="1">Yes</MenuItem>
										<MenuItem value="0">No</MenuItem>
									</TextField>
									{errors?.is_popular && 
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.is_popular.message}</MDTypography>
										</MDBox>
									}
								</MDBox>
								<MDBox mb={2}>
									<TextField
										{...register("is_active", { required: "Required" })}
										id="select-is-active" 
										size="small" 
										fullWidth 
										label="Active"
										type="select" 
										select
										error={errors?.is_active} 
										value={watchAllFields.is_active || ""}
									>
										<MenuItem disabled value="">
											<em>Neither</em>
										</MenuItem>
										<MenuItem value="1">Active</MenuItem>
										<MenuItem value="0">Not Active</MenuItem>
									</TextField>
									{errors?.is_active && 
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.is_active.message}</MDTypography>
										</MDBox>
									}
								</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		</Grid>
  );
}

export default BrandAdd;
