import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

import { getImageError } from '../../utils';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  width: 'fit-content',
	maxWidth: '90vw',
  p: 3,
};

const ImageZoomModal = ({ open, setOpen, imgSource, setImgSource }) => {

	const handleClose = () => {
		setOpen(false);
		setImgSource(null);
	};

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="grid-modal" sx={style}>
          <img style={{ maxWidth: "100%" }} src={imgSource} onError={getImageError} />
        </Box>
      </Modal>
    </div>
  );
}

export default ImageZoomModal;