import {
	Navigate,
	Outlet,
} from 'react-router-dom';
import Cookies from "js-cookie";
  
const ProtectedRoute = ({
	children
}) => {

	let isLoggedIn = Cookies.get("loggedIn") ? Cookies.get("loggedIn") : false;
	
	if(!isLoggedIn){
		return <Navigate to="/" replace />;
	}

	return children ? children : <Outlet />;
};

export default ProtectedRoute;