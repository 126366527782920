import { useEffect, useState } from "react";
import axios from "axios";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import ImageZoomModal from "../../../../components/modals/imageZoom";
import ModelIndex from "../../../../components/AppComponents/Autoloka/masterData/model";
import ModelAdd from "../../../../components/AppComponents/Autoloka/masterData/model/add";
import ModelEdit from "../../../../components/AppComponents/Autoloka/masterData/model/edit";
import DeleteConfirmationModal from "../../../../components/modals/deleteConfirmation";

import services from "../../../../service/services";

import placeholder from "../../../../assets/images/placeholder-image.jpeg";
import MDSnackbar from "../../../../components/MDSnackbar";

import { cleanObject, getImageError } from "../../../../utils";
import { useSearchParams } from "react-router-dom";

const Model = ({
	setIsLoading,
	setMode,
	mode,
}) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [rows, setRows] = useState([]);
	const [rowsDisplayed, setRowsDisplayed] = useState([]);
	const [rawRowsDisplayed, setRawRowsDisplayed] = useState([]);
	const [paginationDetail, setPaginationDetail] = useState({});
	const [imgSource, setImgSource] = useState(null);
	const [open, setOpen] = useState(false);
	const [brandNameOptions, setBrandNameOptions] = useState([]);
	const [modelNameOptions, setModelNameOptions] = useState([]);
	const [modelCodeOptions, setModelCodeOptions] = useState([]);
	const [parentNameOptions, setParentNameOptions] = useState([]);
	const [brandCode, setBrandCode] = useState([]);
	const [indexDetail, setIndexDetail] = useState(null);
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [isFiltered, setIsFiltered] = useState(false);
	const [id, setId] = useState(null);
	const [confirmationDisplay, setConfirmationDisplay] = useState(false);
	const [dataFilter, setDataFilter] = useState({
		brand_name: searchParams.get("brand_name") || "",
		model_name: searchParams.get("model_name") || "",
		brand_code: searchParams.get("brand_code") || "",
		is_active: searchParams.get("is_active") || "",
	});

	const handleOpen = (e, source) => {
		if (source) {
			setOpen(true);
			setImgSource(source);
		} else {
			e.preventDefault();
		}
	};

	const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Edit", accessor: "edit", align: "left" },
		{ Header: "Brand Name", accessor: "brand_name", align: "left" },
		{ Header: "Model Name", accessor: "model_name", align: "left" },
		{ Header: "Model Year", accessor: "model_year", align: "left" },
		{ Header: "Model Code", accessor: "model_code", align: "left" },
		{ Header: "Model Image", accessor: "model_image", align: "left" },
		{ Header: "Status", accessor: "status", align: "left" },
	];

	useEffect(() => {

		let fetcher;
		if (Object.keys(cleanObject(dataFilter)).length !== 0) {
			fetcher = services.filterModelList({ ...dataFilter, page: searchParams.get("page") || 1 })
		} else {
			fetcher = services.getMasterDataModel(searchParams.get("page") || 1)
		}

		if (mode === "index") {
			setIsLoading(true);
			Promise
				.all([
					fetcher,
					services.getOptionsModelName(""),
					services.getOptionsModelCode(""),
					services.getBrandInformationModel(),
					services.getParentNameModel(),
				]).then(axios.spread((...res) => {
					setIsLoading(false);
					setPaginationDetail(res[0]?.data?.data);
					setRows(settingRowsDataTable(res[0]?.data?.data?.list, res[0]?.data?.data?.current_page));
					setRawRowsDisplayed(res[0]?.data?.data?.list);
					setModelNameOptions(res[1]?.data?.data);
					setModelCodeOptions(res[2]?.data?.data);
					// let bnOptions = res[3]?.data?.data.map(r => r.brand_name);
					// setBrandNameOptions(bnOptions);
					setBrandNameOptions(res[3]?.data?.data);
					let bcArray = res[3]?.data?.data.map(r => r.brand_code);
					setBrandCode(bcArray);
					setParentNameOptions(res[4]?.data?.data);
				})).catch(errors => {
					setIsLoading(false);
					console.log("Error is found, apologies.")
					console.log(errors)
				})
		}
	}, [mode]);

	const deleteModel = (id) => {
		setIsLoading(true);
		setShowNotifSuccess(false);
		services.deleteModel(id)
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
				setConfirmationDisplay(false);
				getList(1);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	}

	const getList = (page) => {
		setIsLoading(true)
		services.getMasterDataModel(page)
			.then(res => {
				setIsLoading(false);
				setIsFiltered(false);
				setRows(settingRowsDataTable(res?.data?.data?.list, res?.data?.data?.current_page));
				setRawRowsDisplayed(res?.data?.data?.list);
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors);
			})
	};

	const settingRowsDataTable = (rowsArray, current_page) => {
		return rowsArray?.map((arr, i) => {
			return {
				no: ((current_page - 1) * 10) + i + 1,
				edit: (
					<>
						<Icon style={{ cursor: "pointer" }} fontSize="small" onClick={() => { setMode("edit"); setIndexDetail(i) }}>edit</Icon>
						<Icon style={{ cursor: "pointer" }} fontSize="small" onClick={() => { setId(arr.id); setConfirmationDisplay(true); }}>delete</Icon>
					</>
				),
				brand_name: arr?.brand_name,
				model_name: arr?.model_name,
				model_year: arr?.model_year == null ? " - " : arr?.model_year?.join(', '),
				model_code: arr?.model_code,
				model_image: <img onClick={(e) => handleOpen(e, arr?.model_image)} onError={getImageError} className={"img-thumbnail-pagination " + (arr.model_image ? "" : "forbidden")} src={arr?.model_image ? arr.model_image : placeholder} />,
				status: arr?.is_active == "0" || arr?.status == "0" ? "Not Active" : arr?.is_active == "1" || arr?.status == "1" ? "Active" : ""
			};
		});
	};

	return (
		<>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="Model Deleted!"
				dateTime="Just Now"
				open={showNotifSuccess}
				close={() => setShowNotifSuccess(false)}
			/>
			<Grid container spacing={6} className="grid-pure-container">
				{
					mode === "index" ?
						<ModelIndex
							setDataFilter={setDataFilter}
							dataFilter={dataFilter}
							columns={columns}
							rows={rows}
							rowsDisplayed={rowsDisplayed}
							setRowsDisplayed={setRowsDisplayed}
							setRawRowsDisplayed={setRawRowsDisplayed}
							setIndexDetail={setIndexDetail}
							brandNameOptions={brandNameOptions}
							modelNameOptions={modelNameOptions}
							modelCodeOptions={modelCodeOptions}
							setIsLoading={setIsLoading}
							placeholder={placeholder}
							setMode={setMode}
							deleteModel={deleteModel}
							paginationDetail={paginationDetail}
							setPaginationDetail={setPaginationDetail}
							getList={getList}
							isFiltered={isFiltered}
							setIsFiltered={setIsFiltered}
							settingRowsDataTable={settingRowsDataTable}
						/>
						: mode === "add" ?
							<ModelAdd brandNameOptions={brandNameOptions} brandCode={brandCode} parentNameOptions={parentNameOptions} setIsLoading={setIsLoading} />
							: mode === "edit" ?
								<ModelEdit setMode={setMode} brandNameOptions={brandNameOptions} brandCode={brandCode} parentNameOptions={parentNameOptions} indexDetail={indexDetail} rawRowsDisplayed={rawRowsDisplayed} setIsLoading={setIsLoading} />
								: null
				}
			</Grid>
			<ImageZoomModal open={open} setOpen={setOpen} imgSource={imgSource} setImgSource={setImgSource} />
			<DeleteConfirmationModal open={confirmationDisplay} setOpen={setConfirmationDisplay} itemName="model" deleteHandler={() => deleteModel(id)} />
		</>
	);
}

export default Model;
