import { useEffect, useState } from "react";
import axios from "axios";
import moment from 'moment'

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import PointOfSalesIndex from "../../../../components/AppComponents/Autoloka/pointOfSales/sales/index";
import PointOfSalesAdd from "../../../../components/AppComponents/Autoloka/pointOfSales/sales/add";
import PointOfSalesEdit from "../../../../components/AppComponents/Autoloka/pointOfSales/sales/edit";
import PointOfSalesView from "../../../../components/AppComponents/Autoloka/pointOfSales/sales/view";
import DeleteConfirmationModal from "../../../../components/modals/deleteConfirmation";

import MDSnackbar from "../../../../components/MDSnackbar";

import services from "../../../../service/services";

import { addCommas, cleanObject } from "../../../../utils";
import { marketPlace } from "../../../../components/AppComponents/Autoloka/pointOfSales/sales/data";
import { useSearchParams } from "react-router-dom";

const PosSales = ({
	isLoading,
	setIsLoading,
	setMode,
	mode,
 }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [rows, setRows] = useState([]);
	const [rowsDisplayed, setRowsDisplayed] = useState([]);
	const [idDetail, setIdDetail] = useState(null);
	const [paginationDetail, setPaginationDetail] = useState({});
	const [isFiltered, setIsFiltered] = useState(false);
	const [productOptions, setProductOptions] = useState([]);
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [confirmationDisplay, setConfirmationDisplay] = useState(false);
	const [dataFilter, setDataFilter] = useState({
		sales_order_no: searchParams.get("sales_order_no") || "",
		invoice_no: searchParams.get("invoice_no") || "",
		marketplace_id: searchParams.get("marketplace_id") || "",
		customer_name: searchParams.get("customer_name") || "",
		status: searchParams.get("status") || "",
		date_range: searchParams.get("date_range") || "",
	});

  const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Action", accessor: "action", align: "left" },
		{ Header: "Sales Order No", accessor: "so_no", align: "left" },
		{ Header: "Invoice No", accessor: "inv_no", align: "left" },
		{ Header: "Marketplace Name", accessor: "marketplace_name", align: "left" },
		{ Header: "Customer Name", accessor: "customer_name", align: "left" },
		{ Header: "Grand Total", accessor: "grand_total", align: "left" },
		{ Header: "Status", accessor: "status", align: "left" },
		{ Header: "Date", accessor: "date", align: "left" },
	];

	useEffect(() => {

		let data = {...dataFilter, page: searchParams.get("page") || 1};
		if(data?.date_range?.length > 0){
			let daterangevalue = data.date_range.split(" - ");
			data.start_date = moment(daterangevalue[0], "DD.MM.YYYY").format("YYYY.MM.DD");
			data.end_date = moment(daterangevalue[1], "DD.MM.YYYY").format("YYYY.MM.DD");
		} else {
			data.start_date = "";
			data.end_date = "";
		};
		delete data.date_range;

		let fetcher;
		if(Object.keys(cleanObject(dataFilter)).length !== 0) {
			fetcher = services.getFilterSales(data)
		}else {
			fetcher = services.getAllSales(searchParams.get("page") || 1)
		}

		if(mode === "index"){
			setIsLoading(true);
			Promise
				.all([
					fetcher,
					services.getSalesAllProduct(),
				]).then(axios.spread((...res) => {
					setIsLoading(false);
					setRows(settingRowsDataTable(res[0]?.data?.data?.list_so, 1));
					setPaginationDetail(res[0]?.data?.data);
					setProductOptions(res[1]?.data?.data);
				})).catch(errors => {
					setIsLoading(false);
					console.log("Error is found, apologies.")
					console.log(errors)
				})
		}
	}, [mode]);

	const settingRowsDataTable = (rowsArray, current_page) => {
		return rowsArray?.map((arr, i) => {
			return {
				no: ((current_page - 1) * 10) + i + 1,
				action: 
					arr?.status === 1 ?
					<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setMode("view"); setIdDetail(arr.id)}}>visibility</Icon>
					: <>
						<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setMode("edit"); setIdDetail(arr.id)}}>edit</Icon>
						<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setIdDetail(arr.id); setConfirmationDisplay(true);}}>delete</Icon>
					</>
				,
				so_no: arr?.so_no,
				inv_no: arr?.inv_no,
				marketplace_name: marketPlace.find((el) => el.id === arr?.marketplace_name)?.value || '',
				customer_name: arr?.customer_name,
				grand_total: "Rp"+addCommas(arr?.grand_total),
				status: arr?.status === 0 ? "Draft" : "New",
				date: arr?.date,
			}
		})
	};

	const getList = (page) => {
		setIsLoading(true);
		setIsFiltered(false);
		services.getAllSales(page)
			.then(res => {
				setIsLoading(false);
				setIsFiltered(false);
				setRows(settingRowsDataTable(res?.data?.data?.list_so, res?.data?.data?.current_page));
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	};

	const deleteDraft = (id) => {
		setIsLoading(true);
		setShowNotifSuccess(false);
		services.deleteDraftSales(id)
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
				setConfirmationDisplay(false);
				getList(1);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors);
			})
	};

  return (
		<>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="Point Of Sales Draft Deleted!"
				dateTime="Just Now"
				open={showNotifSuccess}
				close={() => setShowNotifSuccess(false)}
			/>
			<Grid container spacing={6} className="grid-pure-container">
				{
					mode === "index" ?
						<PointOfSalesIndex 
						setDataFilter={setDataFilter}
						dataFilter={dataFilter}
							columns={columns} 
							rows={rows}
							rowsDisplayed={rowsDisplayed}
							setRowsDisplayed={setRowsDisplayed}
							setIsLoading={setIsLoading}
							paginationDetail={paginationDetail}
							setPaginationDetail={setPaginationDetail}
							isFiltered={isFiltered}
							setIsFiltered={setIsFiltered}
							getList={getList}
							settingRowsDataTable={settingRowsDataTable}
							setMode={setMode}
						/>
					: mode === "add" ?
						<PointOfSalesAdd 
							setIsLoading={setIsLoading}
							productOptions={productOptions}
						/>
					: mode === "edit" ?
						<PointOfSalesEdit
						setMode={setMode}
							setIsLoading={setIsLoading}
							id={idDetail}
							productOptions={productOptions}
						/>
					: mode === "view" ?
						<PointOfSalesView 
						setMode={setMode}
							setIsLoading={setIsLoading} 
							id={idDetail} 
						/>
					: null
				}
			</Grid>
			<DeleteConfirmationModal open={confirmationDisplay} setOpen={setConfirmationDisplay} itemName="Point Of Sales" deleteHandler={() => deleteDraft(idDetail)} /> 
		</>
  );
}

export default PosSales;
