import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import MDTypography from '../MDTypography';
import MDButton from '../MDButton';
import MDBox from '../MDBox';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  width: 'fit-content',
	maxWidth: '90vw',
  p: 4,
  borderRadius: "8px",
};

const DeleteConfirmationModal = ({ open, setOpen, itemName, deleteHandler }) => {

	const handleClose = () => {
		setOpen(false);
	};

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="grid-modal" sx={style}>
					<MDTypography as="h3" sx={{ marginBottom: "24px"}}>
						Are you sure to delete the following {itemName}?
					</MDTypography>
          <MDBox display="flex" justifyContent="flex-end" gap={2}>
            <MDButton color="primary" onClick={deleteHandler}>
              YES
            </MDButton>
            <MDButton color="secondary" onClick={handleClose}>
              NO
            </MDButton>
          </MDBox>
        </Box>
      </Modal>
    </div>
  );
}

export default DeleteConfirmationModal;