import { useState } from "react";
import Cookies from "js-cookie";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

import breakpoints from "../../assets/theme/base/breakpoints";

// Material Dashboard 2 React example components
import SidenavCollapse from "./SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "./SidenavRoot";
import { Collapse } from "@mui/material";

// Material Dashboard 2 React context
import {
  setMiniSidenav,
  useMaterialUIController
} from "../../context";
import { set } from "date-fns";

function Sidenav({ color, brand,setAppActive, routes, appActive, menuActive, setMenuActive, setMode, mobileView, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { whiteSidenav, miniSidenav } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  const [isPreviouslyFalse, setIsPreviouslyFalse] = useState(false);

  let textColor = "dark";

  const [open, setOpen] = useState(false);
  const [expand, setExpand] = useState(" ");

  const handleClick = (e) => {
    if (e) {
      let dataID = e.currentTarget.getAttribute("data-id");
      if (expand === dataID) {
        setOpen(false);
        setExpand("");
      } else {
        setExpand(dataID);
        setOpen(true);
      }
    } else {
      setOpen(false);
      setExpand("");
    }
  };

  const handleMenuClick = (menu) => {
    if (menuActive === menu) {
      setMode("render again");
      setTimeout(() => setMode("index"), 100);
    } else {
      Cookies.set("menuActive", menu);
      setMenuActive(menu);
      setMode("index");
    }
  }

  const onClickLogoHandler = () => {
      Cookies.set("appActive", "");
      Cookies.set("menuActive", "");
      setAppActive(""); 
      setMenuActive("");
  }

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route, collapse, dropdown }, i) => {
    let returnValue;
    if (type === "collapse") {
      returnValue = href ?
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
            type={type}
          />
        </Link>
        : !dropdown ?
          <NavLink key={key} to={appActive + route}>
            <SidenavCollapse
              name={name}
              icon={icon}
              active={collapseName.split("/")[1] === key}
              handleClick={() => handleMenuClick(route)}
              type={"title"}
              noCollapse={noCollapse}
            />
          </NavLink>
          :
          <div key={key}>
            <SidenavCollapse
              name={name}
              icon={icon}
              active={collapseName.split("/")[1] === key}
              noCollapse={noCollapse}
              handleClick={(e) => handleClick(e)}
              open={open && expand === key}
              type={type}
              dataId={key}
              className="sidenav-child"
            />
            <Collapse in={open && expand === key} timeout="auto" unmountOnExit
            >
              <List component="div" disablePadding>
                {
                  collapse?.map((c, i) => {
                    return (
                      <NavLink onClick={() => handleMenuClick(c.route, c.route === menuActive)} key={"children-nav-" + i} to={appActive + c.route}>
                        <SidenavCollapse
                          name={c.name}
                          icon={c.icon}
                          active={c.route === menuActive}
                          noCollapse={true}
                        />
                      </NavLink>
                    )
                  })
                }
              </List>
            </Collapse>
          </div>
        ;
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={whiteSidenav}
        />
      );
    }

    return returnValue;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      mobileView={mobileView}
      ownerState={{ whiteSidenav, miniSidenav }}
      onMouseEnter={() => {
        if (miniSidenav) {
          setMiniSidenav(dispatch, false)
          setIsPreviouslyFalse(false);
        } else {
          setIsPreviouslyFalse(true);
        }
      }}
      onMouseLeave={() => !isPreviouslyFalse ? setMiniSidenav(dispatch, true) : null}
    >
      <MDBox style={{cursor: 'pointer'}} onClick={onClickLogoHandler} pt={3} paddingBottom="0" marginBottom="-4px" px={miniSidenav || mobileView ? 1 : 4} textAlign="center">
        <MDBox display="flex" alignItems="center" justifyContent="center">
          {brand && <MDBox component="img" src={brand} alt="Brand" maxWidth="100%" width="125px" />}
        </MDBox>
      </MDBox>
      <h1 className="title-app">
        <b>{collapseName?.split('/') ? collapseName?.split('/')[0] : ''}</b>
      </h1>
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
