import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { Helmet } from 'react-helmet';

import { MaterialUIControllerProvider } from './context';
import reportWebVitals from './reportWebVitals';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <Helmet>
          <title>{process.env.REACT_APP_MODE.toLocaleUpperCase() + ' Dashboard'}</title>
          <link rel="icon" href={`./${process.env.REACT_APP_MODE}.png`} />
        </Helmet>
        <App />
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
