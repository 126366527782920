import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import ImageZoomModal from "../../../../components/modals/imageZoom";
import BrandIndex from "../../../../components/AppComponents/Autoloka/masterData/brand";
import BrandAdd from "../../../../components/AppComponents/Autoloka/masterData/brand/add";
import BrandEdit from "../../../../components/AppComponents/Autoloka/masterData/brand/edit";
import DeleteConfirmationModal from "../../../../components/modals/deleteConfirmation";

import services from "../../../../service/services";

import placeholder from "../../../../assets/images/placeholder-image.jpeg";
import axios from "axios";
import MDSnackbar from "../../../../components/MDSnackbar";

import { cleanObject, getImageError } from "../../../../utils";
import { useSearchParams } from "react-router-dom";

const Brand = ({
	setIsLoading,
	setMode,
	mode,
 }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [rows, setRows] = useState([]);
	const [rowsDisplayed, setRowsDisplayed] = useState([]);
	const [rawRowsDisplayed, setRawRowsDisplayed] = useState([]);
	const [paginationDetail, setPaginationDetail] = useState({});
	const [imgSource, setImgSource] = useState(null);
	const [open, setOpen] = useState(false);
	const [brandNameOptions, setBrandNameOptions] = useState([]);
	const [brandCodeOptions, setBrandCodeOptions] = useState([]);
	const [brandTypeOptions, setBrandTypeOptions] = useState([]);
	const [indexDetail, setIndexDetail] = useState(null);
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [isFiltered, setIsFiltered] = useState(false);
	const [id, setId] = useState(null);
	const [confirmationDisplay, setConfirmationDisplay] = useState(false);
	const [dataFilter, setDataFilter] = useState({
		brand_name: searchParams.get("brand_name") || "",
		brand_code: searchParams.get("brand_code") || "",
		brand_type: searchParams.get("brand_type") || "",
		is_popular: searchParams.get("is_popular") || ""
	});
	
	const handleOpen = (e, source) => {
		if(source){
			setOpen(true);
			setImgSource(source);
		} else {
			e.preventDefault();
		}
	};

  const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Edit", accessor: "edit", align: "left" },
		{ Header: "Brand Name", accessor: "brand_name", align: "left" },
		{ Header: "Brand Code", accessor: "brand_code", align: "left" },
		{ Header: "Brand Type", accessor: "brand_type", align: "left" },
		{ Header: "is popular", accessor: "is_popular", align: "left" },
		{ Header: "Brand Image", accessor: "brand_image", align: "left" },
		{ Header: "Header Image", accessor: "header_image", align: "left" },
		{ Header: "Car Image", accessor: "car_image", align: "left" },
		{ Header: "Status", accessor: "status", align: "left" },
	];

	useEffect(() => {

		let fetcher;
		if(Object.keys(cleanObject(dataFilter)).length !== 0) {
			fetcher = services.filterBrandList({...dataFilter, page: searchParams.get("page") || 1})
		}else {
			fetcher = services.getMasterDataBrand(searchParams.get("page") || 1)
		}

		if(mode === "index"){
			setIsLoading(true);
			Promise
				.all([
					fetcher,
					services.getOptionsBrandName(""),
					services.getOptionsBrandCode(""),
					services.getOptionsBrandType("")
				]).then(axios.spread((...res) => {
					setIsLoading(false);
					setPaginationDetail(res[0]?.data?.data);
					setRows(settingRowsDataTable(res[0]?.data?.data?.list, res[0]?.data?.data?.current_page));
					setRawRowsDisplayed(res[0]?.data?.data.list);
					setBrandNameOptions(res[1]?.data?.data);
					setBrandCodeOptions(res[2]?.data?.data);
					setBrandTypeOptions(res[3]?.data?.data);
				})).catch(errors => {
					setIsLoading(false);
					console.log("Error is found, apologies.")
					console.log(errors)
				})
		}
	}, [mode]);

	const deleteBrand = (id) => {
		setIsLoading(true);
		setShowNotifSuccess(false);
		services.deleteBrand(id)
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
				setConfirmationDisplay(false);
				getList(1);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors);
			})
	};

	const getList = (page) => {
		setIsLoading(true);
		services.getMasterDataBrand(page)
			.then(res => {
				setIsLoading(false);
				setIsFiltered(false);
				setRows(settingRowsDataTable(res?.data?.data?.list, res?.data?.data?.current_page));
				setRawRowsDisplayed(res?.data?.data?.list);
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	};

	const settingRowsDataTable = (rowsArray, current_page) => {
		return rowsArray?.map((arr, i) => {
			return {
				no: ((current_page - 1) * 10) + i + 1,
				edit: (
					<>
						<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setMode("edit"); setIndexDetail(i)}}>edit</Icon>
						<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setId(arr.id); setConfirmationDisplay(true);}}>delete</Icon>
					</>
				),
				brand_name: arr?.brand_name,
				brand_code: arr?.brand_code,
				brand_type: arr?.brand_type === "1" ? "Internal" : arr?.brand_type === "2" ? "External" : "",
				is_popular: arr?.is_popular ? "Yes" : "No",
				brand_image: <img onClick={(e) => handleOpen(e, arr?.brand_image)} onError={getImageError} className={"img-thumbnail-pagination " + (arr.brand_image ? "" : "forbidden")} src={arr?.brand_image ? arr?.brand_image : placeholder} />,
				header_image: <img onClick={(e) => handleOpen(e, arr?.header_image)} onError={getImageError} className={"img-thumbnail-pagination " + (arr.header_image ? "" : "forbidden")} src={arr?.header_image ? arr?.header_image : placeholder} />,
				car_image: <img onClick={(e) => handleOpen(e, arr?.car_image)} onError={getImageError} className={"img-thumbnail-pagination " + (arr.car_image ? "" : "forbidden")} src={arr?.car_image ? arr?.car_image : placeholder} />,
				status: arr?.status === 0 ? "Not Active" : arr?.status === 1 ? "Active" : ""
			};
		});
	};

  return (
	  <>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="Brand Deleted!"
				dateTime="Just Now"
				open={showNotifSuccess}
				close={() => setShowNotifSuccess(false)}
			/>
			<Grid container spacing={6} className="grid-pure-container">
				{
					mode === "index" ?
						<BrandIndex 
						setDataFilter={setDataFilter}
						dataFilter={dataFilter}
							columns={columns} 
							rows={rows} 
							rowsDisplayed={rowsDisplayed}
							setRowsDisplayed={setRowsDisplayed} 
							setRawRowsDisplayed={setRawRowsDisplayed}
							setIndexDetail={setIndexDetail}
							brandNameOptions={brandNameOptions} 
							brandCodeOptions={brandCodeOptions} 
							brandTypeOptions={brandTypeOptions} 
							setIsLoading={setIsLoading}
							handleOpen={handleOpen}
							setMode={setMode}
							deleteBrand={deleteBrand}
							paginationDetail={paginationDetail}
							setPaginationDetail={setPaginationDetail}
							getList={getList}
							isFiltered={isFiltered}
							setIsFiltered={setIsFiltered}
							settingRowsDataTable={settingRowsDataTable}
						/>
					: mode === "add" ?
						<BrandAdd brandTypeOptions={brandTypeOptions} setIsLoading={setIsLoading} />
					: mode === "edit" ?
						<BrandEdit setMode={setMode} indexDetail={indexDetail} setIndexDetail={setIndexDetail} rawRowsDisplayed={rawRowsDisplayed} brandTypeOptions={brandTypeOptions} setIsLoading={setIsLoading} /> 
					: null
				}
			</Grid>
			<ImageZoomModal open={open} setOpen={setOpen} imgSource={imgSource} setImgSource={setImgSource} />
			<DeleteConfirmationModal open={confirmationDisplay} setOpen={setConfirmationDisplay} itemName="brand" deleteHandler={() => deleteBrand(id)} /> 
		</>
  );
}

export default Brand;
