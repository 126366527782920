import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from "react-hook-form";

import Box from '@mui/material/Box';
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Modal from '@mui/material/Modal';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import MDTypography from '../../../../MDTypography';
import MDInput from '../../../../MDInput';
import MDButton from '../../../../MDButton';
import MDBox from '../../../../MDBox';

import { getImageError } from '../../../../../utils';

import services from '../../../../../service/services';

import qrImage from "../../../../../assets/images/qr-code.png";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  width: 'fit-content',
	maxWidth: '90vw',
  p: 3,
	borderRadius: "12px",
};

const AddTransferModal = ({ 
	setIsLoading,
	open, 
	setOpen,
	productOptions,
	locatorOptions,
	setLocatorOptions,
	setShowNotifError,
	setErrorMessage,
	locatorPaginationDetail,
	settingLocatorRows,
	setLocatorRows,
}) => {
	const inputProductRef = useRef(null);

	const [skipCount, setSkipCount] = useState(true);

	const { register, control, reset, handleSubmit, setValue, watch, trigger, clearErrors, formState: { errors } } = useForm({mode: "all"});

	const watchAllFields = watch();

	useEffect(async() => {
		if(skipCount){
			setLocatorRows([]);
			setSkipCount(false);
		} else if(!skipCount){ 
			clearErrors(["locator_from", "locator_to", "qty_transfer"]);
			const result = await trigger(["locator_from", "locator_to", "qty_transfer"]);
			let from_qty_before, to_qty_before;
			if(watchAllFields?.locator_from){
				from_qty_before = locatorPaginationDetail?.list?.filter((lpd, i) => lpd?.product.product_id === watchAllFields?.product?.product_id && (lpd?.locator_from_id === watchAllFields?.locator_from?.area_id || lpd?.locator_to_id === watchAllFields?.locator_from?.area_id));
				from_qty_before = from_qty_before[from_qty_before?.length - 1];
				from_qty_before = from_qty_before?.locator_from_id === watchAllFields?.locator_from?.area_id ? from_qty_before?.locator_from_qty_after : from_qty_before?.locator_to_id === watchAllFields?.locator_from?.area_id ? from_qty_before?.locator_to_qty_after : "";
				from_qty_before = parseInt(from_qty_before) || watchAllFields?.locator_from?.qty_before;
				setValue("locator_from_qty_before", parseInt(from_qty_before));
			}
			if(watchAllFields?.locator_to){
				to_qty_before = locatorPaginationDetail?.list?.filter((lpd, i) => lpd?.product.product_id === watchAllFields?.product?.product_id && (lpd?.locator_to_id === watchAllFields?.locator_to?.area_id || lpd?.locator_from_id === watchAllFields?.locator_to?.area_id));
				to_qty_before = to_qty_before[to_qty_before?.length - 1];
				to_qty_before = to_qty_before?.locator_to_id === watchAllFields?.locator_to?.area_id ? to_qty_before?.locator_to_qty_after : to_qty_before?.locator_from_id === watchAllFields?.locator_to?.area_id ? to_qty_before?.locator_from_qty_after : "";
				to_qty_before = parseInt(to_qty_before) || watchAllFields?.locator_to?.qty_before;
				setValue("locator_to_qty_before", parseInt(to_qty_before));
			}
			if(watchAllFields?.qty_transfer){
				setValue("locator_from_qty_after", parseInt(from_qty_before) - parseInt(watchAllFields?.qty_transfer));
				setValue("locator_to_qty_after", parseInt(to_qty_before) + parseInt(watchAllFields?.qty_transfer));
			}
		}
	}, [watchAllFields.locator_from, watchAllFields.locator_to, watchAllFields.qty_transfer]);

	useEffect(() => {
		setValue("locator_from", "");
		setValue("locator_from_qty_before", "");
		setValue("locator_from_qty_after", "");
		setValue("qty_transfer", "");
		setValue("locator_to", "");
		setValue("locator_to_qty_before", "");
		setValue("locator_to_qty_after", "");
		if(watchAllFields?.product?.product_id){
			setIsLoading(true);
			services.getOptionsLocatorByProduct(watchAllFields?.product?.product_id)
				.then(res => {
					setIsLoading(false);
					setLocatorOptions(res?.data?.data);
				}).catch(errors => {
					setIsLoading(false);
					setShowNotifError(true);
					setErrorMessage(errors?.response?.data?.message);
				})
		}
	}, [watchAllFields.product]);

	const handleAdd = (e) => {
		setLocatorRows(settingLocatorRows([...locatorPaginationDetail?.list, e]));
		reset({});
		setOpen(false);
		setLocatorOptions([]);
	};

	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		limit: 100,
		stringify: (option) => option.product_name + option.product_code,
	});

	const handleKeyDown = event => {
    if(event.key === "Enter"){
			event.preventDefault();
			event.stopPropagation();
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="grid-modal add-product-inventory" sx={style}>
					<MDBox position="relative">
						<Icon style={{ position: "absolute", right: "-12px", top: "-12px" }} className="c-pointer" onClick={() => setOpen(false)} fontSize="small">close</Icon>
					</MDBox>
          <MDTypography variant="h4">
						Transfer
					</MDTypography>
					<MDBox width="100%" mb={2} component="form" role="form" id="create-add-transfer" onSubmit={handleSubmit(handleAdd)}>
						<Grid container spacing={3} sx={{ width: "600px", maxWidth: "90vw", marginTop: "0!important", marginBottom: "24px"}}>
							<Grid item xs={12} lg={12}>
								<MDBox display="flex" alignItems="flex-start" justifyContent={window.innerWidth < 768 ? "center" : "space-between"} gap="16px" maxWidth={window.innerWidth > 1200 ? "450px" : "100%"}>
									<MDBox width="100%">
										<Controller 
											rules={{ required: "Required" }} 
											name="product" control={control}
											render={(props) => {
												return <Autocomplete
													className='autocomplete-product'
													options={productOptions || []}
													getOptionLabel={option => option.product_name}
													filterOptions={filterOptions}
													size="small"
													renderInput={(params) => {
														params.inputProps.onKeyDown = handleKeyDown;
														return <MDInput fullWidth {...params} autoFocus={true} inputRef={inputProductRef} label="Product" size="small" /> 
													}}
													renderOption={(props, option, { selected }) => (
														<li {...props} style={{ marginRight: "8px" }}>
															<MDBox width="100%" display="flex" flexDirection="column" justifyContent="space-evenly" mt={1}>
																<MDTypography fontSize="12px" sx={{ fontWeight: 900, mb: "-0.4px" }}>{option.product_name}</MDTypography>
																<MDTypography fontSize="11px" sx={{ mb: 1 }}>{option.product_code}</MDTypography>
																<MDBox width="100%" sx={{ borderBottom: "1px solid #344767!important" }} mb={"-6px"}></MDBox>
															</MDBox>
														</li>
													)}
													onInputChange={(event, newInputValue, reason) => {
														if (reason === "clear") {
															setValue("locator_from", "");
															setValue("locator_from_qty_before", "");
															setValue("locator_from_qty_after", "");
															setValue("qty_transfer", "");
															setValue("locator_to", "");
															setValue("locator_to_qty_before", "");
															setValue("locator_to_qty_after", "");
															return
														};
													}}
													onChange={async (e, data) => {
														let temp = { target: { value: data } };
														props?.field?.onChange(temp)?.then(async () => {
															clearErrors(["locator_from", "locator_to", "qty_transfer"]);
															const result = await trigger(["locator_from", "locator_to", "qty_transfer"]);
														})
													}}
													onBlur={async () => {
														props?.field?.onBlur()?.then(async () => {
															clearErrors(["locator_from", "locator_to", "qty_transfer"]);
															const result = await trigger(["locator_from", "locator_to", "qty_transfer"]);
														})
													}}
												/>
											}
										}/>
										{errors?.product && 
											<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
												<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.product.message}</MDTypography>
											</MDBox>
										}
									</MDBox>
									<MDBox
										component="img"
										src={qrImage}
										alt="Scanner Image"
										maxHeight="34.25px"
										width="auto"
										height="100%"
										position="relative"
										onError={getImageError}
										onClick={() => inputProductRef.current.focus()}
									/>
								</MDBox>
							</Grid>
							<Grid item xs={12} lg={5}>
								<Controller 
									rules={{ required: "Required", validate:  v => v?.area_id !== parseInt(watchAllFields?.locator_to?.area_id) || 'Transfer cannot have the same locator' }} 
									name="locator_from" control={control}
									render={(props) => {
										return <Autocomplete
											options={locatorOptions || []}
											getOptionLabel={option => option.area_name}
											size="small"
											renderInput={(params) => ( <MDInput label="Locator From" InputLabelProps={{ shrink: watchAllFields?.locator_from?.length > 0 }} fullWidth  {...params}/> )}
											onInputChange={(event, newInputValue, reason) => {
												if (reason === "clear") {
													setValue("qty_transfer", "");
													return
												}
											}}
											onChange={async (e, data) => {
												let temp = { target: { value: data } };
												props?.field?.onChange(temp)?.then(async () => {
													clearErrors(["locator_from", "locator_to", "qty_transfer"]);
													const result = await trigger(["locator_from", "locator_to", "qty_transfer"]);
												})
											}}
											onBlur={async () => {
												props?.field?.onBlur()?.then(async () => {
													clearErrors(["locator_from", "locator_to", "qty_transfer"]);
													const result = await trigger(["locator_from", "locator_to", "qty_transfer"]);
												})
											}}
											value={props?.field?.value || null}
										/>
									}
								}/>
								{errors?.locator_from && 
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.locator_from.message}</MDTypography>
									</MDBox>
								}
							</Grid>
							<Grid item xs={12} lg={3}>
								<MDInput {...register("locator_from_qty_before")} disabled type="number" label="Qty Before" value={watchAllFields?.locator_from_qty_before || ""} fullWidth />
							</Grid>
							<Grid item xs={12} lg={3} sx={{ position: "relative"}}>
								<MDBox className="qty_transfer-container">
									<MDBox width="100%">
										<MDInput {...register("qty_transfer", { required: "Required", 
												pattern: { value: /^[1-9][0-9]*$/, message: "Integer Number only"}, 
												min: {
													value: 1,
													message: 'Minimum position value is 1'
												},
												validate:  v => v < parseInt(watchAllFields?.locator_from_qty_before) || 'Should be lower than locator from qty_transfer'
											})} 
											type="number" 
											label="Qty Transfer" 
											fullWidth
											value={watchAllFields?.qty_transfer || ""}
										/>
										{errors?.qty_transfer && 
											<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
												<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.qty_transfer.message}</MDTypography>
											</MDBox>
										}
										</MDBox>
									</MDBox>
							</Grid>
							<Grid item xs={12} lg={3} sx={{ marginLeft: "auto"}}>
								<MDInput {...register("locator_from_qty_after")} disabled type="number" label={"Qty After"} value={watchAllFields?.locator_from_qty_after || ""} fullWidth />
							</Grid>
							<Grid item xs={12} lg={5}>
								<Controller 
									rules={{ required: "Required", validate:  v => v?.area_id !== watchAllFields?.locator_from?.area_id || 'Transfer cannot have the same locator' }} 
									name="locator_to" control={control}
									render={(props) => {
										return <Autocomplete
											options={locatorOptions || []}
											getOptionLabel={option => option.area_name}
											size="small"
											renderInput={(params) => ( <MDInput label="Locator To" InputLabelProps={{ shrink: watchAllFields?.locator_to?.length > 0 }} fullWidth  {...params}/> )}
											onInputChange={(event, newInputValue, reason) => {
												if (reason === "clear") {
													setValue("qty_transfer", "");
													return
												}
											}}
											onChange={async (e, data) => {
												let temp = { target: { value: data } };
												props?.field?.onChange(temp)?.then(async () => {
													clearErrors(["locator_to", "locator_to", "qty_transfer"]);
													const result = await trigger(["locator_to", "locator_to", "qty_transfer"]);
												})
											}}
											onBlur={async () => {
												props?.field?.onBlur()?.then(async () => {
													clearErrors(["locator_to", "locator_to", "qty_transfer"]);
													const result = await trigger(["locator_to", "locator_to", "qty_transfer"]);
												})
											}}
											value={props?.field?.value || null}
										/>
									}
								}/>
								{errors?.locator_to && 
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.locator_to.message}</MDTypography>
									</MDBox>
								}
							</Grid>
							<Grid item xs={12} lg={3}>
								<MDInput {...register("locator_to_qty_before")} disabled type="number" label="Qty Before" value={watchAllFields?.locator_to_qty_before || ""} fullWidth />
							</Grid>
							<Grid item xs={12} lg={3} sx={{ marginLeft: "auto"}}>
								<MDInput {...register("locator_to_qty_after")} disabled type="number" label={"Qty After"} value={watchAllFields?.locator_to_qty_after || ""} fullWidth />
							</Grid>
							<Grid item xs={12} sx={12}>
								<MDBox display="flex" alignItems="center" justifyContent="end">
									<MDButton type="submit" variant="gradient" color="info" form="create-add-transfer">ADD</MDButton>
								</MDBox>
							</Grid>
						</Grid>
					</MDBox>
        </Box>
      </Modal>
    </div>
  );
}

export default AddTransferModal;