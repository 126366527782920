import { useFieldArray, useForm } from "react-hook-form";
import { useEffect, useMemo, useState, useRef } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";

import DataTable from "../../../../Table";
import MDInput from "../../../../MDInput";
import MDBox from "../../../../MDBox";
import MDButton from "../../../../MDButton";
import MDSnackbar from "../../../../MDSnackbar";
import MDTypography from "../../../../MDTypography";

import FilterModal from "./filterModal";

import services from "../../../../../service/services";

import breakpoints from "../../../../../assets/theme/base/breakpoints";
import CardBarcode from "./card-barcode";
import BarcodesLayout from "./barcodes-layout";
import ConfigLayout from "../config-layout";

import { cleanObject, setSizePrintPaper } from '../../../../../utils';
import { useSearchParams } from "react-router-dom";

const ItemProductsIndex = ({
	setPrintItems,
	removePrintItem,
	printItems,
	columns, 
	rows,
	rowsDisplayed,
	setRowsDisplayed,
	setRawRowsDisplayed,
	setIsLoading,
	setMode,
	paginationDetail,
	setPaginationDetail,
	getList,
	isFiltered,
	setIsFiltered,
	settingRowsDataTable,
	brandOptions,
	modelOptions,
	modelTypeOptions,
	setDataFilter,
	dataFilter,
}) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [query, setQuery] = useState(searchParams.get("query") || "");
	const [querySearched, setQuerySearched] = useState(searchParams.get("query") || "");
	const [openFilter, setOpenFilter] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [rowPrintItems, setRowPrintItems] = useState([]);
	const [flatenPrintItems, setFlatenPrintItems] = useState([]);
	const [configPaper, setConfigPaper] = useState();

	const columnsPrintItems = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Product Name", accessor: "product_name", align: "left" },
		{ Header: "Product Code", accessor: "product_code", align: "left" },
		{ Header: "Molding Code", accessor: "molding_code", align: "left" },
		{ Header: "Qty", accessor: "qty", align: "left" },
		{ Header: "Action", accessor: "action", align: "left" },
	];

	const settingRowsDataTablePrint = (array) => {
		return array?.map((arr, i) => {
			return {
				no: i + 1,
				product_name: arr?.name,
				product_code: arr?.code1,
				molding_code: arr?.molding_code,
				qty: <>
					<TextField 
						id={arr?.code1.split(' ').join('-')}
						defaultValue={1}
						required
						onInput={(e) => {
							if(e.target.value === "0") {
								e.target.value = 1;
							}
						}}
						onBlur={(e) => {
							if(!e.target.value) {
								e.target.value = 1;
							}
						}}
						className="without-label-input" 
						style={{ width: "48px" }} 
						type="number" 
						inputProps={{ inputMode: 'numeric', pattern: '[1-9]*' }} 
						InputLabelProps={{ shrink: false }} />
				</>,
                action: (
					<Icon 
						style={{cursor: "pointer"}} 
						fontSize="small" 
						onClick={() => {
							removePrintItem(arr);
						}} >
						delete
					</Icon>
				)
			};
		});
	};

	const handleSearchChange = (e) => {
		setQuery(e.target.value);
	}

	const searchList = (e) => {
		if(e.keyCode === 13 && query.length >= 3){
			setSearchParams({
				page: 1,
				query,
			})
			setQuerySearched(query);
		}
	}

	useEffect(() => {
		setRowPrintItems(settingRowsDataTablePrint(printItems));
	},[printItems]);

	useEffect(() => {
		if(querySearched.length === 0 && rows.length > 0){
			setRowsDisplayed(rows);
		}
	},[query, rows]);

	const searchInput = (page) => {
		setIsLoading(true);
		services.searchItemProductsList(querySearched, page)
			.then(res => {
				setIsLoading(false);
				setIsFiltered(false);
				setRowsDisplayed(settingRowsDataTable(res?.data?.data?.list, res?.data?.data?.current_page));
				setRawRowsDisplayed(res?.data?.data?.list);
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			});
	};

	useMemo(() => {
		if(querySearched.length > 0){
			searchInput(searchParams.get("page") || 1)
		}
	}, [querySearched]);

	const filterList = (page) => {
		setIsLoading(true);
		setSearchParams(cleanObject({ ...dataFilter, page }));
		services.filterItemProductsList({...dataFilter, page: page})
			.then(res => {
				setIsFiltered(true);
				setIsLoading(false);
				setRowsDisplayed(settingRowsDataTable(res?.data?.data?.list, res?.data?.data?.current_page));
				setPaginationDetail(res?.data?.data)
				setRawRowsDisplayed(res?.data?.data?.list);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	}

	const gotoPage = (page) => {
		setSearchParams({
			...(searchParams.get("query") ? { query: searchParams.get("query") || ""} : {}),
			page,
		})
		if(isFiltered){
			filterList(page);
		} else if(querySearched.length > 0){
			searchInput(page);
		} else {
			getList(page);
		}
	}

	const sanitizePrinItems = (printItems) => {
		if(printItems.length === 0) return null;
		const newPrintItems = printItems?.map((el) => {
			const qtyInputId = '#' + el?.product_code?.split(' ')?.join('-')
			const qtyInput = document.querySelector(qtyInputId);
			return ({
				product_name: el.product_name,
				product_code: el.product_code,
				molding_code: el.molding_code,
				qty: qtyInput?.value || 1,
			})
		});
		return newPrintItems;
	}

	const onClickPrintHandler = () => {
		setFlatenPrintItems(sanitizePrinItems(rowPrintItems));
		const submitConfigLayout = document.querySelector('#config-layout-submit');
		submitConfigLayout.click();
	
		setTimeout(() => {
			const button = document.querySelector('#print-barcode-btn');
			if(button) {
				button.click();
			}
		}, 500);	
	}

	const onClickCheckHandler = () => {
		setFlatenPrintItems(sanitizePrinItems(rowPrintItems));
		const submitConfigLayout = document.querySelector('#config-layout-submit');
		submitConfigLayout.click();
	}

	const removeQuery = () => {
		setQuery("");
		setQuerySearched("");
		setSearchParams({ page: 1, query: '' });
	  }
	

  return (
		<>
			<Grid item xs={12}>
				<MDSnackbar
					color="error"
					icon="error"
					title="Autoloka"
					content={errorMessage}
					open={showNotifError}
					dateTime="Just Now"
					close={() => setShowNotifError(false)}
				/>
				<FilterModal
					open={openFilter} 
					setOpen={setOpenFilter}
					brandOptions={brandOptions}
					modelOptions={modelOptions}
					modelTypeOptions={modelTypeOptions}
					filterList={filterList}
					dataFilter={dataFilter}
					setDataFilter={setDataFilter}
				/>
				<MDBox sx={ window.innerWidth > breakpoints.values.md ? {display: "flex", padding: "12px 48px", alignItems: "center"} : {display: "flex", flexWrap: "wrap", padding: "12px 24px", justifyContent: "center"} } bgColor="#E5ECF0" borderRadius="6px" marginBottom="12px">
					<MDInput 
						variant="outlined" 
						label="Product Name or Product Code or Molding Code or Brand or Model"
						className="search-input"
						InputProps={{
				endAdornment: <InputAdornment position="end"><Icon className="c-pointer" onClick={removeQuery} fontSize="small">close</Icon><Icon fontSize="small">search</Icon></InputAdornment>,
			}}
						backgroundColor="#FFF"
						style={{ height:"100%", borderRadius:"6px", lineHeight:"22px" }}
						fullWidth
						onChange={handleSearchChange}
						onKeyDown={searchList}
						value={query}
						autoFocus
					/>
					<MDBox sx={window.innerWidth > breakpoints.values.md ? {marginLeft: "24px", gap: "16px", justifyContent: "space-between"} : {marginTop: "8px", flexWrap: "wrap", gap: "8px", justifyContent: "center", alignItems: "center"}} display="flex">
						<MDButton variant="gradient" color="info" onClick={() => setOpenFilter(true)}>
							<Icon fontSize="small" style={{marginRight: "8px"}}>filter_list</Icon> Filter
						</MDButton>
					</MDBox>
				</MDBox>
				<Grid container spacing={3}>
					<Grid item xs={printItems.length === 0 ? 12 : 7}>
						<DataTable
							table={{ columns, rows : rowsDisplayed }}
							isSorted={false}
							entriesPerPage={false}
							showTotalEntries={true}
							pagination={{variant: "contained", color: "primary"}}
							noEndBorder
							paginationDetail={paginationDetail}
							gotoPage={gotoPage}
						/>
					</Grid>
					{printItems.length !== 0 && 
						<Grid item xs={5}>
							<MDBox sx={{display: 'flex', justifyContent: 'flex-end',marginBottom: '2rem'}}>
								<MDButton onClick={onClickPrintHandler} variant="gradient" color="info">
									<Icon fontSize="small" style={{marginRight: "8px"}}>print</Icon> Print
								</MDButton>
							</MDBox>
							<DataTable
								sx={{maxHeight: '400px'}}
								table={{ columns: columnsPrintItems, rows : rowPrintItems}}
								isSorted={false}
								entriesPerPage={false}
								showTotalEntries={false}
								pagination={{variant: "contained", color: "primary"}}
								noEndBorder
								paginationDetail={null}
							/>
							<MDBox sx={{display: 'flex', justifyContent: 'flex-end',marginTop: '2rem'}}>
								<MDButton onClick={onClickCheckHandler} variant="gradient" color="info">
									<Icon fontSize="small" style={{marginRight: "8px"}}>margin</Icon> Check
								</MDButton>
							</MDBox>
							<ConfigLayout setConfigPaper={setConfigPaper} />
						</Grid>}
				</Grid>
			</Grid>
			<div className="layout-center">
				<BarcodesLayout configPaper={configPaper} printItems={flatenPrintItems}/>
			</div>
		</>
  );
}

export default ItemProductsIndex;
