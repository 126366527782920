import { useEffect, useRef, useState } from "react";
import axios from "axios";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDTypography from "../../../../MDTypography";
import DataTable from "../../../../Table";
import MDInput from "../../../../MDInput";
import MDSnackbar from "../../../../MDSnackbar";

import services from "../../../../../service/services";


const QuantityProductsView = ({
	id,
	setIsLoading,
}) => {

	const [area, setArea] = useState({
		area_code: ""
	});
	const [detailData, setDetailData] = useState({});
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [productHistoryRows, setProductHistoryRows] = useState([]);
	const [productHistoryPaginationDetail, setProductHistoryPaginationDetail] = useState({});

	const [productHistoryColumns, setProductHistoryColumns] = useState([
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "ID Document", accessor: "id_document", align: "left" },
		{ Header: "Document Type", accessor: "document_type", align: "left" },
		{ Header: "Qty", accessor: "qty", align: "left" },
		{ Header: "Created At", accessor: "created_at", align: "left" },
		{ Header: "Created By", accessor: "created_by", align: "left" },
	]);

	const settingProductHistoryRows = (data, page) => {
		return data?.map((arr, i) => {
			return {
				no: ((page - 1) * 10) + i + 1,
				id_document: arr?.doc_id,
				document_type: arr?.doc_type,
				qty: arr?.qty,
				created_at: arr?.created_at,
				created_by: arr?.created_by,
			};
		});
	};

	const productHistoryGotoPage = (page) => {
		setIsLoading(true);
		services.getProductHistory(id, area?.area_id ? area.area_id : 0, page)
			.then(res => {
				setIsLoading(false);
				setProductHistoryRows(settingProductHistoryRows(res?.data?.data?.list_history, page))
				setProductHistoryPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	}

	useEffect(() => {
		if(id){
			setIsLoading(true);
			Promise
				.all([
					services.getQuantityProductsDetail(id),
					services.getProductHistory(id, 0, 1),
				]).then(axios.spread((...res) => {
					setIsLoading(false);
					setDetailData(res[0]?.data?.data);
					setProductHistoryRows(settingProductHistoryRows(res[1]?.data?.data?.list_history, 1))
					setProductHistoryPaginationDetail(res[1]?.data?.data);
				})).catch(errors => {
					setIsLoading(false);
					setShowNotifError(true);
					setErrorMessage(errors?.response?.data?.message);
				})
		}
	}, [id]);

	const useComponentDidMount = () => {
		const ref = useRef();
		useEffect(() => {
			ref.current = true;
		}, []);
		return ref.current;
	};	
	
	const isComponentMounted = useComponentDidMount();

	useEffect(() => {
		if(isComponentMounted) {
			productHistoryGotoPage(1);
		}
	}, [area]);

  return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" justifyContent="space-between" mt={-0.5} mb={2.5} width="100%">
				<MDTypography variant="h3" fontWeight="regular">
					Detail Quantity Product
				</MDTypography>
				<MDSnackbar
					color="error"
					icon="error"
					title="Autoloka"
					content={errorMessage}
					open={showNotifError}
					dateTime="Just Now"
					close={() => setShowNotifError(false)}
				/>
			</MDBox>
			<MDBox width="100%" maxWidth="800px" mb={2}>
				<Card
					sx={{
						width: "100%",
						margin: "0 auto",
						padding: "24px"
					}}
				>
					<Grid container spacing={3}>
						<Grid item xs={12} md={8}>
							<MDInput disabled InputLabelProps={{ shrink: detailData?.name?.length > 0 }} type="text" label="Product Name" value={detailData?.name} fullWidth />
						</Grid>
						<Grid item xs={12} md={4}>
							<MDInput disabled InputLabelProps={{ shrink: detailData?.product_code?.length > 0 }} type="text" label="Product Code" value={detailData?.product_code} fullWidth />
						</Grid>
						<Grid item xs={12} md={8}>
							<MDInput disabled InputLabelProps={{ shrink: detailData?.product_desc?.length > 0 }} type="text" label="Product Description" value={detailData?.product_desc} fullWidth multiline rows={5} />
						</Grid>
						<Grid item xs={12} md={4}>
							<MDInput disabled InputLabelProps={{ shrink: detailData?.molding_code?.length > 0 }} type="text" label="Molding Code" value={detailData?.molding_code} fullWidth />
						</Grid>
						<Grid item xs={12} md={8}>
							<Autocomplete
								options={detailData?.list_area ? detailData?.list_area : []}
								getOptionLabel={option => option.area_code}
								size="small"
								renderInput={(params) => (
									<MDInput label="All Locator" {...params} />
								)}
								onChange={(e, newValue) => {
									setArea(newValue);
								}}
								value={area?.area_code ? area : null}
							/>
						</Grid>
						<Grid item xs={12} md={4} sx={{ marginBottom: "24px"}}>
							<MDInput disabled InputLabelProps={{ shrink: true }} type="text" label="Quantity" value={area?.area_code ? area.qty : detailData?.list_area?.reduce((total, obj) => obj.qty + total,0)} fullWidth />
						</Grid>
						<Grid item xs={12}>
							<MDTypography variant="h3" fontWeight="regular">
								Product History
							</MDTypography>
							<DataTable
								table={{ columns: productHistoryColumns, rows : productHistoryRows }}
								isSorted={false}
								entriesPerPage={false}
								showTotalEntries={true}
								pagination={{variant: "contained", color: "primary"}}
								noEndBorder
								paginationDetail={productHistoryPaginationDetail}
								gotoPage={productHistoryGotoPage}
							/>
						</Grid>
					</Grid>
				</Card>
			</MDBox>
		</Grid>
  );
}

export default QuantityProductsView;
