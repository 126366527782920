import React from 'react';

const Data = ({ children, state }) =>
  state === 'data' ? <>{children}</> : <></>;
const Loading = ({ children, state }) =>
  state === 'loading' ? <>{children}</> : <></>;
const Empty = ({ children, state }) =>
  state === 'empty' ? <>{children}</> : <></>;
const Error = ({ children, state }) =>
  state === 'error' ? <>{children}</> : <></>;

const State = ({
  data,
  isLoading,
  isEmpty,
  isError,
  children,
  isBlank,
}) => {
  let state = 'loading';

  if (data && !isEmpty) state = 'data';
  else if (isLoading) state = 'loading';
  else if (isEmpty && !isLoading && !isError) state = 'empty';
  else if (isError) state = 'error';
  else if (isBlank) state = 'blank';
  else state = 'blank';

  return <>{children(state)}</>;
};

State.Data = Data;
State.Loading = Loading;
State.Empty = Empty;
State.Error = Error;

export default State;
