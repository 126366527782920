import { useEffect, useState } from "react";
import parse from 'html-react-parser';

import moment from 'moment';
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import ImageZoomModal from "../../../../components/modals/imageZoom";
import DeleteConfirmationModal from "../../../../components/modals/deleteConfirmation";

import services from "../../../../service/services";

import placeholder from "../../../../assets/images/placeholder-image.jpeg";
import MDSnackbar from "../../../../components/MDSnackbar";
import InventoryIndex from "../../../../components/AppComponents/CompanyAsset/Inventory";
import InventoryAdd from "../../../../components/AppComponents/CompanyAsset/Inventory/add";
import InventoryEdit from "../../../../components/AppComponents/CompanyAsset/Inventory/edit";

import { getImageError } from "../../../../utils";

const InventoryAsset = ({
	setIsLoading,
	setMode,
	mode,
 }) => {
	const [rows, setRows] = useState([]);
	const [rowsDisplayed, setRowsDisplayed] = useState([]);
	const [rawRowsDisplayed, setRawRowsDisplayed] = useState([]);
	const [paginationDetail, setPaginationDetail] = useState({});
	const [imgSource, setImgSource] = useState(null);
	const [open, setOpen] = useState(false);
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [isFiltered, setIsFiltered] = useState(false);
	const [id, setId] = useState(null);
	const [confirmationDisplay, setConfirmationDisplay] = useState(false);

	const handleOpen = (e, source) => {
		if(source){
			setOpen(true);
			setImgSource(source);
		} else {
			e.preventDefault();
		}
	};

  const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Edit", accessor: "edit", align: "left" },

		{ Header: "Name", accessor: "name", align: "left" },
		{ Header: "Quantity", accessor: "quantity", align: "left" },
		{ Header: "Serial Code", accessor: "serial_code", align: "left" },
        { Header: "Expired Date", accessor: "expired_date", align: "left" },
		{ Header: "type", accessor: "type", align: "left" },
		{ Header: "Uom", accessor: "uom", align: "left" },
		{ Header: "Asset Image", accessor: "image", align: "left" },
		{ Header: "Is Active", accessor: "is_active", align: "left" },
	];

	useEffect(() => {
		if(mode === "index"){
			getList(1);
		}
	}, [mode]);

	const deleteCompanyAsset = (id) => {
		setIsLoading(true);
		setShowNotifSuccess(false);
		services.deleteCompanyAsset(id)
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
				setConfirmationDisplay(false);
				getList(1);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors);
			})
	};

	const getList = (page) => {
		setIsLoading(true);

		services.getCompanyAssetList(page)
			.then(res => {
				setIsLoading(false);
				setIsFiltered(false);
				setRows(settingRowsDataTable(res?.data?.data?.list_inventory, res?.data?.data?.current_page));
				setRawRowsDisplayed(res?.data?.data?.list_inventory);
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	};

	const settingRowsDataTable = (rowsArray, current_page) => {
		return rowsArray?.map((arr, i) => {
			return {
				no: ((current_page - 1) * 10) + i + 1,
				edit: (
					<>
						<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setMode("edit"); setId(arr.id)}}>edit</Icon>
						{arr?.is_active === 1 && <Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setId(arr.id); setConfirmationDisplay(true);}}>delete</Icon>}
					</>
				),
				name: arr?.name,
				quantity: arr?.quantity,
				serial_code: arr?.serial_code,
                expired_date: moment(arr?.expired_date).format("DD/MM/yyyy"),
				type: arr?.type === 1 ? "Mobil" : arr?.type === 2 ? "ATK" : "",
                uom: arr?.uom,
				image: <img onClick={(e) => handleOpen(e, arr?.image)} onError={getImageError} className={"img-thumbnail-pagination " + (arr.image ? "" : "forbidden")} src={arr?.image ? arr?.image : placeholder} />,
				is_active: arr?.is_active === 0 ? "Not Active" : arr?.is_active === 1 ? "Active" : ""
			};
		});
	};

  return (
    <>
      <MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="Inventory Asset Deleted!"
				dateTime="Just Now"
				open={showNotifSuccess}
				close={() => setShowNotifSuccess(false)}
			/>
			<Grid container spacing={6} className="grid-pure-container">
				{
					mode === "index" ?
						<InventoryIndex 
							setIsLoading={setIsLoading}
							columns={columns} 
							rows={rows}
							rowsDisplayed={rowsDisplayed}
							setRowsDisplayed={setRowsDisplayed} 
							setRawRowsDisplayed={setRawRowsDisplayed}
							placeholder={placeholder}
							getList={getList}
							setMode={setMode}
							isFiltered={isFiltered}
							setIsFiltered={setIsFiltered}
							settingRowsDataTable={settingRowsDataTable}
							paginationDetail={paginationDetail}
							setPaginationDetail={setPaginationDetail}
						/>
					: mode === "add" ?
						<InventoryAdd setIsLoading={setIsLoading} />
					: mode === "edit" ?
						<InventoryEdit id={id} rawRowsDisplayed={rawRowsDisplayed} setIsLoading={setIsLoading} /> 
					: null
				}
			</Grid>
			<ImageZoomModal open={open} setOpen={setOpen} imgSource={imgSource} setImgSource={setImgSource} />
			<DeleteConfirmationModal open={confirmationDisplay} setOpen={setConfirmationDisplay} itemName="asset" deleteHandler={() => deleteCompanyAsset(id)} /> 
    </>
  );
}

export default InventoryAsset;
