import { useEffect, useState } from "react";
import moment from 'moment';
import axios from "axios";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';

import AddRemarkModal from "./remark";
import StatusNoteModal from "./note";
import LocatorModal from "./locator";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDTypography from "../../../../MDTypography";
import MDSnackbar from "../../../../MDSnackbar";
import DataTable from "../../../../Table";
import MDButton from "../../../../MDButton";

import services from "../../../../../service/services";

import { addCommas } from "../../../../../utils";
import { Checkbox, Icon } from "@mui/material";

const ListOrdersView = ({
	id,
	setIsLoading,
	orderStatus,
	setMode,
	paymentStatus,
}) => {

	const [detailData, setDetailData] = useState({});
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [productRows, setProductRows] = useState([]);
	const [productPaginationDetail, setProductPaginationDetail] = useState({});
	const [remarkRows, setRemarkRows] = useState([]);
	const [remarkPaginationDetail, setRemarkPaginationDetail] = useState({});
	const [historyRows, setHistoryRows] = useState([]);
	const [historyPaginationDetail, setHistoryPaginationDetail] = useState({});
	const [displayNote, setDisplayNote] = useState(false);
	const [statusDetail, setStatusDetail] = useState({});
	const [displayRemark, setDisplayRemark] = useState(false);
	const [initialParamChecked, setInitialParamChecked] = useState([]);
	const [paramChecked, setParamChecked] = useState([]);
	const [displayLocator, setDisplayLocator] = useState(false);
	const [product, setProduct] = useState({});

	const [productColumns, setProductColumns] = useState([
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Product Name", accessor: "product_name", align: "left" },
		{ Header: "Price", accessor: "price", align: "left" },
		{ Header: "Qty", accessor: "qty", align: "left" },
		{ Header: "Stock Qty", accessor: "stock_qty", align: "left" },
		{ Header: "Locator", accessor: "area_id", align: "left" },
	]);

	const remarkColumns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Remarks", accessor: "remarks", align: "left" },
		{ Header: "Created By", accessor: "created_by", align: "left" },
		{ Header: "Created At", accessor: "created_at", align: "left" },
	];

	const historyColumns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Note From User", accessor: "note1", align: "left" },
		{ Header: "Note From Admin", accessor: "note2", align: "left" },
		{ Header: "Status", accessor: "status", align: "left" },
		{ Header: "Created By", accessor: "created_by", align: "left" },
		{ Header: "Created At", accessor: "created_at", align: "left" },
	]

	const settingProductRows = (data, page) => {
		return data?.map((arr, i) => {
			return {
				no: ((page - 1) * 10) + i + 1,
				product_name: arr?.product_name,
				price: "Rp " + addCommas(arr?.price),
				qty: arr?.quantity,
				stock_qty: 
					<MDBox sx={{ textDecoration: "underline", cursor: "pointer" }} 
						onClick={() => {
							if(detailData?.payment_status === 2){
								setDisplayLocator(true);
								setProduct(arr);
							}
						}}>
							{arr?.stock_quantity}
					</MDBox>,
				area_id: arr?.area_name,
				check:
					arr?.area_id ?
						<MDBox display="flex" alignItems="center" justifyContent="space-between" sx={{ gap: "8px" }}>Checked <Icon sx={{ color: "red!important" }} className="c-pointer" onClick={() => { setProduct({...product, order_detail_id: arr?.order_detail_id, area_id: null, area_name: ""}); selectLocator(null, null)}} fontSize="small">close</Icon></MDBox>
					: null,
			};
		});
	};

	const settingRemarkRows = (data, page) => {
		return data?.map((arr, i) => {
			return {
				no: ((page - 1) * 10) + i + 1,
				remarks: arr?.remark,
				created_by: arr?.created_by,
				created_at: arr?.created_at + " WIB",
			};
		});
	};

	const settingHistoryRows = (list, page) => {
		let total_page = parseInt(list.length/10);
		if(list.length%10 !== 0){
			total_page = total_page + 1;
		};
		if(!page){
			page = total_page;
		};
		let listAll = list?.map((arr, i) => {
			return {
				no: ((page - 1) * 10) + i + 1,
				note1: arr?.note1 ? <MDBox sx={{ textDecoration: "underline" }}><a target="_blank" href={arr?.note1} rel="noreferrer">View Image</a></MDBox> : "",
				note2: arr?.note2,
				status: arr?.status === null ? "Waiting for Payment" : paymentStatus[arr?.status],
				created_by: arr?.created_by,
				created_at: moment(arr?.created_at).format("DD MMMM YYYY HH:mm:ss") + " WIB",
			};
		});
		setHistoryPaginationDetail({
			current_page: page,
			total_page: total_page,
			total_data: list.length,
			list: listAll,
		});
		return listAll?.filter((arr, i) => (((page - 1) * 10) <= i && (page * 10) > i));
	};

	const settingCheckedProducts = (data) => {
		return data.map((ic, i) => {
			return {
				order_detail_id: ic.order_detail_id,
				area_id: ic.area_id,
			}
		});
	}

	const selectLocator = (area, stock) => {
		setShowNotifError(false);
		if(parseInt(product.quantity) <= parseInt(stock) || stock === null){
			let tempList = paramChecked.map((ipc, i) => {
				if(ipc.order_detail_id === product.order_detail_id){
					ipc.area_name = area?.area_name || "";
					ipc.area_id = area?.area_id || null;
				}
				return ipc;
			})
			setParamChecked(tempList);
		} else {
			setShowNotifError(true);
			setErrorMessage("Stock not available");
		}
	}
	const productGotoPage = (page) => {
		setIsLoading(true);
		services.getOrderProduct(id, page)
			.then(res => {
				setIsLoading(false);
				setProductPaginationDetail(res?.data?.data);
				setInitialParamChecked([...initialParamChecked.concat(settingCheckedProducts(res.data?.data?.list_order_product))]);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	};

	const remarkGotoPage = (page) => {
		setIsLoading(true);
		services.getOrderProductRemark(id, page)
			.then(res => {
				setIsLoading(false);
				setRemarkRows(settingRemarkRows(res?.data?.data?.list_order_product, page));
				setRemarkPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	};

	const historyGotoPage = (page) => {
		setHistoryRows(settingHistoryRows(historyPaginationDetail.list, page));
	};

	const TabsList = styled(TabsListUnstyled)`
		width: fit-content;
		display: flex;
		align-items: center;
		justify-content: center;
		align-content: space-between;
		overflow: auto;
	`;

	const Tab = styled(TabUnstyled)`
		cursor: pointer;
		font-size: 12px;
		background-color: #CFD8DC;
		width: 200px;
		border-radius: 8px 8px 0 0;
		border: 2px solid #000;
		border-bottom: none;
		display: flex;
		justify-content: center;
		
		&:hover, &:focus, &.${tabUnstyledClasses.selected} {
			font-weight: bold;
			background-color: #FFF;
		}
	`;

	const backClickHandler = () => {
		setMode("index");
	}
	
	useEffect(() => {
		if(id){
			setIsLoading(true);
			Promise
				.all([
					services.getOrderDetail(id),
					services.getOrderProduct(id, 1),
					services.getOrderProductRemark(id,1),
					services.getOrderPaymentHistory(id),
				]).then(axios.spread((...res) => {
					setIsLoading(false);
					let tempDetail = res[0]?.data?.data;
					setDetailData(tempDetail);
					if(tempDetail?.payment_status === 2 && tempDetail?.order_status !== 2 && productColumns.filter(pc => pc.Header === "Check").length === 0){
						setProductColumns([...productColumns, { Header: "Check", accessor: "check", align: "left" }])
					}
					setProductPaginationDetail(res[1]?.data?.data);
					setInitialParamChecked([...initialParamChecked.concat(settingCheckedProducts(res[1].data?.data?.list_order_product))]);
					setRemarkRows(settingRemarkRows(res[2].data?.data?.list_order_product, 1));
					setRemarkPaginationDetail(res[2]?.data?.data);
					setHistoryRows(settingHistoryRows(res[3]?.data?.data));
				})).catch(errors => {
					setIsLoading(false);
					console.log("Error is found, apologies.");
				})
		}
	}, [id]);

	useEffect(() => {
		if(initialParamChecked.length > 0 && Object.keys(productPaginationDetail).length > 0){
			setParamChecked(initialParamChecked);
		}
	}, [initialParamChecked, productPaginationDetail]);

	useEffect(() => {
		if(paramChecked.length > 0){
			let tempPC = productPaginationDetail.list_order_product.map(pr => {
				if(pr?.order_detail_id === product?.order_detail_id){
					pr.area_id = product?.area_id;
					pr.area_name = product.area_name;
				}
				return pr;
			});
			setProductRows(settingProductRows(tempPC, productPaginationDetail.current_page));
		}
	}, [paramChecked])
	
	const handleStatusOrder = () => {
		setIsLoading(true);
		services.changeStatusPayment(
			{
				order_id: id,
				status: statusDetail.status,
				note: statusDetail.note,
			}
		).then(res => {
			setShowNotifSuccess(true);
			setDisplayNote(false);
			services.getOrderDetail(id)
				.then(res2 => {
					setIsLoading(false);
					let tempDetail = res2?.data?.data;
					setDetailData(tempDetail);
					if(statusDetail.status === 2){
						productGotoPage(1);
					}
					if(tempDetail?.payment_status === 2 && detailData?.order_status !== 2 && productColumns.filter(pc => pc.Header === "Check").length === 0){
						setProductColumns([...productColumns, { Header: "Check", accessor: "check", align: "left" }])
					} else if(detailData?.order_status === 2){
						let pcNew = productColumns.filter(pc => pc.Header !== "Check");
						setProductColumns(pcNew);
					}
				}).catch(errors => {
					setIsLoading(false);
					setShowNotifError(true);
					setErrorMessage(errors?.response?.data?.message);
				})
		}).catch(errors => {
			setIsLoading(false);
			setShowNotifError(true);
			setErrorMessage(errors?.response?.data?.message);
		})
	}

	const changeStatusDetail = (status, type) => {
		setDisplayNote(true);
		setStatusDetail({
			type: type,
			status: status,
			note: "",
		})
	}

	const submitProductChecked = () => {
		setIsLoading(true);
		let paramCheckedList = paramChecked.map(pc => ({ 
			order_detail_id: pc.order_detail_id,
			area_id: pc.area_id,
		}));
		services.changeOrderProductCheck(
			{ detail_order: paramCheckedList}
		).then(res => {
			setShowNotifSuccess(true);
			services.getOrderProduct(id, productPaginationDetail.current_page)
				.then(res2 => {
					setIsLoading(false);
					setProductPaginationDetail(res2?.data?.data);
				}).catch(errors => {
					setIsLoading(false);
					setShowNotifError(true);
					setErrorMessage(errors?.response?.data?.message);
				})
		}).catch(errors => {
			setIsLoading(false);
			setShowNotifError(true);
			setErrorMessage(errors?.response?.data?.message);
		})
	}

	const submitStatusOrder = (status) => {
		setIsLoading(true);
		services.changeOrderStatus(
			id, status
		).then(res => {
			setShowNotifSuccess(true);
			services.getOrderDetail(id)
				.then(res2 => {
					setIsLoading(false);
					let tempDetail = res2?.data?.data;
					setDetailData(tempDetail);
					if(!(tempDetail?.payment_status === 2 && detailData?.order_status === 2 && productColumns.filter(pc => pc.Header === "Check").length > 0)){
						let pcNew = productColumns.filter(pc => pc.Header !== "Check");
						setProductColumns(pcNew);
					}
				}).catch(errors => {
					setIsLoading(false);
					setShowNotifError(true);
					setErrorMessage(errors?.response?.data?.message);
				})
		}).catch(errors => {
			setIsLoading(false);
			setShowNotifError(true);
			setErrorMessage(errors?.response?.data?.message);
		})
	}

  return (
		<Grid item xs={12} sm={12}>
			<MDBox display="flex" alignItems="center" justifyContent="space-between" sx={{ flexDirection: window.innerWidth < 768 ? "column" : "row", gap: window.innerWidth < 768 ? "8px" : ""}} mt={-0.5} mb={2.5} width="100%">
				<MDBox display="flex" alignItems="center">
					<button onClick={backClickHandler} style={{border: 'none', background: 'white', borderRadius: '5px', marginRight: '1rem',width: '2rem', height: '2rem',display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
						<Icon fontSize="medium">
							arrow_back
						</Icon>
					</button>
					<MDTypography variant="h3" fontWeight="regular">
						Detail Order
					</MDTypography>
				</MDBox>
				{
					detailData?.payment_status === null || detailData?.payment_status === 1 ?
						<MDButton color="info" onClick={() => changeStatusDetail(3, "Cancel")}>
							Cancel Order
						</MDButton>
					: detailData?.payment_status === 2 && detailData?.order_status !== 2 ?
					 	<MDBox>
							<MDButton color="info" onClick={() => submitStatusOrder(3)}>
								On Hold
							</MDButton>
							{/*<MDButton color="info" onClick={() => submitStatusOrder(2)}>
								Packing
							</MDButton> */}
						</MDBox>
					: null
				}
			</MDBox>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="Order Updated!"
				open={showNotifSuccess}
				dateTime="Just Now"
				close={() => setShowNotifSuccess(false)}
			/>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			<Grid container spacing={3} sx={{ width: window.innerWidth < 768 ? "100%" : "calc(100% + 24px)", margin: window.innerWidth < 768 ? "-24px auto 0 auto" : "inherit" }}>
				<Grid item xs={12} md={7}>
					<MDBox width="100%">
						<Card
							sx={{
								width: "100%",
								margin: "0 auto",
								padding: "24px"
							}}
						>
							<Grid container sx={{ mb: 3 }}>
								<Grid item lg={2} md={3.5}><MDTypography>No Trx</MDTypography></Grid>
								<Grid item md={"auto"} sx={{ paddingRight: "8px!important"}}><MDTypography>:</MDTypography></Grid>
								<Grid item md={8} xs={12}><MDTypography>{detailData?.order_no}</MDTypography></Grid>
								<Grid lg={2} md={3.5}><MDTypography>No Inv</MDTypography></Grid>
								<Grid md={"auto"} sx={{ paddingRight: "8px!important"}}><MDTypography>:</MDTypography></Grid>
								<Grid item md={8} xs={12}><MDTypography>{detailData?.invoice_no}</MDTypography></Grid>
								<Grid item lg={2} md={3.5}><MDTypography>Client Name</MDTypography></Grid>
								<Grid item md={"auto"} sx={{ paddingRight: "8px!important"}}><MDTypography>:</MDTypography></Grid>
								<Grid item md={8} xs={12}><MDTypography>{detailData?.client_name}</MDTypography></Grid>
								<Grid item lg={2} md={3.5}><MDTypography>Total Price</MDTypography></Grid>
								<Grid item md={"auto"} sx={{ paddingRight: "8px!important"}}><MDTypography>:</MDTypography></Grid>
								<Grid item md={8} xs={12}><MDTypography>Rp {addCommas(detailData?.total_price)}</MDTypography></Grid>
								<Grid item lg={2} md={3.5}><MDTypography>Total Qty</MDTypography></Grid>
								<Grid item md={"auto"} sx={{ paddingRight: "8px!important"}}><MDTypography>:</MDTypography></Grid>
								<Grid item md={8} xs={12}><MDTypography>{detailData?.total_quantity}</MDTypography></Grid>
								<Grid item lg={2} md={3.5}><MDTypography>Status Order</MDTypography></Grid>
								<Grid item md={"auto"} sx={{ paddingRight: "8px!important"}}><MDTypography>:</MDTypography></Grid>
								<Grid item md={8} xs={12}><MDTypography>{orderStatus[detailData?.order_status]}</MDTypography></Grid>
							</Grid>
							<TabsUnstyled defaultValue={0}>
								<MDBox sx={{ overflow: "auto" }}>
									<TabsList className="tablist-customized">
										<Tab>Detail Product</Tab>
										<Tab sx={{ marginLeft: "-2px" }}>Remarks</Tab>
										<Tab sx={{ marginLeft: "-2px" }}>Payment History</Tab>
									</TabsList>
								</MDBox>
								<TabPanelUnstyled value={0}>
									<MDBox sx={{ border: "2px solid #000" }}>
										<DataTable
											table={{ columns: productColumns, rows : productRows }}
											isSorted={false}
											entriesPerPage={false}
											showTotalEntries={true}
											pagination={{variant: "contained", color: "primary"}}
											noEndBorder
											paginationDetail={productPaginationDetail}
											gotoPage={productGotoPage}
										/>
										{
											detailData?.payment_status === 2 && detailData?.order_status === 2 ?
												<MDBox display="flex" justifyContent="flex-end" pr={3} pb={3}>
													<MDButton color="primary" onClick={submitProductChecked}>Save</MDButton>
												</MDBox>
											: null
										}
									</MDBox>
								</TabPanelUnstyled>
								<TabPanelUnstyled value={1}>
									<MDBox px={2} py={3} sx={{ border: "2px solid #000" }}>
										<MDButton sx={{ marginBottom: "16px" }} color="primary" onClick={() => setDisplayRemark(true)}>Add Remarks</MDButton>
										<DataTable
											table={{ columns: remarkColumns, rows : remarkRows }}
											isSorted={false}
											entriesPerPage={false}
											showTotalEntries={true}
											pagination={{variant: "contained", color: "primary"}}
											noEndBorder
											paginationDetail={remarkPaginationDetail}
											gotoPage={remarkGotoPage}
										/>
									</MDBox>
								</TabPanelUnstyled>
								<TabPanelUnstyled value={2}>
									<MDBox px={2} py={3} sx={{ border: "2px solid #000" }}>
										<DataTable
											table={{ columns: historyColumns, rows : historyRows }}
											isSorted={false}
											entriesPerPage={false}
											showTotalEntries={true}
											pagination={{variant: "contained", color: "primary"}}
											noEndBorder
											paginationDetail={historyPaginationDetail}
											gotoPage={historyGotoPage}
										/>
									</MDBox>
								</TabPanelUnstyled>
							</TabsUnstyled>
						</Card>
					</MDBox>
				</Grid>
				<Grid item xs={12} md={5}>
					<MDBox width="100%">
						<Card
							sx={{
								width: "100%",
								margin: "0 auto",
								padding: "24px"
							}}
						>
							<MDTypography variant="h5" fontWeight="bold">Payment Method</MDTypography>
							<MDTypography variant="h5" fontWeight="regular">{detailData?.payment_channel}</MDTypography>
							<MDTypography variant="h5" fontWeight="regular">{detailData?.transfer_dest}</MDTypography>
							<MDTypography variant="h5" fontWeight="regular">{detailData?.order_no}</MDTypography>
							<MDBox my={3} pb={2} sx={{ borderBottom: "1px solid #000"}} display="flex" justifyContent="space-between" alignItems="flex-end">
								<MDBox>
									<MDTypography variant="h5" fontWeight="regular" sx={{ color: detailData?.payment_status === null ? "red" : "inherit"}}>{detailData?.payment_status === null ? "Waiting for Payment" : paymentStatus[detailData?.payment_status]}</MDTypography>
									<MDTypography variant="h5" fontWeight="bold" sx={{ visibility: detailData?.payment_status === 1 ? "visible" : "hidden"}}>{moment(detailData?.paid_at).format("DD MMMM YYYY HH:mm:ss")} WIB</MDTypography>
									<MDTypography variant="h5" fontWeight="regular" sx={{ textDecoration: "underline", fontStyle: "italic", visibility: detailData?.payment_status === 1 ? "visible" : "hidden", cursor: detailData?.payment_receipt_url ? "pointer" : "not-allowed" }}><a target="_blank" href={detailData?.payment_receipt_url} rel="noreferrer">Lihat Bukti Transaksi</a></MDTypography>
								</MDBox>
								{
									detailData?.payment_status === 3 && detailData?.is_approve === 1 ?
										<MDBox sx={{ backgroundColor: "green", color: "#FFF", borderRadius: "50%", width: "30px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center"}}>
											<Icon fontSize="big">done</Icon>
										</MDBox>
									: null
								}
							</MDBox>
							<Grid
								container
								direction="row"
								justifyContent="space-between"
								alignItems="center"
							>
								<Grid item xs>
									<MDTypography variant="h5" fontWeight="bold">Total Price</MDTypography>
									<MDTypography variant="h5" fontWeight="bold" className="hide-in-desktop mb-2">Rp {addCommas(detailData?.total_price)}</MDTypography>
									<MDTypography variant="h5" fontWeight="bold">Shipping</MDTypography>
									<MDTypography variant="h5" fontWeight="bold" className="hide-in-desktop mb-2">Rp {addCommas(detailData?.shipping_cost)}</MDTypography>
									<MDTypography variant="h5" fontWeight="bold">Discount</MDTypography>
									<MDTypography variant="h5" fontWeight="bold" className="hide-in-desktop mb-2">Rp -{addCommas(detailData?.discount)}</MDTypography>
									<MDTypography variant="h5" fontWeight="bold">Grand Total</MDTypography>
									<MDTypography variant="h5" fontWeight="bold" className="hide-in-desktop">Rp {addCommas(detailData?.grand_total)}</MDTypography>
								</Grid>
								<Grid className="hide-in-mobile" item sm="auto">
									<Grid container direction={"column"} alignItems="center">
										<Grid item xs={12} sm={12} sx={{ minWidth: "100%" }}>
											<Grid container alignItems={"center"} spacing={1} justifyContent="space-between">
												<Grid item xs={"auto"} alignItems={"center"}>
													<MDTypography variant="h5" fontWeight="bold">Rp</MDTypography>
												</Grid>
												<Grid item>
													<MDTypography variant="h5" fontWeight="bold">{addCommas(detailData?.total_price)}</MDTypography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12} sm={12} sx={{ minWidth: "100%" }}>
											<Grid container alignItems={"center"} spacing={1} justifyContent="space-between">
												<Grid item xs={"auto"}>
													<MDTypography variant="h5" fontWeight="bold">Rp</MDTypography>
												</Grid>
												<Grid item>
													<MDTypography variant="h5" fontWeight="bold">{addCommas(detailData?.shipping_cost)}</MDTypography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12} sm={12} sx={{ minWidth: "100%" }}>
											<Grid container alignItems={"center"} spacing={1} justifyContent="space-between">
												<Grid item xs={"auto"}>
													<MDTypography variant="h5" fontWeight="bold">Rp</MDTypography>
												</Grid>
												<Grid item>
													<MDTypography variant="h5" fontWeight="bold">-{addCommas(detailData?.discount)}</MDTypography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12} sm={12} sx={{ minWidth: "100%" }}>
											<Grid container alignItems={"center"} spacing={1} justifyContent="space-between">
												<Grid item xs={"auto"}>
													<MDTypography variant="h5" fontWeight="bold">Rp</MDTypography>
												</Grid>
												<Grid item>
													<MDTypography variant="h5" fontWeight="bold">{addCommas(detailData?.grand_total)}</MDTypography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<MDBox mt={4} mb={2} display="flex" justifyContent="flex-end" gap="16px" visibility={detailData?.payment_status === 1 ? "visible" : "hidden"}>
								<MDButton color="secondary" onClick={() => changeStatusDetail(3, "Reject")}>Reject</MDButton>
								<MDButton color="primary" onClick={() => changeStatusDetail(2, "Approve")}>Aprrove</MDButton>
							</MDBox>
						</Card>
					</MDBox>
					<MDBox width="100%" mt={3}>
						<Card
							sx={{
								width: "100%",
								margin: "0 auto",
								padding: "24px"
							}}
						>
							<MDTypography variant="h5" fontWeight="regular">Recipient Address</MDTypography>
							<MDTypography variant="h5" fontWeight="bold">{detailData?.alias_address}</MDTypography>
							<MDTypography variant="h5" fontWeight="bold">{detailData?.address}</MDTypography>
							<MDBox mt={2}>
								<MDTypography variant="h5" fontWeight="regular">Courier</MDTypography>
								<MDTypography variant="h5" fontWeight="bold">{detailData?.courier_name}</MDTypography>
							</MDBox>
						</Card>
					</MDBox>
				</Grid>
			</Grid>
			<StatusNoteModal open={displayNote} setOpen={setDisplayNote} detail={statusDetail} setDetail={setStatusDetail} handleSubmit={handleStatusOrder} />
			<AddRemarkModal open={displayRemark} setOpen={setDisplayRemark} remarkGotoPage={remarkGotoPage} id={id} setIsLoading={setIsLoading} />
			<LocatorModal open={displayLocator} setOpen={setDisplayLocator} product={product} setIsLoading={setIsLoading} setErrorMessage={setErrorMessage} setShowNotifError={setShowNotifError} selectLocator={selectLocator} setProduct={setProduct} />
		</Grid>
  );
}

export default ListOrdersView;
