import { useEffect, useState } from "react";
import axios from "axios";

// @mui material components
import {Grid, Icon} from "@mui/material";

import LoyaltyIndex from "../../../../components/AppComponents/Autoloka/promo/loyalty";
import LoyaltyAdd from "../../../../components/AppComponents/Autoloka/promo/loyalty/add";

import services from "../../../../service/services";
import LoyaltyView from "../../../../components/AppComponents/Autoloka/promo/loyalty/view";
import { useSearchParams } from "react-router-dom";

const Loyalty = ({
	setIsLoading,
	setMode,
	mode,
 }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [rows, setRows] = useState([]);
	const [rowsDisplayed, setRowsDisplayed] = useState([]);
	const [rawRowsDisplayed, setRawRowsDisplayed] = useState([]);
	const [paginationDetail, setPaginationDetail] = useState({});
	const [idDetail, setIdDetail] = useState(null);

  const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "View", accessor: "view", align: "left" },
		{ Header: "Loyalty Name", accessor: "loyalty_name", align: "left" },
		{ Header: "Loyalty Type", accessor: "loyalty_type", align: "left" },
		{ Header: "Effective Date", accessor: "effective_date", align: "left" },
	];

	useEffect(() => {
		if(mode === "index"){
			getList();
		}
	}, [mode]);

	const getList = (page) => { 
		setIsLoading(true)
		services.getLoyaltyList(page || searchParams.get("page") || 1)
			.then(res => {
				setIsLoading(false);
				setRows(settingRowsDataTable(res?.data?.data?.list, res?.data?.data?.current_page));
				setRawRowsDisplayed(res?.data?.data?.list);
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	};

	const settingRowsDataTable = (rowsArray, current_page) => {
		return rowsArray?.map((arr, i) => {
			return {
				no: ((current_page - 1) * 10) + i + 1,
				view: (
					<>
						<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setMode("view"); setIdDetail(arr.id)}}>visibility</Icon>
					</>
				),
				loyalty_name: arr?.loyalty_name,
				loyalty_type: arr?.loyalty_type == 0 ? "First Customer" : arr?.loyalty_type == 1 ? "Daily Trx" : arr?.loyalty_type == 2 ? "Top Spender" : "",
				effective_date: arr?.effective_date,
			};
		});
	};

  return (
    <>
			<Grid container spacing={6} className="grid-pure-container">
				{
					mode === "index" ?
						<LoyaltyIndex 
							columns={columns} 
							rows={rows}
							rowsDisplayed={rowsDisplayed}
							paginationDetail={paginationDetail}
							setMode={setMode}
							setIsLoading={setIsLoading}
							setRowsDisplayed={setRowsDisplayed} 
							setRawRowsDisplayed={setRawRowsDisplayed}
							setPaginationDetail={setPaginationDetail}
							getList={getList}
							settingRowsDataTable={settingRowsDataTable}
						/>
					: mode === "add" ?
						<LoyaltyAdd 
							setIsLoading={setIsLoading} 
							getList={getList}
						/>
					: mode === "view" ?
						<LoyaltyView 
							setMode={setMode}
							setIsLoading={setIsLoading} 
							id={idDetail}
						/>
					: null
				}
			</Grid>
    </>
  );
}

export default Loyalty;
