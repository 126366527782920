import React, { useState } from 'react';
import randomColor from "randomcolor";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { firebase_app } from "../firebaseConfig";
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";

const randomBgColor = randomColor();

const Header = ({setMenuActive, setAppActive, setMode}) => {

	const navigate = useNavigate();

	const [isOpen, setIsOpen] = useState(false);

	const signOut = () => {
		firebase_app.auth().signOut().then(() => {
			Object.keys(Cookies.get()).forEach((cookieName) => {
				Cookies.remove(cookieName);
			});
			setMenuActive("");
			setAppActive("");
			setMode("index");
			navigate('/');
		}).catch(function(error) {
			console.log(error);
		});
	}

	return (
		<div className='d-flex align-items-center justify-content-end position-relative'>
			<div className='d-flex align-items-center justify-content-end profile-flex' onClick={() => setIsOpen(!isOpen)}>
				<div style={{ backgroundColor: randomBgColor }} className='user-button me-2'>
					{Cookies.get("email") ? Cookies.get("email")[0] : "E"}
				</div>
				<div className='user-name'>
					{Cookies.get("email") ? Cookies.get("email") : "Error"}
				</div>
			</div>

			{
				isOpen ?
					<div className='popup-profile'>
						<div className='popup-feature d-flex align-items-end mb-3'>
							<FontAwesomeIcon style={{ width: "24px"}} className="me-2" icon="fa-solid fa-user" />
							<div className='popup-menu'>Profile</div>
						</div>
						<div className='popup-feature d-flex align-items-end mb-3'>
							<FontAwesomeIcon style={{ width: "24px"}} className="me-2" icon="fa-solid fa-gears" />
							<div className='popup-menu'>Preferences</div>
						</div>
						<div onClick={signOut} className='popup-feature d-flex align-items-end'>
							<FontAwesomeIcon style={{ width: "24px"}} className="me-2" icon="fa-solid fa-arrow-right-from-bracket" />
							<div className='popup-menu'>Logout</div>
						</div>
					</div>
				: null
			}

		</div>
	)
}

export default Header;