import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Icon, MenuItem, TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDButton from "../../../../MDButton";
import MDTypography from "../../../../MDTypography";

import placeholder from "../../../../../assets/images/placeholder-image.jpeg";
import MDInput from "../../../../MDInput";
import MDSnackbar from "../../../../MDSnackbar";

import services from "../../../../../service/services";

import { getImageError } from "../../../../../utils";

const CategoryEdit = ({
	categoryInfo,
	setIsLoading,
	rawRowsDisplayed,
	indexDetail,
	setMode
}) => {

	const [detailData, setDetailData] = useState({});
	const [categoryCode, setCategoryCode] = useState("");
	const [categoryImage, setCategoryImage] = useState("");
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const { register, handleSubmit, formState: { errors } } = useForm({mode: "all"});
	
	const submitEdit = (e) => {
		setIsLoading(true);
		e.id = detailData.id;
		e.category_code = categoryCode;
		e.category_image = categoryImage;
		services.editCategory(e)
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
			}).catch(errors => {
				setIsLoading(false);
				setErrorMessage(errors?.response?.data?.message);
				setShowNotifError(true);
				console.log(errors)
			})
	}

	useEffect(() => {
		if(rawRowsDisplayed.length > 0){
			setCategoryCode(rawRowsDisplayed[indexDetail].category_code);
			setCategoryImage(rawRowsDisplayed[indexDetail].category_image);
			setDetailData(rawRowsDisplayed[indexDetail]);
		}
	}, [rawRowsDisplayed])

	const backClickHandler = () => {
		setMode("index");
	}
	

  return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" justifyContent="space-between" mt={-0.5} mb={2.5} width="100%">
				<MDBox display="flex" alignItems="center">
					<button onClick={backClickHandler} style={{border: 'none', background: 'white', borderRadius: '5px', marginRight: '1rem',width: '2rem', height: '2rem',display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
						<Icon fontSize="medium">
							arrow_back
						</Icon>
					</button>
					<MDTypography variant="h3" fontWeight="regular">
					Edit Category
					</MDTypography>
				</MDBox>
				<MDButton color="info" type="submit" form="edit-category">
					Update
				</MDButton>
			</MDBox>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="Category Updated!"
				open={showNotifSuccess}
				dateTime="Just Now"
				close={() => setShowNotifSuccess(false)}
			/>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			{
				rawRowsDisplayed.length > 0 && Object.keys(detailData)?.length > 0 ?
					<MDBox component="form" role="form" id="edit-category" onSubmit={handleSubmit(submitEdit)}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6}>
								<MDBox width="100%" mb={2}>
									<Card
										sx={{
											border: "2px solid gray",
											width: "100%",
											maxWidth: "500px",
											margin: "0 auto",
											color: "gray"
										}}
									>
										<MDBox
											position="relative"
											borderRadius="lg"
											mt={-3}
											mx={1}
											className="card-header"
											sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
										>
											<MDBox
												component="img"
												src={categoryImage ? categoryImage : placeholder}
												alt="Category Image"
												borderRadius="lg"
												shadow="sm"
												width="100%"
												height="100%"
												position="relative"
												zIndex={10}
												onError={getImageError}
											/>
										</MDBox>
										<MDBox textAlign="center" pt={4} pb={1} px={3}>
											<MDTypography variant="h5" fontWeight="regular" sx={{ mt: -4 }}>
												Category Image
											</MDTypography>
										</MDBox>
									</Card>
								</MDBox>
							</Grid>
							<Grid xs={12} md={6} item>
								<Card sx={{ padding: "24px 32px" }}>
									<MDTypography variant="h4" fontWeight="regular" sx={{ ml: -1, mb: 4}}>
										Category Information
									</MDTypography>
									<MDBox mb={2}>
										<TextField
											{...register("category_name", { required: "Required" })}
											id="select-category-name" 
											size="small" 
											fullWidth 
											label="Category Name"
											type="select" 
											select
											error={errors?.category_name} 
											defaultValue={detailData.category_name}
										>
											<MenuItem disabled value="">
												<em>Neither</em>
											</MenuItem>
											{
												categoryInfo?.map((cno, i) => {
													return (
														<MenuItem 
															key={"category-name-select-list-"+i} 
															value={cno.category_name} 
															onClick={() => {
																setCategoryCode(categoryInfo[i].category_code);
																setCategoryImage(categoryInfo[i].category_image);
															}}>
															{cno.category_name}
														</MenuItem>
													)
												})
											}
										</TextField>
										{errors?.category_name && 
											<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
												<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.category_name.message}</MDTypography>
											</MDBox>
										}
									</MDBox>
									{
										categoryCode &&
											<MDBox mb={2}>
												<MDInput 
													type="text" 
													label="Category Code"
													fullWidth
													value={categoryCode}
													disabled
													{...register("category_code")}
												/>
											</MDBox>
									}
									<MDBox mb={2}>
										<MDInput type="text" label="Category Type" defaultValue={detailData.category_type} error={errors?.category_type} fullWidth {...register("category_type", { required: "Required" })} />
										{errors?.category_type && 
											<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
												<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.category_type.message}</MDTypography>
											</MDBox>
										}
									</MDBox>
									<MDBox mb={2}>
										<TextField
											{...register("is_active", { required: "Required" })}
											id="select-is-active" 
											size="small" 
											fullWidth 
											label="Status"
											type="select" 
											select
											error={errors?.is_active} 
											defaultValue={detailData.status}
										>
											<MenuItem disabled value="">
												<em>Neither</em>
											</MenuItem>
											<MenuItem value="1">Active</MenuItem>
											<MenuItem value="0">Not Active</MenuItem>
										</TextField>
										{errors?.is_active && 
											<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
												<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.is_active.message}</MDTypography>
											</MDBox>
										}
									</MDBox>
								</Card>
							</Grid>
						</Grid>
					</MDBox>
				: null
			}
		</Grid>
  );
}

export default CategoryEdit;
