import { useEffect, useMemo, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Autocomplete, Chip, Icon, MenuItem, TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDInput from "../../../../MDInput";
import MDButton from "../../../../MDButton";
import MDSnackbar from "../../../../MDSnackbar";
import MDTypography from "../../../../MDTypography";

import services from "../../../../../service/services";

import { addCommas, getImageError } from "../../../../../utils";

import placeholder from "../../../../../assets/images/placeholder-image.jpeg";

const ItemPriceEdit = ({
	setMode,
	setIsLoading,
	id,
	listWarehouse
}) => {

	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [productDetail, setProductDetail] = useState(null);
	const [onShrinkLabel, setOnShrinkLabel] = useState(true);
	const [warehouse, setWarehouse] = useState(null);

	const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({ mode: "all" });

	useEffect(() => {
		if (id) {
			setIsLoading(true);
			services.getEditPriceDetail(id)
				.then(res => {
					setIsLoading(false);
					setProductDetail(res.data.data);
					setValue("price", addCommas(res.data.data.price));
					setWarehouse(res.data.data?.warehouse_id);
				}).catch(errors => {
					setIsLoading(false);
					setErrorMessage(errors?.response?.data?.message);
					setShowNotifError(true);
					console.log(errors)
				})
		} else {
			setProductDetail(null)
		}
	}, [id]);

	const submitEdit = (e) => {
		if (!warehouse) {
			setErrorMessage("Select warehouse first");
			setShowNotifError(true);
			return;
		}
		setIsLoading(true);
		let data = {
			id: id,
			warehouse_id: warehouse,
			price: e.price.replaceAll(".", ""),
		};
		services.editProductPrice(data)
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
			}).catch(errors => {
				setIsLoading(false);
				setErrorMessage(errors?.response?.data?.message);
				setShowNotifError(true);
				console.log(errors)
			})
	}

	const backClickHandler = () => {
		setMode("index");
	}

	return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" justifyContent="space-between" mt={-0.5} mb={4} width="100%">
				<MDBox display="flex" alignItems="center">
					<button onClick={backClickHandler} style={{ border: 'none', background: 'white', borderRadius: '5px', marginRight: '1rem', width: '2rem', height: '2rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<Icon fontSize="medium">
							arrow_back
						</Icon>
					</button>
					<MDTypography variant="h3" fontWeight="regular">
						Edit Product Price
					</MDTypography>
				</MDBox>
				<MDButton color="info" type="submit" form="edit-price">
					Save
				</MDButton>
			</MDBox>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="Product Price Updated!"
				open={showNotifSuccess}
				dateTime="Just Now"
				close={() => setShowNotifSuccess(false)}
			/>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			<MDBox component="form" role="form" id="edit-price" onSubmit={handleSubmit(submitEdit)}>
				<Grid container spacing={3}>
					<Grid xs={12} lg={8} item>
						<Card sx={{ padding: "24px 32px" }}>
							<Grid container spacing={2}>
								<Grid item xs={12} lg={12}>
									<MDTypography variant="h4" fontWeight="regular" sx={{ mb: 4 }}>Product Information</MDTypography>
								</Grid>
								<Grid item xs={12} lg={8}>
									<MDInput disabled type="text" label="Product Name" fullWidth value={productDetail?.product_name ? productDetail.product_name : ""} />
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput disabled type="text" label="Product Code" fullWidth value={productDetail?.product_code ? productDetail.product_code : ""} />
								</Grid>
								<Grid item xs={12} lg={8}>
									<MDInput
										{...register("price",
											{
												required: "Required",
												pattern: {
													value: /^([1-9]{1}[0-9]{0,2})(\.{1}\d{3})*$/,
													message: "Format error"
												},
												onBlur: (e) => setOnShrinkLabel(e.target.value.length > 0),
											},
										)}
										label="Price (IDR)" fullWidth
										value={addCommas(productDetail?.price)}
										InputLabelProps={{ shrink: onShrinkLabel }}
										error={errors?.price}
										onChange={(e) => {
											setProductDetail({ ...productDetail, price: addCommas(e.target.value) });
										}}
										onFocus={() => setOnShrinkLabel(true)}
									/>
									{errors?.price &&
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.price.message}</MDTypography>
										</MDBox>
									}
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput
										id="select-is-active"
										size="small"
										fullWidth
										label="Warehouse"
										type="select"
										select
										value={warehouse}
										onChange={(e) => setWarehouse(e.target.value)}
									>
										{listWarehouse?.map((wh) => (
											<MenuItem value={wh.warehouse_id}>
												{wh.warehouse_name}
											</MenuItem>
										))}
									</MDInput>
								</Grid>
								<Grid item xs={12} lg={8}>
									<MDInput disabled type="text" label="Product Description" value={productDetail?.product_desc ? productDetail.product_desc : ""} fullWidth />
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput disabled type="text" label="Molding Code" value={productDetail?.molding_code ? productDetail?.molding_code : ""} fullWidth />
								</Grid>
								<Grid item xs={12} sm={8}>
									<MDInput disabled type="text" label="Status" value={productDetail?.status == 1 ? "Active" : productDetail?.status == 0 ? "Not Active" : ""} fullWidth />
								</Grid>
							</Grid>
							<Grid item xs={12} sm={12}>
								<MDBox pt={2}>
									<MDTypography variant="h4" fontWeight="regular" sx={{ mb: 4 }}>
										Product Literation
									</MDTypography>
								</MDBox>
							</Grid>
							<Grid container spacing={2}>
								<Grid item xs={12} lg={8}>
									<MDInput disabled type="text" label="Brand Name" fullWidth value={productDetail?.brand_name ? productDetail.brand_name : ""} />
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput disabled type="text" label="Brand Code" fullWidth value={productDetail?.brand_code ? productDetail.brand_code : ""} />
								</Grid>
								<Grid item xs={12} lg={8}>
									<MDInput disabled type="text" label="Model Name" fullWidth value={productDetail?.model_name ? productDetail.model_name : ""} />
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput disabled type="text" label="Model Code" fullWidth value={productDetail?.model_code ? productDetail.model_code : ""} />
								</Grid>
								<Grid item xs={12} lg={8}>
									<MDInput disabled type="number" label="Model Year" fullWidth value={productDetail?.model_year ? productDetail.model_year : ""} />
								</Grid>
								<Grid item xs={12} lg={8}>
									<MDInput disabled type="text" label="Category Name" fullWidth value={productDetail?.category_name ? productDetail.category_name : ""} />
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput disabled type="text" label="Category Type" fullWidth value={productDetail?.category_type ? productDetail.category_type : ""} />
								</Grid>
								<Grid item xs={12} lg={8}>
									<MDInput disabled type="text" label="Subcategory Name" fullWidth value={productDetail?.subcategory_name ? productDetail.subcategory_name : ""} />
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput disabled type="text" label="Subcategory Code" fullWidth value={productDetail?.subcategory_code ? productDetail.subcategory_code : ""} />
								</Grid>
								<Grid item xs={12} lg={8}>
									<MDInput disabled type="text" label="Product Style Name" fullWidth value={productDetail?.style_name ? productDetail.style_name : ""} />
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput disabled type="text" label="Product Style Code" fullWidth value={productDetail?.style_code ? productDetail.style_code : ""} />
								</Grid>
								<Grid item xs={12} lg={8}>
									<MDInput disabled type="text" label="Attribute Name" fullWidth value={productDetail?.attribute_name ? productDetail.attribute_name : ""} />
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput disabled type="text" label="Attribute Code" fullWidth value={productDetail?.attribute_code ? productDetail.attribute_code : ""} />
								</Grid>
								<Grid item xs={12} lg={8}>
									<MDInput disabled type="text" label="Subattribute Name" fullWidth value={productDetail?.subattribute_name ? productDetail.subattribute_name : ""} />
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput disabled type="text" label="Subattribute Code" fullWidth value={productDetail?.subattribute_code ? productDetail.subattribute_code : ""} />
								</Grid>
							</Grid>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		</Grid>
	);
}

export default ItemPriceEdit;
