import { useEffect, useState } from "react";
import axios from "axios";

import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import AttributeIndex from "../../../../components/AppComponents/Autoloka/masterData/attribute";
import AttributeAdd from "../../../../components/AppComponents/Autoloka/masterData/attribute/add";
import AttributeEdit from "../../../../components/AppComponents/Autoloka/masterData/attribute/edit";
import DeleteConfirmationModal from "../../../../components/modals/deleteConfirmation";

import services from "../../../../service/services";

import placeholder from "../../../../assets/images/placeholder-image.jpeg";
import MDSnackbar from "../../../../components/MDSnackbar";
import { useSearchParams } from "react-router-dom";
import { cleanObject } from "../../../../utils";

const Attribute = ({
	setIsLoading,
	setMode,
	mode,
 }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [rows, setRows] = useState([]);
	const [rowsDisplayed, setRowsDisplayed] = useState([]);
	const [rawRowsDisplayed, setRawRowsDisplayed] = useState([]);
	const [paginationDetail, setPaginationDetail] = useState({});
	const [attributeNameOptions, setAttributeNameOptions] = useState([]);
	const [attributeCodeOptions, setAttributeCodeOptions] = useState([]);
	const [indexDetail, setIndexDetail] = useState(null);
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [isFiltered, setIsFiltered] = useState(false);
	const [id, setId] = useState(null);
	const [confirmationDisplay, setConfirmationDisplay] = useState(false);
	const [dataFilter, setDataFilter] = useState({
		attribute_name: searchParams.get("attribute_name") || "",
		attribute_code: searchParams.get("attribute_code") || "",
		is_active: searchParams.get("is_active") || "",
	});

  const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Edit", accessor: "edit", align: "left" },
		{ Header: "Attribute Name", accessor: "attribute_name", align: "left" },
		{ Header: "Attribute Code", accessor: "attribute_code", align: "left" },
		{ Header: "Status", accessor: "status", align: "left" },
	];

	useEffect(() => {

		let fetcher;
		if(Object.keys(cleanObject(dataFilter)).length !== 0) {
			fetcher = services.filterAttributeList({...dataFilter, page: searchParams.get("page") || 1})
		}else {
			fetcher = services.getMasterDataAttribute(searchParams.get("page") || 1)
		}

		if(mode === "index"){
			setIsLoading(true);
			Promise
				.all([
					fetcher,
					services.getOptionsAttributeName(),
					services.getOptionsAttributeCode(),
				]).then(axios.spread((...res) => {
					setIsLoading(false);
					setPaginationDetail(res[0]?.data?.data);
					setRows(settingRowsDataTable(res[0]?.data?.data?.list, res[0]?.data?.data?.current_page));
					setRawRowsDisplayed(res[0]?.data?.data?.list);
					setAttributeNameOptions(res[1]?.data?.data);
					setAttributeCodeOptions(res[2]?.data?.data);
				})).catch(errors => {
					setIsLoading(false);
					console.log("Error is found, apologies.")
					console.log(errors)
				})
		}
	}, [mode]);

	const deleteAttribute = (id) => {
		setIsLoading(true);
		setShowNotifSuccess(false);
		services.deleteAttribute(id)
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
				setConfirmationDisplay(false);
				getList(1);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors);
			})
	};

	const getList = (page) => {
		setIsLoading(true);
		services.getMasterDataAttribute(page)
			.then(res => {
				setIsLoading(false);
				setIsFiltered(false);
				setRows(settingRowsDataTable(res?.data?.data?.list, res?.data?.data?.current_page));
				setRawRowsDisplayed(res?.data?.data?.list);
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	};

	const settingRowsDataTable = (rowsArray, current_page) => {
		return rowsArray?.map((arr, i) => {
			return {
				no: ((current_page - 1) * 10) + i + 1,
				edit: (
					<>
						<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setMode("edit"); setIndexDetail(i)}}>edit</Icon>
						<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setId(arr.id); setConfirmationDisplay(true);}}>delete</Icon>
					</>
				),
				attribute_name: arr?.attribute_name,
				attribute_code: arr?.attribute_code,
				status: arr?.status == "0" ? "Not Active" : arr?.status == "1" ? "Active" : ""
			};
		});
	};

  return (
		<>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="Attribute Deleted!"
				dateTime="Just Now"
				open={showNotifSuccess}
				close={() => setShowNotifSuccess(false)}
			/>
			<Grid container spacing={6} className="grid-pure-container">
				{
					mode === "index" ?
						<AttributeIndex 
						setDataFilter={setDataFilter}
						dataFilter={dataFilter}
							columns={columns} 
							rows={rows}
							rowsDisplayed={rowsDisplayed}
							setRowsDisplayed={setRowsDisplayed} 
							setRawRowsDisplayed={setRawRowsDisplayed}
							setIndexDetail={setIndexDetail}
							attributeNameOptions={attributeNameOptions} 
							attributeCodeOptions={attributeCodeOptions} 
							setIsLoading={setIsLoading}
							placeholder={placeholder}
							setMode={setMode}
							deleteAttribute={deleteAttribute}
							paginationDetail={paginationDetail}
							setPaginationDetail={setPaginationDetail}
							getList={getList}
							isFiltered={isFiltered}
							setIsFiltered={setIsFiltered}
							settingRowsDataTable={settingRowsDataTable}
						/>
					: mode === "add" ?
						<AttributeAdd setIsLoading={setIsLoading} />
					: mode === "edit" ?
						<AttributeEdit setMode={setMode} indexDetail={indexDetail} rawRowsDisplayed={rawRowsDisplayed} setIsLoading={setIsLoading} /> 
					: null
				}
			</Grid>
			<DeleteConfirmationModal open={confirmationDisplay} setOpen={setConfirmationDisplay} itemName="attribute" deleteHandler={() => deleteAttribute(id)} /> 
		</>
  );
}

export default Attribute;
