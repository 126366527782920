import { Autocomplete, Box, createFilterOptions, Icon, Modal } from "@mui/material";
import MDBox from "../../MDBox";
import MDTypography from "../../MDTypography";
import MDInput from "../../MDInput";
import MDButton from "../../MDButton";
import { useEffect } from "react";
import useFetchState from "../../../hooks/useFetchState";
import services from "../../../service/services";
import { Controller } from "react-hook-form";
import { useRef } from "react";
import { useState } from "react";
import Filter from "./filter";
import SubcategoryDetail from "./SubcategoryDetail";
import Products from "./products";

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: 'background.paper',
	boxShadow: 24,
	minWidth: '450px',
	maxWidth: '90vw',
  	p: 3,
	borderRadius: "12px",
};


const AdvanceSearch = ({ 
	open, 
	setOpen,
    children,
    setProductPaginationDetail,
}) => {
    const [step, setStep] = useState(0);
    const [type, setType] = useState(null);
    const [subCategory, setSubCategory] = useState(null);

    const modalClose = () => {
        setOpen(false);
        setStep(0);
        setType(null);
        setSubCategory(null);
    }

    return (
        <>
            <Modal
                open={open}
                onClose={modalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="grid-modal add-product-inventory" sx={style}>
                    <MDBox position="relative">
                        <Icon style={{ position: "absolute", right: "-12px", top: "-12px" }} className="c-pointer" onClick={() => setOpen(false)} fontSize="small">close</Icon>
                    </MDBox>
                    {step === 0 && <Filter setStep={setStep} setType={setType} />}
                    {step === 1 && <SubcategoryDetail setSubCategory={setSubCategory} setStep={setStep} type={type} />}
                    {step === 2 && <Products setProductPaginationDetail={setProductPaginationDetail} modalClose={modalClose} setStep={setStep} type={type} subCategory={subCategory} />}
                </Box>
            </Modal>
        </>
    )
}

export default AdvanceSearch;