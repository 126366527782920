import { useState } from "react";
import { useForm } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { MenuItem, TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDButton from "../../../../MDButton";
import MDTypography from "../../../../MDTypography";

import MDInput from "../../../../MDInput";
import services from "../../../../../service/services";
import MDSnackbar from "../../../../MDSnackbar";

const AttributeAdd = ({
	setIsLoading,
}) => {

	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [statusSelected, setStatusSelected] = useState("");

	const { register, handleSubmit, setValue, trigger, reset, formState: { errors } } = useForm({mode: "all"});
	
	const submitCreate = (e) => {
		setIsLoading(true);
		services.createAttribute(e)
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
				setStatusSelected("");
				reset({
					attribute_name: "",
					attribute_code: "",
					is_active: "",
				});
			}).catch(errors => {
				setIsLoading(false);
				setErrorMessage(errors?.response?.data?.message);
				setShowNotifError(true);
				console.log(errors)
			})
	}

  return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" justifyContent="space-between" mt={-0.5} mb={2.5} width="100%">
				<MDTypography variant="h3" fontWeight="regular">
					Add New Attribute
				</MDTypography>
				<MDButton color="info" type="submit" form="create-attribute">
					Save
				</MDButton>
			</MDBox>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="New Attribute Added!"
				open={showNotifSuccess}
				dateTime="Just Now"
				close={() => setShowNotifSuccess(false)}
			/>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			<MDBox maxWidth="500px" component="form" role="form" id="create-attribute" onSubmit={handleSubmit(submitCreate)}>
				<Card sx={{ padding: "24px 32px" }}>
					<MDTypography variant="h4" fontWeight="regular" sx={{ ml: -1, mb: 4}}>
						Attribute Information
					</MDTypography>
					<MDBox mb={2}>
						<MDInput label="Attribute Name" error={errors?.attribute_name} {...register("attribute_name", { required: "Required" })} fullWidth />
						{errors?.attribute_name && 
							<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
								<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.attribute_name.message}</MDTypography>
							</MDBox>
						}
					</MDBox>
						<MDBox mb={2}>
							<MDInput error={errors?.attribute_code} {...register("attribute_code", { required: "Required" })} type="text" label="Attribute Code" fullWidth />
							{errors?.attribute_code && 
								<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
									<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.attribute_code.message}</MDTypography>
								</MDBox>
							}
						</MDBox>
						<MDBox mb={2}>
							<TextField
								{...register("is_active", { required: "Required" })}
								id="select-is-active" 
								size="small" 
								fullWidth 
								label="Status"
								type="select" 
								select
								error={errors?.is_active} 
								onChange={async (e) => {
									let trigger1 = await trigger("is_active");
									setStatusSelected(e.target.value);
								}}
								value={statusSelected}
							>
								<MenuItem disabled value="">
									<em>Neither</em>
								</MenuItem>
								<MenuItem value="1">Active</MenuItem>
								<MenuItem value="0">Not Active</MenuItem>
							</TextField>
							{errors?.is_active && 
								<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
									<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.is_active.message}</MDTypography>
								</MDBox>
							}
						</MDBox>
				</Card>
			</MDBox>
		</Grid>
  );
}

export default AttributeAdd;
