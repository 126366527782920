import { useEffect, useRef, useState } from "react";
import DateRangePicker from "react-daterange-picker";
import { useForm } from "react-hook-form";
import moment from "moment";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Icon, MenuItem, InputAdornment } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDButton from "../../../../MDButton";
import MDTypography from "../../../../MDTypography";
import MDEditor from "../../../../MDEditor";

import placeholder from "../../../../../assets/images/placeholder-image.jpeg";
import MDInput from "../../../../MDInput";
import MDSnackbar from "../../../../MDSnackbar";

import services from "../../../../../service/services";

import { getImageError, toBase64 } from "../../../../../utils";

const BannerAdd = ({
	setIsLoading,
}) => {

	const bannerImageRef = useRef();

	const [displayDate, setDisplayDate] = useState(false);
	const [bannerImage, setModelImage] = useState();
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isTransparent, setIsTransparent] = useState(false);

	const { register, handleSubmit, setValue, watch, trigger, reset, formState: { errors } } = useForm({mode: "all"});

	const watchAllFields = watch();

	useEffect(() => {
		register("date");
	}, []);

	const onImageChange = async (e, key) => {
		let format = e.target.files[0].name.split('.').pop();
		setIsTransparent(format.includes("png"));
		let val = await toBase64(e.target.files[0]);
		setValue(key, val);
		const result = await trigger(key);
		setModelImage(URL.createObjectURL(e.target.files[0]));
	}

	const removeImage = async (key) => {
		setIsTransparent(false);
		setValue(key, null);
		const result = await trigger(key);
		setModelImage(null);
	}
	
	const submitCreate = (e) => {
		setIsLoading(true);
		let dateTemp = e.date.split(" - ");
		let data = {
			title: e.title,
			description: e.description,
			position: e.position,
			type: e.type,
			link: e.link,
			start_date: dateTemp[0],
			end_date: dateTemp[1],
			active: e.is_active,
			image: e.banner_image,
		}
		services.createBanner(data)
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
				reset({
					title: "",
					description: "",
					position: "",
					type: "",
					link: "",
					date: "",
					date_displayed: "",
					is_active: "",
					banner_image: "",
				});
				setModelImage("");
			}).catch(errors => {
				setIsLoading(false);
				setErrorMessage(errors?.response?.data?.message);
				setShowNotifError(true);
				console.log(errors)
			})
	}

  return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" justifyContent="space-between" mt={-0.5} mb={2.5} width="100%">
				<MDTypography variant="h3" fontWeight="regular">
					Add New Banner
				</MDTypography>
				<MDButton color="info" type="submit" form="create-brand">
					Save
				</MDButton>
			</MDBox>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="New Banner Added!"
				open={showNotifSuccess}
				dateTime="Just Now"
				close={() => setShowNotifSuccess(false)}
			/>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			<MDBox component="form" role="form" id="create-brand" onSubmit={handleSubmit(submitCreate)}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>
						<MDBox width="100%" mb={2}>
							<Card
								sx={{
									"&:hover .card-header": {
										transform: "translate3d(0, -50px, 0)",
									},
									border: errors?.banner_image ? "2px solid red" : "inherit",
									width: "100%",
									maxWidth: "500px",
									margin: "0 auto"
								}}
							>
								<MDBox
									position="relative"
									borderRadius="lg"
									mt={-3}
									mx={1}
									mb={-2}
									className="card-header"
									sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)", zIndex: 999, backgroundColor: "transparent" }}
								>
									<MDBox
										sx={{ backgroundColor: isTransparent ? "#FFF" : "", borderRadius: "8px" }}
									>
										<MDBox
											component="img"
											src={bannerImage ? bannerImage : placeholder}
											alt="Model Image"
											borderRadius="lg"
											shadow="sm"
											width="100%"
											height="100%"
											position="relative"
											zIndex={10}
											onError={getImageError}
										/>
									</MDBox>
								</MDBox>
								<MDBox textAlign="center" pt={4} pb={1} px={3}>
									<MDBox
										display="flex"
										justifyContent="center"
										alignItems="center"
										mt={-9}
										mb={-1}
										position="relative"
										zIndex={1}
									>
										<MDBox mr={1}>
											<input
												{...register("banner_image", { required: "Required" })}
												type="file"
												accept="image/png;image/jpg;image/jpeg;"
												ref={bannerImageRef}
												onChange={(e) => onImageChange(e, "banner_image")}
												className="d-none"
											/>
											<MDButton variant="gradient" color="info" size="small" onClick={() => bannerImageRef.current.click()}>
												edit
											</MDButton>
										</MDBox>
										<MDButton variant="outlined" color="dark" size="small" onClick={() => removeImage("banner_image")}>
											remove
										</MDButton>
									</MDBox>
									<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 4 }}>
										Model Image
									</MDTypography>
									{
										errors?.banner_image &&
											<MDTypography fontWeight="bold" sx={{ fontSize: "10px", color: "red" }}>
												{errors.banner_image.message}
											</MDTypography>
									}
								</MDBox>
							</Card>
						</MDBox>
					</Grid>
					<Grid xs={12} md={6} item>
						<Card sx={{ padding: "24px 32px" }}>
							<MDTypography variant="h4" fontWeight="regular" sx={{ ml: -1, mb: 4}}>
								Banner Information
							</MDTypography>
							<MDBox mb={2}>
								<MDInput error={errors?.title} {...register("title", { required: "Required" })} type="text" label="Title" fullWidth />
								{errors?.title && 
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.title.message}</MDTypography>
									</MDBox>
								}
							</MDBox>
							<MDBox mb={2}>
								<MDTypography style={{color: "#7b809a"}} fontSize={"12px"}>Description</MDTypography>
								<MDEditor
									style={{ backgroundColor: "#FFF", borderRadius: "8px", height: "100%" }}
									{...register("description", { required: "Required" })} 
									error={errors?.description} 
									onChange={(e) => {
										setValue('description', e); 
									}}
									value={watchAllFields?.description || ""}
								/>
								{errors?.description && 
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.description.message}</MDTypography>
									</MDBox>
								}
							</MDBox>
							<MDBox mb={2}>
								<MDInput type="number" error={errors?.position} 
									{...register("position", { required: "Required", 
										pattern: { value: /^[1-9][0-9]*$/, message: "Integer Number only"}, 
										min: {
											value: 1,
											message: 'Minimum position value is 1'
										},max: {
											value: 10,
											message: 'Maximum position value is 10'
										},
									})} 
									label="Position" fullWidth
								/>
								{errors?.position && 
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.position.message}</MDTypography>
									</MDBox>
								}
							</MDBox>
							<MDBox mb={2}>
								<MDInput
									{...register("type", { required: "Required" })}
									id="select-is-active" 
									size="small" 
									fullWidth 
									label="Type"
									type="select" 
									select
									error={errors?.type}
									value={watchAllFields?.type || ""}
								>
									<MenuItem disabled value="">
										<em>Neither</em>
									</MenuItem>
									<MenuItem value="1">Primary</MenuItem>
									<MenuItem value="2">Secondary</MenuItem>
								</MDInput>
								{errors?.type && 
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.type.message}</MDTypography>
									</MDBox>
								}
							</MDBox>
							<MDBox mb={2}>
								<MDInput error={errors?.link} {...register("link", { required: "Required"})} type="text" label="Link" fullWidth />
								{errors?.link && 
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.link.message}</MDTypography>
									</MDBox>
								}
							</MDBox>
							<MDBox mb={2}>
								<MDInput
									size='small'
									label="Date Range"
									InputProps={{
										endAdornment: 
										<InputAdornment position="end">
											{watchAllFields?.date?.length > 0 ? 
												<Icon className="c-pointer" 
													onClick={() => {
														setValue("date_displayed", "");
														setValue("date", "")}
													} 
													fontSize="small">close</Icon>
												: null
											}
											<Icon fontSize="small">calendar_today</Icon>
										</InputAdornment>,
									}}
									InputLabelProps={{ shrink: watchAllFields?.date?.length > 0 }}
									{...register("date_displayed", { required: "Required" })} 
									error={errors?.date_displayed}
									fullWidth
									value={watchAllFields?.date_displayed}
									onClick={() => setDisplayDate(!displayDate)}
								/>
								{
									displayDate ?
										<DateRangePicker
											className="customized-daterange-picker"
											firstOfWeek={1}
											numberOfCalendars={2}
											selectionType="range"
											onSelect={ async (dateValue) => {
												let val = `${moment(dateValue.start).format('DD.MM.YYYY')} - ${moment(dateValue.end).format('DD.MM.YYYY')}`;
												setValue("date_displayed", val);
												setValue("date", `${moment(dateValue.start).format('YYYY-MM-DD')} - ${moment(dateValue.end).format('YYYY-MM-DD')}`)
												const result = await trigger("date_displayed");
												setDisplayDate(false);
											}}
										/>
									: null
								}
							</MDBox>
							<MDBox mb={2}>
								<MDInput
									{...register("is_active", { required: "Required" })}
									id="select-is-active" 
									size="small" 
									fullWidth 
									label="Status"
									type="select" 
									select
									error={errors?.is_active}
									value={watchAllFields?.is_active || ""}
								>
									<MenuItem disabled value="">
										<em>Neither</em>
									</MenuItem>
									<MenuItem value="1">Active</MenuItem>
									<MenuItem value="0">Not Active</MenuItem>
								</MDInput>
								{errors?.is_active && 
									<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
										<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.is_active.message}</MDTypography>
									</MDBox>
								}
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		</Grid>
  );
}

export default BannerAdd;
