import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import MDTypography from '../../../../MDTypography';
import MDInput from '../../../../MDInput';
import MDButton from '../../../../MDButton';
import MDBox from '../../../../MDBox';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  width: 'fit-content',
	maxWidth: '90vw',
  p: 3,
	borderRadius: "12px",
};

const FilterModal = ({ 
	open, 
	setOpen, 
	brandNameOptions,
	brandCodeOptions,
	brandTypeOptions,
	dataFilter,
	setDataFilter,
	filterList,
}) => {

	const handleClose = () => {
		setOpen(false);
	};

	const handleFilter = () => {
		handleClose();
		filterList(1);
	}

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="grid-modal" sx={style}>
					<MDBox position="relative">
						<Icon style={{ position: "absolute", right: "-12px", top: "-12px" }} className="c-pointer" onClick={handleClose} fontSize="small">close</Icon>
					</MDBox>
          <MDTypography variant="h4">
						Filter
					</MDTypography>
					<Grid container spacing={3} sx={{ width: "800px", maxWidth: "90vw", marginTop: "0", marginBottom: "24px"}}>
						<Grid item xs={12} sm={6}>
							<Autocomplete
								options={brandNameOptions}
								size="small"
								renderInput={(params) => (
									<MDInput {...params} label="Brand Name" />
								)}
								onChange={(e, newValue) => setDataFilter({...dataFilter, brand_name: newValue})}
								value={dataFilter.brand_name}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Autocomplete
								options={brandCodeOptions}
								size="small"
								renderInput={(params) => (
									<MDInput {...params} label="Brand Code" />
								)}
								onChange={(e, newValue) => setDataFilter({...dataFilter, brand_code: newValue})}
								value={dataFilter.brand_code}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<MDInput 
								onChange={e => setDataFilter({...dataFilter, brand_type: e.target.value})}
								value={dataFilter.brand_type} 
								id="select-brand-type" 
								size="small" 
								fullWidth 
								label="Brand Type" 
								type="select" 
								select
								>
								<MenuItem value={null}>Any</MenuItem>
								{
									brandTypeOptions.map((bt, i) => {
										return <MenuItem key={"option-autoloka-brand-name-"+i} value={bt.brand_type}>{bt.type_name}</MenuItem>
									})
								}
							</MDInput>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField 
								id="select-popular" 
								size="small" fullWidth 
								label="Popular" 
								select
								onChange={e => setDataFilter({...dataFilter, is_popular: e.target.value})}
								value={dataFilter.is_popular} 
							>
								<MenuItem value={null}>Any</MenuItem>
								<MenuItem value="1">Yes</MenuItem>
								<MenuItem value="0">No</MenuItem>
							</TextField>
						</Grid>
					</Grid>
					<MDBox display="flex" alignItems="center" justifyContent="end">
						<MDButton onClick={handleFilter} variant="gradient" color="info">Filter</MDButton>
					</MDBox>
        </Box>
      </Modal>
    </div>
  );
}

export default FilterModal;