import { useEffect, useMemo, useRef, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import moment from 'moment';

// @mui material components
import Grid from "@mui/material/Grid";
import { Autocomplete, Box, Card, Icon, Modal, Tab, Tabs, TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDInput from "../../../../MDInput";
import MDButton from "../../../../MDButton";
import MDSnackbar from "../../../../MDSnackbar";
import MDTypography from "../../../../MDTypography";

import services from "../../../../../service/services";

import DataTable from "../../../../Table";
import { addCommas } from "../../../../../utils";
import PrintLetterDO from "./PrintLetterDO";
import axios from "axios";

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: 'background.paper',
	boxShadow: 24,
	width: 'fit-content',
	  maxWidth: '90vw',
	p: 3,
	  borderRadius: "12px",
};
  
const DeliveryOrderView = ({
	setIsLoading,
	requestFromOptions,
	id,
	setMode
}) => {

	const printRef = useRef();
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifSuccessDraft, setShowNotifSuccessDraft] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [errorProduct, setErrorProduct] = useState("");
	const [productRows, setProductRows] = useState([]);
	const [productPaginationDetail, setProductPaginationDetail] = useState({
		current_page: 1,
		total_data: 0,
		total_page: 0,
		list: [],
	});
	const [historyPaginationDetail, setHistoryPaginationDetail] = useState({
		current_page: 1,
		total_data: 0,
		total_page: 0,
		list: [],
	});
	const [historyRows, setHistoryRows] = useState([]);
	const [detailData, setDetailData] = useState({});
	const [tab, setTab] = useState(0);

	const [modalLink, setModalLink] = useState(false);
	const [links, setLinks] = useState([]);

	const { register, trigger, control, reset, watch, formState: { errors } } = useForm({mode: "all"});

	const watchAllFields = watch();

	const { replace } = useFieldArray({
		control,
		shouldUnregister: false,
    name: "qty",
	});

	const backClickHandler = () => {
		setMode("index");
	}

	const onModalClose = () => {
		setModalLink(false);
	}

	const openModalLink = (links) => {
		setLinks(links || []);
		setModalLink(true);
	}

	const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Sales Order No", accessor: "so_no", align: "left" },
		{ Header: "Product Name", accessor: "product_name", align: "left" },
		{ Header: "Code", accessor: "code", align: "left" },
		{ Header: "Price", accessor: "price", align: "left" },
		{ Header: "Qty", accessor: "qty", align: "left" },
	];

	const columnsHistory = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Notes", accessor: "notes", align: "left" },
		{ Header: "Link", accessor: "link", align: "left" },
		{ Header: "Created By", accessor: "created_by", align: "left" },
		{ Header: "Created At", accessor: "created_at", align: "left" },
	];

	const columnsDetailLinkSO = [
		{ Header: "SO Id", accessor: "so_id", align: "left" },
		{ Header: "SO No", accessor: "so_no", align: "left" },
	];

	const columnsDetailLinkP0 = [
		{ Header: "PO Id", accessor: "po_id", align: "left" },
		{ Header: "PO No", accessor: "po_no", align: "left" },
	];

	useEffect(() => {
		setIsLoading(true);
		Promise.all([
			services.detailDO(id),
			services.getHistoryDO(id, 1),
		]).then(axios.spread((...res) => {
			setIsLoading(false);
			setDetailData(res[0]?.data?.data);
			let listAll = res[0]?.data?.data.list_detail;
			let total_page = parseInt(listAll.length/10);
			if(listAll.length%10 !== 0){
				total_page = total_page + 1;
			};
			setProductPaginationDetail({
				current_page: 1,
				total_page: total_page,
				total_data: listAll.length,
				list: listAll,
			});
			replace(listAll.map( list => list.qty));

			let totalPageRemarks = parseInt(res[1]?.data?.data?.list_history, 10) / 10;
			if(res[1]?.data?.data?.list_history.length % 10 !== 0) {
				totalPageRemarks = totalPageRemarks + 1;
			}
			setHistoryPaginationDetail({
				current_page: 1,
				total_page: totalPageRemarks,
				total_data: res[1]?.data?.data?.list_history.length,
				list: res[1]?.data?.data?.list_history,
			});
		})).catch((err) => {
			setIsLoading(false);
			setShowNotifError(true)
			setErrorMessage(err?.response?.data?.message);
		});
		
	}, [id]);

	const settingRows = () => {
		let listAll = productPaginationDetail.list.map( (arr, j) => {
			return {
				no: j + 1,
				so_no: arr?.so_no,
				product_name: arr?.product_name,
				code: arr?.product_code,
				price: "Rp"+addCommas(arr?.price),
				qty: <TextField className="without-label-input" style={{ width: "48px" }} type="number" disabled value={arr?.qty || ""} />
			}}
		);
		let total_page = parseInt(listAll.length/10);
		if(listAll.length%10 !== 0){
			total_page = total_page + 1;
		};
		setProductPaginationDetail({...productPaginationDetail, total_page: total_page});
		return listAll;
	};
	
	useMemo(() => {
		let listAll = settingRows();
		listAll = listAll?.filter((arr, i) => (((productPaginationDetail.current_page - 1) * 10) <= i && (productPaginationDetail.current_page * 10) > i));
		setProductRows(listAll);
	}, [productPaginationDetail.list, productPaginationDetail.current_page]);

	const settingHistoryRows = () => {
		let listAll = historyPaginationDetail.list?.map((arr, j) => {
			return {
				no: j + 1,
				notes: arr?.notes || '-',
				link: <p style={{cursor: 'pointer'}} onClick={() => openModalLink(arr?.list_so || arr?.list_po)}>
						Link
					</p>,
				created_by: arr?.created_by,
				created_at: moment(arr?.created_at).format('DD-MM-yyyy'),
			}}
		);
		let total_page = parseInt(listAll.length/10);
		if(listAll.length%10 !== 0){
			total_page = total_page + 1;
		};
		setHistoryPaginationDetail({...historyPaginationDetail, total_page: total_page});
		return listAll;
	}

	useMemo(() => {
		let listAll = settingHistoryRows();
		listAll = listAll?.filter((arr, i) => (((historyPaginationDetail.current_page - 1) * 10) <= i && (historyPaginationDetail.current_page * 10) > i));
		setHistoryRows(listAll);
	}, [historyPaginationDetail.list, historyPaginationDetail.current_page])


	const goToPageProduct = (page) => {
		setProductPaginationDetail({...productPaginationDetail, current_page: page});
	};

	const printDOHandler = () => {
		printRef.current?.click();
	}

	const goToPageRemarks = (page) => {
		setHistoryPaginationDetail((prev) => ({
			...prev,
			current_page: page,
		}));
	};

	const handleTabChange = (event, newValue) => {
		setTab(newValue);
	};

  	return (
		<>
		<Modal 
			open={modalLink} 
			onClose={onModalClose}         
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			keepMounted >
			<Box className="grid-modal" sx={style}>
				<MDBox position="relative">
					<Icon style={{ position: "absolute", right: "-12px", top: "-12px" }} className="c-pointer" onClick={onModalClose} fontSize="small">close</Icon>
				</MDBox>
				<DataTable
						table={{ columns: links?.find((el) => el?.so_id ) ? columnsDetailLinkSO : columnsDetailLinkP0, rows : links }}
						isSorted={false}
						entriesPerPage={false}
						showTotalEntries={true}
						pagination={null}
						noEndBorder
						paginationDetail={null}
					/>
			</Box>
		</Modal>
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" flexDirection={window.innerWidth < 768 ? "column" : "row"} gap="8px" justifyContent="space-between" mt={-0.5} mb={2.5} width="100%">
				<MDBox display="flex" alignItems="center">
					<button onClick={backClickHandler} style={{border: 'none', background: 'white', borderRadius: '5px', marginRight: '1rem',width: '2rem', height: '2rem',display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
						<Icon fontSize="medium">
							arrow_back
						</Icon>
					</button>
					<MDTypography variant="h3" fontWeight="regular">
					Detail Delivery Order
					</MDTypography>
				</MDBox>
				<MDBox display="flex" maxWidth="90vw" gap="16px">
					<MDButton color="primary" onClick={printDOHandler}>
						Print DO
					</MDButton>
				</MDBox>
			</MDBox>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			<MDBox maxWidth="800px" width="100%" mb={4}>
				<Card sx={{ px: 3, py: 2 }}>
				<Grid container spacing={3}>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Delivery Order No</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{textDecoration: "underline"}} fontStyle="italic">
								{detailData?.do_no}
							</MDTypography>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Request To</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{textDecoration: "underline"}} fontStyle="italic">
								{detailData?.request_to}
							</MDTypography>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Request From</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{textDecoration: "underline"}} fontStyle="italic">
								{detailData?.request_from}
							</MDTypography>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Driver Name</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{textDecoration: "underline"}} fontStyle="italic">
								{detailData?.driver_name}
							</MDTypography>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Plate Number</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{textDecoration: "underline"}} fontStyle="italic">
								{detailData?.plate_number}
							</MDTypography>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Note</MDTypography>
							<textarea value={detailData?.note} name="notes" id="note-do" rows="1" style={{width: '100%',padding: '.5rem', outline: 'none',border: '1px solid #d1d1d1', borderRadius: '.5rem'}}></textarea>
						</Grid>
					</Grid>
				</Card>
			</MDBox>
			<MDBox maxWidth="950px" width="100%">
				<Box sx={{ borderBottom: 1, borderColor: 'divider', width: 'fit-content !important' }}>
					<Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
						<Tab label="Detail Product" style={{padding: '.5rem 2rem'}} />
						<Tab label="History" style={{padding: '.5rem 2rem'}}/>
					</Tabs>
				</Box>
				{tab === 0 && (
					<DataTable
						table={{ columns, rows : productRows }}
						isSorted={false}
						entriesPerPage={false}
						showTotalEntries={true}
						pagination={{variant: "contained", color: "primary"}}
						noEndBorder
						paginationDetail={productPaginationDetail}
						gotoPage={goToPageProduct}
					/>
				)}
				{tab === 1 && (
					<Box sx={{backgroundColor: 'white', padding: '.5rem', borderRadius: '.5rem'}}>
						<DataTable
							table={{ columns: columnsHistory, rows : historyRows }}
							isSorted={false}
							entriesPerPage={false}
							showTotalEntries={true}
							pagination={{variant: "contained", color: "primary"}}
							noEndBorder
							paginationDetail={historyPaginationDetail}
							gotoPage={goToPageRemarks}
						/>
					</Box>
				)}
			</MDBox>
		</Grid>
		<PrintLetterDO dataDO={detailData}>
			{(clickPrint) => (
				<button ref={printRef} onClick={clickPrint} style={{display: 'none'}} />
			)}
		</PrintLetterDO>
		</>
  );
}

export default DeliveryOrderView;
