import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import { Chart, registerables } from "chart.js";

import MDBox from "../../../../components/MDBox";

import ComplexStatisticsCard from "../../../../components/Card/ComplexStatisticsCard";
import DefaultDoughnutChart from "../../../../components/Chart/DefaultDoughnutChart";
import DefaultLineChart from "../../../../components/Chart/DefaultLineChart";
import VerticalBarChart from "../../../../components/Chart/VerticalBarChart";
// hooks
import useFetchState from "../../../../hooks/useFetchState";

// services
import services from "../../../../service/services";

// utils
import { addCommas } from "../../../../utils";

Chart.register(...registerables);

const salesOverviewData = {
    labels: [],
    datasets: [
      {
        label: "",
        color: "info",
        data: [],
      },
    ],
};

const topTenProductData = {
    labels: [],
    datasets: { 
        label: "Top ten product" ,
        data: [] ,
        backgroundColors: [
            "light",
            "warning",
            "error",
            "primary",
            "secondary",
            "dark",
            "info",
            "success",
        ]
    },
};
const verticalBarChartData = {
    labels: [],
    datasets: [
      {
        label: "Sales performance",
        color: "dark",
        data: [],
      },
    ],
};

const report = [
    {
        id: "today_sales",
        color: "dark",
        icon: "weekend",
        title: "Today's Sales",
        count: 0,
        percentage: {
            color: "success",
            amount: "+55%",
            label: "than last yesterday"
        }
    },
    {
        id: "daily_cust",
        color: "primary",
        icon: "person_add",
        title: "Daily Customer",
        count: 0,
        percentage: {
            color: "success",
            amount: "+55%",
            label: "than last week"
        }
    },
    {
        id: "total_customer",
        color: "success",
        icon: "person_add",
        title: "Total Customer",
        count: 0,
    },
    {
        id: "total_revenue",
        color: "info",
        icon: "weekend",
        title: "Total Revenue",
        count: 0,
    },
];

const POSDashboard = () => {

    const getReport = useFetchState();
    const getTopTenProduct = useFetchState();
    const getSalesOverview = useFetchState();
    const getSalesPerformance = useFetchState();

    // getreport fetch
    useEffect(()=> {
        getReport.fetchStart();
        services.getReport().then((res) => {
            const data = res.data?.data;

            if(!data || res.data?.status !== 200) {
                getReport.fetchFailed(res.data?.message || 'Ooops something went wrong');
                return;
            }

            const mergeData = report.map((el) => {
                if(el.id === "today_sales") {
                    return {
                        ...el,
                        count: addCommas(data.today_sales[0].total_sales || 0),
                        percentage: {
                            ...el.percentage,
                            amount: data.today_sales[0].diff_sales_percentage || 0,
                            color: (data.today_sales[0].diff_sales_percentage || 0) > 0 ? "success" : "secondary"
                        }
                    }
                }

                if(el.id === "daily_cust") {
                    return {
                        ...el,
                        count: addCommas(data.daily_cust[0].daily_customer || 0),
                        percentage: {
                            ...el.percentage,
                            amount: data.daily_cust[0].diff_cust_percentage || 0,
                            color: (data.daily_cust[0].diff_cust_percentage || 0) > 0 ? "success" : "secondary"
                        }
                    }
                }

                return {
                    ...el,
                    count: el.id === "total_customer" ? data.total_customer : addCommas(data.total_revenue),
                }
            });

            getReport.fetchSuccess(mergeData);
        }).catch((error) => {
            getReport.fetchFailed(error?.message);
        });
    },[]);

    // gettoptenproduct fetch
    useEffect(() => {
        getTopTenProduct.fetchStart();
        services.getTopTenProduct().then((res) => {
            const data = res.data?.data;
            if(!data || res.data?.status !== 200) {
                getTopTenProduct.fetchFailed(res.data?.message || 'Ooops something went wrong');
                return;
            }
            const mergeData = {
                ...topTenProductData,
                labels: data.map((el) => el.product_name),
                datasets: {
                    ...topTenProductData.datasets,
                    data: data.map((el) => el.total_product)
                }
            }
            getTopTenProduct.fetchSuccess(mergeData);
        }).catch((error) => {
            getTopTenProduct.fetchFailed(error?.message || 'Ooops something went wrong');
        });
    },[]);

    // getSalesOverview
    useEffect(() => {
        getSalesOverview.fetchStart();
        services.getSalesOverview().then((res) => {
            const data = res.data?.data;
            if(!data || res.data?.status !== 200) {
                getSalesOverview.fetchFailed(res.data?.message || 'Ooops something went wrong');
                return;
            }

            const mergeData = {
                ...salesOverviewData,
                labels: data.list_overview.map((el) => el.x_axis_day),
                datasets: [
                    {
                        label: "list_overview",
                        data: data.list_overview.map((el) => el.y_axis_total),
                        color: "info",
                    }
                ]
            }
            getSalesOverview.fetchSuccess(mergeData);
        }).catch((error) => {
            getSalesOverview.fetchFailed(error?.message || 'Ooops something went wrong');
        });
    },[]);

    // getSalesPerformance
    useEffect(() => {
        getSalesPerformance.fetchStart();
        services.getSalesPerformance().then((res) => {
            const data = res.data?.data?.sort((a, b) => a.sales_name.localeCompare(b.sales_name));

            if(!data || res.data?.status !== 200) {
                getSalesPerformance.fetchFailed(res.data?.message || 'Ooops something went wrong');
                return;
            }
            
            const mergeData = {
                ...verticalBarChartData,
                labels: data.map((el) => el.sales_name),
                datasets: [
                    {
                        label: "sales_performance",
                        data: data.map((el) => el.total_price || 0),
                        backgroundColors: [
                            "light",
                            "warning",
                            "error",
                            "primary",
                            "secondary",
                            "dark",
                            "info",
                            "success",
                        ]
                    }
                ]
            }

            getSalesPerformance.fetchSuccess(mergeData)

        }).catch((error) => {
            getSalesPerformance.fetchFailed(error?.message || 'Ooops something went wrong');
        });
    },[]);

    return (
        <>
        <MDBox mt={1.5}>
            <Grid container spacing={3}>
                {!getReport.loading ? (
                    getReport.data?.map((el) => (
                        <Grid key={el.id} item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color={el.color}
                                    icon={el.icon}
                                    title={el.title}
                                    count={el.count}
                                    percentage={el.percentage}
                                />
                            </MDBox>
                        </Grid>
                    ))
                ): [...new Array(4)].map((el) => (
                    <Grid key={el} item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                color="light"
                                icon="-"
                                title="-"
                                count={0}
                                percentage={{}}
                            />
                        </MDBox>
                    </Grid>
                ))}
            </Grid>
            {getReport.error && <Alert severity="error">{getReport.error}</Alert>}
        </MDBox>
        <MDBox mt={5} >
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={7}>
                    <MDBox display="flex" flexDirection="column">
                        <MDBox mb={1.5}>    
                            {getSalesOverview.loading ? <h2>Loading...</h2> : (
                                <DefaultLineChart 
                                    chart={getSalesOverview.data ? getSalesOverview.data : salesOverviewData} 
                                    title="Sales Overview" />
                            )}
                            {getSalesOverview.error && <Alert severity="error">{getSalesOverview.error}</Alert>}
                        </MDBox>
                        <MDBox mt={2}>    
                            {getSalesPerformance.loading ? <h2>Loading...</h2> : (
                                <VerticalBarChart 
                                    chart={getSalesPerformance.data ? getSalesPerformance.data : verticalBarChartData} 
                                    title="Sales Performance" />
                                )}
                            {getSalesPerformance.error && <Alert severity="error">{getSalesPerformance.error}</Alert>}
                        </MDBox>
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                    <MDBox mb={1.5}>    
                        {getTopTenProduct.loading ? <h2>Loading...</h2> : (
                            <DefaultDoughnutChart 
                                chart={getTopTenProduct.data ? getTopTenProduct.data : topTenProductData} 
                                title="Top 10 Products" />
                        )}
                        {getTopTenProduct.error && <Alert severity="error">{getTopTenProduct.error}</Alert>}
                    </MDBox>
                </Grid>
            </Grid>
        </MDBox>
        </>
    )
}

export default POSDashboard;