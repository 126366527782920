import { useEffect, useMemo, useRef, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import { Card, Icon, TextField, Autocomplete ,MenuItem, Box, Tabs, Tab, Button, DialogTitle, DialogActions, Dialog, DialogContent} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDButton from "../../../../MDButton";
import MDSnackbar from "../../../../MDSnackbar";
import MDTypography from "../../../../MDTypography";
import MDInput from '../../../../MDInput';

import services from "../../../../../service/services";

import AddProductModal from "./addProductModal";
import DataTable from "../../../../Table";
import { addCommas } from "../../../../../utils";
import axios from "axios";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const InventoryOutView = ({
	setIsLoading,
	id,
	productOptions,
	requester,
	setMode
}) => {

	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [status, setStatus] = useState('');
	const [productRows, setProductRows] = useState([]);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isModalForRemarks, setIsModalForRemarks] = useState(false);
	const [remarksRows, setRemarksRows] = useState([]);
	const [productPaginationDetail, setProductPaginationDetail] = useState({
		current_page: 1,
		total_data: 0,
		total_page: 0,
		list: [],
	});
	const [remarksPaginationDetail, setRemarksPaginationDetail] = useState({
		current_page: 1,
		total_data: 0,
		total_page: 0,
		list: [],
	});
	const [detailData, setDetailData] = useState({});
	const [modalRemarks, setModalRemarks] = useState(false);

	const [tab, setTab] = useState(0);

	const handleTabChange = (event, newValue) => {
	  setTab(newValue);
	};


	const { register, trigger, control, reset, watch, formState: { errors } } = useForm({mode: "all"});

	const watchAllFields = watch();

	const { fields, update, remove, replace } = useFieldArray({
		control,
		shouldUnregister: true,
    	name: "qty",
	});

	const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Product Name", accessor: "product_name", align: "left" },
		{ Header: "Code", accessor: "product_code", align: "left" },
		{ Header: "Price", accessor: "price", align: "left" },
		{ Header: "Qty", accessor: "qty", align: "left" },
	];

	const columnsRemarks = [
		{ Header: "Remarks", accessor: "remark", align: "left" },
		{ Header: "Note", accessor: "note", align: "left" },
		{ Header: "Created By", accessor: "created_by", align: "left" },
		{ Header: "Created At", accessor: "created_at", align: "left" },
	];

	const getRemarksInventoryOut = async () => {
		setIsLoading(true);
		services.getRemarksInventoryOut(id, 1).then((res) => {
			setIsLoading(false);

			let totalPageRemarks = parseInt(res?.data?.data?.list_remarks, 10) / 10;
			if(res?.data?.data?.list_remarks.length % 10 !== 0) {
				totalPageRemarks = totalPageRemarks + 1;
			}
			setRemarksPaginationDetail({
				current_page: 1,
				total_page: totalPageRemarks,
				total_data: res?.data?.data?.list_remarks.length,
				list: res?.data?.data?.list_remarks,
			});
			setRemarksRows(res?.data?.data?.list_remarks);
		}).catch((err) => {
			setIsLoading(false);
		});
	}

	const backClickHandler = () => {
		setMode("index");
	}

	useEffect(() => {
		setIsLoading(true);
		Promise
			.all([
				services.detailInventoryOut(id),
				services.detailProductInventoryOut(id, 1),
				services.getRemarksInventoryOut(id, 1),
			]).then(axios.spread((...res) => {
				setIsLoading(false);

				setDetailData(res[0]?.data?.data);
				setStatus(res[0]?.data?.data?.status);
				let listAll = res[1]?.data?.data.list_product;
				let total_page = parseInt(listAll.length/10);
				if(listAll.length%10 !== 0){
					total_page = total_page + 1;
				};
				setProductPaginationDetail({
					current_page: 1,
					total_page: total_page,
					total_data: listAll.length,
					list: listAll,
				});
				replace(listAll.map( list => list.qty));

				let totalPageRemarks = parseInt(res[2]?.data?.data?.list_remarks, 10) / 10;
				if(res[2]?.data?.data?.list_remarks.length % 10 !== 0) {
					totalPageRemarks = totalPageRemarks + 1;
				}
				setRemarksPaginationDetail({
					current_page: 1,
					total_page: totalPageRemarks,
					total_data: res[2]?.data?.data?.list_remarks.length,
					list: res[2]?.data?.data?.list_remarks,
				});
			})).catch(errors => {
				setIsLoading(false);
				setShowNotifError(true)
				setErrorMessage(errors?.response?.data?.message);
			})
	}, []);

	useEffect(() => {
		(async() => {
			const result = await trigger("qty");
			let listAll = settingRows();
			listAll = listAll?.filter((arr, i) => (((productPaginationDetail.current_page - 1) * 10) <= i && (productPaginationDetail.current_page * 10) > i));
			setProductRows(listAll);
		})();
	}, [watchAllFields.qty])

	const settingRows = () => {
		let listAll = productPaginationDetail.list.map( (arr, j) => {
			return {
				no: j + 1,
				product: arr?.product,
				order_detail_id: arr?.order_detail_id,
				product_id: arr?.product_id || arr?.product?.product_id,
				product_name: arr?.product_name,
				product_code: arr?.product_code || arr?.code,
				price: "Rp"+addCommas(arr?.price),
				qty: <>
					<TextField 
						disabled
						key={"without-label-qty-"+j} 
						style={{ width: "48px" }} 
						className="without-label-input" 
						type="number" 
						value={watchAllFields.qty?.[j] || ""} 
						InputLabelProps={{ shrink: false }} />
				</>,
			}}
		);
		let total_page = parseInt(listAll.length/10);
		if(listAll.length%10 !== 0){
			total_page = total_page + 1;
		};
		setProductPaginationDetail({...productPaginationDetail, total_page: total_page});
		return listAll;
	}

	const settingRemarksRows = () => {
		let listAll = remarksPaginationDetail.list.map((arr, j) => {
			return {
				remark: arr?.remark,
				note: arr?.note || '',
				created_by: arr?.created_by,
				created_at: moment(arr?.created_at).format('DD/MM/yyyy - HH:MM'),
			}}
		);
		let total_page = parseInt(listAll.length/10);
		if(listAll.length%10 !== 0){
			total_page = total_page + 1;
		};
		setRemarksPaginationDetail({...remarksPaginationDetail, total_page: total_page});
		return listAll;
	}
	
	useMemo(() => {
		let listAll = settingRemarksRows();
		listAll = listAll?.filter((arr, i) => (((remarksPaginationDetail.current_page - 1) * 10) <= i && (remarksPaginationDetail.current_page * 10) > i));
		setRemarksRows(listAll);
	}, [remarksPaginationDetail.list, remarksPaginationDetail.current_page])

	useMemo(() => {
		let listAll = settingRows();
		listAll = listAll?.filter((arr, i) => (((productPaginationDetail.current_page - 1) * 10) <= i && (productPaginationDetail.current_page * 10) > i));
		setProductRows(listAll);
	}, [productPaginationDetail.list, productPaginationDetail.current_page])

	const goToPageProduct = (page) => {
		setProductPaginationDetail({...productPaginationDetail, current_page: page});
	};

	const goToPageRemarks = (page) => {
		setRemarksPaginationDetail((prev) => ({
			...prev,
			current_page: page,
		}));
	};

	const submitStatus = () => {
		setIsLoading(true);
		const data = {
			id,
			order_status: 0,
			note: document.querySelector('#remarks-inventory-out').value,
		}
		services.setStatusInventoryOut(data)
			.then((res) => {
				setIsLoading(false);
				setShowNotifSuccess(true);
				setModalRemarks(false);
			}).catch((err) => {
				setIsLoading(false);
				setShowNotifError(true);
				setModalRemarks(false);
			});
	}

	const createRemarks = () => {
		setIsLoading(true);
		const data = {
			order_id: id,
			note: document.querySelector('#remarks-inventory-out').value,
		}
		services.addRemarkInventoryOut(data)
			.then((res) => {
				setShowNotifSuccess(true);
				setIsLoading(false);
				setModalRemarks(false);
				getRemarksInventoryOut();
			}).catch((err) => {	
				setIsLoading(false);
				setShowNotifError(true);
				setModalRemarks(false);
			});
	}

	const openModalSave = () => {
		setIsModalForRemarks(false);
		setModalRemarks(true);
	}

	const openModalRemarks = () => {
		setIsModalForRemarks(true);
		setModalRemarks(true);
	}

  return (
	<>
		<Dialog
			open={modalRemarks}
			onClose={() => setModalRemarks(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title" style={{fontSize: '16px'}}>
				{isModalForRemarks ? 'Edit Confirmation': 'Save status'}
			</DialogTitle>
			<DialogContent>
				<textarea name="remarks" id="remarks-inventory-out" rows="5" style={{width: '500px',padding: '.5rem', outline: 'none',border: '1px solid #d1d1d1', borderRadius: '.5rem'}}></textarea>
			</DialogContent>
			<DialogActions>
				<Button color="error" onClick={() => setModalRemarks(false)} >
					Cancel
				</Button>
				<Button onClick={isModalForRemarks ? createRemarks : submitStatus}>
					Submit
				</Button>
			</DialogActions>
		</Dialog>
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" flexDirection={window.innerWidth < 768 ? "column" : "row"} gap="8px" justifyContent="space-between" mt={-0.5} mb={2.5} width="100%">
				<MDBox display="flex" alignItems="center">
					<button onClick={backClickHandler} style={{border: 'none', background: 'white', borderRadius: '5px', marginRight: '1rem',width: '2rem', height: '2rem',display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
						<Icon fontSize="medium">
							arrow_back
						</Icon>
					</button>
					<MDTypography variant="h3" fontWeight="regular">
					Detail Inventory Out
					</MDTypography>
				</MDBox>
			</MDBox>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="Data updated!"
				open={showNotifSuccess}
				dateTime="Just Now"
				close={() => setShowNotifSuccess(false)}
			/>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			<MDBox maxWidth="800px" width="100%" mb={4}>
				<Card sx={{ px: 3, py: 2 }}>
				<Grid container spacing={3}>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Document No</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{textDecoration: "underline"}} fontStyle="italic">
								{detailData?.doc_no}
							</MDTypography>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Requester</MDTypography>
							<TextField style={{width: '100%'}} value={detailData?.requester} disabled readOnly />
						</Grid>
						<Grid item xs={12} sm={6}>
							<MDTypography variant="h4" fontWeight="regular">Status</MDTypography>
							{detailData?.status !== '' && <MDInput 
								disabled
								size="small" 
								fullWidth 
								label="Status" 
								type="select" 
								select 
								value={status}
								onChange={(e) => setStatus(e.target.value)}
							>
								<MenuItem value="1">Open</MenuItem>
								<MenuItem value="0">Close</MenuItem>
							</MDInput>}
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Notes</MDTypography>
							<MDBox height="calc(100% - 24px)" display="flex" alignItems="center">
								<TextField style={{width: '100%'}} value={detailData?.note} disabled readOnly />
							</MDBox>
						</Grid>
					</Grid>
				</Card>
			</MDBox>
			<MDBox maxWidth="950px" width="100%">
				<Box sx={{ borderBottom: 1, borderColor: 'divider', width: 'fit-content !important' }}>
					<Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
						<Tab label="Detail Product" style={{padding: '.5rem 2rem'}} />
						<Tab label="Remarks" style={{padding: '.5rem 2rem'}}/>
					</Tabs>
				</Box>
				{tab === 0 && (
					<DataTable
						table={{ columns, rows : productRows }}
						isSorted={false}
						entriesPerPage={false}
						showTotalEntries={true}
						pagination={{variant: "contained", color: "primary"}}
						noEndBorder
						paginationDetail={productPaginationDetail}
						gotoPage={goToPageProduct}
					/>
				)}
				{tab === 1 && (
					<Box sx={{backgroundColor: 'white', padding: '.5rem', borderRadius: '.5rem'}}>
						<DataTable
							table={{ columns: columnsRemarks, rows : remarksRows }}
							isSorted={false}
							entriesPerPage={false}
							showTotalEntries={true}
							pagination={{variant: "contained", color: "primary"}}
							noEndBorder
							paginationDetail={remarksPaginationDetail}
							gotoPage={goToPageRemarks}
						/>
					</Box>
				)}
			</MDBox>
		</Grid>
	</>
  );
}

export default InventoryOutView;
