import { useEffect, useMemo, useRef, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import moment from "moment";

// @mui material components
import Grid from "@mui/material/Grid";
import { Card, Icon, TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDInput from "../../../../MDInput";
import MDButton from "../../../../MDButton";
import MDSnackbar from "../../../../MDSnackbar";
import MDTypography from "../../../../MDTypography";

import services from "../../../../../service/services";

import AddProductModal from "./addProductModal";
import DataTable from "../../../../Table";
import { addCommas } from "../../../../../utils";
import { append } from "domutils";

const BuyerView = ({
	setIsLoading,
	id,
	productOptions,
	setMode,
}) => {

	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifSuccessDraft, setShowNotifSuccessDraft] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [errorProduct, setErrorProduct] = useState("");
	const [productRows, setProductRows] = useState([]);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [productPaginationDetail, setProductPaginationDetail] = useState({
		current_page: 1,
		total_data: 0,
		total_page: 0,
		list: [],
	});
	const [detailData, setDetailData] = useState({});
	const [displayAddProduct, setDisplayAddProduct] = useState(false);

	const { register, trigger, control, reset, watch, formState: { errors } } = useForm({mode: "all"});

	const watchAllFields = watch();

	const { fields, update, remove, replace } = useFieldArray({
		control,
		shouldUnregister: false,
    name: "qty",
	});

	const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Product Name", accessor: "product_name", align: "left" },
		{ Header: "Code", accessor: "product_code", align: "left" },
		{ Header: "Price", accessor: "price", align: "left" },
		{ Header: "Qty", accessor: "qty", align: "left" },
	];

	const backClickHandler = () => {
		setMode("index");
	}

	useEffect(() => {
		setIsLoading(true);
		services.getDetailBuyer(id)
			.then(res => {
				setIsLoading(false);
				setDetailData(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				setShowNotifError(true)
				setErrorMessage(errors?.response?.data?.message);
			});
	}, []);

  return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" flexDirection={window.innerWidth < 768 ? "column" : "row"} gap="8px" justifyContent="space-between" mt={-0.5} mb={2.5} width="100%">
			<MDBox display="flex" alignItems="center">
					<button onClick={backClickHandler} style={{border: 'none', background: 'white', borderRadius: '5px', marginRight: '1rem',width: '2rem', height: '2rem',display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
						<Icon fontSize="medium">
							arrow_back
						</Icon>
					</button>
					<MDTypography variant="h3" fontWeight="regular">
						Buyer Detail
					</MDTypography>
				</MDBox>
			</MDBox>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			<MDBox maxWidth="800px" width="100%" mb={4}>
				<Card sx={{ px: 3, py: 2 }}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={12} md={12}>
							<MDTypography variant="h4" fontWeight="regular">Profile Image</MDTypography>
							<img className="img-thumbnail-pagination" src={detailData?.profile_image} alt={detailData?.full_name} />
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">User Name</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{textDecoration: "underline"}} fontStyle="italic">{detailData?.full_name}</MDTypography>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Email</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{textDecoration: "underline"}} fontStyle="italic">{detailData?.email}</MDTypography>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Phone Number</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{textDecoration: "underline"}} fontStyle="italic">{detailData?.phone_number}</MDTypography>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Date Of Birth</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{textDecoration: "underline"}} fontStyle="italic">{moment(detailData?.dob).format('DD-MM-yyyy')}</MDTypography>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Address</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{textDecoration: "underline"}} fontStyle="italic">{detailData?.address}</MDTypography>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Address</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{textDecoration: "underline"}} fontStyle="italic">{detailData?.address}</MDTypography>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Gender</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{textDecoration: "underline"}} fontStyle="italic">{detailData?.gender === 1 ? 'Male' : 'Female'}</MDTypography>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Is Verified Phone</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{textDecoration: "underline"}} fontStyle="italic">{detailData?.is_verified_phone ? 'Verified' : 'Not Verified'}</MDTypography>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Status</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{textDecoration: "underline"}} fontStyle="italic">{detailData?.status ? 'Active' : 'Non Active'}</MDTypography>
						</Grid>
					</Grid>
				</Card>
			</MDBox>
			{/* <MDBox maxWidth="950px" width="100%">
				<DataTable
					table={{ columns, rows : productRows }}
					isSorted={false}
					entriesPerPage={false}
					showTotalEntries={true}
					pagination={{variant: "contained", color: "primary"}}
					noEndBorder
					paginationDetail={productPaginationDetail}
					gotoPage={goToPageProduct}
				/>
				{errorProduct && 
						<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
							<MDTypography fontWeight="bold" sx={{ color: "red!important", fontSize: "10px"}}>{errorProduct}</MDTypography>
						</MDBox>
					}
			</MDBox>
			<AddProductModal open={displayAddProduct} setOpen={setDisplayAddProduct} setIsLoading={setIsLoading} productOptions={productOptions} setProductRows={setProductRows} productPaginationDetail={productPaginationDetail} setProductPaginationDetail={setProductPaginationDetail} /> */}
		</Grid>
  );
}

export default BuyerView;
