import { useEffect, useState } from "react";
import axios from "axios";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import MDSnackbar from "../../../../components/MDSnackbar";

import ItemPriceIndex from "../../../../components/AppComponents/Autoloka/product/itemPrice";
import ItemPriceAdd from "../../../../components/AppComponents/Autoloka/product/itemPrice/add";
import ItemPriceEdit from "../../../../components/AppComponents/Autoloka/product/itemPrice/edit";
import DeleteConfirmationModal from "../../../../components/modals/deleteConfirmation";

import services from "../../../../service/services";

import { addCommas, cleanObject } from "../../../../utils";
import { useSearchParams } from "react-router-dom";
import useFetchState from "../../../../hooks/useFetchState";

const ItemPrice = ({
	setIsLoading,
	setMode,
	mode,
 }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [rows, setRows] = useState([]);
	const [rowsDisplayed, setRowsDisplayed] = useState([]);
	const [rawRowsDisplayed, setRawRowsDisplayed] = useState([]);
	const [paginationDetail, setPaginationDetail] = useState({});
	const [brandOptions, setBrandOptions] = useState([]);
	const [modelOptions, setModelOptions] = useState([]);
	const [modelTypeOptions, setModelTypeOptions] = useState([]);
	const [productOptions, setProductOptions] = useState([]);
	const [idDetail, setIdDetail] = useState(null);
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [isFiltered, setIsFiltered] = useState(false);
	const [id, setId] = useState(null);
	const [confirmationDisplay, setConfirmationDisplay] = useState(false);
	const [dataFilter, setDataFilter] = useState({
		product_name: searchParams.get("product_name") || "",
		product_code: searchParams.get("product_code") || "",
		molding_code: searchParams.get("molding_code") || "",
		brand_name: searchParams.get("brand_name") || "",
		model_name: searchParams.get("model_name") || "",
		model_type_name: searchParams.get("model_type_name") || "",
	});
	const [warehouse, setWarehouse] = useState(searchParams.get("model_type_name") || 0);

  const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Edit", accessor: "edit", align: "left" },
		{ Header: "Price", accessor: "price", align: "left" },
		{ Header: "Product Name", accessor: "product_name", align: "left" },
		{ Header: "Product Code", accessor: "product_code", align: "left" },
		{ Header: "Brand", accessor: "brand", align: "left" },
		{ Header: "Model Name", accessor: "model_name", align: "left" },
		{ Header: "Model Year", accessor: "model_year", align: "left" },
		{ Header: "Molding Code", accessor: "molding_code", align: "left" },
		{ Header: "Model Type", accessor: "model_type", align: "left" },
	];

	const getWarehouse = useFetchState();

    // getwarehouse fetcher
    useEffect(()=> {
        getWarehouse.fetchStart();
        services.getWarehouse().then((res) => {
			getWarehouse.fetchSuccess(res.data?.data || null);
			setWarehouse(res.data.data[0].warehouse_id);
        }).catch((error) => {
			getWarehouse.fetchFailed(error?.message || "Ooops something went wrong");
        });
    },[]);

	useEffect(() => {

		let productsFetcher;
		if(Object.keys(cleanObject(dataFilter)).length !== 0) {
			productsFetcher = services.filterPrices({...{...dataFilter, warehouse: (searchParams.get("page") || 1, warehouse)}, page: searchParams.get("page") || 1})
		}else {
			productsFetcher = services.getProductPrices(searchParams.get("page") || 1, warehouse)
		}

		setPaginationDetail({});
		setRows([]);
		setRawRowsDisplayed({});
		setRowsDisplayed([]);

		if(mode === "index"){
			setIsLoading(true);
			Promise
				.all([
					productsFetcher,
					services.getOptionsBrands(),
					services.getOptionsModels(),
					services.getOptionsModelTypes(),
					services.getOptionsProductNameCode(),
				]).then(axios.spread((...res) => {
					setIsLoading(false);
					setPaginationDetail(res[0]?.data?.data);
					setRows(settingRowsDataTable(res[0]?.data?.data?.list, res[0]?.data?.data?.current_page));
					setRawRowsDisplayed(res[0]?.data?.data?.list);
					setBrandOptions(res[1]?.data?.data);
					setModelOptions(res[2]?.data?.data);
					setModelTypeOptions(res[3]?.data?.data);
					setProductOptions(res[4]?.data?.data);
				})).catch(errors => {
					setIsLoading(false);
					console.log("Error is found, apologies.")
					console.log(errors)
				})
		}
	}, [mode, warehouse]);

	const deletePrice = (id) => {
		setIsLoading(true);
		setShowNotifSuccess(false);
		services.deleteProductPrice(id)
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
				setConfirmationDisplay(false);
				getList(1);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors);
			})
	}

	const getList = (page) => {
		setIsLoading(true)
		services.getProductPrices(page, warehouse)
			.then(res => {
				setIsLoading(false);
				setIsFiltered(false);
				setRows(settingRowsDataTable(res?.data?.data?.list, res?.data?.data?.current_page));
				setRawRowsDisplayed(res?.data?.data?.list);
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	};

	useEffect(() => {
		if(warehouse) {
			searchParams.set("warehouse", warehouse);
			setSearchParams(searchParams);
			getList(1);
		}
	},[warehouse]);

	const settingRowsDataTable = (rowsArray, current_page) => {
		return rowsArray?.map((arr, i) => {
			return {
				no: ((current_page - 1) * 10) + i + 1,
				edit: (
					<>
						<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setMode("edit"); setIdDetail(arr.id)}}>edit</Icon>
						<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setId(arr.id); setConfirmationDisplay(true);}}>delete</Icon>
					</>
				),
				price: "Rp "+addCommas(arr?.price),
				product_name: arr?.product_name,
				product_code: arr?.product_code,
				brand: arr?.brand,
				model_name: arr?.model_name,
				model_year: arr?.model_year,
				molding_code: arr?.molding_code,
				model_type: arr?.model_type_name,
			};
		});
	};

  return (
    <>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="Price Deleted!"
				dateTime="Just Now"
				open={showNotifSuccess}
				close={() => setShowNotifSuccess(false)}
			/>
			<Grid container spacing={6} className="grid-pure-container">
				{
					mode === "index" ?
						<ItemPriceIndex 
							setWarehouse={setWarehouse}
							warehouse={warehouse}
							listWarehouse={getWarehouse.data || []}
							setDataFilter={setDataFilter}
							dataFilter={dataFilter}
							columns={columns} 
							rows={rows}
							rowsDisplayed={rowsDisplayed}
							setRowsDisplayed={setRowsDisplayed} 
							setRawRowsDisplayed={setRawRowsDisplayed}
							setIsLoading={setIsLoading}
							brandOptions={brandOptions}
							modelOptions={modelOptions}
							modelTypeOptions={modelTypeOptions}
							setMode={setMode}
							paginationDetail={paginationDetail}
							setPaginationDetail={setPaginationDetail}
							getList={getList}
							isFiltered={isFiltered}
							setIsFiltered={setIsFiltered}
							settingRowsDataTable={settingRowsDataTable}
						/>
					: mode === "add" ?
						<ItemPriceAdd 
						setIsLoading={setIsLoading} 
						productOptions={productOptions}
						listWarehouse={getWarehouse.data || []}
						/>
						: mode === "edit" ?
						<ItemPriceEdit 
							setMode={setMode}
							setIsLoading={setIsLoading}
							productOptions={productOptions}
							listWarehouse={getWarehouse.data || []}
							id={idDetail}
						/> 
					: null
				}
			</Grid>
			<DeleteConfirmationModal open={confirmationDisplay} setOpen={setConfirmationDisplay} itemName="price" deleteHandler={() => deletePrice(id)} /> 
    </>
  );
}

export default ItemPrice;
