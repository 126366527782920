import React from 'react';

import Box from '@mui/material/Box';
import Icon from "@mui/material/Icon";
import Modal from '@mui/material/Modal';

import MDTypography from '../../../../MDTypography';
import MDInput from '../../../../MDInput';
import MDButton from '../../../../MDButton';
import MDBox from '../../../../MDBox';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  width: '350px',
	maxWidth: '90vw',
  p: 3,
	borderRadius: "12px",
};

const StatusNoteModal = ({ 
	open, 
	setOpen,
	detail,
	setDetail,
	handleSubmit,
}) => {
	
  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
				keepMounted
      >
        <Box className="grid-modal" sx={style}>
					<MDBox position="relative">
						<Icon style={{ position: "absolute", right: "-12px", top: "-12px" }} className="c-pointer" onClick={() => setOpen(false)} fontSize="small">close</Icon>
					</MDBox>
          <MDTypography variant="h4">
						{detail.type} Note
					</MDTypography>
					<MDBox mt={2} mb={3}>
						<MDInput type="number" multiline rows={5} label="Insert Note here" onChange={(e) => setDetail({...detail, note: e.target.value})} fullWidth />
					</MDBox>
					<MDBox display="flex" alignItems="center" justifyContent="end">
						<MDButton onClick={handleSubmit} variant="gradient" color="info">Submit</MDButton>
					</MDBox>
        </Box>
      </Modal>
    </div>
  );
}

export default StatusNoteModal;