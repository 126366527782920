import { useEffect, useMemo, useRef, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import { Autocomplete, Card, Icon, TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDInput from "../../../../MDInput";
import MDButton from "../../../../MDButton";
import MDSnackbar from "../../../../MDSnackbar";
import MDTypography from "../../../../MDTypography";

import services from "../../../../../service/services";

import DataTable from "../../../../Table";
import AddPO from "./addPO";
import { addCommas } from "../../../../../utils";

const SalesOrderEdit = ({
	setIsLoading,
	requestFromOptions,
	id,
	setMode,
}) => {

	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifSuccessDraft, setShowNotifSuccessDraft] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [errorProduct, setErrorProduct] = useState("");
	const [productRows, setProductRows] = useState([]);
	const [productPaginationDetail, setProductPaginationDetail] = useState({
		current_page: 1,
		total_data: 0,
		total_page: 0,
		list: [],
	});
	const [requestFromID, setRequestFromID] = useState(null);
	const [displayAddPO, setDisplayAddPO] = useState(false);
	const [detailData, setDetailData] = useState({});
	const [isSubmitted, setIsSubmitted] = useState(false);

	const { register, trigger, control, reset, watch, formState: { errors } } = useForm({mode: "all"});

	const watchAllFields = watch();

	const { replace } = useFieldArray({
		control,
		shouldUnregister: false,
    name: "qty",
	});

	const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Product Name", accessor: "product_name", align: "left" },
		{ Header: "Code", accessor: "product_code", align: "left" },
		{ Header: "Price", accessor: "price", align: "left" },
		{ Header: "Qty Request", accessor: "qty_request", align: "left" },
		{ Header: "Qty Approved", accessor: "qty_approved", align: "left" },
		{ Header: "Delete", accessor: "delete", align: "left" },
	];

	useEffect(() => {
		setIsLoading(true);
		services.getSalesOrderDetail(id)
			.then(res => {
				setIsLoading(false);
				setDetailData(res?.data?.data);
				let listAll = res?.data?.data.list_detail;
				let total_page = parseInt(listAll.length/10);
				if(listAll.length%10 !== 0){
					total_page = total_page + 1;
				};
				setRequestFromID(res?.data?.data?.request_from_id);
				setProductPaginationDetail({
					current_page: 1,
					total_page: total_page,
					total_data: listAll.length,
					list: listAll,
				});
				replace(listAll.map( list => list.qty));
			}).catch(errors => {
				setIsLoading(false);
				setShowNotifError(true)
				setErrorMessage(errors?.response?.data?.message);
			});
	}, [id]);

	useEffect(async() => {
		const result = await trigger("qty");
		let listAll = settingRows();
		listAll = listAll?.filter((arr, i) => (((productPaginationDetail.current_page - 1) * 10) <= i && (productPaginationDetail.current_page * 10) > i));
		setProductRows(listAll);
	}, [watchAllFields.qty]);

	const settingRows = () => {
		let listAll = productPaginationDetail.list.map( (arr, j) => {
			return {
				no: j + 1,
				po_id: arr?.po_id,
				product_id: arr?.product_id,
				product_name: arr?.product_name,
				product_code: arr?.product_code,
				price: "Rp"+addCommas(arr?.price),
				qty_request: <>
					<TextField className="without-label-input" style={{ width: "48px" }} type="number" disabled value={arr?.qty_request} />
				</>,
				qty_approved: <>
					<TextField className="without-label-input" style={{ width: "48px" }} type="number" disabled={isSubmitted} inputProps={{ inputMode: 'numeric', pattern: '[1-9]*' }} {...register(`qty.${j}`, { required: 'Required' })} value={watchAllFields.qty[j] || ""} 
						onChange={(e) => {
							let newVal = e.target.value;
							let allValues = [...watchAllFields.qty];
							allValues[j] = newVal;
							replace(allValues);
						}}
						InputLabelProps={{ shrink: false }} />
					{errors?.qty?.[j] && 
						<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
							<MDTypography fontWeight="bold" sx={{ color: "red!important", fontSize: "10px"}}>{errors.qty[j].message}</MDTypography>
						</MDBox>
					}
				</>,
				delete: 
					<>
						<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => deleteProductRow(arr?.product?.product_id, j)}>delete</Icon>
					</>,
			}}
		);
		let total_page = parseInt(listAll.length/10);
		if(listAll.length%10 !== 0){
			total_page = total_page + 1;
		};
		setProductPaginationDetail({...productPaginationDetail, total_page: total_page});
		return listAll;
	};

	useMemo(() => {
		if(isSubmitted){
			let listAll = settingRows();
			listAll = listAll?.filter((arr, i) => (((productPaginationDetail.current_page - 1) * 10) <= i && (productPaginationDetail.current_page * 10) > i));
			setProductRows(listAll);
		}
	}, [isSubmitted]);
	
	useMemo(() => {
		let listAll = settingRows();
		listAll = listAll?.filter((arr, i) => (((productPaginationDetail.current_page - 1) * 10) <= i && (productPaginationDetail.current_page * 10) > i));
		setProductRows(listAll);
	}, [productPaginationDetail.list, productPaginationDetail.current_page]);

	const deleteProductRow = (id, index) => {
		let allValues = [...watchAllFields.qty];
		allValues.splice(index, 1);
		replace(allValues);
		let array = [...productPaginationDetail.list];
		array.splice(index, 1);
		let current_page = productPaginationDetail.current_page;
		if(array.length%10 === 0){
			current_page = current_page - 1;
		}
		setProductPaginationDetail({...productPaginationDetail, list: [...array], current_page: current_page, total_data: array.length});
	};
	const goToPageProduct = (page) => {
		setProductPaginationDetail({...productPaginationDetail, current_page: page});
	};

	const submitPO = async (type) => {
		if(productPaginationDetail.list.length > 0 ){
			setErrorProduct("");
			const result = await trigger("qty");
			let current_page = productPaginationDetail.current_page;
			setProductPaginationDetail({...productPaginationDetail, current_page: 0});
			setTimeout(() => setProductPaginationDetail({...productPaginationDetail, current_page: current_page}), 50);
			if(result){
				let isDelete = detailData.list_detail
					.filter(x => !productPaginationDetail.list.some(y => y.order_detail_id === x.order_detail_id))
					.map( newList => {
						return {
							order_detail_id: newList.order_detail_id,
							is_active: 0,
						}
					} 
				);
				let isNew = productPaginationDetail.list.map((list, i) => {
					return {
						order_detail_id: list.order_detail_id,
						po_id: list.po_id,
						product_id: list.product_id,
						qty: parseInt(watchAllFields.qty[i]),
						price: list?.price,
					}
				});
				let data = {
					id: id,
					account_id: requestFromID,
					child_document: [...new Set([...productPaginationDetail.list.map(product => product.po_id)])] || null,
					detail_product: [...isNew, ...isDelete],
				}
				if(type === "draft"){
					setIsLoading(true);
					services.editDraftSalesOrder(data)
						.then(res => {
							setIsLoading(false);
							setShowNotifSuccessDraft(true);
							setTimeout(() => {
								setMode("index");
							}, 500);
						}).catch(errors => {
							setIsLoading(false);
							setShowNotifError(true);
							setErrorMessage(errors?.response?.data?.message);
						});
				} else {
					setIsLoading(true);
					services.editSalesOrder(data)
						.then(res => {
							setIsLoading(false);
							setShowNotifSuccess(true);
							setIsSubmitted(true);
							setTimeout(() => {
								setMode("index");
							}, 500);
						}).catch(errors => {
							setIsLoading(false);
							setShowNotifError(true)
							setErrorMessage(errors?.response?.data?.message);
						});
				}
			}
		} else {
			setErrorProduct("Please add atleast 1 Product");
		}
	}
	
	const backClickHandler = () => {
		setMode("index");
	}

  return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" flexDirection={window.innerWidth < 768 ? "column" : "row"} gap="8px" justifyContent="space-between" mt={-0.5} mb={2.5} width="100%">
				<MDBox display="flex" alignItems="center">
					<button onClick={backClickHandler} style={{border: 'none', background: 'white', borderRadius: '5px', marginRight: '1rem',width: '2rem', height: '2rem',display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
						<Icon fontSize="medium">
							arrow_back
						</Icon>
					</button>
					<MDTypography variant="h3" fontWeight="regular">
					Edit Sales Order Draft
					</MDTypography>
				</MDBox>
				{
					!isSubmitted ?
						<MDBox display="flex" maxWidth="90vw" gap="16px">
							<MDButton color="secondary" onClick={() => submitPO("draft")}>
								Draft
							</MDButton>
							<MDButton color="primary" onClick={submitPO}>
							Save And Send
							</MDButton>
						</MDBox>
					: null
				}
			</MDBox>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="New Sales Order Draft Added!"
				open={showNotifSuccessDraft}
				dateTime="Just Now"
				close={() => setShowNotifSuccessDraft(false)}
			/>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="New Sales Order Added!"
				open={showNotifSuccess}
				dateTime="Just Now"
				close={() => setShowNotifSuccess(false)}
			/>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			<MDBox maxWidth="800px" width="100%" mb={4}>
				<Card sx={{ px: 3, py: 2 }}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={12}>
							<MDTypography variant="h4" fontWeight="regular">Sales Order No</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{textDecoration: "underline"}} fontStyle="italic">{detailData.so_number}</MDTypography>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">SO Request From</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{textDecoration: "underline"}} fontStyle="italic">{detailData.request_from}</MDTypography>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<MDTypography variant="h4" fontWeight="regular">Request To</MDTypography>
							<MDTypography variant="h5" fontWeight="regular" sx={{textDecoration: "underline"}} fontStyle="italic">{detailData.request_to}</MDTypography>
						</Grid>
					</Grid>
				</Card>
			</MDBox>
			<MDBox maxWidth="950px" width="100%">
				{
					!isSubmitted ?
						<MDBox width="100%" display="flex" justifyContent="flex-end" my={2}>
							<MDButton disabled={!requestFromID} color="info" onClick={() => setDisplayAddPO(true)}>
								DATA PO
							</MDButton>
						</MDBox>
					: null
				}
				<DataTable
					table={{ columns, rows : productRows }}
					isSorted={false}
					entriesPerPage={false}
					showTotalEntries={true}
					pagination={{variant: "contained", color: "primary"}}
					noEndBorder
					paginationDetail={productPaginationDetail}
					gotoPage={goToPageProduct}
				/>
				{errorProduct && 
						<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
							<MDTypography fontWeight="bold" sx={{ color: "red!important", fontSize: "10px"}}>{errorProduct}</MDTypography>
						</MDBox>
					}
			</MDBox>
			<AddPO open={displayAddPO} setOpen={setDisplayAddPO} setIsLoading={setIsLoading} id={requestFromID} setProductRows={setProductRows} productPaginationDetail={productPaginationDetail} setProductPaginationDetail={setProductPaginationDetail} setErrorMessage={setErrorMessage} setShowNotifError={setShowNotifError} replaceProductQty={replace} />
		</Grid>
  );
}

export default SalesOrderEdit;
