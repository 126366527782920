import { useEffect, useState } from "react";
import axios from "axios";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

const ReportSalesProduct = ({
	isLoading,
	setIsLoading,
	setMode,
	mode,
 }) => {

  return (
<p>Sales Product</p>
  );
}

export default ReportSalesProduct;
