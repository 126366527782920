import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Icon, MenuItem } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDButton from "../../../../MDButton";
import MDTypography from "../../../../MDTypography";

import placeholder from "../../../../../assets/images/placeholder-image.jpeg";
import MDInput from "../../../../MDInput";
import MDSnackbar from "../../../../MDSnackbar";

import services from "../../../../../service/services";

import { getImageError, toBase64 } from "../../../../../utils";

const BrandEdit = ({
	brandTypeOptions,
	setIsLoading,
	rawRowsDisplayed,
	indexDetail,
	setMode,
}) => {
	const header_imageRef = useRef();
	const brand_imageRef = useRef();
	const car_imageRef = useRef();
	
	const [detailData, setDetailData] = useState({});
	const [images, setImages] = useState({
		header_image: "",
		brand_image: "",
		car_image: "",
	});
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isTransparent, setIsTransparent] = useState({
		header_image: false,
		brand_image: false,
		car_image: false,
	});
	const [isRemoved, setIsRemoved] = useState({
		header_image: false,
		brand_image: false,
		car_image: false,
	});

	const { register, handleSubmit, setValue, trigger, reset, formState: { errors } } = useForm({mode: "all"});

	const onImageChange = async (e, key) => {
		setIsRemoved({...isRemoved, [key]: false});
		let format = e.target.files[0].name.split('.').pop();
		setIsTransparent({...isTransparent, [key]: format.includes("png")});
		let val = await toBase64(e.target.files[0]);
		setValue(key, val);
		const result = await trigger(key);
		setImages({...images, [key]: URL.createObjectURL(e.target.files[0])});
	}

	const removeImage = async (key) => {
		setIsRemoved({...isRemoved, [key]: true});
		setIsTransparent({...isTransparent, [key]: false});
		setValue(key, null);
		const result = await trigger(key);
		setImages({...images, [key]: ""});
		setDetailData({...detailData, [key]: ""});
	}
	
	const submitEdit = (e) => {
		setIsLoading(true);
		delete e.status;
		e.id = detailData.id;
		services.editBrand(e)
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
			}).catch(errors => {
				setIsLoading(false);
				setErrorMessage(errors?.response?.data?.message);
				setShowNotifError(true);
				console.log(errors)
			})
	}

	useEffect(() => {
		if(rawRowsDisplayed.length > 0){
			setValue("header_image", "default")
			setValue("brand_image", "default")
			setValue("car_image", "default")
			setDetailData(rawRowsDisplayed[indexDetail]);
		}
	}, [rawRowsDisplayed])

	const backClickHandler = () => {
		setMode("index");
	}

  return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" justifyContent="space-between" mt={-0.5} mb={4} width="100%">
			<MDBox display="flex" alignItems="center">
					<button onClick={backClickHandler} style={{border: 'none', background: 'white', borderRadius: '5px', marginRight: '1rem',width: '2rem', height: '2rem',display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
						<Icon fontSize="medium">
							arrow_back
						</Icon>
					</button>
					<MDTypography variant="h3" fontWeight="regular">
						Edit Brand
					</MDTypography>
				</MDBox>
				<MDButton color="info" type="submit" form="create-brand">
					Update
				</MDButton>
			</MDBox>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="Brand Updated!"
				dateTime="Just Now"
				open={showNotifSuccess}
				close={() => setShowNotifSuccess(false)}
			/>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			{
				rawRowsDisplayed.length > 0 && Object.keys(detailData)?.length > 0 ?
					<MDBox component="form" role="form" id="create-brand" onSubmit={handleSubmit(submitEdit)}>
						<Grid container spacing={3}>
							<Grid item xs={12} lg={3}>
								<Card
									sx={{
										"&:hover .card-header": {
											transform: "translate3d(0, -50px, 0)",
										},
										maxWidth: "500px",
										margin: "0 auto",
										border: errors?.header_image ? "2px solid red" : "inherit"
									}}
								>
									<MDBox
										position="relative"
										borderRadius="lg"
										mt={-3}
										mx={1}
										mb={-1}
										className="card-header"
										sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)", zIndex: 999, backgroundColor: "transparent" }}
									>
										<MDBox
											sx={{ backgroundColor: detailData?.header_image?.includes(".png") || isTransparent.header_image ? "#FFF" : "", borderRadius: "8px" }}
										>
											<MDBox
												component="img"
												src={images?.header_image ? images.header_image : isRemoved.header_image || !images.header_image ? placeholder : detailData?.header_image}
												alt="Header Image"
												borderRadius="lg"
												shadow="sm"
												width="100%"
												height="100%"
												position="relative"
												zIndex={10}
												onError={getImageError}
											/>
										</MDBox>
									</MDBox>
									<MDBox textAlign="center" pt={4} pb={1} px={3}>
										<MDBox
											display="flex"
											justifyContent="center"
											alignItems="center"
											mt={-9}
											mb={-1}
											position="relative"
											zIndex={1}
										>
											<MDBox mr={1}>
												<input
													{...register("header_image", { required: "Required" })}
													type="file"
													accept="image/png;image/jpg;image/jpeg;"
													ref={header_imageRef}
													onChange={(e) => onImageChange(e, "header_image")}
													className="d-none"
												/>
												<MDButton variant="gradient" color="info" size="small" onClick={() => header_imageRef.current.click()}>
													edit
												</MDButton>
											</MDBox>
											<MDButton variant="outlined" color="dark" size="small" onClick={() => removeImage("header_image")}>
												remove
											</MDButton>
										</MDBox>
										<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 3 }}>
											Header Image
										</MDTypography>
										{
											errors?.header_image &&
												<MDTypography fontWeight="bold" sx={{ fontSize: "10px", color: "red" }}>
													{errors.header_image.message}
												</MDTypography>
										}
									</MDBox>
								</Card>
								<Card
									sx={{
										"&:hover .card-header": {
											transform: "translate3d(0, -50px, 0)",
										},
										maxWidth: "500px",
										margin: "0 auto",
										marginTop: "48px",
										border: errors?.car_image ? "2px solid red" : "inherit"
									}}
								>
									<MDBox
										position="relative"
										borderRadius="lg"
										mt={-3}
										mx={1}
										mb={-1}
										className="card-header"
										sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)", zIndex: 999, backgroundColor: "transparent" }}
									>
										<MDBox
											sx={{ backgroundColor: detailData?.car_image?.includes(".png") || isTransparent.car_image ? "#FFF" : "", borderRadius: "8px" }}
										>
											<MDBox
												component="img"
												src={images?.car_image ? images.car_image : isRemoved.car_image || !detailData.car_image ? placeholder : detailData?.car_image}
												alt="Car Image"
												borderRadius="lg"
												shadow="sm"
												width="100%"
												height="100%"
												position="relative"
												zIndex={10}
												onError={getImageError}
											/>
										</MDBox>
									</MDBox>
									<MDBox textAlign="center" pt={4} pb={1} px={3}>
										<MDBox
											display="flex"
											justifyContent="center"
											alignItems="center"
											mt={-9}
											mb={-1}
											position="relative"
											zIndex={0}
										>
											<MDBox mr={1}>
												<input
													{...register("car_image", { required: "Required" })}
													type="file"
													accept="image/png;image/jpg;image/jpeg;"
													ref={car_imageRef}
													onChange={(e) => onImageChange(e, "car_image")}
													className="d-none"
												/>
												<MDButton variant="gradient" color="info" size="small" onClick={() => car_imageRef.current.click()}>
													edit
												</MDButton>
											</MDBox>
											<MDButton variant="outlined" color="dark" size="small" onClick={() => removeImage("car_image")}>
												remove
											</MDButton>
										</MDBox>
										<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 3 }}>
											Car Image
										</MDTypography>
										{
											errors?.car_image &&
												<MDTypography fontWeight="bold" sx={{ fontSize: "10px", color: "red" }}>
													{errors.car_image.message}
												</MDTypography>
										}
									</MDBox>
								</Card>
							</Grid>
							<Grid item xs={12} lg={3}>
								<Card
									sx={{
										"&:hover .card-header": {
											transform: "translate3d(0, -50px, 0)",
										},
										maxWidth: "500px",
										margin: "0 auto",
										border: errors?.brand_image ? "2px solid red" : "inherit"
									}}
								>
									<MDBox
										position="relative"
										borderRadius="lg"
										mt={-3}
										mx={1}
										mb={-1}
										className="card-header"
										sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)", zIndex: 999, backgroundColor: "transparent" }}
									>
										<MDBox
											sx={{ backgroundColor: detailData?.brand_image?.includes(".png") || isTransparent.brand_image ? "#FFF" : "", borderRadius: "8px" }}
										>
											<MDBox
												component="img"
												src={images?.brand_image ? images.brand_image : isRemoved.brand_image || !detailData.brand_image ? placeholder : detailData?.brand_image}
												alt="Brand Image"
												borderRadius="lg"
												shadow="sm"
												width="100%"
												height="100%"
												position="relative"
												zIndex={10}
												onError={getImageError}
											/>
										</MDBox>
									</MDBox>
									<MDBox textAlign="center" pt={4} pb={1} px={3}>
										<MDBox
											display="flex"
											justifyContent="center"
											alignItems="center"
											mt={-9}
											mb={-1}
											position="relative"
											zIndex={1}
										>
											<MDBox mr={1}>
												<input
													{...register("brand_image", { required: "Required" })}
													type="file"
													accept="image/png;image/jpg;image/jpeg;"
													ref={brand_imageRef}
													onChange={(e) => onImageChange(e, "brand_image")}
													className="d-none"
												/>
												<MDButton variant="gradient" color="info" size="small" onClick={() => brand_imageRef.current.click()}>
													edit
												</MDButton>
											</MDBox>
											<MDButton variant="outlined" color="dark" size="small" onClick={() => removeImage("brand_image")}>
												remove
											</MDButton>
										</MDBox>
										<MDTypography variant="h5" fontWeight="regular" sx={{ mt: 3 }}>
											Brand Image
										</MDTypography>
										{
											errors?.brand_image &&
												<MDTypography fontWeight="bold" sx={{ fontSize: "10px", color: "red" }}>
													{errors.brand_image.message}
												</MDTypography>
										}
									</MDBox>
								</Card>
							</Grid>
							<Grid xs={12} lg={6} item>
								<Card sx={{ padding: "24px 32px" }}>
									<MDTypography variant="h4" fontWeight="regular" sx={{ ml: -1, mb: 4}}>
										Brand Information
									</MDTypography>
										<MDBox mb={2}>
											<MDInput error={errors?.brand_name} defaultValue={detailData?.brand_name} {...register("brand_name", { required: "Required" })} type="text" label="Brand Name" fullWidth />
											{errors?.brand_name && 
												<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
													<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.brand_name.message}</MDTypography>
												</MDBox>
											}
										</MDBox>
										<MDBox mb={2}>
											<MDInput error={errors?.brand_code} defaultValue={detailData?.brand_code} {...register("brand_code", { required: "Required", maxLength: {value: 2, message: "Maximum character length is 3"} })} type="text" label="Brand Code" fullWidth />
											{errors?.brand_code && 
												<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
													<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.brand_code.message}</MDTypography>
												</MDBox>
											}
										</MDBox>
										<MDBox mb={2}>
											<MDInput
												id="select-brand-type" 
												size="small" 
												fullWidth 
												label="Brand Type"
												type="select" 
												select
												error={errors?.brand_type} 
												{...register("brand_type", { required: "Required" })}
												defaultValue={detailData?.brand_type}
											>
												<MenuItem disabled value="">
													<em>Neither</em>
												</MenuItem>
												{
													brandTypeOptions.map((bt, i) => {
														return <MenuItem key={"option-autoloka-brand-name-"+i} value={bt.brand_type}>{bt.type_name}</MenuItem>
													})
												}
											</MDInput>
											{errors?.brand_type && 
												<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
													<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.brand_type.message}</MDTypography>
												</MDBox>
											}						
										</MDBox>
										<MDBox mb={2}>
											<MDInput
												id="select-popular" 
												size="small" 
												fullWidth 
												label="Popular"
												type="select" 
												select
												error={errors?.is_popular} 
												{...register("is_popular", { required: "Required" })}
												defaultValue={detailData?.is_popular}
											>
												<MenuItem disabled value="">Neither</MenuItem>
												<MenuItem value="1">Yes</MenuItem>
												<MenuItem value="0">No</MenuItem>
											</MDInput>
											{errors?.is_popular && 
												<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
													<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.is_popular.message}</MDTypography>
												</MDBox>
											}
										</MDBox>
										<MDBox mb={2}>
											<MDInput
												id="select-is-active" 
												size="small" 
												fullWidth 
												label="Active"
												type="select" 
												select
												error={errors?.is_active} 
												{...register("is_active", { required: "Required" })}
												defaultValue={detailData?.status}
											>
												<MenuItem disabled value="">
													<em>Neither</em>
												</MenuItem>
												<MenuItem value="1">Active</MenuItem>
												<MenuItem value="0">Not Active</MenuItem>
											</MDInput>
											{errors?.is_active && 
												<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
													<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px"}}>{errors.is_active.message}</MDTypography>
												</MDBox>
											}
										</MDBox>
								</Card>
							</Grid>
						</Grid>
					</MDBox>
				: null
			}
		</Grid>
  );
}

export default BrandEdit;
