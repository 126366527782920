import { Autocomplete, Box, createFilterOptions, Icon, Modal } from "@mui/material";
import MDBox from "../../MDBox";
import MDTypography from "../../MDTypography";
import MDInput from "../../MDInput";
import MDButton from "../../MDButton";
import { useEffect } from "react";
import useFetchState from "../../../hooks/useFetchState";
import services from "../../../service/services";
import { Controller } from "react-hook-form";
import { useRef } from "react";
import { useState } from "react";

const Filter = ({
    setStep,
    setType: setTypeGlobal,
}) => {

    const [brand, setBrand] = useState(null);
    const [model, setModel] = useState(null);
    const [type, setType] = useState(null);
    const inputProductRef = useRef(null);

    const getBrand = useFetchState();
    const getModelByBrand = useFetchState();
    const getModelType = useFetchState();

    useEffect(() => {
        getBrand.fetchStart();
        services.getBrand().then((res) => {
            getBrand.fetchSuccess(res.data?.data || []);
        }).catch((err) => {
            getBrand.fetchFailed(err?.message || 'Ooops something went wrong!');
        });
    },[]);

    useEffect(() => {
        if(!brand) return;
        getModelByBrand.fetchStart();
        services.getModelByBrand(brand.brand_id).then((res) => {
            getModelByBrand.fetchSuccess(res.data?.data || []);
        }).catch((err) => {
            getModelByBrand.fetchFailed(err?.message || 'Ooops something went wrong!');
        });
    },[brand]);

    useEffect(() => {
        if(!model) return;
        getModelType.fetchStart();
        services.getModelType(model.model_id).then((res) => {
            getModelType.fetchSuccess(res.data?.data || []);
        }).catch((err) => {
            getModelType.fetchFailed(err?.message || 'Ooops something went wrong!');
        });
    },[model]);

    const handleKeyDown = event => {
        if(event.key === "Enter"){
                event.preventDefault();
                event.stopPropagation();
        }
      };

    return (
        <>
            <MDBox width="100%" mb={2}>
                <MDTypography variant="h4" sx={{ mb: 2 }}>
                        Add Product
                </MDTypography>
                <Autocomplete
                    value={brand}
                    onChange={(_, value) => {
                        setModel(null);
                        setType(null);
                        setBrand(value);
                    }}
                    loading={getBrand.loading}
                    className='autocomplete-product'
                    options={getBrand.data || []}
                    getOptionLabel={option => option?.brand_name}
                    size="small"
                    renderInput={(params) => {
                        params.inputProps.onKeyDown = handleKeyDown;
                        return <MDInput fullWidth {...params} autoFocus={true} inputRef={inputProductRef} label="Brand" size="small" /> 
                    }}
                    renderOption={(props, option, { selected }) => (
                        <li {...props} style={{ marginRight: "8px" }}>
                            <MDBox width="100%" display="flex" flexDirection="column" justifyContent="space-evenly" mt={1}>
                                <MDTypography fontSize="12px" sx={{ mb: "-0.4px" }}>{option?.brand_name}</MDTypography>
                                <MDBox width="100%" sx={{ borderBottom: "1px dotted #344767!important" }} mb={"-6px"}></MDBox>
                            </MDBox>
                        </li>
                    )}
                    />
                </MDBox>
                {brand && <MDBox width="100%" mb={2}>
                    <Autocomplete
                        value={model}
                        onChange={(_, value) => {
                            setType(null);
                            setModel(value)
                        }}
                        loading={getModelByBrand.loading}
                        className='autocomplete-product'
                        options={getModelByBrand.data?.model_list || []}
                        getOptionLabel={option => option?.model_name}
                        size="small"
                        renderInput={(params) => {
                            params.inputProps.onKeyDown = handleKeyDown;
                            return <MDInput fullWidth {...params} autoFocus={true} inputRef={inputProductRef} label="Model By Brand" size="small" /> 
                        }}
                        renderOption={(props, option, { selected }) => (
                            <li {...props} style={{ marginRight: "8px" }}>
                                <MDBox width="100%" display="flex" flexDirection="column" justifyContent="space-evenly" mt={1}>
                                    <MDTypography fontSize="12px" sx={{ mb: "-0.4px" }}>{option?.model_name}</MDTypography>
                                    <MDBox width="100%" sx={{ borderBottom: "1px dotted #344767!important" }} mb={"-6px"}></MDBox>
                                </MDBox>
                            </li>
                        )}
                    />
                </MDBox>}
                {model && <MDBox width="100%" mb={2}>
                    <Autocomplete
                        value={type}
                        onChange={(_, value) => {
                            setTypeGlobal(value);
                            setType(value)
                        }}
                        loading={getModelType.loading}
                        className='autocomplete-product'
                        options={getModelType.data || []}
                        getOptionLabel={option => option?.name}
                        size="small"
                        renderInput={(params) => {
                            params.inputProps.onKeyDown = handleKeyDown;
                            return <MDInput fullWidth {...params} autoFocus={true} inputRef={inputProductRef} label="Model By Type" size="small" /> 
                        }}
                        renderOption={(props, option, { selected }) => (
                            <li {...props} style={{ marginRight: "8px" }}>
                                <MDBox width="100%" display="flex" flexDirection="column" justifyContent="space-evenly" mt={1}>
                                    <MDTypography fontSize="12px" sx={{ mb: "-0.4px" }}>{option?.name}</MDTypography>
                                    <MDBox width="100%" sx={{ borderBottom: "1px dotted #344767!important" }} mb={"-6px"}></MDBox>
                                </MDBox>
                            </li>
                        )}
                    />
                </MDBox>}
                <MDBox width="100%" display="flex" justifyContent="flex-end">
                <MDButton onClick={() => setStep((prev) => prev + 1)} disabled={!type} color="info" type="button" form="create-add-product">
                    NEXT
                </MDButton>
            </MDBox>
        </>
    )
}

export default Filter;