import { useEffect, useState } from "react";
import axios from "axios";
 
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import DeliveryOrderIndex from "../../../../components/AppComponents/Autoloka/document/DeliveryOrder";
import DeliverOrderAdd from "../../../../components/AppComponents/Autoloka/document/DeliveryOrder/add";
import DeliveryOrderEdit from "../../../../components/AppComponents/Autoloka/document/DeliveryOrder/edit";
import DeliveryOrderView from "../../../../components/AppComponents/Autoloka/document/DeliveryOrder/view";
import DeleteConfirmationModal from "../../../../components/modals/deleteConfirmation";

import MDSnackbar from "../../../../components/MDSnackbar";

import services from "../../../../service/services";

import { addCommas, cleanObject } from "../../../../utils";
import moment from "moment";
import PrintLetterDO from "../../../../components/AppComponents/Autoloka/document/DeliveryOrder/PrintLetterDO";
import { useSearchParams } from "react-router-dom";

const DeliveryOrder = ({
	setIsLoading,
	setMode,
	mode,
 }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [rows, setRows] = useState([]);
	const [rowsDisplayed, setRowsDisplayed] = useState([]);
	const [idDetail, setIdDetail] = useState(null);
	const [paginationDetail, setPaginationDetail] = useState({});
	const [isFiltered, setIsFiltered] = useState(false);
	const [requester, setRequester] = useState([]);
	const [dataFilter, setDataFilter] = useState({
		purchase_order_no: searchParams.get("purchase_order_no") || "",
		sales_order_no: searchParams.get("sales_order_no") || "",
		invoice_no: searchParams.get("invoice_no") || "",
		request_to: searchParams.get("request_to") || "",
		status: searchParams.get("status") || "",
		date_range: searchParams.get("date_range") || "",
	});
  	const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Action", accessor: "action", align: "left" },
		{ Header: "Delivery Order No", accessor: "do_no", align: "left" },
		{ Header: "Sales Order No", accessor: "so_no", align: "left" },
		{ Header: "Requester", accessor: "requester", align: "left" },
		{ Header: "Driver Name", accessor: "driver_name", align: "left" },
		{ Header: "Status DO", accessor: "status_order", align: "left" },
		{ Header: "Created Date", accessor: "created_date", align: "left" },
	];

	useEffect(() => {

		let data = {...dataFilter, page: searchParams.get("page") || 1};
		if(data?.date_range?.length > 0){
			let daterangevalue = data.date_range.split(" - ");
			data.start_date = moment(daterangevalue[0], "DD.MM.YYYY").format("YYYY.MM.DD");
			data.end_date = moment(daterangevalue[1], "DD.MM.YYYY").format("YYYY.MM.DD");
		} else {
			data.start_date = "";
			data.end_date = "";
		};
		delete data.date_range;

		let fetcher;
		if(Object.keys(cleanObject(dataFilter)).length !== 0) {
			fetcher = services.filterSalesOrder(data)
		}else {
			fetcher = services.getAllDO(searchParams.get("page") || 1)
		}

		if(mode === "index"){
			setIsLoading(true);
			Promise
				.all([
					fetcher,
					services.getRequesterDO(),
				]).then(axios.spread((...res) => {
					setIsLoading(false);
					setRows(settingRowsDataTable(res[0]?.data?.data?.list_do, 1));
					setPaginationDetail(res[0]?.data?.data);
					setRequester(res[1]?.data?.data);
				})).catch(errors => {
					setIsLoading(false);
					console.log("Error is found, apologies.")
					console.log(errors)
				})
		}
	}, [mode]);

	const settingRowsDataTable = (rowsArray, current_page) => {
		return rowsArray?.map((arr, i) => {
			return {
				no: ((current_page - 1) * 10) + i + 1,
				action: <Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => {setMode("view"); setIdDetail(arr.id)}}>visibility</Icon>,
				do_no: arr?.do_no,
				requester: arr?.requester,
				driver_name: arr?.driver_name,
				so_no: arr?.so_no?.join(','),
				status_order: arr?.status_order === 1 ? 'Open' : 'Close',
				created_date: moment(arr?.created_date).format('DD-MM-yyyy'),
			}
		})
	};

	const getList = (page) => {
		setIsLoading(true);
		setIsFiltered(false);
		services.getAllDO(page)
			.then(res => {
				setIsLoading(false);
				setIsFiltered(false);
				setRows(settingRowsDataTable(res?.data?.data?.list_do, res?.data?.data?.current_page));
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	};

  return (
		<>
			<Grid container spacing={6} className="grid-pure-container">
				{
					mode === "index" ?
						<DeliveryOrderIndex 
						setDataFilter={setDataFilter}
						dataFilter={dataFilter}
							columns={columns} 
							rows={rows}
							rowsDisplayed={rowsDisplayed}
							setRowsDisplayed={setRowsDisplayed}
							setIsLoading={setIsLoading}
							paginationDetail={paginationDetail}
							setPaginationDetail={setPaginationDetail}
							isFiltered={isFiltered}
							setIsFiltered={setIsFiltered}
							getList={getList}
							settingRowsDataTable={settingRowsDataTable}
							setMode={setMode}
						/>
					: mode === "add" ?
						<DeliverOrderAdd 
							setMode={setMode}
							setIsLoading={setIsLoading}
							requester={requester}
						/>
					: mode === "view" ?
						<DeliveryOrderView 
						setMode={setMode}
							setIsLoading={setIsLoading} 
							id={idDetail} 
						/>
					: null
				}
			</Grid>
		</>
  );
}

export default DeliveryOrder;
