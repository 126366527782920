import { useEffect, useState } from "react";
import axios from "axios";
import moment from 'moment';
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import SalesOrderIndex from "../../../../components/AppComponents/Autoloka/document/SalesOrder";
import SalesOrderAdd from "../../../../components/AppComponents/Autoloka/document/SalesOrder/add";
import SalesOrderEdit from "../../../../components/AppComponents/Autoloka/document/SalesOrder/edit";
import SalesOrderView from "../../../../components/AppComponents/Autoloka/document/SalesOrder/view";
import DeleteConfirmationModal from "../../../../components/modals/deleteConfirmation";

import MDSnackbar from "../../../../components/MDSnackbar";

import services from "../../../../service/services";

import { addCommas, cleanObject } from "../../../../utils";
import { useSearchParams } from "react-router-dom";
import useFetchState from "../../../../hooks/useFetchState";

const SalesOrder = ({
	setIsLoading,
	setMode,
	mode,
}) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [rows, setRows] = useState([]);
	const [rowsDisplayed, setRowsDisplayed] = useState([]);
	const [idDetail, setIdDetail] = useState(null);
	const [paginationDetail, setPaginationDetail] = useState({});
	const [isFiltered, setIsFiltered] = useState(false);
	const [requestFromOptions, setRequestFromOptions] = useState([]);
	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [confirmationDisplay, setConfirmationDisplay] = useState(false);
	const [dataFilter, setDataFilter] = useState({
		purchase_order_no: searchParams.get("product_name") || "",
		sales_order_no: searchParams.get("product_name") || "",
		invoice_no: searchParams.get("product_name") || "",
		request_from: searchParams.get("product_name") || "",
		status: searchParams.get("product_name") || "",
		date_range: searchParams.get("product_name") || "",
	});

	const columns = [
		{ Header: "No", accessor: "no", align: "left" },
		{ Header: "Action", accessor: "action", align: "left" },
		{ Header: "Purchase Order No", accessor: "po_no", align: "left" },
		{ Header: "Sales Order No", accessor: "so_no", align: "left" },
		{ Header: "Invoice No", accessor: "inv_no", align: "left" },
		{ Header: "Requester", accessor: "request_from", align: "left" },
		{ Header: "Status Order", accessor: "status_order", align: "left" },
		{ Header: "Grand Total", accessor: "grand_total", align: "left" },
		{ Header: "Created Date", accessor: "date", align: "left" },
	];

	useEffect(() => {
		let data = { ...dataFilter, page: searchParams.get("page") || 1 };
		if (data?.date_range?.length > 0) {
			let daterangevalue = data.date_range.split(" - ");
			data.start_date = moment(daterangevalue[0], "DD.MM.YYYY").format("YYYY.MM.DD");
			data.end_date = moment(daterangevalue[1], "DD.MM.YYYY").format("YYYY.MM.DD");
		} else {
			data.start_date = "";
			data.end_date = "";
		};
		delete data.date_range;

		let fetcher;
		if (Object.keys(cleanObject(dataFilter)).length !== 0) {
			fetcher = services.filterSalesOrder(data)
		} else {
			fetcher = services.getSalesOrder(searchParams.get("page") || 1)
		}

		if (mode === "index") {
			setIsLoading(true);
			Promise
				.all([
					fetcher,
					services.getRequestFromOptions(),
				]).then(axios.spread((...res) => {
					setIsLoading(false);
					setRows(settingRowsDataTable(res[0]?.data?.data?.list_so, 1));
					setPaginationDetail(res[0]?.data?.data);
					setRequestFromOptions(res[1]?.data?.data);
				})).catch(errors => {
					setIsLoading(false);
					console.log("Error is found, apologies.")
					console.log(errors)
				})
		}
	}, [mode]);

	const settingRowsDataTable = (rowsArray, current_page) => {
		return rowsArray?.map((arr, i) => {
			return {
				no: ((current_page - 1) * 10) + i + 1,
				action:
					arr?.status_order === 0 ?
						<>
							<Icon style={{ cursor: "pointer" }} fontSize="small" onClick={() => { setMode("edit"); setIdDetail(arr.id) }}>edit</Icon>
							<Icon style={{ cursor: "pointer" }} fontSize="small" onClick={() => { setIdDetail(arr.id); setConfirmationDisplay(true); }}>delete</Icon>
						</>
						:
						<Icon style={{ cursor: "pointer" }} fontSize="small" onClick={() => { setMode("view"); setIdDetail(arr.id) }}>visibility</Icon>
				,
				po_no: arr?.po_no.join(", "),
				so_no: arr?.so_no,
				inv_no: arr?.inv_no,
				request_from: arr?.request_from,
				status_order: arr?.status_order === 0 ? "Draft" : "Submitted",
				grand_total: "Rp" + addCommas(arr?.grand_total),
				date: arr?.date,
			}
		})
	};

	const getList = (page) => {
		setIsLoading(true);
		setIsFiltered(false);
		services.getSalesOrder(page)
			.then(res => {
				setIsLoading(false);
				setIsFiltered(false);
				setRows(settingRowsDataTable(res?.data?.data?.list_so, res?.data?.data?.current_page));
				setPaginationDetail(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors)
			})
	};

	const deleteDraft = (id) => {
		setIsLoading(true);
		setShowNotifSuccess(false);
		services.deleteDraftSalesOrder(id)
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
				setConfirmationDisplay(false);
				getList(1);
			}).catch(errors => {
				setIsLoading(false);
				console.log("Error is found, apologies.")
				console.log(errors);
			})
	};

	return (
		<>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="Purchase Order Draft Deleted!"
				dateTime="Just Now"
				open={showNotifSuccess}
				close={() => setShowNotifSuccess(false)}
			/>
			<Grid container spacing={6} className="grid-pure-container">
				{
					mode === "index" ?
						<SalesOrderIndex
							setDataFilter={setDataFilter}
							dataFilter={dataFilter}
							columns={columns}
							rows={rows}
							rowsDisplayed={rowsDisplayed}
							setRowsDisplayed={setRowsDisplayed}
							setIsLoading={setIsLoading}
							paginationDetail={paginationDetail}
							setPaginationDetail={setPaginationDetail}
							isFiltered={isFiltered}
							setIsFiltered={setIsFiltered}
							getList={getList}
							settingRowsDataTable={settingRowsDataTable}
							setMode={setMode}
						/>
						: mode === "add" ?
							<SalesOrderAdd
								setMode={setMode}
								setIsLoading={setIsLoading}
								requestFromOptions={requestFromOptions}
							/>
							: mode === "edit" ?
								<SalesOrderEdit
									setMode={setMode}
									setIsLoading={setIsLoading}
									requestFromOptions={requestFromOptions}
									id={idDetail}
								/>
								: mode === "view" ?
									<SalesOrderView
										setMode={setMode}
										setIsLoading={setIsLoading}
										id={idDetail}
									/>
									: null
				}
			</Grid>
			<DeleteConfirmationModal open={confirmationDisplay} setOpen={setConfirmationDisplay} itemName="Sales Order" deleteHandler={() => deleteDraft(idDetail)} />
		</>
	);
}

export default SalesOrder;
