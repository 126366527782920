import { useEffect, useRef, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Autocomplete, Chip, createFilterOptions, MenuItem, TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDInput from "../../../../MDInput";
import MDButton from "../../../../MDButton";
import MDSnackbar from "../../../../MDSnackbar";
import MDTypography from "../../../../MDTypography";

import services from "../../../../../service/services";

import { addCommas, getImageError } from "../../../../../utils";

import placeholder from "../../../../../assets/images/placeholder-image.jpeg";

const ItemPriceAdd = ({
	setIsLoading,
	productOptions,
	listWarehouse
}) => {

	const [showNotifSuccess, setShowNotifSuccess] = useState(false);
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [product, setProduct] = useState({});
	const [productDetail, setProductDetail] = useState(null);
	const [warehouse, setWarehouse] = useState(null);

	const { register, handleSubmit, setValue, control, reset, formState: { errors } } = useForm({ mode: "all" });

	useEffect(() => {
		if (product.id) {
			setIsLoading(true);
			services.getDetailProductInfo(product.id)
				.then(res => {
					setIsLoading(false);
					setProductDetail(res.data.data);
				}).catch(errors => {
					setIsLoading(false);
					setErrorMessage(errors?.response?.data?.message);
					setShowNotifError(true);
					console.log(errors)
				})
		} else {
			setProductDetail(null)
		}
	}, [product])

	const submitCreate = (e) => {
		if (!warehouse) {
			setErrorMessage("Select warehouse first");
			setShowNotifError(true);
			return;
		}
		setErrorMessage();
		setShowNotifError(false);
		setIsLoading(true);
		let data = {
			product_id: product.id,
			warehouse_id: warehouse,
			price: e.price.replaceAll(".", ""),
		};
		services.createProductPrice(data)
			.then(res => {
				setIsLoading(false);
				setShowNotifSuccess(true);
				setProduct({});
				reset({
					product_name: "",
					product_code: "",
					price: "",
				}, {
					keepValues: false,
				})
			}).catch(errors => {
				setIsLoading(false);
				setErrorMessage(errors?.response?.data?.message);
				setShowNotifError(true);
				console.log(errors)
			})
	};

	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		limit: 100,
	});

	return (
		<Grid item xs={12}>
			<MDBox display="flex" alignItems="center" justifyContent="space-between" mt={-0.5} mb={2.5} width="100%">
				<MDTypography variant="h3" fontWeight="regular">
					Add New Product Price
				</MDTypography>
				<MDButton color="info" type="submit" form="create-price">
					Save
				</MDButton>
			</MDBox>
			<MDSnackbar
				color="success"
				icon="done"
				title="Autoloka"
				content="New Product Price Added!"
				open={showNotifSuccess}
				dateTime="Just Now"
				close={() => setShowNotifSuccess(false)}
			/>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			<MDBox component="form" role="form" id="create-price" onSubmit={handleSubmit(submitCreate)}>
				<Grid container spacing={3}>
					<Grid xs={12} lg={8} item>
						<Card sx={{ padding: "24px 32px" }}>
							<Grid container spacing={2}>
								<Grid item xs={12} lg={12}>
									<MDTypography variant="h4" fontWeight="regular" sx={{ mb: 4 }}>Product Information</MDTypography>
								</Grid>
								<Grid item xs={12} lg={8}>
									<Autocomplete
										options={productOptions}
										getOptionLabel={(option) => option.product_name}
										size="small"
										filterOptions={filterOptions}
										renderInput={(params) => (
											<MDInput label="Product Name" {...register("product_name", { required: "Required" })} error={errors?.product_name} {...params} />
										)}
										onChange={(e, newValue) => {
											setValue("product_name", newValue.product_name);
											setValue("product_code", newValue.product_code);
											setProduct(newValue);
										}}
										onInputChange={(event, newInputValue, reason) => {
											if (reason === "clear") {
												setValue("product_name", "");
												setValue("product_code", "");
												setProduct({});
												return
											}
										}}
										value={product?.product_name ? product : null}
										className={errors.product_name ? "autocomplete-error" : ""}
									/>
									{errors?.product_name &&
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.product_name.message}</MDTypography>
										</MDBox>
									}
								</Grid>
								<Grid item xs={12} lg={4}>
									<Autocomplete
										options={productOptions}
										getOptionLabel={(option) => option.product_code}
										size="small"
										renderInput={(params) => (
											<MDInput label="Product Code" {...register("product_code", { required: "Required" })} error={errors?.product_code} {...params} />
										)}
										onChange={(e, newValue) => {
											setValue("product_name", newValue.product_name);
											setValue("product_code", newValue.product_code);
											setProduct(newValue);
										}}
										onInputChange={(event, newInputValue, reason) => {
											if (reason === "clear") {
												setValue("product_name", "");
												setValue("product_code", "");
												setProduct({});
												return
											}
										}}
										value={product?.product_code ? product : null}
										className={errors.product_code ? "autocomplete-error" : ""}
									/>
									{errors?.product_code &&
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.product_code.message}</MDTypography>
										</MDBox>
									}
								</Grid>
								<Grid item xs={12} lg={8}>
									<Controller name="price" control={control} render={(props) => {
										return <MDInput label="Price (IDR)" fullWidth value={props.field.value} error={props.formState?.errors?.price}
											{...register("price",
												{
													required: "Required",
													pattern: {
														value: /^([1-9]{1}[0-9]{0,2})(\.{1}\d{3})*$/,
														message: "Format error"
													}
												}
											)}
											onChange={(e) => {
												setValue("price", addCommas(e.target.value));
											}
											} />
									}} />
									{errors?.price &&
										<MDBox mt={1} mb={-1} display="flex" justifyContent="flex-end">
											<MDTypography fontWeight="bold" sx={{ color: "red", fontSize: "10px" }}>{errors.price.message}</MDTypography>
										</MDBox>
									}
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput
										id="select-is-active"
										size="small"
										fullWidth
										label="Warehouse"
										type="select"
										select
										value={warehouse}
										onChange={(e) => setWarehouse(e.target.value)}
									>
										{listWarehouse?.map((wh) => (
											<MenuItem value={wh.warehouse_id}>
												{wh.warehouse_name}
											</MenuItem>
										))}
									</MDInput>
								</Grid>
								<Grid item xs={12} lg={8}>
									<MDInput disabled type="text" label="Product Description" value={productDetail?.product_desc ? productDetail.product_desc : ""} fullWidth />
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput disabled type="text" label="Molding Code" value={productDetail?.molding_code ? productDetail?.molding_code : ""} fullWidth />
								</Grid>
								<Grid item xs={12} sm={8}>
									<MDInput disabled type="text" label="Status" value={productDetail?.status == 1 ? "Active" : productDetail?.status == 0 ? "Not Active" : ""} fullWidth />
								</Grid>
							</Grid>
							<Grid item xs={12} sm={12}>
								<MDBox pt={2}>
									<MDTypography variant="h4" fontWeight="regular" sx={{ mb: 4 }}>
										Product Literation
									</MDTypography>
								</MDBox>
							</Grid>
							<Grid container spacing={2}>
								<Grid item xs={12} lg={8}>
									<MDInput disabled type="text" label="Brand Name" fullWidth value={productDetail?.brand_name ? productDetail.brand_name : ""} />
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput disabled type="text" label="Brand Code" fullWidth value={productDetail?.brand_code ? productDetail.brand_code : ""} />
								</Grid>
								<Grid item xs={12} lg={8}>
									<MDInput disabled type="text" label="Model Name" fullWidth value={productDetail?.model_name ? productDetail.model_name : ""} />
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput disabled type="text" label="Model Code" fullWidth value={productDetail?.model_code ? productDetail.model_code : ""} />
								</Grid>
								<Grid item xs={12} lg={8}>
									<MDInput disabled type="number" label="Model Year" fullWidth value={productDetail?.model_year ? productDetail.model_year : ""} />
								</Grid>
								<Grid item xs={12} lg={8}>
									<MDInput disabled type="text" label="Category Name" fullWidth value={productDetail?.category_name ? productDetail.category_name : ""} />
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput disabled type="text" label="Category Type" fullWidth value={productDetail?.category_type ? productDetail.category_type : ""} />
								</Grid>
								<Grid item xs={12} lg={8}>
									<MDInput disabled type="text" label="Subcategory Name" fullWidth value={productDetail?.subcategory_name ? productDetail.subcategory_name : ""} />
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput disabled type="text" label="Subcategory Code" fullWidth value={productDetail?.subcategory_code ? productDetail.subcategory_code : ""} />
								</Grid>
								<Grid item xs={12} lg={8}>
									<MDInput disabled type="text" label="Product Style Name" fullWidth value={productDetail?.style_name ? productDetail.style_name : ""} />
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput disabled type="text" label="Product Style Code" fullWidth value={productDetail?.style_code ? productDetail.style_code : ""} />
								</Grid>
								<Grid item xs={12} lg={8}>
									<MDInput disabled type="text" label="Attribute Name" fullWidth value={productDetail?.attribute_name ? productDetail.attribute_name : ""} />
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput disabled type="text" label="Attribute Code" fullWidth value={productDetail?.attribute_code ? productDetail.attribute_code : ""} />
								</Grid>
								<Grid item xs={12} lg={8}>
									<MDInput disabled type="text" label="Subattribute Name" fullWidth value={productDetail?.subattribute_name ? productDetail.subattribute_name : ""} />
								</Grid>
								<Grid item xs={12} lg={4}>
									<MDInput disabled type="text" label="Subattribute Code" fullWidth value={productDetail?.subattribute_code ? productDetail.subattribute_code : ""} />
								</Grid>
							</Grid>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		</Grid>
	);
}

export default ItemPriceAdd;
