import { useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import DateRangePicker from "react-daterange-picker";
import moment from 'moment';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { MenuItem, InputAdornment, Icon } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../MDBox";
import MDInput from "../../../../MDInput";
import MDButton from "../../../../MDButton";
import MDTypography from "../../../../MDTypography";
import MDSnackbar from "../../../../MDSnackbar";

import services from "../../../../../service/services";

import { addCommas } from "../../../../../utils";

const LoyaltyView = ({
	setMode,
	setIsLoading,
	id
}) => {

	const [detailData, setDetailData] = useState({});
	const [showNotifError, setShowNotifError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	useEffect(() => {
		setIsLoading(true);
		services.getLoyaltyDetail(id)
			.then(res => {
				setIsLoading(false);
				setDetailData(res?.data?.data);
			}).catch(errors => {
				setIsLoading(false);
				setErrorMessage(errors?.response?.data?.message);
				setShowNotifError(true);
			})
	}, [id])

	const backClickHandler = () => {
		setMode("index");
	}

  return (
		<Grid item xs={12}>
			<MDBox mb={5} width="100%">
				<MDBox display="flex" alignItems="center">
					<button onClick={backClickHandler} style={{border: 'none', background: 'white', borderRadius: '5px', marginRight: '1rem',width: '2rem', height: '2rem',display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
						<Icon fontSize="medium">
							arrow_back
						</Icon>
					</button>
					<MDTypography variant="h3" fontWeight="regular">
						Detail Loyalty
					</MDTypography>
				</MDBox>
			</MDBox>
			<MDSnackbar
				color="error"
				icon="error"
				title="Autoloka"
				content={errorMessage}
				open={showNotifError}
				dateTime="Just Now"
				close={() => setShowNotifError(false)}
			/>
			<MDBox maxWidth="800px" component="form" role="form">
				<Card sx={{ padding: "24px 32px" }}>
					<Grid container spacing={3} justifyContent="flex-end" sx={{ mb: 5 }}>
						<Grid item xs={12} md={8}>
							<MDInput label="Loyalty Name" disabled value={detailData?.name} fullWidth InputLabelProps={{ shrink: true }} />
						</Grid>
						<Grid item xs={12} md={4}>
							<MDInput
								id="select-is-active" 
								size="small" 
								fullWidth 
								label="Loyalty Type"
								type="select" 
								select
								value={detailData?.type || ""}
								disabled
							>
								<MenuItem disabled value="">
									<em>Neither</em>
								</MenuItem>
								<MenuItem value="0">First Customer</MenuItem>
								<MenuItem value="1">Daily Trx</MenuItem>
								<MenuItem value="2">Top Spender</MenuItem>
							</MDInput>
						</Grid>
						<Grid item xs={12} md={8}>
							<MDInput label="Starting Price" fullWidth disabled value={addCommas(detailData?.starting_price)} />
						</Grid>
						<Grid item xs={12} md={4}>
							<MDInput
								sx={{ backgroundColor: "#FFF", borderRadius: "8px" }}
								size='small'
								label="Effective Date"
								InputProps={{
									endAdornment: 
									<InputAdornment position="end">
										<Icon fontSize="small">calendar_today</Icon>
									</InputAdornment>,
								}}
								disabled
								fullWidth
								value={`${moment(detailData?.start_date).format('DD.MM.YYYY')} - ${moment(detailData?.end_date).format('DD.MM.YYYY')}`}
								InputLabelProps={{ shrink: detailData?.start_date?.length > 0 || detailData?.end_date?.length > 0 }}
							/>
						</Grid>
						<Grid item xs={12} md={8}>
							<MDInput label="End Price" fullWidth disabled value={addCommas(detailData?.end_price)} />
						</Grid>
						<Grid item xs={12} md={4}>
							<MDInput
								id="select-category-name" 
								size="small" 
								fullWidth 
								label="Multiple"
								type="select" 
								select
								disabled
								value={String(detailData?.is_multiple) || ""}
							>
								<MenuItem disabled value="">
									<em>Neither</em>
								</MenuItem>
								<MenuItem value="1">YES</MenuItem>
								<MenuItem value="0">NO</MenuItem>
							</MDInput>
						</Grid>
						<Grid item xs={12} md={8}>
							<MDInput type="text" label="Max User Per Day" fullWidth disabled value={detailData.max_user_per_day} InputLabelProps={{ shrink: true }} />
						</Grid>
						<Grid item xs={12} md={4}>
							<MDInput label="Point" fullWidth disabled value={addCommas(detailData.point)} />
						</Grid>
						<Grid item xs={12} md={4}>
							<MDInput label="Max Point" fullWidth disabled value={addCommas(detailData.max_point)} />
						</Grid>
					</Grid>
				</Card>
			</MDBox>
		</Grid>
  );
}

export default LoyaltyView;
