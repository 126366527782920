import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';

import MDTypography from '../../../../MDTypography';
import MDInput from '../../../../MDInput';
import MDButton from '../../../../MDButton';
import MDBox from '../../../../MDBox';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  width: 'fit-content',
	maxWidth: '90vw',
  p: 3,
	borderRadius: "12px",
};

const FilterModal = ({ 
	open, 
	setOpen,
	filterList,
	dataFilter,
	setDataFilter,
}) => {

	const handleClose = () => {
		setOpen(false);
	};

	const handleFilter = () => {
		handleClose();
		filterList(1);
	}

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
				keepMounted
      >
        <Box className="grid-modal" sx={style}>
					<MDBox position="relative">
						<Icon style={{ position: "absolute", right: "-12px", top: "-12px" }} className="c-pointer" onClick={handleClose} fontSize="small">close</Icon>
					</MDBox>
          <MDTypography variant="h4">
						Filter
					</MDTypography>
					<Grid container spacing={3} sx={{ width: "800px", maxWidth: "90vw", marginTop: "0", marginBottom: "24px"}}>
						<Grid item xs={12} sm={6}>
							<MDInput type="number" label="Order Number" onChange={(e) => setDataFilter({...dataFilter, order_number: e.target.value})} fullWidth />
						</Grid>
						<Grid item xs={12} sm={6}>
							<MDInput type="text" label="Invoice No" onChange={(e) => setDataFilter({...dataFilter, invoice_no: e.target.value})} fullWidth />
						</Grid>
						<Grid item xs={12} sm={6}>
							<MDInput type="text" label="Client Name" onChange={(e) => setDataFilter({...dataFilter, client_name: e.target.value})} fullWidth />
						</Grid>
						<Grid item xs={12} sm={6}>
							<MDInput 
								onChange={e => setDataFilter({...dataFilter, order_status: e.target.value})}
								value={dataFilter.status} 
								id="select-brand-type" 
								size="small" 
								fullWidth 
								label="Status Order" 
								type="select" 
								select
							>
								<MenuItem value={""}>Any</MenuItem>
								<MenuItem value="1">Waiting</MenuItem>
								<MenuItem value="2">Packing</MenuItem>
								<MenuItem value="3">On Hold</MenuItem>
								<MenuItem value="4">Ready to Deliver</MenuItem>
								<MenuItem value="5">On Deliver</MenuItem>
								<MenuItem value="6">Delivered</MenuItem>
								<MenuItem value="7">Confirmed By User</MenuItem>
								<MenuItem value="8">Cancelled</MenuItem>
							</MDInput>
						</Grid>
						<Grid item xs={12} sm={6}>
							<MDInput 
								onChange={e => setDataFilter({...dataFilter, payment_status: e.target.value ? parseInt(e.target.value) : e.target.value})}
								value={dataFilter.status} 
								id="select-brand-type" 
								size="small" 
								fullWidth 
								label="Status Payment" 
								type="select" 
								select
							>
								<MenuItem value={""}>Any</MenuItem>
								<MenuItem value="0">Waiting for Payment</MenuItem>
								<MenuItem value="1">Checking Payment Status</MenuItem>
								<MenuItem value="2">Approve by Admin</MenuItem>
								<MenuItem value="3">Reject by Admin</MenuItem>
							</MDInput>
						</Grid>
					</Grid>
					<MDBox display="flex" alignItems="center" justifyContent="end">
						<MDButton onClick={handleFilter} variant="gradient" color="info">Filter</MDButton>
					</MDBox>
        </Box>
      </Modal>
    </div>
  );
}

export default FilterModal;