import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from "js-cookie";

import AppBox from '../components/AppBox';
import Header from '../components/Header';

const MyApps = ({
	myAppsList, 
	setAppActive, 
	appActive, 
	setPrimaryColor, 
	menuActive, 
	setMenuActive,
	setMode
}) => {
	
	const navigate = useNavigate();

	const handleDashboard = (app) => {
		setPrimaryColor(app.apps_theme_color);
		Cookies.set("appActive", app.apps_name.toLowerCase().replace(" ", "-"));
		setAppActive(app.apps_name.toLowerCase().toLowerCase().replace(" ", "-"));
		Cookies.set("menuActive", app.apps_menu[0].dropdown ? app.apps_menu[0].collapse[0].route : app.apps_menu[0].route);
		setMenuActive(app.apps_menu[0].dropdown ? app.apps_menu[0].collapse[0].route : app.apps_menu[0].route);
	};

	return (
		<>
			<Header setMenuActive={setMenuActive} setAppActive={setAppActive} setMode={setMode} />
			<div className='apps'>
				{ 
					myAppsList
						.map((fl, i) => {
							return(
								<AppBox key={`app-container-`+i} app={fl} setPrimaryColor={setPrimaryColor} count={i+1} onClick={() => handleDashboard(fl)}/>
							)
						})
					}
				<div className='app-container-inactive'>
					<div className="app mb-1" onClick={() => navigate('/all-apps')}>
						<FontAwesomeIcon icon="fa-solid fa-cubes" />
					</div>
					Apps
				</div>
				<div className='app-container-inactive'>
					<div className="app mb-1" onClick={() => navigate('/setting')}>
						<FontAwesomeIcon icon="fa-solid fa-gear" />
					</div>
					Setting
				</div>
			</div>
		</>
	)
}
	
export default MyApps;